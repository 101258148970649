import React, {Component} from "react";

import "../components/components.scss";

export default class ViewComponents extends Component {
  render() {
    return (
      <div>
        <h1> Alerts </h1>
      </div>
    );
  }
}
