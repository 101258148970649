import React, {Component} from 'react';
import './Tabs.scss';

class Tabs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true
        };

        this.togglePanel = this.togglePanel.bind(this);

    }

    togglePanel(e) {
        this.setState({open: !this.state.open})
    }

    render() {
        return (

            <div>
                <div onClick={(e) => this.togglePanel(e)} className='header'>
                    <ul className='tabname'>
                        {this.props.title}
                    </ul>
                </div>

                <div className='tabcontent'>
                    {!this.state.open ? (
                        <div className='col-md-8 tabs-content'>
                            {this.props.children}
                        </div>
                    ) : null}
                </div>

            </div>

        );
    }

}

export default Tabs;