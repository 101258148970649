import {createBrowserHistory} from "history";
const tinyQS = require("tiny-query-string");

const history = createBrowserHistory();

export const addQuery = (query, routerHistory) => {
  let _history = routerHistory || history;
  const oldQuery = tinyQS.getAll(_history.location.search);
  const newQuery = {...oldQuery, ...query};
  const search = tinyQS.setMany(newQuery, "");
  _history.replace({
    ..._history.location,
    search
  });
};

export const getAddedQuery = (query, routerHistory) => {
  let _history = routerHistory || history;
  const oldQuery = tinyQS.getAll(_history.location.search);
  const newQuery = {...oldQuery, ...query};
  const search = tinyQS.setMany(newQuery, "");
  return search;
};

export const getQueryParams = search => {
  if (search) {
    return tinyQS.getAll(search);
  }
  return tinyQS.getAll(history.location.search);
};

export default history;
