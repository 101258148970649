import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";
import {BtnRectangle, BlogFeatures} from "../../components";
import "./blog-post-details.scss";
import BlogImageList from "../../components/BlogImageList";

export default class SearchResultsBlog extends Component {
  render() {
    return (
      <div>
        <div>
          <img
            className="nuclei-image-top zero-padding"
            src={require("../../assets/Blog-Post.png")}
            alt="..."
          />
        </div>
        <div id="headStyle">
          <span className="label label-primary blog-post-lable">FEATURED</span>
          <h2>Hatton National Bank Exclusive Offer</h2>
          <p id="sign">By Jane Doe | 25th February 2018 </p>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12 zero-padding content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur
            adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea
          </p>
        </div>
        <br />

        <div className="zero-padding">
          <div className="nuclei-blog-img zero-padding-left">
            <BlogImageList
              blogImages={[
                {
                  imgUrl:
                    "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/ads2.jpg?alt=media&token=309ad67b-e0e6-4fbc-a744-df095d37f8d3",
                  imgLink: "Home"
                },
                {
                  imgUrl:
                    "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/ads1.jpg?alt=media&token=bb2b4b79-59c5-4073-948c-7a35c224485c",
                  imgLink: "/"
                }
              ]}
              twoImg
            />
          </div>

          <div className="col-md-12 col-sm-12 col-xs-12 zero-padding content">
            <p>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur
              adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum
              dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet,
              consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            </p>
            <br />
          </div>

          <div className="col-md-12 col-sm-12 col-xs-12 zero-padding">
            <p id="Text">Share This:</p>

            <Col id="socialIcon">
              <a href="#" title="Click here">
                {" "}
                <img
                  src={require("../../assets/facebook.png")}
                  alt="fb"
                  id="facebook"
                />{" "}
              </a>
              <a href="#" title="Click here">
                {" "}
                <img
                  src={require("../../assets/twitter1.png")}
                  alt="px"
                  id="twitter"
                />
              </a>
              <a href="#" title="Click here">
                {" "}
                <img src={require("../../assets/link.png")} alt="link" />
              </a>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}
