import axios from "axios";
import {INME_HOME_API, WEB_IMAGE} from "../../config";

const LOADING_DATA = "LOADING_DATA";
const GET_SLIDER_DATA = "GET_SLIDER_DATA";
const GET_MULTIPLE_CAROUSEL_DATA = "GET_MULTIPLE_CAROUSEL_DATA";
const GET_FEATURE_CARD_DATA = "GET_FEATURE_CARD_DATA";
const GET_USER_DATA = "GET_USER_DATA";
const GET_BLOG_IMAGE_DATA = "GET_BLOG_IMAGE_DATA";
const GET_INSUREME_PARTNERS_DATA = "GET_INSUREME_PARTNERS_DATA";
const GET_BLOG_DATA = "GET_BLOG_DATA";


const initialState = {
  loading: false,
  sliderData: null,
  multipleCarouselData: null,
  featureCardData: null,
  userData: null,
  blogImageData: null,
  insureMePartnersData: null,
  blogData: null
};

const sliderData = {
  carouselImage: [],
  dockPanel: [
    {
      gridTile: {
        header: "Car & SUV",
        style: {backgroundColor: "#e8188e"},
        gridTileImg: `${WEB_IMAGE  }/web/car-2019-09-20-v1.png`,
        bottomText: "Compare & Buy",
        Url: "/motor/Car/full-insurance/Quotation"
      }
    },
    {
      gridTile: {
        header: "Motor Third Party",
        style: {backgroundColor: "#df1cf2"},
        gridTileImg:
            `${WEB_IMAGE  }/web/motorCycle-2019-09-20-v1.png`,
        bottomText: "Compare & Buy",
        Url: "/motor/Motor-Cycle/third-party/Quotation"
      }
    },
    {
      gridTile: {
        header: "Start-UPS & SME",
        style: {backgroundColor: "#dad53d"},
        gridTileImg: `${WEB_IMAGE  }/web/health-2019-09-20-v1.png`,
        bottomText: "Compare & Buy",
        Url: "/start-ups-&-sme"
      }
    },
    {
      gridTile: {
        header: "Major Illnesses",
        style: {backgroundColor: "#e8c243"},
        gridTileImg:
          `${WEB_IMAGE  }/web/CI_icon-2022-03-25-v1.webp`,
        bottomText: "Compare & Buy",
        Url: "https://contact-us.insureme.lk"
      }
    },
    {
      gridTile: {
        header: "Senior Citizens",
        style: {backgroundColor: "#e86d49"},
        gridTileImg: `${WEB_IMAGE  }/web/ss-2019-09-20-v1.png`,
        bottomText: "Compare & Buy",
        Url: "https://senior-citizen.insureme.lk"
      }
    },
    {
      gridTile: {
        header: "Three Wheeler",
        style: {backgroundColor: "#a522f4"},
        gridTileImg:
          `${WEB_IMAGE  }/web/threeWeeler-2019-09-20-v1.png`,
        bottomText: "Compare & Buy",
        Url: "/motor/Three-Wheeler/full-insurance/Quotation"
      }
    },
    {
      gridTile: {
        header: "Van & Cab",
        style: {backgroundColor: "#4c8fef"},
        gridTileImg: `${WEB_IMAGE  }/web/van-2019-09-20-v1.png`,
        bottomText: "Compare & Buy",
        Url: "/motor/Dual-Purpose/full-insurance/Quotation"
      }
    },
    {
      gridTile: {
        header: "Other Vehicles",
        style: {backgroundColor: "#1edf86"},
        gridTileImg: `${WEB_IMAGE  }/web/Other-2019-09-20-v1.png`,
        bottomText: "Compare & Buy",
        Url: "https://contact-us.insureme.lk"
      }
    },
    {
      gridTile: {
        header: "Life",
        style: {backgroundColor: "#9adc45"},
        gridTileImg: `${WEB_IMAGE  }/web/life-2019-09-20-v1.png`,
        bottomText: "Compare & Buy",
        Url: "https://contact-us.insureme.lk"
      }
    }
    
  ]
};

const multipleCarouselData = {
  multipleImage: [
    {
      imageUrl:
        `${WEB_IMAGE 
        }/web/featured-deals/Featured-deals-ntb-2019-09-20-v1.webp`,
      Url: "promotions/online-web/motor/ntb/credit-card-offer"
    },
    // {
    //   imageUrl:
    //     `${WEB_IMAGE 
    //     }/web/featured-deals/Featured-deals-scb-2019-09-20-v1.png`,
    //   Url: "promotions/online-web/motor/scb/credit-card-offer"
    // },
    // {
    //   imageUrl:
    //     `${WEB_IMAGE 
    //     }/web/featured-deals/Featured-deals-ub-2019-09-20-v1.png`,
    //   Url: "promotions/online-web/motor/ub/credit-card-offer"
    // },
    {
      imageUrl:
        `${WEB_IMAGE 
        }/web/featured-deals/Featured-deals-ndb-2022-03-25-v1.webp`,
      Url: "promotions/online-web/motor/ndb/credit-card-offer"
    },
    {
      imageUrl:
        `${WEB_IMAGE 
        }/web/featured-deals/Featured-deals-hnb-2022-03-25-v1.webp`,
      Url: "promotions/online-web/motor/hnb/credit-card-offer"
    }
  ]
};

const featureCardData = {
  featureCard: [
    {
      shadow: true,
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/Blog3.png?alt=media&token=e1ba2a19-73aa-49b3-bef3-8a2beb428c25",
      header: "Hatton National bank",
      paragraph:
        "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
      getAQuoteUrl: "/Home",
      readMoreUrl: "/Home",
      hover: true,
      textContentStyle: {maxHeight: "40.9px"}
    },
    {
      shadow: false,
      imageUrl: "",
      header: "Hatton National bank",
      paragraph:
        "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
      getAQuoteUrl: "/",
      readMoreUrl: "/",
      badgeCaption: "FEATURED",
      badgeColor: "red",
      hover: true
    },
    {
      shadow: false,
      imageUrl: "",
      header: "Hatton National bank",
      paragraph:
        "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
      getAQuoteUrl: "/",
      readMoreUrl: "/",
      badgeCaption: "POPULAR",
      hover: true
    },
    {
      shadow: false,
      imageUrl: "",
      header: "Hatton National bank",
      paragraph:
        "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
      getAQuoteUrl: "/",
      readMoreUrl: "/",
      hover: true
    },
    {
      shadow: false,
      imageUrl: "",
      header: "Hatton National bank",
      paragraph:
        "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
      getAQuoteUrl: "/",
      readMoreUrl: "/",
      hover: true
    }
  ]
};

const userData = {
  userDetail: [
    {
      userImgUrl: `${WEB_IMAGE  }/web/profile1-2019-09-20-v1.webp`,
      userName: "Jehan",
      comment: "Very easy to use.One web platform for all the insurance needs."
    },
    {
      userImgUrl: `${WEB_IMAGE  }/web/profile2-2019-09-20-v1.webp`,
      userName: "Udith",
      comment:
        "Professional service.Highly recommend to anyone who wants to find the best insurance deal."
    },
    {
      userImgUrl: `${WEB_IMAGE  }/web/profile3-2019-09-20-v1.webp`,
      userName: "Chakshika",
      comment:
        "Compared the premium,Picked the best option, Paid online , Got my online cover note, Policy deliverd to my doorstep.Superb service by insureme."
    }
  ]
};

const blogImageData = {
  blogImages: [
    {
      imgUrl:
        "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/Blog1.png?alt=media&token=d1f370ad-e509-40e9-8634-6a162006b4ab",
      imgLink: "Home"
    },
    {
      imgUrl:
        "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/Blog2.png?alt=media&token=14fc6163-92be-4a10-81a5-481187f1d873",
      imgLink: "/"
    },
    {
      imgUrl:
        "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/Blog4.png?alt=media&token=86aac007-3712-4ee6-88b6-7c3b621acbba",
      imgLink: "/"
    }
  ]
};

const insureMePartnersData = {
  partnersImg: [
    {
      imgUrl: `${WEB_IMAGE  }/web/amana-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl: `${WEB_IMAGE  }/web/allianz-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl: `${WEB_IMAGE  }/web/ceylinco-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl: `${WEB_IMAGE  }/web/COOP-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl: `${WEB_IMAGE  }/web/fairfast-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl: `${WEB_IMAGE  }/web/HNB-assurance-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl: `${WEB_IMAGE  }/web/HNB-2019-09-20-v1.png`,
      imgLink: ""
    },
    {
      imgUrl: `${WEB_IMAGE  }/web/LOLc-2019-09-20-v1.png`,
      imgLink: ""
    },
    {
      imgUrl: `${WEB_IMAGE  }/web/orient-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl:
        `${WEB_IMAGE  }/web/Peoples-insurance-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl:
        `${WEB_IMAGE  }/web/softlogic-logo-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl:
        `${WEB_IMAGE  }/web/sri_lanka_insurance-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl:
        `${WEB_IMAGE  }/web/union-assurance-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl: `${WEB_IMAGE  }/web/aia-2019-09-20-v1.webp`,
      imgLink: ""
    },
    {
      imgUrl:
        `${WEB_IMAGE  }/web/continental.png`,
      imgLink: ""
    }
  ]
};

export const getSliderData = () => async dispatch => {
  try {
    const res = await axios.get(`${INME_HOME_API}/v1/home_data`);
    dispatch({type: LOADING_DATA, payload: true});
    sliderData.carouselImage = res.data.payload;
    const response = sliderData;
    dispatch({type: LOADING_DATA, payload: false});
    return dispatch({type: GET_SLIDER_DATA, payload: response});
  } catch (e) {
    return dispatch({type: LOADING_DATA, payload: false});
  }
};

export const getMultipleSliderData = () => async dispatch => {
  try {
    dispatch({type: LOADING_DATA, payload: true});
    const response = multipleCarouselData;
    dispatch({type: LOADING_DATA, payload: false});
    return dispatch({type: GET_MULTIPLE_CAROUSEL_DATA, payload: response});
  } catch (e) {
    return dispatch({type: LOADING_DATA, payload: false});
  }
};

export const getFeatureCardData = () => async dispatch => {
  try {
    dispatch({type: LOADING_DATA, payload: true});
    const response = featureCardData;
    dispatch({type: LOADING_DATA, payload: false});
    return dispatch({type: GET_FEATURE_CARD_DATA, payload: response});
  } catch (e) {
    return dispatch({type: LOADING_DATA, payload: false});
  }
};

export const getUserData = () => async dispatch => {
  try {
    dispatch({type: LOADING_DATA, payload: true});
    const response = userData;
    dispatch({type: LOADING_DATA, payload: false});
    return dispatch({type: GET_USER_DATA, payload: response});
  } catch (e) {
    return dispatch({type: LOADING_DATA, payload: false});
  }
};

export const getBlogImageData = () => async dispatch => {
  try {
    dispatch({type: LOADING_DATA, payload: true});
    const response = blogImageData;
    dispatch({type: LOADING_DATA, payload: false});
    return dispatch({type: GET_BLOG_IMAGE_DATA, payload: response});
  } catch (e) {
    return dispatch({type: LOADING_DATA, payload: false});
  }
};

export const getInsureMePartnersData = () => async dispatch => {
  try {
    dispatch({type: LOADING_DATA, payload: true});
    const response = insureMePartnersData;
    dispatch({type: LOADING_DATA, payload: false});
    return dispatch({type: GET_INSUREME_PARTNERS_DATA, payload: response});
  } catch (e) {
    return dispatch({type: LOADING_DATA, payload: false});
  }
};

export const getBlogData = () => async dispatch => {
  try {
    dispatch({type: LOADING_DATA, payload: true});
    const res = await axios.get(`https://blog.insureme.lk/index.php/feed/json`);
    dispatch({type: LOADING_DATA, payload: false});
    return dispatch({type: GET_BLOG_DATA, payload: res.data});
  } catch (error) {
    return dispatch({type: LOADING_DATA, payload: false});
  }
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case LOADING_DATA:
      return {...state, loading: payload};
    case GET_SLIDER_DATA:
      return {...state, sliderData: payload};
    case GET_MULTIPLE_CAROUSEL_DATA:
      return {...state, multipleCarouselData: payload};
    case GET_FEATURE_CARD_DATA:
      return {...state, featureCardData: payload};
    case GET_BLOG_DATA:
      return {...state, blogData: payload};
    case GET_USER_DATA:
      return {...state, userData: payload};
    case GET_BLOG_IMAGE_DATA:
      return {...state, blogImageData: payload};
    case GET_INSUREME_PARTNERS_DATA:
      return {...state, insureMePartnersData: payload};
    default:
      return state;
  }
}
