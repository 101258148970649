import React from "react";
import {Button} from "../components";
import "./FeatureCard.scss";
import Badge from "./Badge";
export const FeatureCard = props => {
  const {
    imageUrl,
    header,
    paragraph,
    readMoreUrl = "",
    getAQuoteUrl = "",
    shadow,
    badgeColor,
    badgeCaption,
    textContentStyle = {
      maxHeight: "40.9px"
    },
    hover = false
  } = props;
  let className = "";
  if (shadow) {
    className = " shadow ";
  }
  let hoverClass = "";
  if (hover) {
    hoverClass = " nuclei-feature-card-hover";
  }

  //TODO: Label, Card hover interaction, Image height issue
  return (
    <div className={`nuclei-feature-card ${hoverClass} ${className}`}>
      {imageUrl ? <img src={imageUrl} className="feature-image" /> : null}
      <div className="feature-body">
        <h3 className="feature-header ">
          {header}
          {badgeCaption ? (
            <sup>
              <Badge
                type="radius"
                badgeCaption={badgeCaption}
                size="small"
                color={badgeColor}
                className="nuclei-feature-badge "
              />
            </sup>
          ) : null}
        </h3>
        <p style={textContentStyle} className="text-content">
          {paragraph}
        </p>
        <div>
          {readMoreUrl ? (
            <a href={readMoreUrl} target="_blank" rel="noopener noreferrer">
              <Button type="radius" outline color="blue">
                Read More
              </Button>
            </a>
          ) : null}
          {getAQuoteUrl ? (
            <a href={getAQuoteUrl} target="_blank" rel="noopener noreferrer">
              <Button type="radius" outline color="yellow">
                Get a Quote
              </Button>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default FeatureCard;
