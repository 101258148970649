import React, {Component} from "react";
import "./GridTile.scss";

import CustomLink from "../CustomLink";
export default class GridTile extends Component {
  render() {
    return (
      <div className="grid-tile-link">
        <CustomLink to={this.props.gridTile.Url}>
          <div
            className=""
            style={this.props.gridTile.style ? this.props.gridTile.style : {}}
            id="nuclei-grid-tile"
          >
            <p className="nuclei-grid-tile-top-text">
              {this.props.gridTile.header
                ? this.props.gridTile.header
                : "Three Wheeler"}
            </p>
            <img
              src={this.props.gridTile.gridTileImg}
              className="nuclei-grid-tile-img"
              alt="..."
            />
            <p className="nuclei-grid-tile-bottom-text">
              {this.props.gridTile.bottomText
                ? this.props.gridTile.bottomText
                : "Compare & Buy"}
            </p>
          </div>
        </CustomLink>
      </div>
    );
  }
}
