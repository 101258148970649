import React from "react";
import "./components.scss";

export const QuickTagList = ({header = "", list = []}) => {
  return (
    <div className="quick-tags">
      <div className="quick-tag-header">
        <h2>{header}</h2>
      </div>
      <div className="quick-tag-lists">
        {list.map((option, i) => {
          return (
            <p key={i} className="quick-tag-list">
              {option.label}
            </p>
          );
        })}
      </div>
    </div>
  );
};
