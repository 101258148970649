import React, {Component} from "react";
import {InputText, Button, Spinner} from "../../components";
import "./promotion.scss";
import {getInquiryCookie} from "../../util";
import {validateWithValidations, isEmptyObjectValue} from "../../util";
import {
  getFeatureCardData,
  postInquiry,
  clearErrors
} from "../../redux/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

const validations = {
  cx_name: {presence: true},
  cx_email: {presence: true, email: true},
  cx_mobile: {
    format: {
      pattern: "^[0-9]{9}",
      flags: "i",
      message: "mobile is not valid. ex: 94778621045"
    },
    numericality: {
      notLessThan: "9",
      presence: true
    },
    presence: true,
    length: {
      is: 9
    }
  }
};

class Promotions extends Component {
  async componentDidMount() {}
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      cx_name: "",
      cx_email: "",
      cx_mobile: "",
      cx_message: "",
      inquiry_type: "motor-promo",
      errorMessage: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async handleSubmit() {
    const error = validateWithValidations(this.state, validations);
    this.setState({error});
    const category =
      this.props.match.params.category.charAt(0).toUpperCase() +
      this.props.match.params.category.slice(1).toLowerCase();
    const promo_type = this.props.match.params.promo_type.toUpperCase();
    const offer = this.props.match.params.offer;
    const inqueryBody = {
      cx_name: this.state.cx_name,
      cx_email: this.state.cx_email,
      cx_mobile: this.state.cx_mobile,
      cx_message: `${category}-${promo_type}-${offer}`,
      inquiry_code: "sampathpromo15",
      promo_code: "credit-card-offer",
      insurance_type: "Motor",
      inquiry_media: "web",
      inquiry_type: "promo",
      email_subject_json: `InsureMe.lk | ${promo_type}-${offer} | ${category} | 94${
        this.state.cx_mobile
      }`,
      email_body_json: {
        cx_name: this.state.cx_name,
        cx_email: this.state.cx_email,
        cx_mobile: this.state.cx_mobile,
        inquiry_code: "websampathmotpromo15",
        inquiry_media: "web",
        inquiry_type: "promo"
      },
      bank_type: this.props.match.params.promo_type
    };

    if (isEmptyObjectValue(error)) {
      this.setState({loading: true});
      await this.props.postInquiry(inqueryBody);
      this.setState({loading: false, isCompleted: true, flag: true});
    }
    this.props.clearErrors();
    this.inquiryStatus();
  }
  onChange = (type, e) => {
    const error = this.state.error;
    delete error[type];
    this.setState({errorMessage: ""});
    this.setState({[type]: e.target.value, error});
  };
  clearErrors() {
    this.setState({errorMessage: ""});
  }
  inquiryStatus() {
    const current_page = this.props.match.params.promo_type;
    const form_submissions = [{type: "sb"}];
    form_submissions.forEach((item, i) => {
      if (item.type == current_page) {
        if (localStorage.getItem(`cx_inquiry_id_${  current_page}`)) {
          this.setState({flagOne: true});
        }
      }
    });
  }
  render() {
    const {flag, flagOne} = this.state;
    const promo = this.props.match.params.promo_type;
    const isCompleted = getInquiryCookie().cx_inquiry_id;
    // const hnbInquiry = localStorage.getItem('cx_inquiry_id_hnb');
    // const ntbInquiry = localStorage.getItem('cx_inquiry_id_ntb');
    // const ubInquiry = localStorage.getItem('cx_inquiry_id_ub');
    // const pbInquiry = localStorage.getItem('cx_inquiry_id_pb');
    return (
      <div>
        <div className="container-fluid zero-padding">
          <div className="row">
            <div className="container" />
            <div>
              <img
                className="nuclei-image-top zero-padding"
                src={
                  `https://s3-ap-southeast-1.amazonaws.com/insureme-prod/web/promotions/promotion-${ 
                  promo 
                  }-landing-page.jpg`
                }
                alt="..."
              />
            </div>
            <div id="headStyle">
              {promo === "ub" ? (
                <h2>15% discount with Insureme</h2>
              ) : promo === "ntb" ? (
                <h2>10% discount with Insureme</h2>
              ) : promo === "scb" ? (
                <h2>11% discount with Insureme</h2>
              ) : promo === "ndb" ? (
                <h2>10% discount with Insureme</h2>
              ) : promo === "hsbc" ? (
                  <h2>10% discount with Insureme</h2>
              ) : promo === "hnb" ? (
                <h2>10% discount with Insureme</h2>
              ): promo === "sey" ? (
                  <h2>10% discount with Insureme</h2>
              ): promo === "dfcc" ? (
                <h2>15% discount with Insureme</h2>
              ):
              null}
            </div>

            <br />
          </div>

          <div className="col-md-8 col-sm-8 col-xs-12 zero-padding">
            {promo === "ntb" ? (
              <div className="col-md-12 col-sm-12 col-xs-12 zero-padding">
                <div className="terms-cond">
                  <h3>Terms & Conditions</h3>
                </div>
                <p className="prmo-details">
                  <ol className="terms">
                    <li>
                      The promotion is open to all holders of American Express® Credit Cards issued by Nations Trust Bank PLC. (Excluding Corporate Cards).
                      <br />
                    </li>
                    <li>
                      The promotion will be valid from 01st May 2019 to 31st December 2021. (Both days included)
                      <br />
                    </li>
                    <li>
                      The offer is valid on the website www.insureme.lk only.
                      <br />
                    </li>
                    <li>
                      The Cardmember may enjoy 10% savings on new basic annual premiums of insurance policies purchased at www.insureme.lk during the aforementioned offer period.
                      <br />
                    </li>
                    <li>
                      The Cardmember may also enjoy Up to 12 months interest free instalment plan(s) on single insurance transactions above LKR 10,000/-
                    </li>
                    <li>
                      This offer will apply for all insurance policies except for life insurance policy renewals under this promotion.
                      <br />
                    </li>
                    <li>
                      The Cardmember may also enjoy Up to 12 months interest free instalment plan(s) on single insurance transactions above LKR 10,000/-
                      <br />
                    </li>
                    <li>
                      Cardmember may select the online payment option as 6 or 12 months at the time of purchasing to obtain the instalment facility.
                      <br />
                    </li>
                    <li>
                      The standard online charges will continue to apply.
                      <br />
                    </li>
                    <li>
                      The offers cannot be exchanged for cash and/or used in conjunction with any other promotional programs or offers provided by service establishment involved in this promotion.
                      <br />
                    </li>
                    <li>
                      Nations Trust Bank PLC reserves the right to withdraw, modify or change all or any of the rules, terms & conditions applicable to this promotion at any given time without prior notice.
                      <br />
                    </li>
                    <li>
                      The authorization of the transaction is subject to the available balance and authorized limit of the credit card at the time of performing the transaction in the event the Cardmember wishes to obtain aforementioned instalment plan.
                      <br />
                    </li>
                    <li>
                      Transactions under the aforementioned promotion are permitted only in personal nature.
                      <br />
                    </li>
                    <li>
                      If any dispute arises regarding any of the terms and conditions contained herewith, the decision of the respective service establishment & Nations Trust Bank PLC shall be final.
                      <br />
                    </li>
                    <li>
                      The general Credit Card terms and conditions will continue to apply.
                      <br />
                    </li>
                    <li>
                      This offer is valid only for new InsureMe customers.
                      <br />
                    </li>
                  </ol>
                </p>
              </div>
            ) : promo === "ub" ? (
              <div className="col-md-12 col-sm-12 col-xs-12 zero-padding">
                <div className="terms-cond">
                  <h3>Terms & Conditions</h3>
                </div>
                <p className="prmo-details">
                  <ol className="terms">
                    <li>
                      The Cardmember may enjoy 15% savings on new basic annual motor premiums of insurance policies purchased
                      at insureme.lk during the aforementioned offer period. (Maximum discount is limited up to LKR 15,000)
                      <br />
                    </li>
                    <li>
                      Offer is valid from 1st Feb to 31st December 2021.
                      <br />
                    </li>
                    <li>
                      Cardholder may enjoy 3, 6 & 12 months interest free instalment plans at no extra charge
                      for transactions of LKR 25,000 & above at selected partner merchants.
                      <br />
                    </li>
                    <li>
                      To convert transactions to an instalment plan, the Cardholder should call the
                      Union Bank contact center on 011 5800 800 within 14 days from the transaction date.
                      <br />
                    </li>
                    <li>
                      Promotion will not be applicable for commercial use purchases.
                      <br />
                    </li>
                    <li>
                      The excess discount values offered at point of purchase for Cardholders, if any,
                      will be reversed subsequently and will reflect on the next Credit Card statement.
                      <br />
                    </li>
                    <li>
                      Offer is valid for online purchases.
                      <br />
                    </li>
                    <li>
                      Card holder(s) is/are to settle the total bill payment via Union Bank
                      Visa International Credit Card(s) to be eligible for the offers mentioned herein.
                      <br />
                    </li>
                    <li>
                      The offers cannot be exchanged for cash and/or used in conjunction with any other promotional
                      programs or offers provided by service establishments involved in this promotion.
                      <br />
                    </li>
                    <li>
                      Union Bank of Colombo PLC reserves the right to withdraw, modify or change all or any of the rules,
                      terms & conditions applicable to this promotion at any given time without prior notice.
                      <br />
                    </li>
                    <li>
                      Union Bank of Colombo PLC accepts no liability for the quality of
                      goods and services provided by the service establishments involved in this promotion.
                      <br />
                    </li>
                    <li>
                      The terms & conditions of the respective service establishment and Union Bank of Colombo PLC bind the promotion.
                      <br />
                    </li>
                    <li>
                      If any dispute arises regarding any of the terms and conditions contained herewith, the decision of the
                      respective service establishment and Union Bank of Colombo PLC shall be final.
                      <br />
                    </li>
                    <li>
                      The promotion is bound by the terms and conditions of the respective establishment and Union Bank PLC.
                      <br />
                    </li>
                    <li>
                      The general Credit Card terms and conditions will continue to apply.
                      <br />
                    </li>
                  </ol>
                </p>
              </div>
            ) : promo === "hsbc" ? (
                <div className="col-md-12 col-sm-12 col-xs-12 zero-padding">
                  <div className="terms-cond">
                    <h3>Terms & Conditions</h3>
                  </div>
                  <p className="prmo-details">
                    <ol className="terms">
                      <li>
                        The offer will be valid only for locally issued HSBC Credit Cards (Excluding Corporate Cards).
                        <br />
                      </li>
                      <li>
                        The offer is valid on the <a style={{textDecoration:"none"}} href="https://insureme.lk/" target="_blank">website</a> only & full
                        payment needs to be settled with a HSBC Credit Card.
                        <br />
                      </li>
                      <li>
                        The Cardmember may enjoy 10% savings on new basic annual premiums of insurance policies
                        purchased at <a href="https://insureme.lk/" style={{textDecoration:"none"}} target="_blank">www.insureme.lk</a> during the aforementioned offer period.
                        <br />
                      </li>
                      <li>
                        This offer will apply for all insurance policies except for life insurance policy renewals
                        under this promotion.
                        <br />
                      </li>
                      <li>
                        The Offer cannot be combined with any other discount/offer and on special themed promotions
                        provided by the merchant and HSBC.
                        <br />
                      </li>
                      <li>
                        Transaction value between Rs.25,000 to Rs.800,000 can be converted to a 3, 6 or 12 months 0% plan.
                        <br />
                      </li>
                      <li>
                        The eligible transaction can be converted to a 3, 6 or 12 month instalment plan
                        at 0% interest and transaction should be
                        converted within 30 days from the transaction date by using the
                        <a href="https://www.hsbc.lk/flexi" style={{textDecoration:"none"}} target="_blank">&nbsp;link&nbsp;</a>
                        or call on <a href="tel:+94114472200" style={{textDecoration:"none"}}>+94 11 447 2200</a>.
                        <br />
                      </li>
                      <li>
                        Maximum of 2 Transactions can be converted every month to the 0% plan during the promo period.
                        <br />
                      </li>
                      <li>
                        The general credit card terms and conditions of HSBC shall apply to this Offer.
                        <br />
                      </li>
                      <li>
                        HSBC at its sole discretion reserves the right to modify, change or withdraw all or
                        any of the Terms and Conditions, herein contained, applicable to this Offer at
                        any time without notice to the Cardholders.
                        <br />
                      </li>
                      <li>
                        If any dispute arises regarding any of the terms and conditions contained herein,
                        the decision of HSBC shall be final and conclusive.
                        <br />
                      </li>
                      <li>
                        These Terms and Conditions shall be governed and construed by the laws of Sri Lanka and shall be
                        subject to the exclusive jurisdiction of the Courts of Sri Lanka.
                        <br />
                      </li>
                    </ol>
                  </p>
                </div>
            ): promo === "dfcc" ? (
                <div className="col-md-12 col-sm-12 col-xs-12 zero-padding">
                  <div className="terms-cond">
                    <h3>Terms & Conditions</h3>
                  </div>
                  <p className="prmo-details">
                    <ol className="terms">
                      <li>
                        The promotion will be conducted from 01st February to 30th June 2021 on the
                        website <a href="https://insureme.lk/" style={{textDecoration:"none"}} target="_blank">“insureme.lk”</a>.
                        <br />
                      </li>
                      <li>
                        The Cardmember may enjoy 15% savings on new basic annual motor premiums of
                        insurance policies purchased at insureme.lk during the aforementioned offer period.
                        <br />
                      </li>
                      <li>
                        The maximum discount value a Cardholder can avail should be Rs. 15,000/- per day, per card.
                        <br />
                      </li>
                      <li>
                        A Cardholder can perform only two transactions during the promotion period.
                        <br />
                      </li>
                      <li>
                        Up to 12 months 0% installment plan for transactions above Rs. 25,000/- will be provided by
                        DFCC Bank at no additional cost to the Cardholder during the promotion period. The Cardholder will
                        need to contact the Bank on 0112-350000 after completing
                        the purchase at InsureMe Insurance Brokers (Pvt) Limited to convert it into an installment plan.
                        <br />
                      </li>
                      <li>
                        The goods purchased should be for personal use and not for resale.
                        <br />
                      </li>
                      <li>
                        This offer cannot be used in conjunction with other promotional offers,
                        other payment modes and cannot be exchanged for cash.
                        <br />
                      </li>
                      <li>
                        The general Credit Card terms and conditions will continue to apply.
                        <br />
                      </li>
                    </ol>
                  </p>
                </div>
            ): promo === "scb" ? (
              <div className="col-md-12 col-sm-12 col-xs-12 zero-padding">
                <div className="terms-cond">
                  <h3>Terms & Conditions</h3>
                </div>
                <p className="prmo-details">
                  <ol className="terms">
                    <li>
                      This promotion is applicable only for Standard Chartered credit cards issued in Sri Lanka.
                      <br />
                    </li>
                    <li>
                      No promotion code is required to avail the offer.
                      <br />
                    </li>
                    <li>
                      Maximum discount is limited to LKR 16,500.
                      <br />
                    </li>
                    <li>
                      11- months 0% Instalment plan is applicable only to New InsureMe customers and the customers who are willing to change the Insurer on their renewals.
                      <br />
                    </li>
                    <li>
                      The 11% discount offer will apply for all Insurance policies except for Life Insurance policy renewals under this promotion.
                      <br />
                    </li>
                    <li>
                      The minimum transaction value for Call & Convert should be LKR25,000 or above.
                      <br />
                    </li>
                    <li>
                      Please call the hotline 0112480480 upon completing the transaction.
                      <br />
                    </li>
                    <li>
                      The promotion is valid until 31 August 2021.
                      <br />
                    </li>
                    <li>
                      If any dispute arises, the decision of Insureme.lk and Standard Chartered shall be considered final.
                      <br />
                    </li>
                    <li>
                      The Bank and partner establishments accepts no liability or responsibility for the consequences arising out of the interruption/disruption of its business or service/s by and/or because of but not limited to acts of God, riots, civil commotion, insurrections, wars, strikes or lockouts or for any reason beyond their control which may delay the delivery of any item to the customer.
                      <br />
                    </li>
                    <li>
                      The general credit card Terms & Conditions will continue to apply.
                      <br />
                    </li>
                  </ol>
                </p>
              </div>
            ) : promo === "ndb" ? (
              <div className="col-md-12 col-sm-12 col-xs-12 zero-padding">
                <div className="terms-cond">
                  <h3>Terms & Conditions</h3>
                </div>
                <p className="prmo-details">
                  <ol className="terms">
                    <li>
                      This offer is valid only for NDB Credit Cardholders.
                      <br />
                    </li>
                    <li>
                      In order to enjoy the promotion, the full payment needs to
                      be settled with NDB Credit Card.  
                      <br />
                    </li>
                    <li>
                      The offer will be FLAT 10% OFF on the Basic premium of
                      Full Motor Insurance
                      <br />
                    </li>
                    <li>
                      Client will have to call and convert in order to obtain
                      instalment plan upto 60 Months (0.9% Interest).
                      <br />
                    </li>
                    <li>
                      The offer is valid on the website www.insureme.lk only.  
                      <br />
                    </li>
                    <li>
                      This offer cannot be combined with any other existing
                      offers.   
                      <br />
                    </li>
                    <li>
                      The general terms & conditions of National Development
                      Bank & InsureMe Insurance Brokers (Pvt) Ltd shall apply.  
                      <br />
                    </li>
                    <li>
                      In the event of any dispute, the bank’s decision shall be
                      final.
                      <br />
                    </li>
                    <li>
                      The Bank reserves the right to modify or change the terms
                      & conditions of the offer.
                      <br />
                    </li>
                    <li>
                      The Bank reserves the right to amend/ withdraw this offer
                      at its sole discretion.
                      <br />
                    </li>
                    <li>
                      The Bank accepts no responsibility in any way for any
                      goods or services purchased and any dispute relating to
                      same should be resolved by the cardholder directly with
                      the partner and any claim by the cardholder
                      <br />
                    </li>
                    <li>
                      against the partner will not relive the cardholder from
                      his/ her obligation or repayment to the Bank.
                      <br />
                    </li>
                    <li>
                      This offer is valid only for new InsureMe customers.
                      <br />
                    </li>
                  </ol>
                </p>
              </div>
            ) :promo == "hnb" ? (
              <div className="col-md-12 col-sm-12 col-xs-12 zero-padding">
                <div className="terms-cond">
                  <h3>Terms & Conditions</h3>
                </div>
                <p className="prmo-details">
                  <ol className="terms">
                    <li>
                      The card member may perform single/multiple purchase(s) and request for easy payment plan of Six <b>(06) months</b> & twelve <b>(12) months</b>, 
                      provided the single transaction is LKR 10,000 to Rs.1.0 Mn, and such transaction value is within the available balance and authorized 
                      limit of the credit card at the time of performing the transaction.
                      <br />
                    </li>
                    <li>
                      In order to enjoy the installment facility, the purchases must be performed during the Promotional Period and informed to the call 
                      center, via calling <b>0112 462 462</b> & provide the date of such transaction, the name of the merchant and the value of such transaction and 
                      the same will be reflected in the customer’s next credit card statement.
                      <br />
                    </li>
                    <li>
                      The customers must Call & Convert the Transaction within 5 calendar days from the date of performing the transaction. Eg. Transaction 
                      performed on 15th July 2020 should be informed to HNB Call Centre on /or before the 20th July 2020, on 011 2462 462 for conversion to 
                      easy payment plan.
                      <br />
                    </li>
                    <br />
                    <p><b>IMPORTANT – In the event that transactions made 5 days prior to the billing cycle are to be converted to Easy Payment Plans, they 
                      will be converted only during the next billing period. In this instance, the cardholder will be required to settle the amount stated 
                      in the statement. The installment plan will come into effect from the next billing cycle onwards.
                    </b></p>
                    <li>
                      The Transaction must be within the customers approved Credit Limit & transactions performed by depositing funds over the credit limit 
                      will not be eligible for this promotion.
                      <br />
                    </li>
                    <li>
                      Card member(s) is/are to settle the total bill via Hatton National Bank PLC credit card(s) to be eligible for the offers mentioned here in.
                    </li>
                    <li>
                      The offers cannot be exchanged for cash and/or used in conjunction with any other promotional programs or offers provided by service 
                      establishments involved in this promotion.
                      <br />
                    </li>
                    <br />
                    <p><b>IMPORTANT – In the event that transactions made 5 days prior to the billing cycle are to be converted to Easy Payment Plans, they 
                      will be converted only during the next billing period. In this instance, the cardholder will be required to settle the amount stated 
                      in the statement. The installment plan will come into effect from the next billing cycle onwards.
                    </b></p>
                    <li>
                      If any dispute arises regarding any of the terms and conditions contained herewith, the decision of the respective service establishment 
                      & Hatton National Bank PLC shall be final.
                      <br />
                    </li>
                    <li>
                      Promotion period for this offer will be 15 July 2020 to 30 September 2020.
                      <br />
                    </li>
                  </ol>
                </p>
              </div>
            ) : promo === "sey" ? (
                    <div className="col-md-12 col-sm-12 col-xs-12 zero-padding">
                        <div className="terms-cond">
                            <h3>Terms & Conditions</h3>
                        </div>
                        <p className="prmo-details">
                            <ol className="terms">
                                <li>
                                    The Cardmember may enjoy 10% savings on new basic annual premiums of insurance policies purchased at www.insureme.lk during the aforementioned offer period.
                                    <br/>
                                </li>
                                <li>
                                    Promotion valid till 31st August 2022.
                                    <br/>
                                </li>
                                <li>
                                    Discount will be applicable when a customer proceeds with a different Insurance company from his / her existing policy.
                                  <br/>
                                </li>
                                <li>
                                    10% Discount will be applicable only for Motor Insurance Policies.
                                    <br/>
                                </li>
                                <li>
                                    0% ,3M & 6M installment Plans available as call & convert   hotline 0112008888
                                    <br/>
                                </li>
                                <li>
                                    General Terms and Conditions of the Merchant & Bank apply
                                    <br/>
                                </li>
                            </ol>
                        </p>
                    </div>
                )
                : null}
          </div>

          <div className="col-md-4 col-sm-4 col-xs-12 zero-padding">
            <div className="col-md-1 col-sm-1 col-xs-1 zero-padding" />
            {flag || flagOne ? (
              <label className="col-md-10 col-sm-10 col-xs-10 zero-padding quote-form">
                Thank you for submitting. We will call you back.
              </label>
            ) : (
              <div className="col-md-10 col-sm-10 col-xs-10 zero-padding quote-form">
                <InputText
                  className="form-control email-input"
                  id="name-input"
                  placeholder="Name"
                  type={"text"}
                  value={this.state.cx_name}
                  onChange={e => this.onChange("cx_name", e)}
                  validation={{presence: true}}
                  errorText={this.state.error.cx_name}
                />

                <InputText
                  className="form-control email-input"
                  id="email-input"
                  placeholder="Email"
                  type={"text"}
                  value={this.state.cx_email}
                  onChange={e => this.onChange("cx_email", e)}
                  validation={validations.cx_email}
                  errorText={this.state.error.cx_email}
                />
                <div className="col-md-2 col-sm-2 col-xs-2 zero-padding">
                  <InputText
                    className="form-control email-input"
                    id="mobile-input"
                    type={"text"}
                    value="94"
                    disabled="disabled"
                  />
                </div>
                <div className="col-md-10 col-sm-10 col-xs-10 zero-padding">
                  <InputText
                    className="form-control email-input"
                    id="mobile-input"
                    placeholder="Mobile"
                    type={"text"}
                    value={this.state.cx_mobile}
                    onChange={e => this.onChange("cx_mobile", e)}
                    validation={validations.cx_mobile}
                    errorText={this.state.error.cx_mobile}
                  />
                </div>
                {this.state.loading ? (
                  <Spinner size="" />
                ) : (
                  <Button
                    type="radius"
                    color="red"
                    className="nuclei-bottom-red-button "
                    onClick={e => this.handleSubmit(e)}
                  >
                    Get A Quote
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.data,
    leads: state.lead
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFeatureCardData,
      postInquiry,
      clearErrors
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promotions);
