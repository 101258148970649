import React from "react";
import "./Collapse.scss";

export const Collapse = ({title, content, index, titleClass, titleTextClass, rightIcon}) => {
  return (
    <div className="nuclei-collapse nuclei-collapse-default">
      <div
        className={`nuclei-collapse-heading ${titleClass} `}
        data-toggle="collapse"
        data-parent="#accordion"
        href={`#collapse${index}`}
      >
        <a className={` nuclei-collapse-content ${titleTextClass}`}>{title}</a>
        {rightIcon? 
        <a className="nuclei-collapse-arrow">
          <img src={require("../assets/collapse.svg")} alt="" />
        </a>
        : null
        }
      </div>
      <div id={`collapse${index}`} className="panel-collapse collapse">
        <div className="nuclei-collapse-body">{content}</div>
      </div>
    </div>
  );
};
export default Collapse;
