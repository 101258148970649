import React, {Component} from "react";
import "./DockPanel.scss";

import GridTile from "./GridTile";

export default class Dockpanel extends Component {
  renderGridTitle(dockPanel, i) {
    return (
      <div
        key={i}
        className="col-md-4 col-sm-4 col-xs-4 nuclei-dock-grid-tile zero-padding"
      >
        <GridTile gridTile={dockPanel.gridTile} />
      </div>
    );
  }
  render() {
    return (
      <div>
        <div className="col-md-12 col-sm-12 col-xs-12 nuclei-home-dock">
          <div className="container-fluid zero-padding">
            <div className="row">
              {this.props.dockPanel.map((dockPanel, i) => {
                return this.renderGridTitle(dockPanel, i);
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
