import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import React, {Component} from "react";

import {Alert} from "../../../components";
import MotorBreadcrumb from "../../motor/MotorBreadcrumb";
import {
  getAuthToken,
  getParamsByUniqHash,
  getCoverComparisons,
  clearErrors,
  fetchPremiumsByUniqHash
} from "../../../redux/actions";
// import InsuranceDetails from "../../motor/comparison/InsuranceDetails";
import "./personal-info.scss";
import PersonalInfoForm from "../personal-info-form/personal-info-form";
import HeaderTitle from "../../../components/HeaderTitle";
import InsuranceDetailCard from "./insurance-detail-card";
import ClassicQuoteForm from "../../motor/quotation/classic-quote-form";
import {getQueryParams} from "../../../history";
import {HIDE_XS_WIDTH} from "../../../config";
import windowDimensions from "react-window-dimensions";
import {Helmet} from "react-helmet";

const divStyle = {
  fontWeight: "bold"
};
class PiMotorClassic extends Component {
  state = {showResults: false};
  onClickHandler = () => {
    this.setState({showResults: !this.state.showResults});
  };

  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };
  }
  async componentDidMount() {
    const quotations = {};
    const queryParams = getQueryParams(this.props.location.search);
    if (!queryParams.unq_hash || queryParams.unq_hash == "undefined") {
      return null;
    }
    if (queryParams.qot_number) {
      quotations[0] = queryParams.qot_number;
    } else {
      return null;
    }

    await this.props.getAuthToken();
    await this.props.fetchPremiumsByUniqHash(queryParams.unq_hash, quotations);
    await this.props.getParamsByUniqHash(queryParams.unq_hash);

    let cover = null;
    this.props.premium.premiums.forEach(element => {
      if (element.qot_number === queryParams.qot_number) {
        cover = element;
      }
    });
    this.setState({cover});
  }

  onChange = (type, e) => {
    this.setState({[type]: e.target.value});
  };
  render() {
    const isHideOnXS = this.props.width < HIDE_XS_WIDTH;
    return (
      <div>
        <Helmet>
          <meta name="author" content="InsureMe Insurance Brokers (Pvt) Ltd" />
          <meta name="robots" content="index, follow" />
          <meta name="revisit-after" content="1 month" />
          <title>
            Personal Information | InsureMe.lk | Sri Lanka's First Insurance
            Comparison Website
          </title>
        </Helmet>
        <Alert
          type="danger"
          message={
            this.props.auth.errorMessage ||
            this.props.premium.errorMessage ||
            this.props.motor.errorMessage ||
            this.props.user.errorMessage
          }
          onClick={() => this.props.clearErrors()}
        />

        <div className="container personal-information insureme-form">
          <div className="col-md-4 col-sm-4 col-xs-12 nuclie-collapse-insureme-form">
            <div className="col-md-3 col-sm-12 visible-xs ">
              <div className="col-xs-7 zero-padding">
                <div className="col-xs-12 insurance-type-header">
                  <HeaderTitle
                    mainFirstWord="Insurance"
                    restOfTitle="Details"
                    headerIcon={true}
                    headerIconStyle={{marginLeft: "-16px"}}
                    headerStyle={{marginLeft: "28px"}}
                    textStyle={{marginLeft: "30px"}}
                    headerStyle={{fontWeight: "bold"}}
                    subTitleStyle={{paddingLeft: "30px"}}
                    subTitle="Tap and change the details"
                  />
                </div>
              </div>

              <div className="visible-xs col-xs-5 ">
                <a
                  className="btn nuceli-sub-collapse edit-detail"
                  onClick={this.onClickHandler}
                >
                  Edit Detail
                </a>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-xs-12 hidden-xs">
              <HeaderTitle
                mainFirstWord="Insurance"
                restOfTitle="Detail"
                headerStyle={{fontWeight: "bold"}}
                subTitle="Tap and change the details"
              />
            </div>

            <InsuranceDetailCard company={this.state.cover} />
            <ClassicQuoteForm
              isDocked
              disabled={true}
              show={!isHideOnXS || this.state.showResults}
            />

            <div className="col-md-12 col-sm-12 col-xs-12 hidden-xs">
              {/* <InsuranceDetails isLable={true} /> */}
            </div>
          </div>
          <div className="col-md-8 col-sm-8 col-xs-12 form-group">
            <ul className="nuclei-margin-top sub-button">
              <HeaderTitle
                mainFirstWord="Personal "
                restOfTitle="Information"
                headerIcon={true}
                headerIconStyle={{marginLeft: "-34px"}}
                headerStyle={{marginLeft: "10px", fontWeight: "bold"}}
                textStyle={{marginTop: "-16px", marginBottom: "10px"}}
              />

              <PersonalInfoForm company={this.state.cover} />
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth,
    premium: state.premium,
    motor: state.motor
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAuthToken,
      getCoverComparisons,
      clearErrors,
      getParamsByUniqHash,
      fetchPremiumsByUniqHash
    },
    dispatch
  );
}

export default compose(
  windowDimensions(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PiMotorClassic);
