import React, {Component} from "react";
import {Popover, OverlayTrigger} from "react-bootstrap";
import validate from "validate.js";
import Select from "react-select";

export class DateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blurred: false,
      selectedOption: null
    };
  }

  handleChange = selectedOption => {
    this.setState({selectedOption});
    this.props.onChange(selectedOption);
  };

  render() {
    const {selectedOption} = this.state;
    let {
      tooltip = "",
      // label,
      placeholder,
      value,
      errorText,
      validation,
      // date,
      options
    } = this.props;
    if (!errorText && this.state.blurred && validation) {
      errorText = validate.single(value, validation);
    }
    return (
      <div>
        <Select
          value={selectedOption}
          onChange={e => {
            this.handleChange(e);
          }}
          className={`form-control-auto ${
            errorText ? "form-input-has-error" : ""
          }`}
          options={options}
          placeholder={placeholder}
        />
        {errorText ? <p className="form-input-error">{errorText}</p> : null}
          {/* below commented code here */}
      </div>
    );
  }
}

export default DateTime;

// {tooltip ? (
//   <span>
//     <OverlayTrigger
//       trigger="click"
//       rootClose
//       placement="bottom"
//       overlay={
//         <Popover
//           id="popover-positioned-bottom"
//           className="tooltip-popover"
//           title={
//             typeof tooltip == "boolean"
//               ? "Please set tooltip title"
//               : tooltip
//           }
//         />
//       }
//     >
//       <button type="button" className="btn  nuclei-tooltip-text">
//         ?
//       </button>
//     </OverlayTrigger>
//   </span>
// ) : null}