import axios from 'axios';
import {getCommonError, hasAuthError} from "../../util";
import {INME_AUTH} from '../../config';

const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
const VALIDATE_TOKEN_DONE = 'VALIDATE_TOKEN_DONE';
const VALIDATE_TOKEN_FAILD = 'VALIDATE_TOKEN_FAILD';

const INIT_STATE = {
    loading: false,
    isLoggedIN: false,
    errorMessage: '',
    customerData: '',
    valid: false
};

export default function reducer(state = INIT_STATE, action) {
    const {type, payload} = action;

    switch (type) {

        case VALIDATE_TOKEN:
            return {...state, loading: true, isLoggedIN: false};
        case VALIDATE_TOKEN_DONE:
            return {...state, loading: false, customerData: payload, isLoggedIN: true};
        case VALIDATE_TOKEN_FAILD:
            return {...state, loading: false, errorMessage: payload, isLoggedIN: false};

        default:
            return state;
    }
}
//
export const validateToken = (token) => async dispatch => {

    try {

        dispatch({type: VALIDATE_TOKEN});
        const res = await axios({
            method: 'post',
            url: `${INME_AUTH}/api/v2/customer/token_verify`,
            header: {'Content-Type': 'application/json'},
            data: {
                token: token
            }
        });

        const error = hasAuthError(res);

        if (error) {
            return dispatch({type: VALIDATE_TOKEN_FAILD, payload: error, valid: false});
        }
        return dispatch({type: VALIDATE_TOKEN_DONE, payload: res.data.data, valid: true});

    } catch (error) {
        return dispatch({type: VALIDATE_TOKEN_FAILD, payload: getCommonError(error), valid: false});
    }

};