import React from "react";
import "./Footer.scss";
const Footer = () => {
  return (
    <div className="container-fluid background-image">
      <div style={{paddingTop: "15px"}}>
        <h3 className="footer-title">InsureMe Insurance Brokers (Pvt) Ltd</h3>
        <p className="footer-description">
          14A, Boyd Place, Colombo 03, Sri Lanka.
        </p>
        <p className="footer-description">
          <a href="mailto:info@insureme.lk"> info@insureme.lk</a>
        </p>
      </div>
      <div className="social-icons">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/InsureMe.lk"
          title="Click here"
          className="footer-icon"
        >
          {" "}
          <img src={require("../../assets/fb.png")} alt="fb" />{" "}
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/Insureme.lk"
          title="Click here"
          className="footer-icon"
        >
          {" "}
          <img src={require("../../assets/px.png")} alt="px" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/insureme-insurance-brokers-pvt-ltd?trk=biz-companies-cym"
          title="Click here"
          className="footer-icon"
        >
          {" "}
          <img src={require("../../assets/link.png")} alt="link" />
        </a>
        <a
          target="_blank"
          href="viber://chat?number=%2B94768282424"
          title="Click here"
          className="footer-sub-icon"
        >
          {" "}
          <img
            src={
              "https://s3-ap-southeast-1.amazonaws.com/insureme-prod/web/vb.png"
            }
            alt="vbr"
          />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://wa.me/94768282424"
          title="Click here"
          className="footer-sub-icon"
        >
          {" "}
          <img
            src={
              "https://s3-ap-southeast-1.amazonaws.com/insureme-prod/web/whatsapp.png"
            }
            alt="vbr"
          />
        </a>
      </div>

      <div className="footer-yellow-bar" />
    </div>
  );
};

export default Footer;
