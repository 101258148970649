import React from "react";
import "./HeaderTitle.scss";

const HeaderTitle = ({
  mainWord = "",
  restOfTitle = "",
  mainFirstWord = "",
  subTitle = "",
  headerStyle = {},
  textStyle = {},
  headerIcon = false,
  headerIconStyle = {},
  headerTextStyle = {},
  subTitleStyle = {}
}) => {
  return (
    <div className="nuclei-header-title">
      {headerIcon ? (
        <span style={headerIconStyle} className="nuclei-header-title-icon">
          <img src={require("../assets/bullseye.png")} alt="..." />
        </span>
      ) : null}
      <h3 style={textStyle}>
        <span className="bold" style={headerStyle}>
          {mainFirstWord || mainWord}
        </span>{" "}
        {restOfTitle}
      </h3>
      <p style={subTitleStyle} className="subTitle">
        {subTitle}
      </p>
    </div>
  );
};
export default HeaderTitle;
