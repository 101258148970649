import React, {Component} from 'react';
import {Redirect} from "react-router";
import queryString from 'query-string';

import {
    validateToken
} from "../redux/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Spinner} from "../components";

class ProtectedRoutes extends Component{

    constructor(props) {
        super(props);
        this.state = {
            params: null,
            authToken: null,
            tokenValid: false,
            value: ''
        };

        const authParams = queryString.parse(this.props.location.search);
        localStorage.setItem('authToken', authParams.token);

    }

    async componentDidMount() {
        let token = localStorage.getItem('authToken');
        if (token) {
            const valid = await this.props.validateToken(token);
        } else {
            this.setState({
                tokenValid: false
            });
        }

        if (this.props.customerAuth.isLoggedIN === false) {
            this.setState({
                tokenValid: false
            });
        } else {
            this.setState({
                tokenValid: true
            });
        }


    }


    render() {

        const authParams = this.props.customerAuth;
            return (
                <div>
                    <Spinner spinning={this.props.customerAuth.loading} />
                    <div>login success</div>
                    <Redirect to={{pathname: '/'}} />
                </div>
            );

    }

}

function mapStateToProps(state) {
    return {
        customerAuth: state.customerAuth,
    };
}

export default connect(mapStateToProps, {
        validateToken
    }
)(withRouter(ProtectedRoutes));