import React, {Component} from "react";
import "./InsuremePartners.scss";
import HeaderTitle from "./HeaderTitle";
import {Link} from "react-router-dom";
export default class InsuremePartners extends Component {
  render() {
    return (
      <div className="container-fluid nuclei-insureme-partners-img">
        <div className="row" style={{display: "inline-block"}}>
          <HeaderTitle
            mainFirstWord="InsureMe"
            restOfTitle="Partner"
            headerStyle={{fontWeight: "bold", marginTop: 10}}
          />
          {this.props.partnersImg.map((partnerImg, i) => {
            return (
              <Link key={i} to={partnerImg.imgLink ? partnerImg.imgLink : ""}>
                <img
                  src={partnerImg.imgUrl}
                  alt="..."
                  className="rounded mx-auto d-block"
                />
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}
