import React, {Component} from "react";
import {withRouter} from "react-router";
import HeaderTitle from "../../components/HeaderTitle";
import {Helmet} from "react-helmet";
import "./CriticalIllness.scss";
import {connect} from "react-redux";
import NotCoveredModal from "./NotCoveredModal";
import CoverComparisonModal from "./CoverComparisonModal";
import SettlementModal from "./SettlementModal";
import queryString from 'query-string'

import {
    addNewCurrentPolicyHolder,
    addPolicyHolder,
    clearErrors,
    creatFirstPoliscyHolder,
    getCovers,
    getPorodcutInformation,
    policyHolderValidate,
    removePolicyHolder,
    setCurrentPolicyHolder,
    updatePolicyHolder
} from "../../redux/actions";
import {Spinner} from "../../components";
import "react-rangeslider/lib/index.css";
import CartWidget from "./CartWidet";
import _ from "lodash";
import PersonalIfoForm from "./PersonalInfoForm";
import Companies from "./Companies";
import DeclarationModal from "./DeclarationModal";
import CiFAQs from "./CiFAQs";
// import { shadowState, shadowPolicyHolderList, shadowCompanies } from "./Tempdata";

class CriticalIllness extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            relationShips: [],
            infoAgreed: false,
            enableGetCover: false,
            newState: false,
            currentHolder: {},
            promoCode: (queryString.parse(this.props.location.search).promo) ? queryString.parse(this.props.location.search).promo : null,
            channel: (queryString.parse(this.props.location.search).channel) ? queryString.parse(this.props.location.search).channel : null
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.props.getPorodcutInformation( this.state.channel, this.state.promoCode),
            this.props.creatFirstPoliscyHolder()
        ]);
    }

    componentWillReceiveProps(nextprops, nextState) {
        let enableGetCover = false,
            verifyPayload =null,
            balancesVerified = true;
        const currentHolder = _.find(nextprops.policyHolderList, {
            id: nextprops.currentPolicyHolderId
        });
        const mySelf = nextprops.policyHolderList.find((holder) => {
            return typeof holder.relationship === 'object' && holder.relationship.code === "MS";
        });
        if (mySelf && nextprops.verifiedNumbers[mySelf.mobileNumber] !== undefined && nextprops.verifiedNumbers[mySelf.mobileNumber]['token']) {
            verifyPayload = nextprops.verifiedNumbers[mySelf.mobileNumber];
        }
        let nicNumbers = nextprops.policyHolderList.map((i) => i.nicNumber);
        for (let i in nicNumbers) {
            if (nextprops.policyHolderBalanceList[nicNumbers[i]] === undefined) balancesVerified = false;
        }
        if (nextprops.policyHolderValid && this.state.infoAgreed && verifyPayload && verifyPayload.token && nextprops.getBalanceLoading === false && balancesVerified) {
            enableGetCover = true;
        }

        this.setState({
            relationShips: nextprops.productInfo ? nextprops.productInfo.relationship : [],
            enableGetCover: enableGetCover,
            currentHolder
        });
    }

    selectPolicyHolder = (type, policyHolder) => {
        if (type === "policy") {
            this.setState({
                relationShips: [],
                enableGetCover: false
            });
            this.props.setCurrentPolicyHolder(policyHolder.id);
        }
        if (type === "addNew") {
            this.setState({
                relationShips: [],
                enableGetCover: false,
                infoAgreed: false,
                newState: true
            });
            this.props.addNewCurrentPolicyHolder(policyHolder + 1);
        }
    };

    personalInfoForm = () => {
        const currentHolder = _.find(this.props.policyHolderList, {
            id: this.props.currentPolicyHolderId
        });
        const policyHolderBalanceList = this.props.policyHolderBalanceList;
        return (
            <PersonalIfoForm
                key={currentHolder}
                currentHolder={currentHolder}
                policyHolderBalanceList={policyHolderBalanceList}
                newState={this.state.newState}
                newUserState={() => this.setState({newState: false})}
            />
        );
    };

    renderModal = () => {

        let currentHolder = this.state.currentHolder;
        let channel       = this.state.channel;
        let holders = _.cloneDeep(this.props.policyHolderList);
        let updatePolicyHolder = this.props.updatePolicyHolder;
        let companies = _.cloneDeep(this.props.companies);

        let checkedCompanies = [];
        let checkedCompanyholder = [];
        // let checkedLimits = [];
        let allCompanyCoverdIllness = [];
        let coverdIllness = [];
        let notCoverdIllness = [];
        for (let i in holders) {
            let holder = holders[i];
            let exsistingholder = [];
            for (let j in holder.plans) {
                let checkedCompany = _.find(companies, function (company) {
                    return company.id === holder.plans[j].companyId;
                });

                exsistingholder.push(checkedCompany);
                // let checkedLimit = checkedCompany.limits.find(function (limit) {
                //     return limit.id === holder.plans[j].limitId;
                // });
                checkedCompanies[checkedCompany.id] = checkedCompany;
                holder.checkedCompany = exsistingholder;
                checkedCompanyholder.push({holder});
                for (let p in checkedCompany.covered) {
                    if (coverdIllness[checkedCompany.covered[p].name] === undefined) {
                        coverdIllness[checkedCompany.covered[p].name] = [];
                    }
                    coverdIllness[checkedCompany.covered[p].name].push(checkedCompany.id);
                }
                for (let q in checkedCompany.not_covered) {
                    if (
                        checkedCompany.covered[q] &&
                        notCoverdIllness[checkedCompany.covered[q].name] === undefined
                    ) {
                        notCoverdIllness[checkedCompany.not_covered[q].name] = [];
                    }
                    if (checkedCompany.covered[q]) {
                        notCoverdIllness[checkedCompany.not_covered[q].name].push(
                            checkedCompany.id
                        );
                    }
                }
            }
        }
        for (let i in companies) {
            let company = companies[i];
            for (let j in company.covered) {
                if (allCompanyCoverdIllness[company.covered[j].name] === undefined) {
                    allCompanyCoverdIllness[company.covered[j].name] = [];
                }
                allCompanyCoverdIllness[company.covered[j].name].push(company.id);
            }
        }
        return (
            <div>
                <CoverComparisonModal
                    illness={allCompanyCoverdIllness}
                    currentHolder={currentHolder}
                    companies={this.props.companies}
                    checkedCompanies={checkedCompanies}
                    getCoverLoading={this.props.getCoverLoading}
                />
                <NotCoveredModal
                    illness={notCoverdIllness}
                    checkedCompanies={checkedCompanies}
                    companies={this.props.companies}
                    currentHolder={currentHolder}
                />
                <SettlementModal
                    companies={this.props.companies}
                    holders={holders}
                    checkedCompanies={checkedCompanies}
                />
                <DeclarationModal
                    holders={holders}
                    companies={companies}
                    checkedCompanies={checkedCompanyholder}
                    buyCoverFn={(declarations, agentEmail ) => this.proceedGetCovers( declarations, agentEmail )}
                    channel={channel}
                    updatePolicyHolder={updatePolicyHolder}
                />
            </div>
        );
    };

    proceedGetCovers =  async ( declarations, agentEmail ) => {
        let channel = this.state.channel;
        this.props.getCovers(channel ,this.props.policyHolderList, this.state.promoCode, declarations, agentEmail);
    };

    checkAgreed(e) {
        let checked = e.target.checked;
        
        this.setState({infoAgreed: checked}, () =>
            this.props.policyHolderValidate()
        );
    }

    removePolicyHolder = removeUser => {
        this.props.removePolicyHolder(removeUser);
    };

    renderPolicyHolders() {
        let currentHolderId = this.props.currentPolicyHolderId;
        let holdersList = this.props.policyHolderList;
        let currentHolder = {
            id: 0
        };
        let uservalidity = false;
        let uerlist = holdersList.map((avatar, i) => {
            let avatarIcon = avatar.id === currentHolderId ? "-activated" : "";
            uservalidity = avatar.valid;
            currentHolder = avatar;
            return (
                <div
                    key={i}
                    className="col-sm-3 col-xs-4 text-center"
                    style={{display: "inline-block"}}
                >
                    {!avatarIcon && currentHolder.relationship.code !== "MS" ? (
                        <span
                            onClick={() => {
                                this.removePolicyHolder(avatar);
                            }}
                            className="badge"
                        >
                        x
                        </span>
                    ) : null}
                    <img
                        src={require(`../../assets/ci/add-user-icon${avatarIcon}.png`)}
                        onClick={() => this.selectPolicyHolder("policy", avatar)}
                        className="policy-holder-avatar"
                        alt="Major Illnesses cover"
                    />
                    <label className={"nuclei-input-label mi-lable-edit"}>
                        {avatar.nameInitials
                            ? avatar.nameInitials
                            : currentHolder.relationship.code === "MS"
                                ? "Add your details"
                                : "Add member details"}
                    </label>
                </div>
            );
        });

        if (
            uservalidity &&
            holdersList.length < 5 &&
            this.props.verifyPayload &&
            this.props.verifyPayload.token
        ) {
            uerlist.push(
                <div
                    key={holdersList.length + 1}
                    onClick={() => this.selectPolicyHolder("addNew", currentHolder.id)}
                    className="col-sm-3 col-xs-4 text-center"
                >
                    <img
                        src={require("../../assets/ci/add-user-icon.png")}
                        className="policy-holder-avatar"
                        alt="MI"
                    />
                    <label className={"nuclei-input-label mi-lable-edit add-new-user-lable"}>
                        Add New Member
                    </label>
                </div>
            );
        }

        return uerlist;
    }

    renderCompanies() {
        const currentHolder = _.find(this.props.policyHolderList, {
            id: this.props.currentPolicyHolderId
        });
        return this.props.companies.map((company, i) => {
            return (
                <Companies
                    key={i}
                    companies={company}
                    currentHolder={currentHolder}
                    userPlans={currentHolder ? currentHolder.plans : []}
                    error={this.state.error}
                />
            );
        });
    }

    render() {
        const {enableGetCover, infoAgreed, currentHolder} = this.state;
        let myself = currentHolder.relationship && currentHolder.relationship.code === "MS";
        return (
            <div>
                <Helmet>
                    <meta name="keywords" content="Insureme.lk" />
                    <meta name="author" content="InsureMe Insurance Brokers (Pvt) Ltd" />
                    <meta name="robots" content="index, follow" />
                    <meta name="revisit-after" content="1 month" />
                    <meta name="description" content="Major Illneses Insurance Cover" />
                </Helmet>
                <Spinner spinning={this.props.loading}>
                    <div className="container">
                        <div className="row">
                            <div className="clearfix">{this.renderModal()}</div>
                        </div>

                        <CartWidget
                            users={this.props.policyHolderList}
                            companies={this.props.companies}
                            relationships={this.state.relationShips}
                        />
                        <div className="row">
                            <div
                                className="col-md-5"
                                style={{background: "#f5ce30", height: "1000px"}}
                            >
                                <img
                                    className="sideHangerImg"
                                    src={require("../../assets/ci/side-hanger-promo.png")}
                                    alt="Major Illnesses cover"
                                />
                                <div className="dialogHeader">
                                    <img
                                        className="dialogHeaderImg"
                                        src={require("../../assets/ci/dialog-banner-logo-ci.png")}
                                        alt="Major Illnesses cover"
                                    />
                                </div>
                                <div className="col-md-12 side-panel-title">
                                    {/* <h2 className="main-title"></h2> */}
                                    <img
                                        className="ci-log-image"
                                        src={
                                            "https://s3-ap-southeast-1.amazonaws.com/inme-assets/web/retail_ci/ciritical_illness_bodyguard_v1.png"
                                        }
                                        alt="Major Illnesses cover"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <p className="cover-descript">
                                        Cover for all major illnesses, first time in Sri Lanka
                                    </p>
                                </div>
                                <div className="col-md-12 side-panel-button-group">
                                    <button
                                        className="side-panel-button btn"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#ilness-coverd-modal"
                                    >
                                        Covered illnesses
                                    </button>
                                    {/* <button
                    className="side-panel-button btn"
                    type="button"
                    data-toggle="modal"
                    data-target="#ilness-not-coverd-modal"
                  >
                    Illnesses not covered
                  </button> */}
                                    <button
                                        className="side-panel-button btn"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#settlement-modal"
                                    >
                                        Schedule of benefits
                                    </button>
                                </div>
                                <div className="col-md-12 ">
                                    <div className="side-panel-providers">
                                        <img
                                            className="buddy-providers-block"
                                            src={require("../../assets/ci/buddy-icon-ci.png")}
                                            alt="Major Illnesses cover"
                                        />
                                        <p className="cover-descript">
                                            Exclusive cover from InsureMe with
                                        </p>
                                        <div className="providers-provider-block">
                                            <img
                                                src={require("../../assets/ci/vip-provider-ci.png")}
                                                alt="Major Illnesses cover"
                                            />
                                            {/*<img src={require("../../assets/ci/hnb-provider-ci.png")}/>*/}
                                            {/*<img src={require("../../assets/ci/union-provider-ci.png")}/>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="buddy-message-container">
                                    <p>For Major Illnesses from 1 to 70 years of age</p>
                                    <img
                                        src={require("../../assets/ci/buddy-icon-red-cross.png")}
                                        alt="Major Illnesses cover"
                                    />
                                </div>
                                <div className="faq-container">
                                    <p
                                        className="faq-modal"
                                        data-toggle="modal"
                                        data-target="#cifaqs-modal"
                                    >
                                        FAQ's
                                    </p>
                                    <CiFAQs />
                                </div>
                            </div>
                            <div className="col-md-7 personal-info-form">
                                <div className="row ">
                                    <div className="col-sm-12 col-xs-12">
                                        <HeaderTitle
                                            mainFirstWord="Select"
                                            restOfTitle="Policy Holder"
                                            headerStyle={{fontWeight: "bold"}}
                                            headerIcon={true}
                                        />
                                        <label className="nuclei-input-label mi-lable-edit text-left">
                                            Please select the policy holder
                                        </label>
                                    </div>
                                    <div className="col-sm-12 col-xs-12 policyholder-list">
                                        <div className="row">{this.renderPolicyHolders()}</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-xs-12">
                                        <HeaderTitle
                                            mainFirstWord="Select"
                                            restOfTitle="the insurance company"
                                            headerStyle={{fontWeight: "bold"}}
                                            headerIcon={true}
                                        />
                                        {myself ? (
                                            <label className="nuclei-input-label mi-lable-edit text-left">
                                                Please drag the below slider to customize the limit of
                                                your insurance cover
                                            </label>
                                        ) : (
                                            <label className="nuclei-input-label  mi-lable-edit text-left">
                                                Please drag the below slider to customize the limit of
                                                the insurance cover of the member
                                            </label>
                                        )}
                                    </div>
                                </div>

                                {this.renderCompanies()}

                                <div className="row">
                                    <div className="col-sm-12 col-xs-12">
                                        <HeaderTitle
                                            mainFirstWord=""
                                            restOfTitle="Personal information"
                                            headerStyle={{fontWeight: "bold"}}
                                            headerIcon={true}
                                        />
                                        {myself ? (
                                            <label className="nuclei-input-label  mi-lable-edit text-left">
                                                Please fill the details about yourself
                                            </label>
                                        ) : (
                                            <label className="nuclei-input-label  mi-lable-edit text-left">
                                                Please fill the details about the member
                                            </label>
                                        )}
                                    </div>
                                    {this.personalInfoForm()}

                                </div>

                                <div className="row">
                                    <div className="col-md-12 col-xs-12">
                                        <p className="privacy-acceptance">
                                            <input
                                                type="checkbox"
                                                onChange={e => this.checkAgreed(e)}
                                                checked={infoAgreed}
                                            />
                                            I confirm, the information mentioned above is correct, and
                                            agree to the{" "}
                                            <a
                                                href="https://s3-ap-southeast-1.amazonaws.com/inme-assets/web/retail_ci/major_illness_cover_policy_dialog_customers.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <strong>Terms and Conditions</strong>
                                            </a>{" "}
                                            of the insurance policy
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 col-xs-12 get-cover-container">
                                        <button
                                            disabled={!enableGetCover}
                                            className="btn"
                                            data-toggle="modal"
                                            data-target="#declaration-modal"
                                            // onClick={this.proceedGetCovers}
                                        >
                                            Proceed
                                            {/* {loading ? (
                    <ReactLoading
                    type={'spin'}
                    color={'#fff'}
                    width={20}
                    />
                    ) : null}  */}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Spinner>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.ci.loading,
        getBalanceLoading: state.ci.getBalanceLoading,
        policyHolderList: state.ci.policyHolderList,
        policyHolderBalanceList: state.ci.policyHolderBalanceList,
        productInfo: state.ci.productInfo,
        currentPolicyHolderId: state.ci.currentPolicyHolderId,
        companies: state.ci.companies,
        relationshipsOptions: state.ci.relationshipsOptions,
        verifyPayload: state.user.verifyPayload,
        verifiedNumbers: state.ci.verifiedNumbers,
        getCoverLoading: state.ci.getCoverLoading,
        policyHolderValid: state.ci.policyHolderValid
    };
};

export default connect(
    mapStateToProps,
    {
        getPorodcutInformation,
        addPolicyHolder,
        clearErrors,
        updatePolicyHolder,
        removePolicyHolder,
        getCovers,
        setCurrentPolicyHolder,
        creatFirstPoliscyHolder,
        addNewCurrentPolicyHolder,
        policyHolderValidate
    }
)(withRouter(CriticalIllness));
