import React, {Component} from "react";
import Header from "./Header";
import Footer from "./Footer";
import FooterDockPanel from "../../components/DockPanel/FooterDockPanel";
import {connect} from "react-redux";
import Button from "../../components/Button";
import {InputText, Spinner} from "../../components";
import {generateOTP, verifyOTPCode} from "../../redux/reducers/user";
import {validateToken} from "../../redux/actions";
import ValidateJS from "validate.js";
import validations from "../../validations";
import {FileInput} from "../../components/FileInput";
import {INME_AUTH} from "../../config";
import { withRouter } from "react-router-dom";

class Layout extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            authenticated: null,
            showModal: false,
            activeModalForm: null,
            signupForm: {
                fullName: '',
                nicOrPassportNumber: '',
                address: '',
                profilePicture: ''
            },
            loginForm: {
                mobileNumber: null,
                OTPcode: '',
            },
            errorsSignUp: {
                fullName: null,
                nicOrPassportNumber: null,
                address: null,
                // profilePicture: null,
            },
            errors: {
                mobileNumber: null,
                OTPcode: null,
            },
            enableSignup: false,
            enableReqestOTP: false,
            enableVerifyOTP: false,
            isLogin: false,
        };
    }

    async componentDidMount() {
        this.setState({
            authenticated: false
        });
    }

    renderAuthBar() {
        if (this.state.isLogin === null || window.location.href.indexOf('profile') > -1) {
            return null;
        } else if (this.state.isLogin === true) {
            return (
                <>
                    <nav className="navbar navbar-default navbar-auth">
                        <div className="container">
                            {/*<a className="navbar-brand" href="#">Hi John Doe</a>*/}
                            <div className=" navbar-collapse navbar-ex1-collapse">
                                <ul className="nav navbar-nav justify-content-right">
                                    <li>
                                        <a className={'auth-nav-button'} href="profile">Go to Profile</a>
                                    </li>

                                    <li className="dropdown">
                                        <a className="dropdown-toggle no-padding" data-toggle="dropdown" href="#">
                                            <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar"
                                                 className="avatar"/>
                                        </a>

                                        <ul className="dropdown-menu">
                                            <li>
                                                {/*<a href='/'>*/}
                                                    <Button
                                                        className={'logout-button'}
                                                        onClick={() => this.onLogout()}
                                                        style={{}}>
                                                        <p className={'no-margin-text'} style={{marginBottom: -2}}> Logout </p>
                                                    </Button>
                                                {/*</a>*/}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </>
            );
        } else {
            return (
                <>
                    <nav className="navbar navbar-default navbar-auth">
                        {/*<div className="container">*/}

                            {/*<div className="navbar-collapse navbar-ex1-collapse ">*/}
                                <ul className="nav navbar-nav justify-content-center" style={{float: 'right'}}>
                                    {/*<li>*/}
                                    {/*    <a*/}
                                    {/*        // data-toggle="modal"*/}
                                    {/*       // data-target="#login-modal" onClick={(e) => this.onPressOTP(e)}*/}
                                    {/*       className={'auth-nav-button'}*/}
                                    {/*       href="http://auth-inme.abc">Login With OTP</a>*/}
                                    {/*</li>*/}
                                    <li>
                                        <a href={`${INME_AUTH}/v1/public_login`}>
                                            <Button
                                                className={'login-button'}
                                                onCkick={() => this.onLogin()}
                                                style={{}}>
                                                <p className={'no-margin-text'} style={{marginBottom: -2}}> Login </p>
                                            </Button>
                                        </a>

                                    </li>
                                    {/*<li>*/}
                                    {/*    <a href='/'>*/}
                                    {/*        <Button*/}
                                    {/*            className={'logout-button'}*/}
                                    {/*            onCkick={() => this.onLogout()}*/}
                                    {/*            style={{backgroundColor: '#2551CC'}}>*/}
                                    {/*            <p className={'no-margin-text'} style={{marginBottom: -2}}> Logout </p>*/}
                                    {/*        </Button>*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}

                                </ul>
                            {/*</div>*/}
                        {/*</div>*/}
                    </nav>
                </>
            );
        }
    }

    renderModal() {

        return (
            <div
                className={`modal fade}`}
                id="login-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Login or Register With OTP</h5>
                            <button
                                type="button"
                                style={{marginTop: "-20px"}}
                                onClick={() => this.onCloseModal()}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body no-margin-row">
                            {this.renderModalContent()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onLogout = () => {
        localStorage.removeItem('authToken');
        this.props.history.push(`/`);
        window.location.reload();
    };

    onCloseModal() {
        this.setState({
            showModal: false
        });
    }

    onPressOTP(e) {
        e.preventDefault();
        this.setState({
            showModal: true,
            activeModalForm: 'RequestOTP'
        });
    }

    async onSendVerification() {
        if (this.state.enableVerifyOTP === false) return;
        await this.props.verifyOTPCode(
            this.state.loginForm.OTPcode,
            this.props.user.generatedOTP
        );
        this.setState({
            activeModalForm: 'SignUp'
        })
    };

    async onRequestOTP() {
        if (this.state.enableReqestOTP === false) return;
        await this.props.generateOTP({
            mobile_number: this.state.loginForm.mobileNumber.substring(1),
            email: '',
            otp_media: "SMS"

        });
        this.setState({
            activeModalForm: 'VerifyOTP'
        })
    }

    onChangeInput(val, type) {

        let {loginForm, errors, enableReqestOTP, enableVerifyOTP} = this.state;
        if (val.target) {
            val = val.target.value
        }
        loginForm[type] = val;
        errors[type] = ValidateJS.single(loginForm[type], validations[type]);
        if (type === 'mobileNumber' && errors.mobileNumber === undefined) {
            enableReqestOTP = true;
        }
        if (type === 'OTPcode' && errors.OTPcode === undefined) {
            enableVerifyOTP = true;
        }
        this.setState({
            loginForm: loginForm,
            enableReqestOTP: enableReqestOTP,
            enableVerifyOTP: enableVerifyOTP,
            errors: errors,
        });
    }

    onChangeInputSignup(val, type) {

        try {
            let {signupForm, errorsSignUp, enableSignup} = this.state;
            if (val.target) {
                val = val.target.value
            }
            signupForm[type] = val;
            errorsSignUp[type] = ValidateJS.single(signupForm[type], validations[type]);

            enableSignup = Object.keys(errorsSignUp).find((key)=>errorsSignUp[key] !== undefined) === undefined;

            this.setState({
                signupForm: signupForm,
                enableSignup: enableSignup,
                errorsSignUp: errorsSignUp,
            });
        } catch (e) {
            console.log(e.message)
        }
    }

    renderModalContent() {

        if (this.state.activeModalForm === 'RequestOTP') {
            return (
                <div className="row">
                    <div className="col-sm-8">
                        <InputText
                            errors={this.state.errors.mobileNumber}
                            value={this.state.loginForm.mobileNumber}
                            onChange={(value) => this.onChangeInput(value, 'mobileNumber')}
                            placeholder={'Mobile Number (07X XXXX XXX)'}/>
                    </div>
                    <div className="col-sm-4">
                        <Button
                            disabled={!this.state.enableReqestOTP}
                            className={'btn request-otp'}
                            onClick={() => this.onRequestOTP()}>
                            <p className={'no-margin-text'}>Request OTP</p>
                        </Button>
                    </div>

                </div>
            );
        }
        if (this.state.activeModalForm === 'VerifyOTP') {
            return (
                <>

                    <div className="row">
                        <div className="col-sm-8">
                            <InputText
                                value={this.state.loginForm.OTPcode}
                                errors={this.state.errors.OTPcode}
                                onChange={(value) => this.onChangeInput(value, 'OTPcode', 'loginForm')}
                                placeholder={'OTP code (123456)'}/>
                        </div>
                        <div className="col-sm-4">
                            <Button
                                disabled={!this.state.enableVerifyOTP}
                                className={'btn request-otp'}
                                onClick={() => this.onSendVerification()}>
                                <p className={'no-margin-text'}>Verify</p>
                            </Button>
                        </div>

                    </div>
                    <div className="row">
                        <p>Didn't received code? <a href="#">click here</a> to retry</p>
                    </div>
                </>
            );
        }
        if (this.state.activeModalForm === 'SignUp') {
            return (
                <>

                    <div className="row">
                        <div className="col-sm-12">
                            <InputText
                                value={this.state.signupForm.fullName}
                                errors={this.state.errorsSignUp.fullName}
                                onChange={(value) => this.onChangeInputSignup(value, 'fullName')}
                                placeholder={'Full Name'}/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <InputText
                                value={this.state.signupForm.nicOrPassportNumber}
                                errors={this.state.errorsSignUp.nicOrPassportNumber}
                                onChange={(value) => this.onChangeInputSignup(value, 'nicOrPassportNumber')}
                                placeholder={'NIC/Passport Number'}/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <InputText
                                value={this.state.signupForm.address}
                                errors={this.state.errorsSignUp.address}
                                onChange={(value) => this.onChangeInputSignup(value, 'address')}
                                placeholder={'Address'}/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-5 justify-content-center">
                            <FileInput
                                title={"Profile picture"}
                                icon={'https://inme-assets.s3-ap-southeast-1.amazonaws.com/web/icons/user-profile-icon.png'}
                                status={1}
                                allowedFileTypes={'[.png]'}
                                onPressRemove={(id) => this.onRemoveDocument(id)}
                                onPressUpload={(data) => this.onUploadDocument(data)}
                                uploadedURL={''}
                            />
                        </div>

                    </div>
                    <div className="row" style={{paddingBottom: 20}}>
                        <Button
                            disabled={!this.state.enableSignup}
                            className={'btn request-otp'}
                            onClick={() => this.onSendVerification()}>
                            <p className={'no-margin-text'}>Save And Complete Profile</p>
                        </Button>
                    </div>
                </>
            );
        }
        return null;
    }

    render() {
        return (
            <div id="layout">
                <div id="wrap">
                    {/*{this.renderAuthBar()}*/}
                    <Header
                        hotLine={[
                            {
                                name: "HOTLINE",
                                number: "076 828 2424",
                                href: "tel:+94768282424",
                                image:
                                    "https://s3-ap-southeast-1.amazonaws.com/insureme-prod/web/tel.png"
                            },
                            {
                                name: "VIBER",
                                number: "07 6 828 2424",
                                href: "viber://chat?number=%2B94768282424",
                                image:
                                    "https://s3-ap-southeast-1.amazonaws.com/insureme-prod/web/vb.png"
                            },
                            {
                                name: "WHATSAPP",
                                number: "07 6 828 2424",
                                href: "https://wa.me/94768282424",
                                image:
                                    "https://s3-ap-southeast-1.amazonaws.com/insureme-prod/web/whatsapp.png"
                            }
                        ]}
                        headerNavText={[
                            {
                                navText: "Home",
                                navLink: "home"
                            },
                            {
                                navText: "Corporate",
                                navLink: "/"
                            },
                            {
                                navText: "About",
                                navLink: "/"
                            },
                            {
                                navText: "Products",
                                navLink: "SearchResultsProduct"
                            },
                            {
                                navText: "Blogs",
                                navLink: "blog"
                            },
                            {
                                navText: "Contact",
                                navLink: "/"
                            }
                        ]}
                        breadcrumb={{
                            breadcrumbData: [
                                {
                                    title: "Quotation",
                                    to: "step1"
                                },
                                {
                                    title: "Personal Information",
                                    to: "step2"
                                },
                                {
                                    title: "Payment",
                                    to: "step3"
                                },
                                {
                                    title: "Complete",
                                    to: "step4"
                                }
                            ],
                            breadcrumbSet: [
                                {
                                    name: "step1"
                                },
                                {
                                    name: "step2"
                                },
                                {
                                    name: "step3"
                                },
                                {
                                    name: "step4"
                                },
                                {
                                    name: "step5"
                                },
                                {
                                    name: "step6"
                                },
                                {
                                    name: "step7"
                                }
                            ]
                        }}
                    />

                    <div id="main" className="container-fluid clear-top">
                        {this.props.children}
                    </div>
                </div>
                <FooterDockPanel/>
                <footer className="footer">
                    <Footer/>
                </footer>
                {this.renderModal()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        session: state.session,
        user: state.user,
        customerAuth: state.customerAuth
    };
}

export default connect(mapStateToProps, {
    generateOTP,
    verifyOTPCode,
    validateToken
    }
)(withRouter(Layout));
