import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import React, {Component} from "react";

import {
  fetchPremiums,
  getAuthToken,
  fetchPremiumsByUniqHash,
  addToChart,
  clearErrors
} from "../../../redux/actions";
import {Widget, Alert, Spinner} from "../../../components";
import ClassicQuoteForm from "../quotation/classic-quote-form";
import HeaderTitle from "../../../components/HeaderTitle";
import MotorBreadcrumb from "..//MotorBreadcrumb";
import "./PremiumComparison.scss";
import {convertToSlug} from "../../../util";
import {getQueryParams, addQuery} from "../../../history";
import PopupC from "../../../components/Popup";
import {HIDE_XS_WIDTH} from "../../../config";
import windowDimensions from "react-window-dimensions";
import {Helmet} from "react-helmet";
import queryString from "query-string";

const headerStyle = {
  fontWeight: "bold"
};

class Premium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      newItems: [],
      viewTable: false,
      thirdParty: false
    };
    this.onQuoteChanges = this.onQuoteChanges.bind(this);

    this.promoCode = (queryString.parse(this.props.location.search).promo) ? queryString.parse(this.props.location.search).promo : 'no-promo'
    this.channel = (queryString.parse(this.props.location.search).channel) ? queryString.parse(this.props.location.search).channel : 'Web-Motor'
  }

  async componentDidMount() {
    await this.props.getAuthToken();
    const queryParams = getQueryParams();
    let {insurance_type} = this.props.match.params;
    if (insurance_type === "third-party") {
      this.setState({thirdParty: true});
    }

    const promoCode    = (queryParams.promo != null) ? `&promo=${this.promoCode}`: 'no-promo';
    const channel  = (queryParams.channel != null) ? `&channel=${this.channel}`: 'Web-Motor';

    if (!queryParams.unq_hash || queryParams.unq_hash === "undefined") {
      this.props.history.push(`/motor/Dual-Purpose/third-party/Quotation?${channel}${promoCode}`);
    } else {
      this.props.fetchPremiumsByUniqHash(queryParams.unq_hash);
    }
  }
  async getPremiums() {
    return await this.props.fetchPremiums(
      this.props.premium.premiumParams,
      false,
      true
    );
  }

  async onQuoteChanges() {
    const old_unq_hash = this.props.premium.unq_hash;
    await this.getPremiums(this.props.premium.premiumParams);

    const unq_hash = this.props.premium.unq_hash;

    if (old_unq_hash !== unq_hash && unq_hash) {
      addQuery(
        {
          unq_hash
        },
        this.props.history
      );
    }
  }

  onSelectItem(item, i) {
    if (item.TotalPremium) {
      this.props.addToChart(item.qot_number);
    }
  }
  renderPopup() {
    return (
      <PopupC newArray={this.state.newItems} viewTable={this.state.viewTable} />
    );
  }

  renderWidgets() {
    return this.props.premium.premiums.sort((a, b) => a.TotalPremium - b.TotalPremium).map((item, i) => {
      return (
        <div key={i}>
          <Widget
            item={item}
            key={i}
            thirdParty={this.state.thirdParty}
            unq_hash={this.props.premium.unq_hash}
            cartItems={this.props.premium.cartItems}
            onClick={() => this.onSelectItem(item, i)}
            channel={this.channel}
            promoCode={this.promoCode}
            // onBuyLater={v => console.log(v)}
            // onBuy={v => this.props.history.push("/r")}
            background
          />
          {this.renderPopup()}
        </div>
      );
    });
  }

  state = {showResults: false};
  onClickHandler = () => {
    this.setState(prev => ({showResults: !prev.showResults}));
  };
  onClickCompaire() {
    const {premiumParams} = this.props.premium;

    if (this.props.premium.cartItems.length > 1) {
      const queryParams = getQueryParams();

      let search = `unq_hash=${queryParams.unq_hash}`;
      this.props.premium.cartItems.forEach((item, i) => {
        search += `&qot_number${i}=${item}`;
      });
      this.props.history.push({
        pathname: `/motor/${convertToSlug(premiumParams.vehicle_type)}/${
          premiumParams.insurance_type
        }/Cover-Comparison`,
        search: `?${search}`,
        state: {
          hello: "hello"
        }
      });
    }
  }

  render() {
    const cartItems = this.props.premium.cartItems;
    const isHideOnXS = this.props.width < HIDE_XS_WIDTH;

    const string = this.props.premium.premiumParams.req_key;
    // const last = string.length - 6;
    // var abc = string.slice(string.length - 6, string.length-1);

    return (
      <div className="row">
        <Helmet>
          <meta name="author" content="InsureMe Insurance Brokers (Pvt) Ltd" />
          <meta name="robots" content="index, follow" />
          <meta name="revisit-after" content="1 month" />
          <title>
            Premium Comparison | InsureMe.lk | Sri Lanka's First Insurance
            Comparison Website
          </title>
        </Helmet>
        <MotorBreadcrumb unq_hash={this.props.premium.unq_hash} />
        <Alert
          type="danger"
          message={
            this.props.auth.errorMessage ||
            this.props.premium.errorMessage ||
            this.props.motor.errorMessage ||
            this.props.user.errorMessage
          }
          onClick={() => this.props.clearErrors()}
        />
        <div className="container-fluid personal-information insureme-form">
          <div className="col-md-3 col-sm-12 col-xs-12 nuclie-collapse-insureme-form">
            <div className="col-md-3 col-sm-12 visible-xs">
              <div className="col-xs-7 zero-padding">
                <div className="col-xs-12 insurance-type-header zero-padding">
                  <HeaderTitle
                    mainFirstWord="Insurance"
                    restOfTitle="Detail"
                    headerStyle={headerStyle}
                    subTitle="Tap and change the details"
                  />
                </div>
              </div>

              <div className="visible-xs col-xs-5 ">
                <a
                  className="btn nuceli-sub-collapse edit-detail"
                  onClick={this.onClickHandler}
                >
                  Edit Detail
                </a>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-xs-12 hidden-xs">
              <HeaderTitle
                mainFirstWord="Insurance"
                restOfTitle="Detail"
                headerStyle={headerStyle}
                subTitle="Tap and change the details"
              />
            </div>

            <div className="col-md-12 col-sm-12  ">
              <ClassicQuoteForm
                noHash
                isDocked={true}
                onQuoteChanges={this.onQuoteChanges}
                show={!isHideOnXS || this.state.showResults}
              />
            </div>
          </div>

          <div
            className="col-md-9 col-sm-12"
            id="insurance-details"
            style={{position: "initial"}}
          >
            <div className="row">

              {/* Note ADD */}
              <div hidden={this.state.thirdParty} className="col-md-12 col-sm-12 col-xs-12">
                <p style={{ color:'#18a3ff', fontSize:'17px', paddingLeft:'5px' }}> <span style={{ fontSize:'18px' }}><b>We found some great deals just for you!</b></span> One of our sales representatives will call you shortly to discuss special deals and promotions,
                       also feel free to call us on <b>076 828 2424</b> with this reference number <b>{string}</b>
                </p>
              </div>

              <div className="col-md-6 col-sm-6 hidden-xs">
                <HeaderTitle
                  mainFirstWord="Compare"
                  restOfTitle="Insurance"
                  headerStyle={headerStyle}
                  subTitle={
                    this.state.thirdParty
                      ? ""
                      : "Select up to 3 models for comparison"
                  }
                />
              </div>
              <div className="hidden-sm hidden-md hidden-lg  col-xs-6 ">
                <HeaderTitle
                  mainFirstWord="Compare"
                  restOfTitle="Insurance"
                  headerStyle={headerStyle}
                  subTitle={
                    this.state.thirdParty
                      ? ""
                      : "Select up to 3 models for comparison"
                  }
                />
              </div>
              {this.props.premium.premiumParams.insurance_type === 'third-party' ? null : ( <div
                  className="col-md-6 col-sm-6 col-xs-6 "
                  id="compare-buttons"
              >
                <button
                    onClick={() => this.onClickCompaire()}
                    className={`btn btn btn-danger btn-lg ${
                        cartItems.length < 2 ? "disabled-compare" : ""
                    }`}
                    id="compare-now"
                >
                  <div className="shopping-icon">
                    <span className="glyphicon glyphicon-shopping-cart"/>
                    <span className="badge">{cartItems.length}</span>
                  </div>
                  Compare Now
                </button>
              </div>
              )}
            </div>
            <div className="hidden-sm hidden-md hidden-lg  col-xs-12 exception-compare-sub" />
            <div className="row">
              <div className="clearfix">
                <Spinner spinning={this.props.premium.loading} />
                {this.renderWidgets()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    motor: state.motor,
    premium: state.premium,
    auth: state.auth,
    user: state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPremiums,
      getAuthToken,
      fetchPremiumsByUniqHash,
      addToChart,
      clearErrors
    },
    dispatch
  );
}

export default compose(
  windowDimensions(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Premium);
