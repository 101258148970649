import React from "react";
import "./Alert.scss";

const styles = {
  conatiner: {
    textAlign: "left",
    color: "white"
  }
};

export const Alert = ({type = "info", message, onClick}) => {
  if (!message) {
    return null;
  }
  return (
    <div
      style={styles.conatiner}
      className={`alert alert-${type} alert-dismissible`}
      role="alert"
    >
      {onClick ? (
        <button
          onClick={e => onClick(e)}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      ) : null}

      {message}
    </div>
  );
};
