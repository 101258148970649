import React, {Component} from "react";
import "./UserDetailList.scss";
import HeaderTitle from "./HeaderTitle";
import UserDetail from "./UserDetail";
export default class UserDetailList extends Component {
  render() {
    return (
      <div>
        <HeaderTitle
          mainFirstWord="What"
          restOfTitle="our clients say"
          headerStyle={{fontWeight: "bold", marginTop: 10}}
        />
        {this.props.userDetail.map((userDetail, i) => {
          var padding = "";
          if (i === 0) {
            padding = "zero-padding-left padding-right-sub";
          } else if (i === 1) {
            padding = "padding-right-sub-middle padding-left-sub-middle";
          } else if (i === 2) {
            padding = "zero-padding-right padding-left-sub";
          }
          if (i > 1) {
            var hiddenItem = " hidden-xs";
          } else {
            hiddenItem = "";
          }
          return (
            <div
              key={i}
              className={`col-md-4 col-sm-4 col-xs-12 user-detail-list ${hiddenItem} ${padding}`}
            >
              <UserDetail
                userImgUrl={userDetail.userImgUrl}
                userName={userDetail.userName}
                comment={userDetail.comment}
              />
            </div>
          );
        })}
      </div>
    );
  }
}
