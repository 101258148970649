import React from "react";
import Spinner from "./Spinner";
import "./ButtonGroup.scss";

export const ButtonGroup = ({
  onChange,
  tooltip,
  label,
  options,
  selected,
  lableStyle = {},
  id,
  errorText,
  spinning,
  disabled
}) => {
  return (
    <div className="nuclei-group">
      <p className="nuclei-input-label"> {label} </p>
      <div
        data-toggle="buttons"
        className={options.length ? "" : "no-options-button"}
      >
        {spinning ? <Spinner style={{marginLeft: 0}} size="small" /> : null}
        {options.map((option, i) => {
          const className = selected === option.value ? "active" : "";
          return (
            <label
              key={i}
              style={lableStyle}
              className={`btn btn-default nuclei-selection-option ${
                disabled ? "nuclei-selection-option-disabled" : ""
              } ${className} `}
              onClick={e =>
                disabled ? null : onChange(id, option.value, option.label)
              }
            >
              {disabled ? null : (
                <input
                  type="radio"
                  name={"name"}
                  id={option.value}
                  autoComplete="off"
                />
              )}
              {option.label}
            </label>
          );
        })}
        {/* below commented code here */}
        {errorText ? <p className="form-input-error">{errorText}</p> : null}
      </div>
    </div>
  );
};

export default ButtonGroup;

// {options.length && tooltip ? (
//   <span>
//     <OverlayTrigger
//       trigger="click"
//       rootClose
//       placement="bottom"
//       overlay={popoverBottom(tooltip)}
//     >
//       <button type="button" className="btn  nuclei-tooltip">
//         ?
//       </button>
//     </OverlayTrigger>
//   </span>
// ) : null}