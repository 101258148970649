import axios from "axios";
import {RETAIL_API} from "../../config";
import _ from "lodash";
import {AgeFromDateString} from "age-calculator";

const LOADING = "LOADING";
const GET_BALANCE_LOADING = "GET_BALANCE_LOADING";
const ADD_POLICY_HOLDER = "ADD_POLICY_HOLDER";
const UPDATE_POLICY_HOLDER = "UPDATE_POLICY_HOLDER";
const ADD_CURRENT_POLICY_HOLDER_ID = "ADD_CURRENT_POLICY_HOLDER_ID";
const REMOVE_POLICY_HOLDER = "REMOVE_POLICY_HOLDER";
const GET_META_DATA = "GET_META_DATA";
const GET_META_DATA_ERROR = "GET_META_DATA_ERROR";
const GET_COVER = "GET_COVER";
const GET_COVER_LOADING = "GET_COVER_LOADING";
const SET_COMPANYIES_LIST = "SET_COMPANYIES_LIST";
const SET_POLICY_HOLDERS = "SET_POLICY_HOLDERS";
const SET_RELATION_OPTIONS = "SET_RELATION_OPTIONS";
const SET_POLICY_HOLDERS_VALID = "SET_POLICY_HOLDERS_VALID";
const SET_POLICY_HOLDERS_BALANCE = "SET_POLICY_HOLDERS_BALANCE";
const UPDATE_VERIFIED_NUMBERS = "UPDATE_VERIFIED_NUMBERS";
const initState = {
  loading: false,
    getBalanceLoading: false,
  getCoverLoading: false,
  currentPolicyHolderId: 0,
  policyHolderList: [],
  getCover: null,
  errors: null,
  productInfo: null,
  companies: [],
  relationshipsOptions: [{label: "Select Relationship", value: "0"}],
  policyHolderValid: false,
  policyHolderBalanceList: [],
  verifiedNumbers:[],
};

export default function reducer(state = initState, action) {
  const {type, payload} = action;
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: payload
      };
    case GET_META_DATA:
      return {
        ...state,
        productInfo: payload.data
      };
    case GET_COVER_LOADING:
      return {
        ...state,
        getCoverLoading: payload
      };
    case SET_COMPANYIES_LIST:
      return {
        ...state,
        companies: payload
      };
    case SET_POLICY_HOLDERS_VALID:
      return {
        ...state,
        policyHolderValid: payload
      };
    case SET_POLICY_HOLDERS:
      return {
        ...state,
        policyHolderList: payload
      };
    case GET_META_DATA_ERROR:
      return {
        ...state,
        error: payload
      };
    case ADD_CURRENT_POLICY_HOLDER_ID:
      return {
        ...state,
        currentPolicyHolderId: payload
      };
    case SET_RELATION_OPTIONS:
      return {
        ...state,
        relationshipsOptions: payload
      };
    case GET_BALANCE_LOADING:
      return {
        ...state,
          getBalanceLoading: action.payload
      };
    case SET_POLICY_HOLDERS_BALANCE:
      return {
        ...state,
        policyHolderBalanceList: {
          ...state.policyHolderBalanceList,
          [action.payload.nic]: action.payload.balance
        }
      };
    case UPDATE_POLICY_HOLDER:
      let data = payload.data,
        index = payload.index;
      return {
        ...state,
        ...state.policyHolderList[index],
        data
      };
    case ADD_POLICY_HOLDER:
      return {
        ...state,
        policyHolderList: [...state.policyHolderList, payload]
      };
    case REMOVE_POLICY_HOLDER:
      let currentPolicyHolders = [...state.policyHolderList];
      if (payload > -1) {
        currentPolicyHolders.splice(payload, 1);
      }
      return {
        ...state,
        policyHolderList: currentPolicyHolders
      };
    case GET_COVER:
      return {
        ...state,
        getCover: payload
      };
    case UPDATE_VERIFIED_NUMBERS:
      return {
        ...state,
        verifiedNumbers: payload
      };
    default:
      return state;
  }
}

export const creatFirstPoliscyHolder = () => async dispatch => {
    try {

        const policyHolder = [
            {
                id: 0,
                nameInitials: "",
                nicNumber: "",
                dateOfBirth: "",
                mobileNumber: "",
                email: "",
                relationship: "",
                plans: [],
                valid: false
            }  
        ];

        
        dispatch({
            type: SET_POLICY_HOLDERS,
            payload: policyHolder
            
        });
    } catch (error) {

    }
};

export const getPorodcutInformation = (channel = null,promoCodeParam = null) => async dispatch => {
    try {
        dispatch({type: LOADING, payload: true});

        let url = `${RETAIL_API}/api/v1/ci/web/get_data`;
        let postPayload = null;
        if( channel !== null || promoCodeParam !== null) {

            postPayload = {
                channel: channel,
                promo: promoCodeParam
            }; 
        }
        
        const res = await axios.post(url, postPayload);
        const companies = res.data.data.companies;
        const relationShip = res.data.data.relationship;
        const options = [{label: "Select Relationship", value: "0"}];
        _.each(relationShip, (relation, i) => {
            options.push({
                ...relation,
                label: relation.description,
                value: `${relation.id}`
            });
        });
        dispatch({
            type: SET_RELATION_OPTIONS,
            payload: options
        });
        dispatch({type: LOADING, payload: false});
        dispatch({type: GET_META_DATA, payload: res.data});
        dispatch({type: SET_COMPANYIES_LIST, payload: companies});
    } catch (error) {
        dispatch({type: LOADING, payload: false});
        dispatch({type: GET_META_DATA_ERROR, payload: "error"});
    }
};

export const addPolicyHolder = data => async dispatch => {
    try {
        return dispatch({type: ADD_POLICY_HOLDER, payload: data});
    } catch (e) {
        return dispatch({type: ADD_POLICY_HOLDER, payload: false});
    }
};

export const updatePolicyHolder = (data, index) => async dispatch => {
    try {
        return dispatch({
            type: UPDATE_POLICY_HOLDER,
            payload: {data: data, index: index}
        });
    } catch (e) {
        return dispatch({type: UPDATE_POLICY_HOLDER, payload: false});
    }
};

export const removePolicyHolderFormId = id => async dispatch => {
    try {
        return dispatch({type: REMOVE_POLICY_HOLDER, payload: id});
    } catch (e) {
        return dispatch({type: REMOVE_POLICY_HOLDER, payload: false});
    }
};

export const addNewCurrentPolicyHolder = id => async (dispatch, getState) => {
    try {
        const state = getState();
        const policyHolder = [...state.ci.policyHolderList];
        policyHolder.push({
            id: id,
            nameInitials: "",
            nicNumber: "",
            dateOfBirth: "",
            mobileNumber: "",
            email: "",
            relationship: "",
            plans: [],
            valid: false
        });
        dispatch({
            type: SET_POLICY_HOLDERS,
            payload: policyHolder
        });
        dispatch({
            type: SET_POLICY_HOLDERS_BALANCE,
            payload: []
        });
        dispatch(policyHolderValidate());
        dispatch({type: ADD_CURRENT_POLICY_HOLDER_ID, payload: id});
    } catch (e) {
        dispatch({type: ADD_CURRENT_POLICY_HOLDER_ID, payload: false});
    }
};

export const setCurrentPolicyHolder = id => async (dispatch) => {
    try {
        dispatch({type: ADD_CURRENT_POLICY_HOLDER_ID, payload: id});
        dispatch(policyHolderValidate());
    } catch (e) {
        dispatch({type: ADD_CURRENT_POLICY_HOLDER_ID, payload: false});
    }
};

export const getCovers = ( channel, policyHolderList, promoCode = null, options = [], agentEmail = null) => async (dispatch, getState) => {
  try {
    const mySelf = policyHolderList.find((holder)=>{
      return typeof holder.relationship === 'object' && holder.relationship.code === "MS";
    });

    
    const state = getState();
    const user_key = state.ci.verifiedNumbers[mySelf.mobileNumber];
    dispatch({type: GET_COVER_LOADING, payload: true});
 
        const dataset = {
            policyHolders: policyHolderList,
            options: options,
            channel: channel,
            agentEmail: agentEmail
        }

        if (promoCode !== null) {
            dataset.promoCode = promoCode;

        }
        const res = await axios({
            method: "post",
            url: `${RETAIL_API}/api/v1/ci/web/create`,
            headers: {"User-Key": user_key.token},
            data: dataset
        });
        
        dispatch({type: GET_COVER_LOADING, payload: false});
        dispatch({type: GET_COVER, payload: res.data});
        window.location.href =res.data.data.url;
    } catch (e) {
        console.error(e);
        dispatch({type: GET_COVER_LOADING, payload: false});
        dispatch({type: GET_COVER, payload: false});
    }
};

export const setpolicyHolderDetails = (value, status, catogory, userId) => async (dispatch, getState) => {
    try {
        const state = getState();
        let policyHolders = [...state.ci.policyHolderList];
        let currentPolicyHolders = _.find(policyHolders, {id: userId});
        switch (catogory) {
            case "company":
                if (status) {
                    if (currentPolicyHolders.plans.length === 0) {
                        currentPolicyHolders.plans.push(value);
                    } else {
                        if (
                            _.some(currentPolicyHolders.plans, ["companyId", value.companyId])
                        ) {
                            let planDetails = _.find(currentPolicyHolders.plans, {
                                companyId: value.companyId
                            });
                            planDetails.limitId = value.limitId;
                        } else {
                            currentPolicyHolders.plans.push(value);
                        }
                    }
                } else {
                    _.remove(currentPolicyHolders.plans, {companyId: value.companyId});
                }
                break;
            case "policyholder":
                currentPolicyHolders = value;
                break;
            default:
                break;
        }
        dispatch(policyHolderValidate());
        dispatch({
            type: SET_POLICY_HOLDERS,
            payload: policyHolders
        });
    } catch (error) {
    }
};

export const policyHolderValidate = () => async (dispatch, getState) => {
    try {
        const state = getState();
        let policyHolders = [...state.ci.policyHolderList];
        _.each(policyHolders, currentPolicyHolders => {
            let allusersvlaid = false;
            let ageFromString = new AgeFromDateString(
                currentPolicyHolders.dateOfBirth
            ).age;
            if (currentPolicyHolders.relationship.code === "MS") {
                if (
                    currentPolicyHolders.nameInitials &&
                    currentPolicyHolders.nicNumber &&
                    ageFromString > 1 &&
                    ageFromString < 70 &&
                    currentPolicyHolders.email &&
                    currentPolicyHolders.mobileNumber &&
                    currentPolicyHolders.plans.length
                ) {
                    currentPolicyHolders.valid = true;
                    allusersvlaid = true;
                }
            } else {
                if (
                    currentPolicyHolders.nameInitials &&
                    currentPolicyHolders.nicNumber &&
                    ageFromString > 1 &&
                    ageFromString < 70 &&
                    currentPolicyHolders.plans.length &&
                    currentPolicyHolders.relationship.code
                ) {
                    currentPolicyHolders.valid = true;
                    allusersvlaid = true;
                }
            }
            dispatch({
                type: SET_POLICY_HOLDERS_VALID,
                payload: allusersvlaid
            });
        });
        if (
            _.some(policyHolders, holders => {
                return !holders.relationship.code;
            })
        ) {
            dispatch({
                type: SET_POLICY_HOLDERS_VALID,
                payload: false
            });
        }
        dispatch({
            type: SET_POLICY_HOLDERS,
            payload: policyHolders
        });
    } catch (error) {
    }
};

export const removePolicyHolder = user => async (dispatch, getState) => {
    try {
        const state = getState();
        let policyHolders = [...state.ci.policyHolderList];

        _.remove(policyHolders, policyHolder => {
            return policyHolder.id === user.id;
        });
        dispatch(policyHolderValidate());
        dispatch({
            type: SET_POLICY_HOLDERS,
            payload: policyHolders
        });
    } catch (error) {
    }
};

export const getBalanceForNic = (nic) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_BALANCE_LOADING, payload: true});
        let state = getState().ci,
            companies = state.companies,
            updatedBalances = {},
            balances = {},
            getBalance = true;

        if (getBalance) {
            const companyIDs = [];
            _.map(companies, company => companyIDs.push(company.id));
            const res = await axios({
                method: "post",
                url: `${RETAIL_API}/api/v1/ci/web/getAvailableBalance`,
                headers: {"User-Key": "WEB"},
                data: {
                    nic: nic,
                    companyIDs: companyIDs,
                }
            });
            balances = res.data.data.balances;
            if (balances) {
                for (let i in companyIDs) {
                    updatedBalances[companyIDs[i]] = balances[companyIDs[i]] ? balances[companyIDs[i]] : 0;
                }
                dispatch({
                    type: SET_POLICY_HOLDERS_BALANCE,
                    payload: {
                        nic: nic,
                        balance: updatedBalances,
                    }
                });
                return dispatch({
                    type: GET_BALANCE_LOADING,
                    payload: false
                });
            }
        }
        else {
            return dispatch({
                type: GET_BALANCE_LOADING,
                payload: false
            });
        }

    } catch (error) {
        console.error(error)
    }
};

export const addVerifiedNumber = (number,token) => async (dispatch,getState) => {
    try {
        let verifiedNumbers = getState().ci.verifiedNumbers;
        verifiedNumbers[number] = token;
        dispatch({
            type:UPDATE_VERIFIED_NUMBERS,
            payload:verifiedNumbers
        });

    } catch (error) {
        console.error(error)
    }
};