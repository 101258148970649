import React, {Component} from "react";

import OptionBlock from "./OptionBlock";

export default class OptionBlockList extends Component {
  OptionBlock(optionBlock, i) {
    return (
      <OptionBlock
        key={i}
        priceBefore={optionBlock.priceBefore}
        priceNow={optionBlock.priceNow}
        name={optionBlock.name}
        email={optionBlock.email}
        logoText={optionBlock.logoText}
        contactNo={optionBlock.contactNo}
      />
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          {this.props.optionBlock.map((optionBlock, i) => {
            return this.OptionBlock(optionBlock, i);
          })}
        </div>
      </div>
    );
  }
}
