import React from "react";

import {BtnRectangle} from "../components";

import "./OptionBlock.scss";

export const OptionBlock = props => {
  let {priceBefore, priceNow, name, email, contactNo, logoText} = props;

  const buttonStyle = {
    float: "left",
    color: "white",
    height: "50px",
    width: "140px",
    fontSize: "10px",
    borderRadius: "5px",
    backgroundColor: "#ea514c",
    fontWeight: "bold",
    border: "none",
    margin: "15px 0px 0px 0px"
  };

  return (
    <div>
      <div className="optionContainer">
        <div>
          <div className="option-block" id="price">
            <p id="underline">Rs. {priceBefore}</p>
            <p id="p1">
              <sup>Rs.</sup> {priceNow}
              <sup>.00</sup>
            </p>
          </div>
          <div className="option-block" id="details">
            <p>
              <span>{name}</span>
            </p>
            <p>{email}</p>
            <p>{contactNo}</p>
          </div>
          <div className="option-block" id="company-logo">
            <img
              src={require("../assets/logo-janashakthi.jpg")}
              alt=".."
              id="janashakhi-logo"
            />

            <h3 id="motor-insurance-text">
              {logoText} <br />
              Premium{" "}
            </h3>
          </div>
        </div>
        <div className="promo-container">
          <h3 id="promo-code">Promo Code</h3>
          <div className="form-group" id=" promo-form">
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Enter Promo Code"
            />

            <div>
              <BtnRectangle
                type="btn btn-danger"
                message="Proceed to Payment"
                buttonStyle={buttonStyle}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionBlock;
