import React, {Component} from "react";
import {connect} from "react-redux";
import {InputText} from "../../components";
import {generateOTP, verifyOTPCode} from "../../redux/actions";
import {addVerifiedNumber} from "../../redux/reducers/criticalillness";

const validations = {
    verificationCode: {
        presence: {
            allowEmpty: false,
            message: "Verification code is required"
        },
        length: {
            is: 6
        }
    }
};

class VeryfyCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            verificationCode: "",
            verificationFieldDisplay: false,
            mobileNumber: "",
            verifyCode: false
        };
    }

    componentWillReceiveProps(nextProps) {
        const mobileNumber = nextProps.mobileNumber;
        if (mobileNumber) {
            if (mobileNumber.match(/^[6-7]{2}[0-9]{7}$/g)) {
                this.setState({
                    mobileNumber,
                    verificationFieldDisplay: true
                });
            } else {
                this.setState({
                    verificationFieldDisplay: false
                });
            }
            if (nextProps.otpVerifyError) {
                this.setState({
                    verifyCode: false
                });
            }
        }
    }

    validate(verificationCode) {
        let error = {};
        if (!verificationCode.match(/^\d+$/) && verificationCode !== "") {
            error.verificationCode = "Verification code not valid";
        }
        this.setState({error});
    }

    onChangeHandler = (key, e) => {
        let verificationCode = e.target.value;
        this.setState(
            {

                verificationCode: verificationCode

            },
            () => {
                this.validate(verificationCode);
            }
        );
    };

    sendVerification = e => {
        const mobileNumber = this.props.mobileNumber;
        const email = this.props.email;
        const body = {
            mobile_number: mobileNumber,
            email: email,
            otp_media: "SMS"
        };
        if (mobileNumber) {
            this.props.generateOTP(body);
            validations.verificationCode = "";
            this.setState({
                verificationFieldDisplay: true,
                verificationCode: "",
                error: {},
            });
        }
    };

  verifyCode = async e => {
    const verificationCode = this.state.verificationCode;
    const generatedOTP = this.props.user.generatedOTP;
    await this.props.verifyOTPCode(verificationCode, generatedOTP);
    if(this.props.verifyPayload && this.props.verifyPayload.token){
      await this.props.addVerifiedNumber(this.props.mobileNumber,this.props.verifyPayload);
    }
    this.setState({
      verificationFieldDisplay: true,
      verifyCode: true
    });
  };

  render() {
    const {
      mobileNumber,
      error,
      verificationCode,
      verificationFieldDisplay
    } = this.state;
    const verifyPayload = this.props.verifiedNumbers[mobileNumber] !== undefined ? this.props.verifiedNumbers[mobileNumber]:null;
    return (
      <>
        {mobileNumber && verificationFieldDisplay ? (
          <>
            <div className="col-md-12 col-sm-12 col-xs-12 button-group">
              <button
                className="btn btn-primary"
                onClick={e => this.sendVerification(e)}
                type="button"
                data-toggle="modal"
                data-target="#verify-modal"
                style={{float: "right"}}
                disabled={verifyPayload && verifyPayload.token ? true : false}>
                Verify your number
              </button>
            </div>
            <div
              className={`form-group input-text modal fade`}
              id="verify-modal"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Verification</h5>
                    <button
                      type="button"
                      style={{marginTop: "-20px"}}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div
                      className="row"
                      style={{padding: "10px 45px 15px 45px"}}
                    >
                      <div
                        className="col-md-12 col-sm-12 col-xs-12 alert alert-success"
                        role="alert"
                        style={{color: "#FFF"}}
                      >
                        Please enter the OTP code that we have sent in to your
                        mobile phone.
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <label className="nuclei-input-label text-left p0">
                          OTP Code
                        </label>
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <InputText
                          tooltip={
                            "Please check your mobile phone for the OTP Code"
                          }
                          placeholder="xxxxxx"
                          type={"tel"}
                          value={verificationCode}
                          errorText={error.verificationCode}
                          onChange={e =>
                            this.onChangeHandler("verificationCode", e)
                          }
                          validation={validations.verificationCode}
                          minLength={6}
                          maxLength={6}
                        />
                      </div>

                                            <div className="col-md-6 col-sm-6 col-xs-6">&nbsp;</div>
                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                <button
                                                    className={"btn btn-sm btn-default"}
                                                    onClick={e => this.sendVerification(e)}
                                                    style={{float: "right", marginLeft: "5px"}}
                                                >
                                                    Resend
                                                </button>
                                                <button
                                                    disabled={
                                                        verificationCode &&
                                                        !error.verificationCode &&
                                                        verificationCode.length === 6
                                                            ? false
                                                            : true
                                                    }
                                                    className={"btn btn-sm btn-primary"}
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={e => this.verifyCode(e)}
                                                    style={{float: "right", marginLeft: "5px"}}
                                                >
                                                    Verify
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = state => {
  return {
    ci: state.ci,
    user: state.user,
    currentPolicyHolderId: state.ci.currentPolicyHolderId,
    verifiedNumbers: state.ci.verifiedNumbers,
    policyHolderList: state.ci.policyHolderList,
    verifyPayload: state.user.verifyPayload
  };
};

export default connect(
  mapStateToProps,
  {
    generateOTP,
    verifyOTPCode,
    addVerifiedNumber,
  }
)(VeryfyCode);
