import React, {Component} from "react";
import {withRouter} from "react-router";
import "./index.scss";
import {connect} from "react-redux";
import {Button, Spinner} from "../../components";
import {getFormData, removeDocument, submitForReview, uploadDocument} from "../../redux/reducers/documentManager";
import { Documents } from "./document";
import {message} from "antd";
import {getQueryParams} from "../../history";
import {isEmpty} from 'lodash';

class DocumentUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            hash_code: null,
            uploads: [],
            policyInformation: null,
            availableSubmit: true,
            uploading: false,
            acknowledgment: false
        };
    }

    async componentDidMount() {
        this.init();
    }

    async init() {
        try {
            this.setState({
                loading: true,
                hash_code: this.props.match.params.hash_code
            });
            if (this.props.match.params.hash_code !== undefined && this.props.match.params.hash_code.length > 10) {
                await this.getUploadDocumentsData();
                await this.checkDocs(this.props.uploader.data.documents)
                this.setState({
                    policyInformation: this.props.uploader.data.policy,
                })
            }
        } catch (e) {
            console.log(e.message)
        }
        this.setState({
            loading: false
        });
    }

    async getUploadDocumentsData() {
        await this.props.getFormData(this.props.match.params.hash_code);
        if (this.props.uploader.status !== 'success') {
            if (this.props.uploader.message !== null && this.props.uploader.message.length > 0) {
                message.error(this.props.uploader.message);
            }
            this.setState({
                loading: false,
            });

            return;
        }
        let availableSubmit = false;
        if (this.props.uploader.data.documents && this.props.uploader.data.documents.length > 0) {
            availableSubmit = this.props.uploader.data.documents.findIndex((item) => (parseInt(item.status) < 2 || !(typeof item.uploadedURL === 'string' && item.uploadedURL.length > 10))) > -1
        }
        this.setState({
            loading: false,
            availableSubmit: availableSubmit,
            uploads: this.props.uploader.data.documents,
        });
    }

    onRemoveDocument = async (id) => {
        this.setState({uploading: true});
        await this.props.removeDocument(id, this.state.hash_code);
        if (this.props.uploader.status !== 'success') {
            message.error(this.props.uploader.message);
        }  await this.getUploadDocumentsData()
        await this.checkDocs(this.props.uploader.data.documents)
        this.setState({uploading: false});
    }

    onUploadDocument = async (data) =>{
        this.setState({uploading: true});
        await this.props.uploadDocument(data);
        if (this.props.uploader.status !== 'success') {
            message.error(this.props.uploader.message);
        } else {
            message.success('Document uploaded success!!!');
            await this.getUploadDocumentsData()
            await this.checkDocs(this.props.uploader.data.documents)
        }
        this.setState({uploading: false});
    }

    async checkDocs(docsArray) {
        const reqDocs = [];
        for (let i  =0; i < docsArray.length; i++) {
            if (docsArray[i].uploadedURL !== null && docsArray[i].uploadedURL !== '') {
                reqDocs.push(docsArray[i].doc_id);
            }
        }
        const acknowledgment = ['2','12','13'].every( a => reqDocs.includes(a));
        this.setState({ acknowledgment: acknowledgment })
    }

    renderPolicyData() {
        if (this.state.policyInformation === null || this.state.policyInformation === undefined) {
            return null;
        } else return (
            <>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Name</p></div>
                    <div className="col-md-6 col-xs-6">
                        <p>{this.state.policyInformation.title} {this.state.policyInformation.name_initials} </p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Policy Number</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.cn_id}</p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Insurance Company</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.cn_company_name}</p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Premium</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.TotalPremium}</p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Vehicle Type</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.vehicle_type}</p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Vehicle registration No</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.veh_registration_no}</p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Engine Capacity</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.engine_capacity}</p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Type Of Cover</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.type_of_cover}</p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Vehicle Make</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.vehicle_make}</p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Vehicle Model</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.vehicle_model}</p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Manufacture Year</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.manufacture_year == 0 ? 'N/A' :
                        this.state.policyInformation.manufacture_year}</p></div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-6"><p>Fuel Type</p></div>
                    <div className="col-md-6 col-xs-6"><p>{this.state.policyInformation.fuel_type}</p></div>
                </div>

            </>
        );
    }

    onSubmitForReview = async() => {
        this.setState({
            loading: true
        });
        const para = getQueryParams();
        const document = this.props.uploader.data.documents;
        const reqDocs = [];
        for (let i  =0; i < document.length; i++) {
            if (document[i].uploadedURL !== null && document[i].uploadedURL !== '') {
                reqDocs.push(document[i].doc_id);
            }
        }
        if (para.app_code === 'ONLINE') {
            if (!reqDocs.includes('2')) {
                message.error('Please Upload Vehicle Registration');
                this.setState({
                    loading: false
                });
                return;
            }
            if (!reqDocs.includes('12')) {
                message.error('Please Upload NIC Front');
                this.setState({loading: false});
                return;
            }
            if (!reqDocs.includes('13')) {
                message.error('Please Upload NIC Back');
                this.setState({loading: false});
                return;
            }
        }

        await this.props.submitForReview(this.state.hash_code);
        const queryString = window.location.search;
        const url = new URLSearchParams(queryString)
        const params = url.get('app_code')

        if (this.props.uploader.status === 'success' && params !== null && params === 'ONLINE') {
            this.setState({
                loading: false
            });
            window.location = `${process.env.REACT_APP_PAYMENT_API}/Pay/insureme/${this.state.hash_code}`;
        }
        if (this.props.uploader.status !== 'success') {
            message.error('Sorry we got error while submit documents. Please contact admin to get fix this')
            return;
        }
        message.success('Documents successfully submitted for review!');
        this.init()
    }

    render() {
        const queryParams = getQueryParams();
        return (
            <Spinner spinning={this.state.loading}>
                <div className={'container-fluid'}>
                    <div className="row">
                        <div className={'col-md-12 col-lg-3 policy-container'}>
                            <div className="col-md-12"><h4>Policy Information</h4></div>
                            {this.renderPolicyData()}
                        </div>
                        <div className={'col-md-12 col-lg-9'}>
                            <Documents uploading={this.state.uploading}
                                       documents={this.state.uploads}
                                       hashCode={this.state.hash_code}
                                       uploadDocs={this.onUploadDocument}
                                       removeDocs={this.onRemoveDocument}
                                       onSubmitForReview={this.onSubmitForReview}
                                       availableSubmit={this.state.availableSubmit}
                                       wording={queryParams.app_code}
                                       coverType={!isEmpty(this.state.policyInformation) ? this.state.policyInformation.type_of_cover : ''}
                                       acknowledgment={this.state.acknowledgment}
                            />
                        </div>
                    </div>
                </div>
            </Spinner>

        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        uploader: state.uploader,
    };
};

export default connect(
    mapStateToProps, {
        getFormData,
        uploadDocument,
        removeDocument,
        submitForReview,
    }
)(withRouter(DocumentUploader));
