import React, {Component} from "react";
import "./index.scss";
import Profile from "./Profile";
import PDMI from "./PDMI";
import MajorIllness from "./MajorIllness";
import SeniorCitizen from "./SeniorCitizen";
import Documents from "./Documents";
import Payments from "./Payments";
import PersonalPolicies from "./PersonalPolicies";

export default class Dashboard extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            activeTab: 'Profile',
            sideNavVisible: false,
            activeSubmenu: null

        }
    }

    getActiveContent() {
        switch (this.state.activeTab) {

            case "Profile":
                return (
                    <Profile/>
                );
            case "PDMI":
                return (
                    <PDMI/>
                );
            case "MI":
                return (
                    <MajorIllness/>
                );
            case "SC":
                return (
                    <SeniorCitizen/>
                );
            case "Documents":
                return (
                    <Documents/>
                );
            case "Payments":
                return (
                    <Payments/>
                );
            case "PersonalPolicies":
                return (
                    <PersonalPolicies/>
                );
        }

    }

    onClickNavItem(e, tabID) {
        e.preventDefault();
        if (tabID === false) {
            return;
        } else {
            this.setState({
                activeTab: tabID,
                sideNavVisible:false
            });
        }
    }

    isActive(tabID) {
        if (this.state.activeTab === tabID) return 'active';
    }

    toggleSideNav(e) {
        this.setState({
            sideNavVisible: !this.state.sideNavVisible
        })
    }

    render() {
        let sideMenuActive = this.state.sideNavVisible ? 'active' : '';
        return (
            <div className={'dashboard-container'}>
                <div className="wrapper">
                    <div className="row">

                        <a href='#' onClick={(event) => this.toggleSideNav(event)}
                           className={`slide-menu-handler ${sideMenuActive}`}>
                            <span className={`glyphicon glyphicon-menu-hamburger ${sideMenuActive}`}></span>
                        </a>
                        {/*
                            TODO:
                            1.APIs should implemented
                            2. Organize this component
                         */}
                        {/*<nav id="sidebar" className={sideMenuActive}>*/}

                            {/*<ul className="list-unstyled components">*/}

                                {/*<li className={this.isActive('Profile')}>*/}
                                {/*    <a href="/profile" onClick={(e) => this.onClickNavItem(e, 'Profile')}>Profile</a>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <a href="/profile/personal-policies" onClick={(e) => this.onClickNavItem(e, 'PersonalPolicies')}>Personal*/}
                                {/*        Policies</a>*/}
                                {/*    /!*<ul className="list-unstyled sub-menu collapse sub-menu-level-1" id="personal-policies">*!/*/}
                                {/*    /!*    <li>*!/*/}
                                {/*    /!*        <a href="#" className={this.isActive('PDMI')}*!/*/}
                                {/*    /!*           onClick={(e) => this.onClickNavItem(e, 'PDMI')}>Per Day Motor*!/*/}
                                {/*    /!*            Insuarance</a>*!/*/}
                                {/*    /!*        <a href="#" className={this.isActive('MI')}*!/*/}
                                {/*    /!*           onClick={(e) => this.onClickNavItem(e, 'MI')}>Major Illness</a>*!/*/}
                                {/*    /!*        <a href="#" className={this.isActive('SC')}*!/*/}
                                {/*    /!*           onClick={(e) => this.onClickNavItem(e, 'SC')}>Senior Citizen</a>*!/*/}
                                {/*    /!*        <a className="dropdown-toggle collapsed" href="#motor-policies"*!/*/}
                                {/*    /!*           data-toggle="collapse" aria-expanded="false">Motor*!/*/}
                                {/*    /!*            <span className="glyphicon glyphicon-chevron-down"></span>*!/*/}
                                {/*    /!*        </a>*!/*/}

                                {/*    /!*        <ul className="list-unstyled sub-menu collapse sub-menu-level-1"*!/*/}
                                {/*    /!*            id="motor-policies">*!/*/}
                                {/*    /!*            <li>*!/*/}
                                {/*    /!*                <a href="#" className={this.isActive('Comprehensive')}*!/*/}
                                {/*    /!*                   onClick={(e) => this.onClickNavItem(e, 'Comprehensive')}>Comprehensive</a>*!/*/}
                                {/*    /!*                <a href="#" className={this.isActive('3rdParty')}*!/*/}
                                {/*    /!*                   onClick={(e) => this.onClickNavItem(e, '3rdParty')}>3rd Party</a>*!/*/}
                                {/*    /!*            </li>*!/*/}
                                {/*    /!*        </ul>*!/*/}
                                {/*    /!*    </li>*!/*/}
                                {/*    /!*</ul>*!/*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <a href="#">Corporate Policies</a>*/}
                                {/*</li>*/}
                                {/*<li className={this.isActive('renewals')}>*/}
                                {/*    <a href="#">Upcoming Renewals</a>*/}
                                {/*</li>*/}
                                {/*<li className={this.isActive('Payments')}>*/}
                                {/*    <a href="#" onClick={(e) => this.onClickNavItem(e, 'Payments')}>Payments</a>*/}
                                {/*</li>*/}
                                {/*<li className={this.isActive('Documents')}>*/}
                                {/*    <a href="#" onClick={(e) => this.onClickNavItem(e, 'Documents')}>Documents</a>*/}
                                {/*</li>*/}
                            {/*</ul>*/}
                        {/*</nav>*/}
                    </div>

                    <div className="row">
                        <div id="content">
                            {this.getActiveContent()}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
