import React, {Component} from "react";
import "./CriticalIllness.scss";
import {IMAGE_ROOT} from "../../config";
import {Overlay} from "../../components";

class NotCoveredModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompany: []
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.currentHolder) {
      if (nextProps.currentHolder.plans.length > 0) {
        this.setState({
          selectedCompany: nextProps.currentHolder.plans
        });
      }
    }
  
  }

  renderCompanyLogos(companies, checkedCompanies) {
    let logos = [];
    for (let i in companies) {
      let checkedClass = "";
      let isChecked = checkedCompanies.indexOf(companies[i].id);
      if (this.state.selectedCompany.length && this.state.selectedCompany[i]) {
        if (this.state.selectedCompany[i].companyId === companies[i].id) {
          checkedClass = "active";
        }
      }

      logos.push(
        <td className="company-column" key={i}>
          <div className={`covered-company-logo-container ${  checkedClass}`}>
            {checkedClass ? null : <Overlay width="68px" height="68px" />}
            <img
              className="covered-company-logo"
              src={`${IMAGE_ROOT}/${companies[i].company_logo}`}
              alt=""
            />
          </div>
        </td>
      );
    }
    return logos;
  }

  render() {
    let checkedCompanies = this.props.checkedCompanies;
    let illness = this.props.illness;
    return (
      <div
        className={`modal fade`}
        id="ilness-not-coverd-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Illnesses not covered</h5>
              <button
                type="button"
                style={{marginTop: "-20px"}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {checkedCompanies.length === 0 ? (
                <div className="row">
                  <div className="col-md-12">
                    <h3>No Company Selected</h3>
                  </div>
                </div>
              ) : null}
              <table className="table table-hover">
                <tbody>
                <tr className="company-row">
                    <td className="ilness-column">
                      <p>Company</p>
                    </td>
                    {this.renderCompanyLogos(
                      this.props.companies,
                      this.props.checkedCompanies
                    )}
                </tr>
                  {this.renderIllnessess(illness, checkedCompanies)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderIllnessess(illnessMetrix, checkedCompanies) {
    let illnessess = [];
    for (let illness in illnessMetrix) {
      illnessess.push(
        <tr className="illnes-row" key={illness}>
          <td>
            <p className={"illnes-name"}>
              {illness}
            </p>
            
          </td>
          {this.renderNotCoveredCompany(
            illnessMetrix[illness],
            checkedCompanies
          )}
        </tr>
      );
    }
    return illnessess;
  }

  renderNotCoveredCompany(notCoverdCompanies, checkedCompanies) {
    let companyLogos = [];

    for (let companyId in checkedCompanies) {
      let isNotCoverd = notCoverdCompanies.findIndex(function(item) {
        return companyId === item;
      });
      let img = "";
      if (isNotCoverd > -1) {
        img = (
          <img
            src={`${IMAGE_ROOT}/${checkedCompanies[companyId].company_logo}`}
            alt=""
          />
        );
      }
      companyLogos.push(
        <td className="not-covered-company" key={companyId}>
          {img}
          <i className="illnes-checked glyphicon glyphicon-remove-circle" />
        </td>
      );
    }
    return companyLogos;
  }
}

export default NotCoveredModal;
