import React, {Component} from "react";
import "./Step5.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import {PaymentBlock} from "../../../components/PaymentBlock";
import {OptionBlock} from "../../../components/OptionBlock";
import PaymentBlockList from "../../../components/PaymentBlockList";
import OptionBlockList from "../../../components/OptionBlockList";
import Promo from "../../../components/Promo";

export default class Step05 extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <HeaderTitle mainFirstWord="Payment" restOfTitle="Method" />

          <PaymentBlock
            paymentBlock={[
              {
                imgOneUrl:
                  "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/log2.png?alt=media&token=4ae5322c-5c37-495c-bd5a-a0a3d5426fb2",
                imgTwoUrl:
                  "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/log1.png?alt=media&token=9744766e-c635-44a2-afbf-7a7e0e37e1e3"
              },
              {
                imgOneUrl:
                  "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/log5.png?alt=media&token=44c92562-0d01-4ff9-bf71-5dd63569e700",
                cardName: "Sampath Vishwa",
                discount: "10",
                bankName: "Sampath",
                styleCardName: {color: "#A9A9A9"},
                styleText: {color: "#ea514c", margin: "0px 0px 0px 6px"}
              },
              {
                imgOneUrl:
                  "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/log3.png?alt=media&token=faf3a81f-14f8-468b-bee4-5711a96dea00",
                cardName: "Sampath PayApp",
                styleCardName: {color: "#A9A9A9"},
                styleText: {color: "#ea514c", margin: "0px 0px 0px 6px"}
              },
              {
                imgOneUrl:
                  "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/log4.png?alt=media&token=9d12e52d-bf40-45c6-b5ba-b444079a0f69",
                cardName: "HSBC Global Payments",
                discount: "20",
                bankName: "HSBC",
                styleCardName: {color: "#A9A9A9"},
                styleText: {color: "#ea514c", margin: "0px 0px 0px 6px"}
              },
              {
                imgOneUrl:
                  "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/log6.png?alt=media&token=1e1500fb-cb16-4182-80e2-e525840712f9",
                cardName: "Dialog Genie",
                styleCardName: {color: "#A9A9A9"},
                styleText: {color: "#ea514c", margin: "0px 0px 0px 6px"}
              },
              {
                imgOneUrl:
                  "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/log7.png?alt=media&token=5271b4ab-7617-4d04-8f31-f90e3d5793fd",
                cardName: "Dialog ezCash",
                styleCardName: {color: "#A9A9A9"},
                styleText: {color: "#ea514c", margin: "0px 0px 0px 6px"}
              },
              {
                imgOneUrl:
                  "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/log8.png?alt=media&token=73a24a9e-6bef-47d3-a6a2-e8902b410616",
                cardName: "Mobitel mCash",
                styleCardName: {color: "#A9A9A9"},
                styleText: {color: "#ea514c", margin: "0px 0px 0px 6px"}
              }
            ]}
          />

          <OptionBlockList
            optionBlock={[
              {
                priceBefore: 95000,
                priceNow: 76400,
                name: "Shafraz Fair",
                email: "mshafraz@gmail.com",
                contactNo: "0776379116",
                logoText: "Motor Inurance"
              }
            ]}
          />
          <div className="bottom-text" id="offer">
            <p id="p1">
              <span>
                <img
                  src={require("../../../assets/hsbc-logo-png-transparent.png")}
                  width="100"
                  alt=".."
                />
              </span>
              <span>20% OFF for HSBC Card Holders</span>
            </p>
            <p id="p2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
