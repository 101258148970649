import React from "react";
import Button from "./Button";
import "./Popup.scss";

function hidden() {
  for (let i = 0; i < document.getElementsByClassName("hiddin").length; i++) {
    if (
      document.getElementsByClassName("hiddin")[i].style.display === "block"
    ) {
      document.getElementsByClassName("hiddin")[i].style.display = "none";
    }
  }
}
function onSubmit(i, id) {
  if (document.getElementById(`bankcard${id}`).style.display === "block") {
    document.getElementById(`bankcard${id}`).style.display = "none";
  } else {
    document.getElementById(`bankcard${id}`).style.display = "block";
  }
}

export const BankDetails = ({
  dataSet = [],
  id,
  header,
  banklogo,
  cardname,
  cardvalue,
  mainBanklogo,
  premium,
  fullvalue
}) => {
  return (
    <div>
      <div className="col-md-12 col-sm-12 col-xs-12 front-gray-body">
        <div className="col-md-3 col-sm-3 col-xs-3">
          <img src={banklogo} className="bank-img-list" alt="" />
        </div>
        <div className="col-md-3 col-sm-3 col-xs-3 ">
          <h4 className="bankname">{cardname}</h4>
        </div>
        <div className="col-md-3 col-sm-3 col-xs-3 bankvalue">
          <p className="card-value">{cardvalue}</p>
        </div>
        <div className="col-md-3 col-sm-3 col-xs-3">
          <Button
            className="nuclei-bottom-bones"
            type="radius"
            color="blue"
            onClick={i => onSubmit(i, id)}
            children={"Select"}
          />
        </div>
      </div>
      <div id={`bankcard${id}`} className="hiddin as hiddinx ">
        <div className="col-md-12 col-sm-12 col-xs-12 front-gray-body-popup">
          <p className="bank-payment-header">{header}</p>
          {dataSet.map((data, i) => {
            return [
              <div key={i}>
                {/* <div className="col-md-5 col-sm-5">
            <div className="card">
              <div className="card-body">{data.month}</div>
            </div>
          </div>
          <div className="col-md-5 col-sm-5">
            <div className="card">
              <div className="card-body">{data.value}</div>
            </div>
          </div>
          <div className="col-md-2">&nbsp;</div> */}
                <tr>
                  <td className="card">
                    <p className="card-body">{data.month}</p>
                  </td>
                  <td className="card">
                    <p className="card-body">{data.value}</p>
                  </td>
                </tr>
              </div>
            ];
          })}
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
