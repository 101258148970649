import React from "react";

import "./components.scss";

export const BtnRectangle = ({
  type = "reactangle-btn",
  message = null,
  buttonStyle = {}
}) => {
  return (
    <button type="button" style={buttonStyle} className={type}>
      {message}
    </button>
  );
};
