import React, {Component} from "react";
import "./MultipleCarousel.scss";

import AliceCarousel from "react-alice-carousel";
import {Link} from "react-router-dom";
export default class MultipleCarousel extends Component {
  routerLink(Url) {
    return <Link to="/step1">About</Link>;
  }
  render() {
    const responsive = {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      1024: {
        items: 3
      }
    };
    return (
      <div id="nuclei-multiple-carousel">
        <AliceCarousel
          duration={400}
          autoPlay={true}
          startIndex={1}
          fadeOutAnimation={true}
          responsive={responsive}
          autoPlayInterval={4000}
          autoPlayDirection="ltl"
          dotsDisabled={true}
        >
          {this.props.multipleImage.map((multipleImage, i) => {
            return (
              <Link key={i} to={multipleImage.Url ? multipleImage.Url : ""}>
                <picture>
                  <source srcSet={multipleImage.imageUrl} type="image/webp" />
                  <img
                    src={multipleImage.imageUrl}
                    onClick={v => this.routerLink(multipleImage.Url)}
                    alt="Smiley face"
                    style={{padding: "10px"}}
                    width="100%"
                  />
                </picture>
              </Link>
            );
          })}
        </AliceCarousel>
      </div>
    );
  }
}
