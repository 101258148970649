import React, {Component} from "react";
import CustomPage from "./CustomPage";
import history, {getQueryParams} from "../../history";

export default class PaymentSuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const query = getQueryParams() || {};

    this.setState({
      title: query.title,
      status: query.status,
      buttonText: query.buttonText || query.buttontext,
      dynamicLink: query.dynamicLink || query.dynamiclink,
      description: query.description
    });
  }
  render() {
    const {title, status, buttonText, dynamicLink, description} = this.state;

    return (
      <CustomPage
        status={status}
        title={title}
        buttonText={buttonText}
        dynamicLink={dynamicLink}
        description={description}
      />
    );
  }
}
