import React, {Component} from "react";
import {
  InputText,
  Button,
  AutoCompleteInput,
  Alert,
  Spinner
} from "../../../components";
import ClassicQuoteForm from "../quotation/classic-quote-form";
import "./Inquiry.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import {
  validateWithValidations,
  isEmptyObjectValue,
  convertToSlug, setPromoTokenCookie
} from "../../../util";
import {connect} from "react-redux";
import {
  postInquiry,
  getAuthToken,
  onChangeInput,
  fetchPremiums,
  clearErrors
} from "../../../redux/actions";
import {compose} from "redux";
import MotorBreadcrumb from "../MotorBreadcrumb";
import history, {getQueryParams, addQuery} from "../../../history";
import {TITLES, HIDE_XS_WIDTH} from "../../../config";
import windowDimensions from "react-window-dimensions";
import {Helmet} from "react-helmet";
import queryString from 'query-string';
import {getPromoTokenCookie} from '../../../util';
import {CHANNEL_FRIMI, PROMO_CODE_FRIMI, PROMO_FRIMI_STYLE_TOKEN} from "../../../config";

const validations = {
  cx_name: {
    format: {
      pattern: "^[a-zA-Z ]+$",
      flags: "i",
      message: "Name is not valid "
    },
    presence: true
  },
  cx_email: {presence: true, email: true},
  cx_mobile: {
    numericality: {
      pattern: "[0-9]{9}",
      notLessThan: "9",
      presence: true
    },
    presence: true,
    length: {
      is: 9
    }
  }
};

class Inquiry extends Component {
  onClickHandler = () => {
    this.setState(prev => ({showResults: !prev.showResults}));
  };

  async componentDidMount() {
    let {insurance_type} = this.props.match.params;
    if (insurance_type === 'third-party') {
      this.setState({
        thirdParty: true
      });
    }
    this.props.clearErrors();
    const queryParams = getQueryParams();
    if (!queryParams.unq_hash || queryParams.unq_hash === "undefined") {
      this.props.history.push("/motor/Car/full-insurance/Quotation");
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      error: {},
      showResults: false,
      thirdParty: false
    };
    
    this.promoCode = (queryString.parse(this.props.location.search).promo) ? queryString.parse(this.props.location.search).promo : 'no-promo'
    this.channel = (queryString.parse(this.props.location.search).channel) ? queryString.parse(this.props.location.search).channel : 'Web-Motor'

    this.onSubmit = this.onSubmit.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  onChange = (type, e) => {
    const error = this.state.error;
    delete error[type];
    if (e.target) {
      this.props.onChangeInput({[type]: e.target.value});
      this.setState({[type]: e.target.value, error});
    } else {
      this.props.onChangeInput({[type]: e});
      this.setState({[type]: e, error});
    }
  };


  fetchFriMiCxInfo = (channel, promoCode, premiumParams)  => {

      if(channel === CHANNEL_FRIMI && promoCode === PROMO_CODE_FRIMI) {
        const cookies = getPromoTokenCookie(PROMO_FRIMI_STYLE_TOKEN);
        const local = JSON.parse(localStorage.getItem(PROMO_FRIMI_STYLE_TOKEN));

        localStorage.removeItem("cx_name");
        localStorage.removeItem("cx_mobile");
        localStorage.removeItem("cx_surname");
        localStorage.removeItem("cx_email");

        if (cookies.friMiLifeStyleCx != undefined) {
          let name = `${cookies.friMiLifeStyleCx.firstName} ${cookies.friMiLifeStyleCx.LastName}`;
          let mobileNumber = cookies.friMiLifeStyleCx.mobileNo.toString();
          mobileNumber = mobileNumber.length == 10  ? mobileNumber.substr(1) : mobileNumber;

          premiumParams.cx_name = name;
          premiumParams.cx_mobile = mobileNumber;

        }else if(local != undefined) {

          let name = `${local.friMiLifeStyleCx.firstName} ${local.friMiLifeStyleCx.LastName}`;

          let mobileNumber = local.friMiLifeStyleCx.mobileNo.toString();

          mobileNumber = mobileNumber.length == 10  ? mobileNumber.substr(1) : mobileNumber;

          premiumParams.cx_name = name;
          premiumParams.cx_mobile = mobileNumber;

        }


        return premiumParams;

      }
  }


  async onSubmit() {
    const premiumParams = this.props.premium.premiumParams;
    const error = validateWithValidations(premiumParams, validations);
    this.setState({error});
    if (isEmptyObjectValue(error)) {
      await this.props.fetchPremiums(premiumParams, true, true);
      const unq_hash = this.props.premium.unq_hash;
      const hostname = window.location.origin;
      premiumParams["prem_link"] = `${hostname}/motor/${convertToSlug(
        premiumParams.vehicle_type
      )}/${
        premiumParams.insurance_type
      }/Premium-Comparison?unq_hash=${unq_hash}`;
      if (
        this.props.premium.errorMessage ||
        !isEmptyObjectValue(this.props.premium.field_errors)
      ) {
        window.scrollTo(0, 0);
        return null;
      }
      addQuery({unq_hash}, this.props.history);

      if (!localStorage.getItem("cx_inquiry_id")) {
        await this.props.postInquiry({
          ...premiumParams,
          insurance_type: "Motor Insurance",
          main_category: "Motor",
          sub_category: premiumParams.vehicle_type,
          product_type: premiumParams.insurance_type,
          cx_message: "Comparison Request",
          promo_code:  this.promoCode,
          media_code: this.channel,
          inquiry_type: "Comparison Request",
          inquiry_code: "Web-Motor",
          inquiry_media: "WEB",
          email_subject_json: `InsureMe.lk | Comparison Request | Motor | ${
            premiumParams.vehicle_type
          } | ${premiumParams.cx_mobile}`,
          email_body_json: premiumParams
        });
      }

      if (!this.props.motor.errorMessage && unq_hash) {
        this.moveToPremium(premiumParams, unq_hash, this.channel, this.promoCode);
      }
    }
  }

  moveToPremium(premiumParams, unq_hash, channel, promoCode) {
    history.push(
      `/motor/${convertToSlug(premiumParams.vehicle_type)}/${
        premiumParams.insurance_type
      }/Premium-Comparison?unq_hash=${unq_hash}&channel=${channel}&promo=${promoCode}`
    );
  }

  onClear() {
    localStorage.removeItem("cx_name");
    localStorage.removeItem("cx_mobile");
    localStorage.removeItem("cx_surname");
    localStorage.removeItem("cx_email");
    this.props.onChangeInput({
      cx_name: "",
      cx_mobile: "",
      cx_surname: "Mr",
      cx_email: ""
    });
  }

  render() {
    const isHideOnXS = this.props.width < HIDE_XS_WIDTH;

    let premiumParams = this.fetchFriMiCxInfo(this.channel, this.promoCode, this.props.premium.premiumParams);

    if(premiumParams == undefined) {
      premiumParams = this.props.premium.premiumParams;
    }

    return (
      <div className="row">
        <Helmet>
          <meta name="author" content="InsureMe Insurance Brokers (Pvt) Ltd" />
          <meta name="robots" content="index, follow" />
          <meta name="revisit-after" content="1 month" />
          <title>
            Inquiry | InsureMe.lk | Sri Lanka's First Insurance Comparison
            Website
          </title>
        </Helmet>
        <MotorBreadcrumb unq_hash={this.props.premium.unq_hash} />
        <Alert
          type="danger"
          message={
            this.props.auth.errorMessage ||
            this.props.premium.errorMessage ||
            this.props.user.errorMessage
          }
          onClick={() => this.props.clearErrors()}
        />
        <div className="container-fluid personal-information insureme-form">
          <div className="col-md-4 col-sm-4 col-xs-12 nuclie-collapse-insureme-form">
            <div className="col-md-3 col-sm-12 visible-xs ">
              <div className="col-xs-7 zero-padding">
                <div className="col-xs-12 insurance-type-header">
                  <HeaderTitle
                    mainFirstWord="Insurance Detail"
                    restOfTitle=""
                    headerIcon={true}
                    headerIconStyle={{marginLeft: "-16px"}}
                    headerStyle={{fontWeight: "bold"}}
                    textStyle={{marginLeft: "30px"}}
                    subTitleStyle={{paddingLeft: "30px"}}
                    subTitle="Tap and change the details"
                  />
                </div>
              </div>

              <div className="visible-xs col-xs-5 ">
                <a
                  className="btn nuceli-sub-collapse edit-detail"
                  onClick={this.onClickHandler}
                >
                  Edit Detail
                </a>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-xs-12 hidden-xs">
              <HeaderTitle
                mainFirstWord="Insurance"
                restOfTitle="Detail"
                headerStyle={{fontWeight: "bold"}}
                subTitle="Tap and change the details"
              />
            </div>
            <div className="col-md-12 col-sm-12  ">
              <ClassicQuoteForm
                noHash
                isDocked={true}
                show={!isHideOnXS || this.state.showResults}
              />
            </div>
          </div>

          <div className="col-md-8 col-sm-8 col-xs-12 form-group">
            <Alert
              type="danger"
              message={this.props.user.errorMessage}
              onClick={() => this.props.clearErrors()}
            />
            <ul className="nuclei-margin-top sub-button">
              <HeaderTitle
                mainFirstWord="Insurance"
                headerIcon={true}
                headerIconStyle={{marginLeft: "-34px"}}
                headerStyle={{fontWeight: "bold"}}
                textStyle={{marginTop: "-16px", marginBottom: "37px"}}
              />

              <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text ">
                <label className="nuclei-input-label">
                  Title and Name of Registered Owner
                </label>

                <div className="col-md-2 col-sm-3 col-xs-4 zero-padding-left">
                  <AutoCompleteInput
                    type="text"
                    className="form-control"
                    placeholder={"Mr"}
                    options={TITLES}
                    id="cx_surname"
                    value={premiumParams.cx_surname}
                    onChange={e => this.onChange("cx_surname", e.value)}
                  />
                </div>
                <div className="col-md-9 col-sm-9 col-xs-8 zero-padding-left">
                  <InputText
                    tooltip="Please enter the name of the registered owner vehicle owner of the vehicle"
                    placeholder="Name with initials"
                    type={"text"}
                    value={premiumParams.cx_name}
                    onChange={e => this.onChange("cx_name", e)}
                    validation={validations.cx_name}
                    errorText={this.state.error.cx_name}
                  />
                </div>
              </div>

              <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text">
                <label className="nuclei-input-label">Phone</label>

                <div className="col-md-2 col-sm-2 col-xs-3 zero-padding-left">
                  <InputText disabled={true} value={"94"} />
                </div>
                <div className="col-md-9 col-sm-10 col-xs-9 zero-padding-left">
                  <InputText
                    tooltip="Please enter the phone number of registered vehicle owner"
                    placeholder="Phone No."
                    type={"text"}
                    value={premiumParams.cx_mobile}
                    errorText={this.state.error.cx_mobile}
                    onChange={e => this.onChange("cx_mobile", e)}
                    validation={validations.cx_mobile}
                  />
                </div>
              </div>

              <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text">
                <label className="nuclei-input-label">Email</label>
                <div className="col-md-11 col-sm-12 col-xs-12 zero-padding-left">
                  <InputText
                    tooltip="Please enter the email address of registered vehicle owner"
                    placeholder="Email Address"
                    type={"text"}
                    value={premiumParams.cx_email}
                    onChange={e => this.onChange("cx_email", e)}
                    errorText={this.state.error.cx_email}
                    validation={validations.cx_email}
                  />
                </div>
              </div>
              {/* Note ADD */}
              <div className="col-md-12 col-sm-12 col-xs-12" hidden={!!this.state.thirdParty}>
                <label style={{color:'#18a3ff',fontSize:'18px', paddingBottom:'5px', fontWeight:'bold'}}> Kindly note that one of our sales representative will contact you in soon to assist you. </label>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12" hidden={!!this.state.thirdParty}>
                <label style={{color:'#18a3ff', fontWeight:'normal', fontSize:'16px', paddingBottom:'5px'}}> We do not share your information with any third parties, this information will be used to provide you a premium service with our trained sales representatives. </label>
              </div>
              <div className="col-md-12 col-xs-12  col-sm-12 zero-padding-left">
                {this.props.user.loading ||
                this.props.premium.loading ||
                this.props.motor.loading ? (
                  <Spinner size="small" />
                ) : (
                  <div className="col-md-8 col-sm-8 col-xs-12 nuclei-sub-button">
                    <Button
                      type="radius"
                      color="red"
                      className="nuclei-bottom-red-button "
                      onClick={this.onSubmit}
                    >
                      Get me a quick quote
                    </Button>
                    <Button
                      type="radius"
                      color="gray"
                      className="nuclei-bottom-gray-button "
                      onClick={this.onClear}
                    >
                      Clear
                    </Button>
                  </div>
                )}
              </div>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
    motor: state.motor,
    premium: state.premium
  };
}

export default compose(
  windowDimensions(),
  connect(
    mapStateToProps,
    {
      postInquiry,
      getAuthToken,
      fetchPremiums,
      onChangeInput,
      clearErrors
    }
  )
)(Inquiry);
