import React, {Component} from "react";
import "./CriticalIllness.scss";
import {IMAGE_ROOT} from "../../config";
import {Overlay} from "../../components";
import _ from "lodash";

class CoverComparisonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompany: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentHolder) {
      if (nextProps.currentHolder.plans.length > 0) {
        this.setState({
          selectedCompany: nextProps.currentHolder.plans
        });
      }
    }
  
  }

  renderCompanyLogos(companies, checkedCompanies) {
    let logos = [];
    for (let i in companies) {
      let checkedClass = "";
      let isChecked = checkedCompanies.indexOf(companies[i].id);
      if (this.state.selectedCompany.length && this.state.selectedCompany[i]) {
        if (this.state.selectedCompany[i].companyId === companies[i].id) {
          checkedClass = "active";
        }
      }

      logos.push(
        <td className="company-column" key={i}>
          <div className={`covered-company-logo-container ${  checkedClass}`}>
            {checkedClass ? null : <Overlay width="68px" height="68px" />}
            <img
              className="covered-company-logo"
              src={`${IMAGE_ROOT}/${companies[i].company_logo}`}
              alt=""
            />
          </div>
        </td>
      );
    }
    return logos;
  }

  renderIlnessComparison(illnessMetrix, companies) {
    let illnessess = [];

    for (let illness in illnessMetrix) {
      illnessess.push(
        <tr className="illnes-row" key={illness}>
          <td>
            <p className="illnes-name">{illness}</p>
          </td>
          {this.renderCoverdStatus(illnessMetrix[illness], companies)}
        </tr>
      );
    }
    return illnessess;
  }

  renderCoverdStatus(illness, companies) {
    let CoverdStatus = [];
    for (let i in companies) {
      let company = companies[i];
      let status = "";
      if (illness.indexOf(company.id) > -1) {
        status = <i className="illnes-checked glyphicon glyphicon-ok-circle" />;
      }
      CoverdStatus.push(
        <td className="coverd-status" key={i}>
          {status}
        </td>
      );
    }
    return CoverdStatus;
  }

  render() {
    let illness = this.props.illness;
    return (
      <div
        className={`modal fade`}
        id="ilness-coverd-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">  
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 className="modal-title text-center">Covered illnesses</h5>
            </div>
            <div className="modal-body">
              <table className="table">
                <tbody>
                  <tr className="company-row">
                    <td className="ilness-column">
                      <p>Company</p>
                    </td>
                    {this.renderCompanyLogos(
                      this.props.companies,
                      this.props.checkedCompanies
                    )}
                  </tr>
                  {this.renderIlnessComparison(illness, this.props.companies)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CoverComparisonModal;
