import React, {Component} from "react";
import {FileInput} from "../../components/FileInput";


const dataSample = [

    {
        id: '1',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '2',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '3',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '4',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '5',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '6',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '7',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '8',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
];

const titlesSample = {
    policy_number: 'Policy Number',
    exp_data: 'Expire Date',
    total_cover: 'Total Cover',
    premium: 'Premium',
};

const sampleDocs = [
    {
        id: '1',
        title: 'Personal Documents',
        docs: [
            {
                id: '1',
                title: 'Identity card',
                icon: '',
                status: '',
                allowedFileTypes: '',
                uploadedURL: '',
            },
            {
                id: '1',
                title: 'Identity card',
                icon: '',
                status: '',
                allowedFileTypes: '',
                uploadedURL: '',
            },
            {
                id: '1',
                title: 'Identity card',
                icon: '',
                status: '',
                allowedFileTypes: '',
                uploadedURL: '',
            },
            {
                id: '1',
                title: 'Identity card',
                icon: '',
                status: '',
                allowedFileTypes: '',
                uploadedURL: '',
            },
            {
                id: '1',
                title: 'Identity card',
                icon: '',
                status: '',
                allowedFileTypes: '',
                uploadedURL: '',
            },
        ]
    },
    {
        id: '2',
        title: 'Another Category',
        docs: [
            {
                id: '1',
                title: 'Identity card',
                icon: '',
                status: '',
                allowedFileTypes: '',
                uploadedURL: '',
            },
            {
                id: '1',
                title: 'Identity card',
                icon: '',
                status: '',
                allowedFileTypes: '',
                uploadedURL: '',
            },
            {
                id: '1',
                title: 'Identity card',
                icon: '',
                status: '',
                allowedFileTypes: '',
                uploadedURL: '',
            },
            {
                id: '1',
                title: 'Identity card',
                icon: '',
                status: '',
                allowedFileTypes: '',
                uploadedURL: '',
            },
            {
                id: '1',
                title: 'Identity card',
                icon: '',
                status: '',
                allowedFileTypes: '',
                uploadedURL: '',
            },
        ]
    },
];


export default class Documents extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            hiddenCategories: []
        }
    }

    onRemoveDocument() {

    }

    onUploadDocument() {

    }

    renderDocumentsRows(data) {
        let rows = [],
            columns = [],
            rowKey = 0,
            columnKey = 0;

        for (let i in data) {
            let item = data[i];

            columns.push(
                <div className={'col-md-3 col-lg-2 col-sm-4'}>
                    <FileInput
                        key={columnKey}
                        id={item.id}
                        title={item.title}
                        icon={item.icon}
                        status={item.status}
                        allowedFileTypes={item.allowedTypes}
                        onPressRemove={(id) => this.onRemoveDocument(id)}
                        onPressUpload={(data) => this.onUploadDocument(data)}
                        uploadedURL={item.uploadedURL}
                    />
                </div>
            );
            columnKey++;
            if (columns.length === 6 || (parseInt(i) + 1) === data.length) {
                rows.push(
                    <div key={rowKey} className="row">
                        {columns}
                    </div>
                );
                rowKey++;
                columns = [];
            }
        }
        return rows;
    }

    onClickCategory(e, category) {
        let hiddenCategories = this.state.hiddenCategories,
            index = hiddenCategories.indexOf(category.id);
        if (index > -1) {
            hiddenCategories.splice(index, 1)
        } else {
            hiddenCategories.push(category.id);
        }
        this.setState({
            hiddenCategories: hiddenCategories
        })
    }

    renderCategories(data) {
        let categories = [];
        for (let i in data) {
            let category = data[i],
                isActive = this.state.hiddenCategories.indexOf(category.id) > -1 ? '' : 'active';

            categories.push(
                <div key={i} className={'doc-container'}>
                    <a href="#" className={`doc-title ${isActive}`}
                       onClick={(e) => this.onClickCategory(e, category)}>{category.title}</a>
                    {isActive !== '' ? this.renderDocumentsRows(category.docs) : null}
                </div>
            );
        }
        return categories;
    }

    render() {
        let item = sampleDocs[0];
        return (
            <div className={'documents-container'}>
                {this.renderCategories(sampleDocs)}
            </div>
        );
    }
}
