import axios from "axios";
import {getCommonError, hasAuthError} from "../../util";

import {ACCOUNT_API} from "../../config";

const GET_CUSTOMER_POLICIES = "GET_CUSTOMER_POLICIES";
const GET_CUSTOMER_POLICIES_DONE = "GET_CUSTOMER_POLICIES_DONE";
const GET_CUSTOMER_POLICIES_FAILD = "GET_CUSTOMER_POLICIES_FAILD";

const GET_CUSTOMER_DETAILS = "GET_CUSTOMER_DETAILS";
const GET_CUSTOMER_DETAILS_DONE = "GET_CUSTOMER_DETAILS_DONE";
const GET_CUSTOMER_DETAILS_FAILD = "GET_CUSTOMER_DETAILS_FAILD";

const GET_PER_DAY_POLICIES = "GET_PER_DAY_POLICIES";
const GET_PER_DAY_POLICIES_DONE = "GET_PER_DAY_POLICIES_DONE";
const GET_PER_DAY_POLICIES_FAILD = "GET_PER_DAY_POLICIES_FAILD";

const INIT_STATE = {
    loading: false,
    errorMessage: null,
    customerPolicies: []
};

export default function reducer(state = INIT_STATE, action) {
    const {type, payload} = action;

    switch (type) {
        case GET_CUSTOMER_POLICIES:
            return {...state, loading: true};
        case GET_CUSTOMER_POLICIES_DONE:
            return {...state, loading: false, customerPolicies: payload};
        case GET_CUSTOMER_POLICIES_FAILD:
            return {...state, loading: false, errorMessage: payload};

        case GET_CUSTOMER_DETAILS:
            return {...state, loading: true};
        case GET_CUSTOMER_DETAILS_DONE:
            return  {...state, loading: false, customerDetails: payload};
        case GET_CUSTOMER_DETAILS_FAILD:
            return {...state, loading: false, errorMessage: payload};

        case GET_PER_DAY_POLICIES:
            return {...state, loading: true};
        case GET_PER_DAY_POLICIES_DONE:
            return {...state, loading: false, perDayPolicies: payload};
        case GET_PER_DAY_POLICIES_FAILD:
            return {...state, loading: false, errorMessage: payload};
        default:
            return state;
    }
}

function isEmptyArray(array) {
    if (array && array.length > 0) {
        return false;
    }
    return true;
}

export const getCustomerPolicies = customerId => async (dispatch, getState) => {
    try {

        // const state = getState();
        //const {policies} = getState().customerPolicies;
        // if (!isEmptyArray(policies)) {
        //     return null;
        // }

        dispatch({type: GET_CUSTOMER_POLICIES});
        const res = await axios.get(`${ACCOUNT_API}/api/accountservice/v1/get_customerPolicies/${customerId}`);

        const error = hasAuthError(res);

        if (error) {
            return dispatch({type: GET_CUSTOMER_POLICIES_FAILD, payload: error});
        }
        return dispatch({type: GET_CUSTOMER_POLICIES_DONE, payload: res.data.data});


    } catch (error) {
        return dispatch({type: GET_CUSTOMER_POLICIES_FAILD, payload: getCommonError(error)});
    }
};

export const getCustomerDetails = (body) => async (dispatch, getState) => {

    try {

        dispatch({type: GET_CUSTOMER_DETAILS});
        const res = await axios({
            method: 'post',
            url: `${ACCOUNT_API}/api/accountservice/v1/lookup_customer`,
            header: {'Content-Type': 'application/json'},
            data: {
                ...body
            }
        });

        const error = hasAuthError(res);

        if (error) {
            return dispatch({type: GET_CUSTOMER_DETAILS_FAILD, payload: error});
        }

        return dispatch({type: GET_CUSTOMER_DETAILS_DONE, payload: res.data.data});

    } catch (error) {
        return dispatch({type: GET_CUSTOMER_DETAILS_FAILD, payload: getCommonError(error)});
    }

};

export const getPerDayPolicies = customerId => async (dispatch, getState) => {
    try {

        // const state = getState();
        //const {policies} = getState().customerPolicies;
        // if (!isEmptyArray(policies)) {
        //     return null;
        // }

        dispatch({type: GET_PER_DAY_POLICIES});
        const res = await axios.get(`${ACCOUNT_API}/api/accountservice/v1/perday_policies/${customerId}`);

        const error = hasAuthError(res);

        if (error) {
            return dispatch({type: GET_PER_DAY_POLICIES_FAILD, payload: error});
        }
        return dispatch({type: GET_PER_DAY_POLICIES_DONE, payload: res.data.data});


    } catch (error) {
        return dispatch({type: GET_PER_DAY_POLICIES_FAILD, payload: getCommonError(error)});
    }
};