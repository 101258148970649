import React, {Component} from "react";
import "./Slider.scss";
import $ from "jquery";
import DockPanel from "./DockPanel";
import SliderButtons from "./SliderButtons";
let refreshIntervalId = null;
export default class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.handleCarousel();
  }

  componentWillUnmount() {
    clearInterval(refreshIntervalId);
  }

  moveCarouselNext() {
    if (this.props.selectedSlide >= this.props.carouselImage.length - 1) {
      this.props.onChangedSelected(0);
    } else {
      this.props.onChangedSelected(this.props.selectedSlide + 1);
    }
  }

  moveCarouselPrev() {
    if (this.props.selectedSlide === 0) {
      this.props.onChangedSelected(this.props.carouselImage.length - 1);
    } else {
      this.props.onChangedSelected(this.props.selectedSlide - 1);
    }
  }

  handleCarousel() {
    var self = this;
    $(document).ready(function() {
      const homeCarousel = $("#home-carousel");
      if (!homeCarousel) {
        return null;
      }
      if (!$("#carosal-right").get(0)) {
        return null;
      }

      refreshIntervalId = setInterval(() => {
        // self.moveCarouselNext();

        $("#carosal-right")
          .get(0)
          .click();
      }, 8000);

      $("#home-carousel").on("swiperight", function() {
        $("#carosal-left")
          .get(0)
          .click();
      });
      $("#home-carousel").on("swipeleft", function() {
        $("#carosal-right")
          .get(0)
          .click();
      });
    });
  }
  resize() {
    if (window.innerWidth < 768) {
      if (this.state.isMobile !== true) {
        this.setState({
          isMobile: true
        });
      }
    } else {
      if (this.state.isMobile !== false) {
        this.setState({
          isMobile: false
        });
      }
    }
  }

  sliderButtons = item => {
    return (
      <SliderButtons
        sliderDetail={{
          header: item.header,
          description: item.description,
          bottomText: item.bottomText,
          headerUrl: item.headerUrl,
          bottomTextUrl: item.bottomTextUrl
        }}
      />
    );
  };
  render() {
    return (
      <div id="nuclei-slider-container">
        <div
          id="home-carousel"
          ref="buttonContainer"
          onChange={() => console.log("afterSlide")}
          className="carousel slide"
          data-ride="carousel"
          data-pause="hover"
          data-slide-to={this.props.selectedSlide}
          data-interval="100000000"
        >
          <ol className="left carousel-indicators hidden-xs">
            <li
              data-target="#home-carousel"
              data-slide-to="0"
              className="active"
            />
            {this.props.carouselImage.map((carouselImage, i) => {
              if (i === 0) {
                return null;
              }
              return (
                <li
                  key={i}
                  onClick={() => this.props.onChangedSelected(i)}
                  data-target="#home-carousel"
                  data-slide-to={i}
                />
              );
            })}
          </ol>

          <div className="carousel-inner ">
            {this.props.carouselImage.map((carouselImage, i) => {
              if (i === 0) {
                return (
                  <div
                    key={i}
                    style={
                      this.state.isMobile
                        ? carouselImage.mobile
                        : carouselImage.web
                    }
                    className="item item-bg active "
                  >
                    {this.sliderButtons(carouselImage)}
                  </div>
                );
              } else {
                return (
                  <div
                    key={i}
                    style={
                      this.state.isMobile
                        ? carouselImage.mobile
                        : carouselImage.web
                    }
                    className="item item-bg "
                  >
                    {this.sliderButtons(carouselImage)}
                  </div>
                );
              }
            })}
          </div>

          <div className="carousel-caption d-none d-md-block nuclei-carousel-detail">
            <div className="col-md-5 col-sm-5 col-xs-12 nuclei-home-dock-panel">
              <DockPanel dockPanel={this.props.dockPanel} />
            </div>
          </div>
          <a
            id="carosal-left"
            onClick={() => this.moveCarouselPrev()}
            className="left carousel-control"
            href="#home-carousel"
            role="button"
            data-slide="prev"
          />
          <a
            id="carosal-right"
            onClick={() => this.moveCarouselNext()}
            className="right carousel-control"
            href="#home-carousel"
            role="button"
            data-slide="next"
          />
        </div>
      </div>
    );
  }
}
