import React, {Component} from "react";
import "./CoverComparison.scss";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import {
  getAuthToken,
  fetchPremiumsByUniqHash,
  getCoverComparisons,
  clearErrors
} from "../../../redux/actions";
import {Alert, Spinner} from "../../../components";
import {addCommas} from "../../../util";
import {getQueryParams} from "../../../history";
import {IMAGE_ROOT} from "../../../config";
import MotorBreadcrumb from "../MotorBreadcrumb";
// import {runInThisContext} from "vm";
import {Helmet} from "react-helmet";

class CoverComparison extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    await this.props.getAuthToken();
    const queryParams = getQueryParams(this.props.location.search);
    if (!queryParams.unq_hash || queryParams.unq_hash === "undefined") {
      this.props.history.push("/motor/Car/full-insurance/Quotation");
      return null;
    }

    const quotationsObject = this.getQuotationObject();
    await this.props.getAuthToken();
    await this.props.getCoverComparisons(
      queryParams.unq_hash,
      quotationsObject
    );
    this.props.fetchPremiumsByUniqHash(queryParams.unq_hash);
  }

  getQuotationObject() {
    const quotations = [];
    const queryParams = getQueryParams(this.props.location.search);
    if (queryParams.qot_number0) {
      quotations.push(queryParams.qot_number0);
    }
    if (queryParams.qot_number1) {
      quotations.push(queryParams.qot_number1);
    }
    if (queryParams.qot_number2) {
      quotations.push(queryParams.qot_number2);
    }
    if (quotations.length < 2) {
      this.props.history.push("/motor/Car/full-insurance/Quotation");
      return null;
    }
    const quotationsObject = {};
    quotations.forEach((item, i) => {
      quotationsObject[i] = item;
    });
    this.setState({quotationsObject});
    return quotationsObject;
  }

  async onClickChangeCompany(qotIndex, company) {
    const quotationsObject = this.state.quotationsObject;
    quotationsObject[this.state.selectedIndex] = company.qot_number;

    const queryParams = getQueryParams(this.props.location.search);
    await this.props.getCoverComparisons(
      queryParams.unq_hash,
      quotationsObject
    );
  }
  optionHeaders(company, i, quotationsObject) {
    if (this.state && !quotationsObject) {
      return null;
    }
    return (
      <th key={i} className="nuclei-plan-header nuclei-plan-header-free">
        <Helmet>
          <meta name="author" content="InsureMe Insurance Brokers (Pvt) Ltd" />
          <meta name="robots" content="index, follow" />
          <meta name="revisit-after" content="1 month" />
          <title>
            Cover Comparison | InsureMe.lk | Sri Lanka's First Insurance
            Comparison Website
          </title>
        </Helmet>
        <div className="nuclei-table-card-header">
          <div
            className="modal fade "
            id="exampleModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered nuclei-model"
              role="document"
            >
              <div className="modal-content nuclei-modal-content">
                {this.props.premium.premiums.map((p, r) => {
                  if (!p.qot_number) {
                    return null;
                  }
                  // let already = false;
                  if (p.qot_number === quotationsObject[0]) {
                    return null;
                  }

                  if (p.qot_number === quotationsObject[1]) {
                    return null;
                  }

                  if (p.qot_number === quotationsObject[2]) {
                    return null;
                  }

                  return (
                    <div
                      onClick={() => this.onClickChangeCompany([i], p)}
                      className="company-option"
                      data-dismiss="modal"
                      key={r}
                    >
                      <img src={`${IMAGE_ROOT}/${p.company_logo}`} alt="Insureme.lk" />
                    </div>
                  );
                })}
                <div className="modal-footer nuclei-model-footer">
                  <button
                    type="button"
                    className="btn btn-secondary nuclei-footer-button"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          <span
            className="nuclei-icon-heder"
            data-toggle="modal"
            data-target="#exampleModalCenter"
          >
            <img
              className="nuclei-icon-img"
              src={require("../../../assets/elevator.svg")}
              alt="..."
            />
            <span
              className="nuclei-icon-text"
              onClick={() => this.setState({selectedIndex: i})}
            >
              Change
            </span>
          </span>
          <div className="nuclei-pricing-plan-name" />
          <div className="nuclei-pricing-plan-header-img text-center">
            <img src={`${IMAGE_ROOT}/${company.logo}`} alt="" />
          </div>
          <div className="nuclei-pricing">
            Rs. {addCommas(company.TotalPremium)}
          </div>
        </div>
      </th>
    );
  }

  renderBasicCoverInSmallView(cover, i) {
    return (
      <tr
        key={i + cover}
        className={`visible-xs ${i % 2 === 1 ? "bg-white-row" : ""}`}
      >
        <td colSpan="3">{cover}</td>
      </tr>
    );
  }

  renderColumn(company, i, optionClassName, key) {
    let value = company.cover_details[i];
    if (value === "TRUE") {
      value = <span className={`icon-yes glyphicon glyphicon-ok`} />;
    } else if (value === "FALSE") {
      value = <span className={`icon-no glyphicon glyphicon-remove`} />;
    }
    return (
      <td
        key={key}
        className={`${optionClassName} ${i % 2 === 1 ? "bg-white-row" : ""}`}
      >
        <div style={{color: "#999999"}}>{value}</div>
      </td>
    );
  }

  renderContents(cover, companies, j) {
    let optionClassName = "";
    return (
      <tr key={j}>
        <td className={`hidden-xs ${j % 2 === 1 ? "bg-white-row" : ""}`}>
          {" "}
          <div className={`${optionClassName} `}>{cover} </div>
        </td>
        {companies.map((company, key) => {
          return this.renderColumn(company, j, optionClassName, key);
        })}
      </tr>
    );
  }

  render() {
    const queryParams = getQueryParams(this.props.location.search);
    const {premium} = this.props;
    return (
      <div className="row">
        <MotorBreadcrumb unq_hash={this.props.premium.unq_hash} />
        <Alert
          type="danger"
          message={
            this.props.auth.errorMessage ||
            this.props.premium.errorMessage ||
            this.props.motor.errorMessage ||
            this.props.user.errorMessage
          }
          onClick={() => this.props.clearErrors()}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="">
              {premium.companies.length ? null : (
                <Spinner spinning={premium.loadingCovers || premium.loading} />
              )}
              <div className="membership-pricing-table">
                <table>
                  <tbody>
                    <tr>
                      <th className="header-white hidden-xs" />
                      {premium.companies.map((company, i) => {
                        return this.optionHeaders(
                          company,
                          i,
                          this.state.quotationsObject
                        );
                      })}
                    </tr>
                    {premium.basic_covers.map((cover, i) => {
                      return [
                        this.renderBasicCoverInSmallView(cover, i),
                        this.renderContents(cover, premium.companies, i)
                      ];
                    })}

                    <tr>
                      <td className="hidden-xs" />
                      {premium.companies.map((company, i) => {
                        return (
                          <td
                            key={i}
                            id="btn-td"
                            className="col-md-12 col-sm-12 col-xs-12 aa"
                          >
                            <Link
                              to={`/account?unq_hash=${
                                queryParams.unq_hash
                              }&qot_number=${company.qot_number ||
                                company.quot_number}`}
                            >
                              <button
                                type="button"
                                className="btn btn-primary btn-buy-now"
                              >
                                BUY NOW
                              </button>
                            </Link>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth,
    premium: state.premium,
    motor: state.motor
  };
}

export default connect(
  mapStateToProps,
  {
    getAuthToken,
    fetchPremiumsByUniqHash,
    getCoverComparisons,
    clearErrors
  }
)(CoverComparison);
