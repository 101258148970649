import React from "react";
import Button from "./Button";
import "./Popup.scss";
import {BankDetails} from "../components";

function paymentSchemeDetails(paymentSchemeDetails = []) {
  return (
    <div>
      {paymentSchemeDetails.map((paymentSchemeDetail, i) => {
        return (
          <BankDetails
            key={i}
            banklogo={require("../assets/logo-janashakthi.jpg")}
            cardname="Sampath bank Card"
            cardvalue="Rs.12500"
            header="Convenient Easy Payment Scheme By HSBC"
            dataSet={paymentSchemeDetail.data}
            id={i}
          />
        );
      })}
    </div>
  );
}

function renderCardItem() {
  return (
    <div>
      <div className="col-md-12 col-sm-12 col-xs-12 popup-class">
        <span className="col-md-3 col-sm-3 col-xs-3 padding-manage">
          <img
            src={require("../assets/logo-janashakthi.jpg")}
            className="bank-img"
            alt=""
          />
        </span>
        <span className="col-md-4 col-sm-4 col-xs-4 text-align">
          <p className="Premium-head">Motor Insurance Premium</p>
        </span>
        <div className="col-md-4 col-sm-4 col-xs-4">
          <h4 id="underline">Rs. 95,800.50</h4>
        </div>
      </div>
    </div>
  );
}

export const PopupC = props => {
  const dataSet = [
    {
      data: [
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        }
      ]
    },
    {
      data: [
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        }
      ]
    },
    {
      data: [
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        }
      ]
    },
    {
      data: [
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        },
        {
          value: "RS.35656",
          month: "6 Month"
        }
      ]
    }
  ];

  return (
    <div
      className="modal fade "
      id="myModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">{renderCardItem()}</div>
          <div className="modal-footer">
            {paymentSchemeDetails(dataSet)}
            <div className="col-md-7 col-sm-8 col-xs-8">
              <Button
                type="radius"
                color="gray"
                className="nuclei-bottom-gray-button bones-clear"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupC;
