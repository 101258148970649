import axios from "axios";
import {
  LEAD_AUTH_API,
  LEAD_AUTH_USERNAME,
  LEAD_ACCESS_KEY,
  LEAD_TOKEN_COOKIE,
  LEAD_ASSIGNED_USER_ID,
  LEAD_SESSION_NAME,
  AUTH_KEY_COOKIE
} from "../../config";
import {leadAuthError, getCommonError} from "../../util";
import Cookies from "js-cookie";
// import { stat } from "fs";

const GET_LEAD_TOKEN = "GET_LEAD_TOKEN";
const GET_LEAD_TOKEN_DONE = "GET_LEAD_TOKEN_DONE";
const GET_LEAD_TOKEN_FAILED = "GET_LEAD_TOKEN_FAILED";

const GET_LEAD_LOGIN_DATA = "GET_LEAD_LOGIN_DATA";
const GET_LEAD_LOGIN_DATA_DONE = "GET_LEAD_LOGIN_DATA_DONE";
const GET_LEAD_LOGIN_DATA_FAILED = "GET_LEAD_LOGIN_DATA_FAILED";

const GET_LEAD_DATA = "GET_LEAD_DATA";
const GET_LEAD_DATA_DONE = "GET_LEAD_DATA_DONE";
const GET_LEAD_DATA_FAILED = "GET_LEAD_DATA_FAILED";

const GET_ASSIGNED_USER_DATA = "GET_ASSIGNED_USER_DATA";
const GET_ASSIGNED_USER_DATA_DONE = "GET_ASSIGNED_USER_DATA_DONE";
const GET_ASSIGNED_USER_DATA_FAILED = "GET_ASSIGNED_USER_DATA_FAILED";

const INIT_STATE = {
  loading: false,
  errorMessage: null,
  leadToken: null,
  assigned_user_id: null,
  leadLoginDetails: []
};

export const getLeadAuthToken = () => async dispatch => {
  try {
    dispatch({type: GET_LEAD_TOKEN});
    const res = await axios.get(
      `${LEAD_AUTH_API}/vtigercrm/webservice.php?operation=getchallenge&username=${LEAD_AUTH_USERNAME}`
    );
    const error = leadAuthError(res);
    if (error) {
      return dispatch({type: GET_LEAD_TOKEN_FAILED, payload: error});
    }
    const leadToken = res.data.result.token;
    Cookies.set(LEAD_TOKEN_COOKIE, leadToken, {
      expires: 1 / 48 // avilable for 30 mins
    });
    return dispatch({type: GET_LEAD_TOKEN_DONE, payload: leadToken});
  } catch (error) {
    return dispatch({type: GET_LEAD_TOKEN_FAILED, payload: false});
  }
};

export const loginToLead = body => async dispatch => {
  try {
    delete axios.defaults.headers.common["auth_key"];
    var md5 = require("md5");
    var querystring = require("querystring");

    dispatch({type: GET_LEAD_LOGIN_DATA});
    axios
      .post(
        `${LEAD_AUTH_API}/vtigercrm/webservice.php`,
        querystring.stringify({
          username: LEAD_AUTH_USERNAME,
          operation: "login",
          accessKey: md5(`${Cookies.get(LEAD_TOKEN_COOKIE)}${LEAD_ACCESS_KEY}`)
        })
      )
      .then(function(response) {
        const error = leadAuthError(response);
        if (error) {
          return dispatch({type: GET_LEAD_LOGIN_DATA_FAILED, payload: error});
        }

        const sessionName = response.data.result.sessionName;
        Cookies.set(LEAD_SESSION_NAME, sessionName);
     
        dispatch({
          type: GET_LEAD_LOGIN_DATA_DONE,
          payload: response.data.result
        });
        return dispatch(getUserId({body}));
      });
  } catch (error) {
    return dispatch({
      type: GET_LEAD_LOGIN_DATA_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getUserId = body => async dispatch => {
  try {
    dispatch({type: GET_ASSIGNED_USER_DATA});
    const res = await axios.get(
      `${ 
        LEAD_AUTH_API 
        }/vtigercrm/webservice.php?operation=query&sessionName=${ 
        Cookies.get(LEAD_SESSION_NAME) 
        }&query=select id from Users where user_name='${ 
        body.body.assingedUserName 
        }';`
    );
    const error = leadAuthError(res);
    if (error) {
      return dispatch({type: GET_ASSIGNED_USER_DATA_FAILED, payload: error});
    }
    const assigned_user_id = res.data.result[0].id;
    Cookies.set(LEAD_ASSIGNED_USER_ID, assigned_user_id);

    dispatch({type: GET_ASSIGNED_USER_DATA_DONE, payload: assigned_user_id});
    return dispatch(createLead({body}));
  } catch (error) {
    return dispatch({type: GET_ASSIGNED_USER_DATA_FAILED, payload: false});
  }
};

export const createLead = body => async dispatch => {
  try {
    const leadBody = {
      lastname: body.body.body.lastName,
      assigned_user_id: Cookies.get(LEAD_ASSIGNED_USER_ID),
      leadsource: body.body.body.leadsource,
      phone: body.body.body.phone,
      email: body.body.body.email,
      leadstatus: "Pending",
      description: body.body.body.description
    };
    delete axios.defaults.headers.common["auth_key"];
    var querystring = require("querystring");

    dispatch({type: GET_LEAD_DATA});
    const res = axios.post(
      `${LEAD_AUTH_API}/vtigercrm/webservice.php`,
      querystring.stringify({
        sessionName: Cookies.get(LEAD_SESSION_NAME),
        operation: "create",
        elementType: "Leads",
        element: JSON.stringify(leadBody)
      })
    );
    const error = leadAuthError(res);
    if (error) {
      return dispatch({type: GET_LEAD_DATA_FAILED, payload: error});
    }
    const savedCookie = Cookies.get(AUTH_KEY_COOKIE);
    axios.defaults.headers.common["auth_key"] = savedCookie;
    return dispatch({type: GET_LEAD_DATA_DONE, payload: res.data.result});
  } catch (error) {
    return dispatch({
      type: GET_LEAD_DATA_FAILED,
      payload: getCommonError(error)
    });
  }
};

export default function reducer(state = INIT_STATE, action) {
  const {type, payload} = action;

  switch (type) {
    case GET_LEAD_TOKEN:
      return {...state, loading: false};
    case GET_LEAD_TOKEN_DONE:
      return {...state, leadToken: payload};
    case GET_LEAD_TOKEN_FAILED:
      return {...state, loading: false};
    case GET_LEAD_LOGIN_DATA:
      return {...state, loading: true};
    case GET_LEAD_LOGIN_DATA_DONE:
      return {...state, loading: false, leadLoginDetails: payload};
    case GET_LEAD_LOGIN_DATA_FAILED:
      return {...state, loading: false};
    case GET_ASSIGNED_USER_DATA:
      return {...state, loading: false};
    case GET_ASSIGNED_USER_DATA_DONE:
      return {...state, assigned_user_id: payload};
    case GET_ASSIGNED_USER_DATA_FAILED:
      return {...state, loading: false};
    default:
      return state;
  }
}
