import React, {Component} from "react";
import "./CriticalIllness.scss";
import {ASSETS_URL} from "../../config";
import {addCommas} from "../../util";

class SettlementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentHolder: 0
    };
  }

  renderCompanies(currentHolderID, companies) {
    let companiesRenderd = [];
    let holders = this.props.holders;
    let currentHolder = holders[currentHolderID];
    if (currentHolder === undefined) return;
    for (let i in currentHolder.plans) {
      let company = companies.find(function(item) {
        return item.id === currentHolder.plans[i].companyId;
      });
      let limit = company.limits.find(function(item) {
        return item.id === currentHolder.plans[i].limitId;
      });
      companiesRenderd.push(
        <div key={i} className={'company-settlement'}>
          <div className="company-container">
            <div className="logo-container">
              <img src={ASSETS_URL + company.company_logo} alt="" />
            </div>
            <div className="company-title">
              <p>{company.name}</p>
            </div>
          </div>
          {this.renderLimits(limit)}
        </div>
      );
    }
    return companiesRenderd;
  }

  render() {
    let holders = this.props.holders;
    let companies = this.props.companies;
    let currentHolder = this.state.currentHolder;
    let checkedCompanies = this.props.checkedCompanies;
    return (
      <div
        className={`modal fade`}
        id="settlement-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Schedule of benefit</h5>
              <button
                type="button"
                style={{marginTop: "-20px"}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {checkedCompanies.length === 0 ? (
                <div className="row">
                  <div className="col-md-12">
                    <h3>No Company Selected</h3>
                  </div>
                </div>
              ) : null}
              <div className="row" style={{padding: "5px 0px 10px 0px"}}>
                {this.renderHolders(holders, currentHolder)}
              </div>
              {this.renderCompanies(currentHolder, companies)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLimits(limit) {
    let renderedSubLimits = [];
    for (let i in limit.sublimits) {
      let subLimit = limit.sublimits[i];
      renderedSubLimits.push(
        <div className="settlement-sub-limit text-left" key={i}>
          <p className="sub-limit-name">{subLimit.name}</p>
          <p className="sub-limit-dec">{subLimit.description}</p>
          {subLimit.limit !== null ?(<p className="sub-limit-price">Rs.{addCommas(subLimit.limit)}</p>):''}
        </div>
      );
    }
    return renderedSubLimits;
  }

  selectPolicyHolder(holder) {
    this.setState({
      currentHolder: holder.id
    });
  }

  renderHolders(holders) {
    let users = [];
    let currentHolderId = this.state.currentHolder;
    if (holders.length < 1) {
      return;
    }
    if (holders[currentHolderId] === undefined) {
      currentHolderId = 0;
    }
    for (let i in holders) {
      let holder = holders[i];
      let isActivated = holder.id === currentHolderId ? "-activated" : "";
      users.push(
        <div
          onClick={() => this.selectPolicyHolder(holder)}
          className="col-sm-3 col-xs-4 text-center"
          key={i}
        >
          <img src={require(`../../assets/ci/add-user-icon${ isActivated }.png`)} 
               alt="Insureme.lk"  
               className="policy-holder-avatar" />
          <label className={"nuclei-input-label"}>{holder.nameInitials}</label>
        </div>
      );
    }

    return users;
  }
}

export default SettlementModal;
