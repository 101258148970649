import React, {Component} from 'react';
import './Tab.scss';

import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";
import {Icon} from 'react-icons-kit';
import {comment} from 'react-icons-kit/metrize/comment'
import {mail} from 'react-icons-kit/metrize/mail'
import {telephone} from 'react-icons-kit/metrize/telephone'
import {connect} from "react-redux";
import {
    getCustomerPolicies, getPerDayPolicies
} from "../redux/reducers/consumer";
import {withRouter} from "react-router";

class Tab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            percentage: '',
            error: ''
        }
    }

    componentDidMount() {
        let errors = this.props.consumer.errorMessage;

        this.setState({error: errors});
    }

    componentWillUnmount() {
        this.setState({error: null});
    }

    renderCircle(date) {

        let currentDate = Date.now();
        let endDate = new Date(date).getTime();
        let dateDifference = Math.floor((endDate - currentDate) / (1000 * 60 * 60 * 24));

        let percentage = ((dateDifference / 360) * 100);


        return (
            <Circle label=''>
                <CircularProgressbar
                    value={Math.abs(percentage)}
                    text={Math.abs(dateDifference)}
                    styles={buildStyles({
                        pathColor: percentage <= 0 ? '#df1e1e' : '#60cc84',
                        textColor: percentage <= 0 ? '#df1e1e' : '#60cc84'
                    })}
                />
            </Circle>
        );

    }

    renderHeader(data) {
        if (Array.isArray(data) === false) {
            return (
                <div style={{paddingLeft: 25}}>
                    <p> {data} </p>
                </div>
            );
        }

        let headers = [];
        let header;

        for (let i in data) {
            header = data[i];

            headers.push(
                <div className='col-md-5 tab-content'>
                    <row className='col-md-12 details'>
                        <div className='circle'>
                            {this.renderCircle(header.commencement_date)}

                        </div>

                        <div className='policy-number'>
                            <row>
                                <p style={{
                                    marginBottom: -5,
                                    fontWeight: "bold",
                                    fontSize: 18
                                }}>{header.policy_number}</p>
                                <a href='#'><span className='detail-span'>Details</span></a>
                                <span style={{paddingBottom: 25}}>Policy Number</span>
                            </row>
                            <row>
                                <div className='total-cover'>
                                    <p style={{
                                        marginBottom: -5,
                                        fontWeight: "bold",
                                        fontSize: 18
                                    }}>{header.total_cover}</p>
                                    <span style={{}}>Total Cover</span>
                                </div>
                                <div className='premium'>
                                    <p style={{
                                        marginBottom: -5,
                                        fontWeight: "bold",
                                        fontSize: 18
                                    }}>{header.premium}</p>
                                    <span style={{}}>Premium</span>
                                </div>
                            </row>
                            <row>
                                <p>Expire Date <span
                                    style={{color: new Date(header.commencement_date).getTime() <= Date.now() ? '#df1e1e' : '#60cc84'}}>{header.commencement_date}</span>
                                </p>
                            </row>

                        </div>

                    </row>
                    <row className='col-md-12 icon'>
                        <div className='social-icon'>
                            <li><a href='#'><Icon size={25} icon={comment}/></a></li>
                            <li><a href='#'><Icon size={25} icon={mail}/></a></li>
                            <li><a href='#'><Icon size={25} icon={telephone}/></a></li>
                        </div>
                        <div className='message-info'
                             style={{visibility: new Date(header.commencement_date).getTime() <= Date.now() ? 'visible' : 'hidden'}}>
                            <span> Renew now and save Rs.2500 </span>
                        </div>
                    </row>
                </div>
            );
        }

        return headers;

    }


    render() {
        return (
            <div className=''>
                {this.renderHeader(this.props.data, this.state.error)}
            </div>
        );
    }

}

function Circle(props) {
    return (
        <div style={{}}>
            <div style={{marginTop: 10}}>
                <div style={{maxWidth: "35%", minWidth: '65%'}}>{props.children}</div>
                {/* <div style={{ width: "60%" }}> */}
                {/* <h3 className="h5">{props.label}</h3> */}
                {/* <p>{props.description}</p> */}
                {/* </div> */}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        consumer: state.consumer

    };
}

export default connect(mapStateToProps, {
        getCustomerPolicies,
        getPerDayPolicies
    }
)(withRouter(Tab));