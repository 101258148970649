import axios from "axios";
import _ from "lodash";
import {
    getCommonError,
    getInquiryCookie,
    hasAuthError,
    hasFieldErrors,
    hasMultipleRecords,
    missingPara,
    setInquiryCookie,
    getPromoTokenCookie
} from "../../util";
import {AUTH_API, MOTOR_API, SYS_API, PROMO_FRIMI_STYLE_TOKEN} from "../../config";
import {onChangeInput} from "./premium";

const POST_INQUIRY = "POST_INQUIRY";
const POST_INQUIRY_DONE = "POST_INQUIRY_DONE";
const POST_INQUIRY_FAILED = "POST_INQUIRY_FAILED";
const CLEAR_ERRORS = "CLEAR_ERRORS";
const USER_PAYMENT_DETAILS_FAILED_FIELDS = "USER_PAYMENT_DETAILS_FAILED_FIELDS";

const REGISTER_USER_DETAILS = "REGISTER_USER_DETAILS";
const REGISTER_USER_DETAILS_DONE = "REGISTER_USER_DETAILS_DONE";
const REGISTER_USER_DETAILS_FAILED = "REGISTER_USER_DETAILS_FAILED";

const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
const UPDATE_USER_DETAILS_DONE = "UPDATE_USER_DETAILS_DONE";
const UPDATE_USER_DETAILS_FAILED = "UPDATE_USER_DETAILS_FAILED";

const CONSUMER_LOOKUP = "CONSUMER_LOOKUP";
const CONSUMER_LOOKUP_DONE = "CONSUMER_LOOKUP_DONE";
const CONSUMER_LOOKUP_FAILED = "CONSUMER_LOOKUP_FAILED";

const CONSUMER_LOOKUP_CUSTOMER_ID = "CONSUMER_LOOKUP_CUSTOMER_ID";
const CONSUMER_LOOKUP_CUSTOMER_ID_DONE = "CONSUMER_LOOKUP_CUSTOMER_ID_DONE";
const CONSUMER_LOOKUP_CUSTOMER_ID_FAILED = "CONSUMER_LOOKUP_CUSTOMER_ID_FAILED";

const GET_INSURE = "GET_INSURE";
const GET_INSURE_DONE = "GET_INSURE_DONE";
const GET_INSURE_FAILED = "GET_INSURE_FAILED";

const GENERATE_OTP = "GENERATE_OTP";
const GENERATE_OTP_DONE = "GENERATE_OTP_DONE";
const GENERATE_OTP_FAILED = "GENERATE_OTP_FAILED";

const VERIFY_OTP = "VERIFY_OTP";
const VERIFY_OTP_DONE = "VERIFY_OTP_DONE";
const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";

const USER_PAYMENT_DETAILS = "USER_PAYMENT_DETAILS";
const USER_PAYMENT_DETAILS_DONE = "USER_PAYMENT_DETAILS_DONE";
const USER_PAYMENT_DETAILS_FAILED = "USER_PAYMENT_DETAILS_FAILED";

const UPDATE_OTP_CODE = "UPDATE_OTP_CODE";

const INIT_STATE = {
    loading: false,
    errorMessage: null,
    userDetails: null,
    consumerDetails: null,
    consumerUpdatedDetails: null,
    paymentDetails: [],
    insureCompanies: [],
    verifyPayload: null,
    otpSteps: {
        lookupDone: false,
        sendOtpCode: false,
        sendOtpCodeAgain: false
    }
};

export const updateCustomer = body => async (dispatch, getState) => {
    dispatch({type: UPDATE_USER_DETAILS});
    try {
        const res = await axios.get(
            `${SYS_API}/consumer-api/v3/update?customer_id=${
                body.customer_id
                }&nic_number=${body.nic_number}&passport_number=${
                body.passport_number
                }&title=${body.title}&name_initials=${body.name_initials}&email=${
                body.email
                }&address_no=${body.address_no}&street_one=${
                body.street_one
                }&street_two=${body.street_two}&street_three=${body.street_three}&city=${
                body.city
                }`
        );
        const error = hasAuthError(res);

        if (error) {
            return dispatch({type: UPDATE_USER_DETAILS_FAILED, payload: error});
        }

        const error2 = missingPara(res);
        if (error2) {
            return dispatch({type: UPDATE_USER_DETAILS_FAILED, payload: error2});
        }

        return dispatch({type: UPDATE_USER_DETAILS_DONE, payload: res.data.data});
    } catch (error) {
        return dispatch({
            type: UPDATE_USER_DETAILS_FAILED,
            payload: getCommonError(error)
        });
    }
};

export const consumerLookup = mNumber => async (dispatch, getState) => {
    dispatch({type: CONSUMER_LOOKUP});
    try {
        const res = await axios.get(
            `${SYS_API}/consumer-api/v3/lookup?mobile_number=${mNumber}&mode=3`
        );
        dispatch({
            type: UPDATE_OTP_CODE,
            payload: {
                lookupDone: true
            }
        });
        const error2 = hasMultipleRecords(res);
        if (error2) {
            return dispatch({type: CONSUMER_LOOKUP_FAILED, payload: error2});
        }
        const error = hasAuthError(res);
        if (error) {
            return dispatch({type: CONSUMER_LOOKUP_FAILED, payload: error});
        }
        return dispatch({type: CONSUMER_LOOKUP_DONE, payload: res.data.data});
    } catch (error) {
        dispatch({
            type: CONSUMER_LOOKUP_FAILED,
            payload: {
                lookupDone: false
            }
        });
        return dispatch({
            type: CONSUMER_LOOKUP_FAILED,
            payload: getCommonError(error)
        });
    }
};

export const consumerLookupCustomerId = customerId => async (dispatch, getState) => {
    dispatch({type: CONSUMER_LOOKUP_CUSTOMER_ID});
    try {
        const res = await axios.get(
            `${SYS_API}/consumer-api/v3/lookup?customer_id=${customerId}&mode=3`
        );

        const error = hasAuthError(res);
        if (error) {
            return dispatch({
                type: CONSUMER_LOOKUP_CUSTOMER_ID_FAILED,
                payload: error
            });
        }
        return dispatch({
            type: CONSUMER_LOOKUP_CUSTOMER_ID_DONE,
            payload: res.data.data
        });
    } catch (error) {
        return dispatch({
            type: CONSUMER_LOOKUP_CUSTOMER_ID_FAILED,
            payload: getCommonError(error)
        });
    }
};

export const consumerLookupByUniqueHash = (mobile_number, token) => async (dispatch, getState) => {
    dispatch({type: CONSUMER_LOOKUP_CUSTOMER_ID});
    try {
        const res = await axios.get(
            `${SYS_API}/consumer-api/v3.1/get_by_mobile?mobile_number=${mobile_number}&mode=3`,
            {
                headers: {
                    otp_token: token
                }
            }
        );
        const error = hasAuthError(res);
        if (error) {
            return dispatch({
                type: CONSUMER_LOOKUP_CUSTOMER_ID_FAILED,
                payload: error
            });
        }
        return dispatch({
            type: CONSUMER_LOOKUP_CUSTOMER_ID_DONE,
            payload: res.data.data
        });
    } catch (error) {
        return dispatch({
            type: CONSUMER_LOOKUP_CUSTOMER_ID_FAILED,
            payload: getCommonError(error)
        });
    }
};

export const getInsureCompanies = () => async (dispatch, getState) => {
    dispatch({type: GET_INSURE});
    try {
        const res = await axios.get(`${SYS_API}/v1/company/get?type=I`);
        const error2 = hasMultipleRecords(res);
        if (error2) {
            return dispatch({type: GET_INSURE_FAILED, payload: error2});
        }
        const error = hasAuthError(res);
        if (error) {
            return dispatch({type: GET_INSURE_FAILED, payload: error});
        }
        return dispatch({type: GET_INSURE_DONE, payload: res.data.data});
    } catch (error) {
        return dispatch({
            type: GET_INSURE_FAILED,
            payload: getCommonError(error)
        });
    }
};

export const userRegisterAndBuy = body => async (dispatch, getState) => {
    try {
        dispatch({type: REGISTER_USER_DETAILS});
        let customer_id = null;
        if (!getState().user.userDetails) {
            const res = await axios.post(
                `${SYS_API}/consumer-api/v3/register`,
                _.pick(body, [
                    "title",
                    "name_initials",
                    "first_name",
                    "last_name",
                    "date_of_birth",
                    "nic_number",
                    "passport_number",
                    "mobile_number",
                    "email",
                    "home_number",
                    "address_no",
                    "street_one",
                    "street_two",
                    "street_three",
                    "city",
                    "country",
                    "occupation",
                    "employee_id",
                    "product_code",
                    "step"
                ])
            );
            const error = hasAuthError(res);
            if (error) {
                return dispatch({type: REGISTER_USER_DETAILS_FAILED, payload: error});
            }
            const fieldError = hasFieldErrors(res);
            if (fieldError) {
                return dispatch({
                    type: USER_PAYMENT_DETAILS_FAILED_FIELDS,
                    payload: fieldError
                });
            }
            customer_id = res.data.data.customer_id;
            dispatch({type: REGISTER_USER_DETAILS_DONE, payload: res.data.data});
        } else {
            customer_id = getState().user.userDetails.customer_id;
        }

        let token = getPromoTokenCookie(PROMO_FRIMI_STYLE_TOKEN).token;

        const local = JSON.parse(localStorage.getItem(PROMO_FRIMI_STYLE_TOKEN));

        if(local) {
            token = local.token;

        }
        return dispatch(
            userPaymentApi({
                ...body,
                customer_id: customer_id,
                quote_number: body.quote_number,
                promo_code: body.promoCode,
                promoToken: token ? token : "0",
            })
        );
    } catch (error) {
        return dispatch({
            type: REGISTER_USER_DETAILS_FAILED,
            payload: getCommonError(error)
        });
    }
};

export const userPaymentApi = body => async (dispatch, getState) => {
    dispatch({type: USER_PAYMENT_DETAILS});
    try {

        let path = '/v2/quote/buy'
        if (body.cover_type !== 'full-insurance') path = '/v3/quote/buy';
        const res = await axios.post(`${MOTOR_API}${path}`, {
            is_renewal: "0",
            existing_insurer: "",
            renwal_date: "",
            payment_type: "FP",
            payment_media: "ONLINE",
            ins_main_cat: "MOTOR",
            ins_sub_cat: "",
            pay_ref: "",
            bank_name: "ONLINE",
            bank_branch: "ONLINE",
            bank_user: "ONLINE",
            ...body,
            cx_inquiry: getInquiryCookie().cx_inquiry_id
        });
        const error = hasAuthError(res);

        if (error) {
            return dispatch({type: USER_PAYMENT_DETAILS_FAILED, payload: error});
        }
        const fieldError = hasFieldErrors(res);
        if (fieldError) {
            return dispatch({
                type: USER_PAYMENT_DETAILS_FAILED_FIELDS,
                payload: fieldError
            });
        }

        window.location = `/user/document/uploader/${res.data.data.pay_sec_hash}?app_code=ONLINE`;
        return dispatch({
            type: USER_PAYMENT_DETAILS_DONE,
            payload: res.data.data
        });
    } catch (error) {
        return dispatch({
            type: USER_PAYMENT_DETAILS_FAILED,
            payload: getCommonError(error)
        });
    }
};

export const postInquiry = body => async dispatch => {
    dispatch({type: POST_INQUIRY});
    // this is temp fix

    try {
        const res = await axios.post(`${SYS_API}/inquiry-api/v1/inquire`, {
            ...body,
            cx_mobile: `94${body.cx_mobile}`
        });
        const error = hasAuthError(res);
        if (error) {
            return dispatch({type: POST_INQUIRY_FAILED, payload: null});
        }

        const inquiry_id = res.data.data.inquiry_id;

        let mobileNumber = body.cx_mobile.length == 10  ? body.cx_mobile.substr(1) : body.cx_mobile;

        setInquiryCookie({
            cx_name: body.cx_name,
            cx_mobile: mobileNumber,
            cx_email: body.cx_email,
            cx_inquiry_id: inquiry_id
        });
        //promo loca storage
        if (body.bank_type === "hnb") {
            localStorage.setItem("cx_inquiry_id_hnb", inquiry_id);
        } else if (body.bank_type === "ntb") {
            localStorage.setItem("cx_inquiry_id_ntb", inquiry_id);
        } else if (body.bank_type === "ub") {
            localStorage.setItem("cx_inquiry_id_ub", inquiry_id);
        } else if (body.bank_type === "pb") {
            localStorage.setItem("cx_inquiry_id_pb", inquiry_id);
        }

        // this is temp fix

        dispatch(onChangeInput("cx_inquiry_id", inquiry_id));
        return dispatch({type: POST_INQUIRY_DONE, payload: inquiry_id});
    } catch (error) {
        return dispatch({
            type: POST_INQUIRY_FAILED,
            payload: getCommonError(error)
        });
    }
};

export const generateOTP = body => async (dispatch, getState) => {
    dispatch({type: GENERATE_OTP});
    try {
        const res = await axios.post(`${AUTH_API}/otp-auth-api/v2/otp/generate`, {
            mobile_number: `94${body.mobile_number}`,
            email: body.email,
            customer_id: "",
            main_cat_code: "MOTOR",
            sub_cat_code: "MOTOR",
            product_code: "FULL",
            media_code: "WEB",
            otp_media: body.otp_media,
            is_resend: "0"
        });

        const error2 = hasMultipleRecords(res);
        if (error2) {
            return dispatch({type: GENERATE_OTP_FAILED, payload: error2});
        }
        const error = hasAuthError(res);
        if (error) {
            return dispatch({type: GENERATE_OTP_FAILED, payload: error});
        }
        return dispatch({type: GENERATE_OTP_DONE, payload: res.data.data});
    } catch (error) {
        return dispatch({
            type: GENERATE_OTP_FAILED,
            payload: getCommonError(error)
        });
    }
};

export const verifyOTPCode = (otpCode, generatedOTP) => async (dispatch, getState) => {
    dispatch({type: VERIFY_OTP});
    try {

        const res = await axios.post(`${AUTH_API}/otp-auth-api/v2/otp/verify`, {
            main_cat_code: "MOTOR",
            sub_cat_code: "MOTOR",
            product_code: "FULL",
            media_code: "WEB",
            otp_media: generatedOTP.otp_media,
            token: generatedOTP.token,
            otp_code: otpCode
        });
        if (
            res.data.error_format === 0 &&
            res.data.error &&
            res.data.error.message
        ) {
            return dispatch({
                type: VERIFY_OTP_FAILED,
                payload: res.data.error.message
            });
        }

        const error2 = hasMultipleRecords(res);
        if (error2) {
            return dispatch({type: VERIFY_OTP_FAILED, payload: error2});
        }
        const error = hasAuthError(res);
        if (error) {
            return dispatch({type: VERIFY_OTP_FAILED, payload: error});
        }

        return dispatch({type: VERIFY_OTP_DONE, payload: res.data.data});


    } catch (error) {
        return dispatch({
            type: VERIFY_OTP_FAILED,
            payload: getCommonError(error)
        });
    }
};

function remove94FromAPI(mobile_number) {
    if (mobile_number && mobile_number.length > 9) {
        return mobile_number.substring(2);
    }
    return mobile_number;
}

export default function reducer(state = INIT_STATE, action) {
    const {type, payload} = action;

    switch (type) {
        case CLEAR_ERRORS:
            return {...state, errorMessage: false, otpVerifyError: null};

        case UPDATE_OTP_CODE:
            return {...state, otpSteps: {...state.otpSteps, ...payload}};

        case VERIFY_OTP:
            return {
                ...state,
                loading: true,
                otpVerifyError: null,
                verifyPayload: null
            };
        case VERIFY_OTP_DONE:
            return {
                ...state,
                loading: false,
                otpVerifyError: null,
                verifyPayload: payload
            };
        case VERIFY_OTP_FAILED:
            return {
                ...state,
                loading: false,
                otpVerifyError: payload,
                verifyPayload: null
            };

        case GENERATE_OTP:
            return {...state, loading: true, errorMessage: null, fieldErrors: null};
        case GENERATE_OTP_DONE:
            return {...state, loading: false, generatedOTP: payload};
        case GENERATE_OTP_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: payload,
                generatedOTP: false
            };

        case REGISTER_USER_DETAILS:
            return {...state, loading: true, errorMessage: null, fieldErrors: null};
        case REGISTER_USER_DETAILS_DONE:
            return {...state, loading: false, userDetails: payload};
        case REGISTER_USER_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: payload,
                userDetails: null
            };

        case CONSUMER_LOOKUP:
            return {
                ...state,
                loading: true,
                consumerDetails: null,
                errorMessage: null,
                fieldErrors: null
            };
        case CONSUMER_LOOKUP_DONE:
            payload.mobile_number = remove94FromAPI(payload.mobile_number);
            return {...state, loading: false, consumerDetails: payload};
        case CONSUMER_LOOKUP_FAILED:
            return {...state, loading: false, consumerDetails: null};

        case CONSUMER_LOOKUP_CUSTOMER_ID:
            return {
                ...state,
                loading: true,
                consumerDetails: null,
                errorMessage: null,
                fieldErrors: null
            };
        case CONSUMER_LOOKUP_CUSTOMER_ID_DONE:
            return {...state, loading: false, consumerDetails: payload};
        case CONSUMER_LOOKUP_CUSTOMER_ID_FAILED:
            return {...state, loading: false, consumerDetails: null};

        case UPDATE_USER_DETAILS:
            return {
                ...state,
                loading: true,
                consumerUpdatedDetails: null,
                errorMessage: null,
                fieldErrors: null
            };
        case UPDATE_USER_DETAILS_DONE:
            return {...state, loading: false, consumerUpdatedDetails: payload};
        case UPDATE_USER_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                consumerUpdatedDetails: null,
                errorMessage: payload
            };

        case GET_INSURE:
            return {...state, loading: true, errorMessage: null};
        case GET_INSURE_DONE:
            return {...state, loading: false, insureCompanies: payload || []};
        case GET_INSURE_FAILED:
            return {...state, loading: false, insureCompanies: []};

        case USER_PAYMENT_DETAILS:
            return {...state, loading: true, errorMessage: null};
        case USER_PAYMENT_DETAILS_DONE:
            payload.mobile_number = remove94FromAPI(payload.mobile_number);
            return {...state, loading: false, consumerDetails: payload};
        case USER_PAYMENT_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: payload,
                paymentDetails: []
            };

        case POST_INQUIRY:
            return {...state, loading: true, errorMessage: null};
        case POST_INQUIRY_DONE:
            return {...state, loading: false, inquiry_id: payload};
        case POST_INQUIRY_FAILED:
            return {...state, loading: false, errorMessage: payload};

        case USER_PAYMENT_DETAILS_FAILED_FIELDS:
            return {
                ...state,
                loading: false,
                userDetails: null,
                fieldErrors: payload
            };
        default:
            return state;
    }
}
