import React from "react";
import "./Overlay.scss";

export const Overlay = props => {
  let style = {
    width: props.width,
    height: props.height
  };
  return <div className="Overlay" style={style} />;
};
