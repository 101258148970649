import React, {Component} from "react";
import {connect} from "react-redux";
import "./classic-quote.scss";
import {AutoCompleteInput, Button, ButtonGroup, InputText} from "../../../components";
import {
  clearAll,
  clearErrors,
  fetchPremiums,
  getAuthToken,
  getFriMiLifeStyleCustomerInformation,
  getFuelTypes,
  getManufactureYears,
  getNCBYears,
  getParamsByUniqHash,
  getVMakes,
  getVModels,
  getVUsages,
  onChangeInput,
  removeFieldError,
  setFieldErrors,
  validateToken,
  vehicleTypes
} from "../../../redux/actions";
import {getQueryParams} from "../../../history";
import {
  convertToSlug,
  convertToWord,
  isEmptyObjectValue,
  placeParams,
  setPromoTokenCookie,
  validateWithValidations
} from "../../../util";
import {withRouter} from "react-router";
import {Helmet} from "react-helmet";
import queryString from 'query-string';
import {isEmpty} from 'lodash'

import {CHANNEL_FRIMI, PROMO_CODE_FRIMI, PROMO_FRIMI_STYLE_TOKEN} from '../../../config';

const validations = {
  vehicle_type: {presence: true},
  fuel_type: {presence: true},
  vehicle_make: {presence: true},
  vehicle_model: {presence: true},
  manufacture_year: {presence: true},
  ncb: {presence: true},
  sum_insured: {
    numericality: {
      onlyInteger: true,
      greaterThan: 100000
    }
  },
  chassis_no: {presence: true},
  engine_no: {presence: true},
  veh_reg_format_1: {presence: true},
  veh_reg_format_2: {presence: true},
  veh_reg_format_3: {presence: true},
};

const DEFAULT_FORM = {
  req_key: "",
  veh_reg_format: "WP ABC-1234",
  veh_registration_no: "WP-ABC-1234",
  veh_reg_format_1: "",
  veh_reg_format_2: "",
  veh_reg_format_3: "",
  engine_no: "",
  chassis_no: "",
  financial_company: "",
  financial_company_branch: "",
  lease_exp_date: "",
  conf_bank_name: "ONLINE",
  conf_bank_branch: "ONLINE",
  conf_bank_user: "ONLINE",
  pdf_status: "1",
  is_registered:"",
  is_leasing:''

};

const reg_format_validation = {
  "WP ABC-1234": {
    veh_reg_format_1: { presence: true,     format: { pattern: "[a-zA-Z0-9]{2,2}", flags: "i", message: "not valid" }, },
    veh_reg_format_2: { presence: true,     format: { pattern: "[a-zA-Z0-9]{3,3}", flags: "i", message: "not valid" }, },
    veh_reg_format_3: { presence: true,     format: { pattern: "[a-zA-Z0-9]{4,4}", flags: "i", message: "not valid" }, },
  },
  "WP AB-1234": {
    veh_reg_format_1: { presence: true,     format: { pattern: "[a-zA-Z0-9]{2,2}", flags: "i", message: "not valid" }, },
    veh_reg_format_2: { presence: true,     format: { pattern: "[a-zA-Z0-9]{2,2}", flags: "i", message: "not valid" }, },
    veh_reg_format_3: { presence: true,     format: { pattern: "[a-zA-Z0-9]{4,4}", flags: "i", message: "not valid" }, },
  },
  "302-1234": {
    veh_reg_format_1: { },
    veh_reg_format_2: { presence: true,     format: { pattern: "[a-zA-Z0-9]{3,3}", flags: "i", message: "not valid" }, },
    veh_reg_format_3: { presence: true,     format: { pattern: "[a-zA-Z0-9]{4,4}", flags: "i", message: "not valid" }, },
  },
  "10-SRI-1234": {
    veh_reg_format_1: { },
    veh_reg_format_2: { presence: true,     format: { pattern: "[a-zA-Z0-9]{2,2}", flags: "i", message: "not valid" }, },
    veh_reg_format_3: { presence: true,     format: { pattern: "[a-zA-Z0-9]{4,4}", flags: "i", message: "not valid" }, },
  },
}

class ClassicQuoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      format1: true,
      format2:false,
      formData: DEFAULT_FORM,
      error: {},
      validations: {
        veh_registration_no: { presence: true },
        engine_no: { presence: true,
          format: {
            pattern: /^.{3,}$/,
            message: "enter minimum 3 characters"
          },
        },
        chassis_no: { presence: true,
          format: {
            pattern: /^.{3,}$/,
            message: "enter minimum 3 characters"
          },
        },
        ...reg_format_validation[DEFAULT_FORM.veh_reg_format]
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeVMake = this.handleChangeVMake.bind(this);
    this.promoCode = (queryString.parse(this.props.location.search).promo) ? queryString.parse(this.props.location.search).promo : 'no-promo';
    this.channel = (queryString.parse(this.props.location.search).channel) ? queryString.parse(this.props.location.search).channel : 'Web-Motor';
    // this.props.history.listen((location, action) => {
    //   this.init(true);
    // });

    if(this.promoCode === 'IKMAN.LK') {
        this.channel = 'IKMAN';
        this.promoCode = 'no-promo'; 
    }

  }

  async componentDidMount() {
    this.init(true);
    const vehicle_type = this.props.match.params.vehicle_type;
    const insurance_type = this.props.match.params.insurance_type;
    await this.initTitle(vehicle_type, insurance_type);

    await this.getFriMiPromoDetails(this.channel, this.promoCode);

    this.setState({
      formData:{
        ...DEFAULT_FORM,
        veh_reg_format_1: this.props.motor.proposalDetails.is_registered ? '' : 'WP',
        veh_reg_format_2: this.props.motor.proposalDetails.is_registered ? '' : 'ABC',
        veh_reg_format_3: this.props.motor.proposalDetails.is_registered ? '' : '1234',
      }
    });

  }

  async getFriMiPromoDetails(channel, promoCode) {

    let promo = promoCode.split('?');
    this.promoCode = promo[0];
    let uuid = promo[1];

     if(channel == CHANNEL_FRIMI && this.promoCode === PROMO_CODE_FRIMI && uuid != undefined) {

       await this.props.getFriMiLifeStyleCustomerInformation(uuid);

      if(this.props.motor.friMiCxDetails !== undefined) {

        setPromoTokenCookie(PROMO_FRIMI_STYLE_TOKEN ,{
          token: uuid,
          friMiLifeStyleCx: this.props.motor.friMiCxDetails,
        });

        localStorage.setItem(PROMO_FRIMI_STYLE_TOKEN,JSON.stringify({
          token: uuid,
          friMiLifeStyleCx: this.props.motor.friMiCxDetails,
        }));
      }else {
        this.channel = 'Web-motor';
        this.promoCode = 'no-promo';
      }

    }
  }

  async initTitle(vehicle_type, insurance_type) {
   
    if (vehicle_type === "Car") {
      this.vehicle_type = "Car / Jeep / SUV";
    } else if (vehicle_type === "Motor Cycle" || vehicle_type == "Motor-Cycle") {
      this.vehicle_type = "Motorcycle";
    } else if (
      vehicle_type === "Three Wheeler" ||
      vehicle_type === "Three-Wheeler"
    ) {
      this.vehicle_type = "Three Wheeler";
    } else if (
      vehicle_type === "Dual Purpose" ||
      vehicle_type === "Dual-Purpose"
    ) {
      this.vehicle_type = "Van / Cab";
    } else {
      this.vehicle_type = "";
    }

    if (insurance_type === "full-insurance") {
      this.insurance_type = "Full Insurance";
    } 
    document.title = `${this.vehicle_type}| ${this.insurance_type} | Quotation`;
  }

  changeUrlRoute({vehicle_type, insurance_type}, unq_hash) {
    if (this.props.disabled) {
      return false;
    }
    let hasChanged = false;
    const currentParams = this.props.match.params;

    if (vehicle_type && currentParams.vehicle_type !== vehicle_type) {
      hasChanged = true;
    }
    if (insurance_type && currentParams.insurance_type !== insurance_type) {
      hasChanged = true;
    }
    if (hasChanged) {
      let newPath = placeParams(this.props.match.path, {
        vehicle_type: convertToSlug(vehicle_type || currentParams.vehicle_type),
        insurance_type: convertToSlug(
          insurance_type || currentParams.insurance_type
        )
      });
      if (unq_hash) {
        newPath = `${newPath}?unq_hash=${unq_hash}`;
      }
      this.props.history.replace(newPath);
    }
  }

  async init(shoudChangeRoute, matchParams) {
    const fromHome = this.props.location.state
      ? this.props.location.state.fromHome
      : false;

    await this.props.getAuthToken();
    let {vehicle_type, insurance_type} =
      matchParams || this.props.match.params;
    let dontRemove = false;
    const unq_hash = getQueryParams().unq_hash;

    if (unq_hash && !fromHome) {
      // if it is from home page.. we don't use the param
      await this.props.getParamsByUniqHash(unq_hash);
      dontRemove = true;
      if (this.props.premium.premiumParams.insurance_type) {
        insurance_type = this.props.premium.premiumParams.insurance_type;
      }
      if (shoudChangeRoute) {
        vehicle_type = this.props.premium.premiumParams.vehicle_type;
        this.changeUrlRoute(
          {
            vehicle_type: vehicle_type,
            insurance_type: insurance_type
          },
          unq_hash
        );
      }
    }

    if (fromHome) {
      // replace home. if it comes from the homepage.
      this.props.history.replace({
        ...this.props.location.pathname,
        state: {},
        search: ""
      });
    }

    this.props.onChangeInput({
      vehicle_type: convertToWord(vehicle_type),
      insurance_type
    });
    if (!isEmpty(this.props.premium.premiumParams.veh_reg_format)) {
      if (this.props.premium.premiumParams.veh_reg_format == "302-1234" || this.props.premium.premiumParams.veh_reg_format == "10-SRI-1234") {
        this.setState({ format1: false });
      } else {
        this.setState({ format1: true });
      }
      if(this.props.premium.premiumParams.veh_reg_format == "10-SRI-1234"){
        this.setState({ format2: true});
      } else{
        this.setState({ format2: false});
      }
    }

    this.fetchStaticData();
    this.fetchOnVTypeChange(convertToWord(vehicle_type), dontRemove);
  }

  async fetchStaticData() {
    this.props.vehicleTypes();
    this.props.getNCBYears();
    this.props.getManufactureYears();
  }

  async fetchOnVTypeChange(vehicle_type, dontRemove) {
    this.props.getFuelTypes(vehicle_type);
    this.props.getVUsages(vehicle_type);
    await this.props.getVMakes(vehicle_type);
    if (!dontRemove) {
      this.props.onChangeInput({
        purpose_of_use: "",
        fuel_type: "",
        vehicle_make: "",
        vehicle_model: "",
        sum_insured: "",
        manufacture_year: ""
      });
    } else {
      this.props.getVModels(
        this.props.premium.premiumParams.vehicle_type,
        this.props.premium.premiumParams.vehicle_make
      );
    }
  }

  async handleChange(key, value) {
    this.props.removeFieldError(key);

    await this.props.onChangeInput({[key]: value});
    if (key === "insurance_type") {
      this.initTitle(this.props.match.params.vehicle_type, value);
      const unq_hash = this.props.premium.unq_hash;
      this.changeUrlRoute({insurance_type: value}, unq_hash);
    }

    if (key === "vehicle_type") {
      this.fetchOnVTypeChange(value);
      this.initTitle(value, this.props.match.params.insurance_type);
      const unq_hash = this.props.premium.unq_hash;
      this.changeUrlRoute({vehicle_type: value}, unq_hash);
    }

    if (key === "vehicle_make") {
      this.props.onChangeInput({vehicle_model: ""});
      this.props.getVModels(
        this.props.premium.premiumParams.vehicle_type,
        value
      );
    }

    if (this.props.isDocked && this.props.onQuoteChanges) {
      this.props.onQuoteChanges();
    }

    if (key === 'veh_reg_format') {
      await this.setState({ [key]: value });
      if (value == "302-1234" || value == "10-SRI-1234") {
        this.setState({ format1: false });
      } else {
        this.setState({ format1: true });
      }
      if(value == "10-SRI-1234"){
        this.setState({ format2: true});
      } else{
        this.setState({ format2: false});
      }
    }

    await this.setState({
      validations: {
        veh_registration_no: { presence: true },
        engine_no: { presence: true,
          format: {
            pattern: /^.{3,}$/,
            message: "enter minimum 3 characters"
          },
        },
        chassis_no: { presence: true,
          format: {
            pattern: /^.{3,}$/,
            message: "enter minimum 3 characters"
          },
        },
        ...reg_format_validation[value]
      }
    });

    const formData = this.state.formData;
    const error = this.state.error;
    delete error[key];
    if (value) {
      formData[key] = value;
    } else {
      formData[key] = value;
    }
    // formData["req_key"] = this.props.motor.proposalDetails.req_key;

    if(key === 'veh_reg_format'){
      const customValidations = reg_format_validation[value];
      this.setState({
        ...this.state.validations,
        ...customValidations,
      })
    }
    if (
        key == "veh_reg_format_1" ||
        key == "veh_reg_format_2" ||
        key == "veh_reg_format_3"
    ) {
      this.props.premium.premiumParams.veh_registration_no =
          this.props.premium.premiumParams.veh_reg_format_1 +
          "-" +
          this.props.premium.premiumParams.veh_reg_format_2 +
          "-" +
          this.props.premium.premiumParams.veh_reg_format_3;
    }
    if(this.state.veh_reg_format=="10-SRI-1234"){
      this.props.premium.premiumParams.veh_registration_no =
          this.props.premium.premiumParams.veh_reg_format_2 +
          "-" +
          "SRI" +
          "-" +
          this.props.premium.premiumParams.veh_reg_format_3;
    }
    if(this.state.veh_reg_format=="302-1234"){
      this.props.premium.premiumParams.veh_registration_no =
          this.props.premium.premiumParams.veh_reg_format_2 +
          "-" +
          this.props.premium.premiumParams.veh_reg_format_3;
    }

    this.setState({ error });

    this.props.clearErrors();
  }

  handleChangeVMake(e) {
    this.props.removeFieldError("vehicle_make");
    this.props.onChangeInput({vehicle_make: e.label, vehicle_model: ""});
    this.props.clearErrors();
    this.props.getVModels(
      this.props.premium.premiumParams.vehicle_type,
      e.label
    );
  }

  validate() {
    this.props.clearErrors();
    const {premiumParams} = this.props.premium;
    let error = {};
    if (premiumParams.insurance_type === "full-insurance") {
      error = validateWithValidations(premiumParams, {...validations, chassis_no: false, engine_no: false,
      veh_reg_format_1: false, veh_reg_format_2: false, veh_reg_format_3: false});
    } else {
      error = validateWithValidations(premiumParams, {
        vehicle_make: validations.vehicle_make,
        vehicle_model: validations.vehicle_model,
        chassis_no: validations.chassis_no,
        engine_no: validations.engine_no,
        veh_reg_format_1: validations.veh_reg_format_1,
        veh_reg_format_2: validations.veh_reg_format_2,
        veh_reg_format_3: validations.veh_reg_format_3
      });
    }
    if (isEmptyObjectValue(error)) {
      this.props.setFieldErrors({}, true);
      return error;
    } else {
      this.props.setFieldErrors(error, true);
      return error;
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const {premiumParams} = this.props.premium;
    this.props.clearErrors();
    const error = this.validate();

    if (isEmptyObjectValue(error)) {

      await this.props.fetchPremiums(premiumParams, true, true);

      if (
          this.props.premium.errorMessage ||
          !isEmptyObjectValue(this.props.premium.field_errors)
      ) {
        window.scrollTo(0, 0);
        return null;
      }
      const unq_hash = this.props.premium.unq_hash;
      const promo = (this.promoCode != null) ? `&promo=${this.promoCode}` : '';
      const channel = (this.channel != null) ? `&channel=${this.channel}` : '';
      if (unq_hash) {
        window.scrollTo(0, 0);
        this.props.history.push({
          pathname: `/motor/${convertToSlug(premiumParams.vehicle_type)}/${
              premiumParams.insurance_type
              }/Inquiry`,
          search: `?unq_hash=${unq_hash}${promo}${channel}`
        });
      }
    } else {
      this.setState({ error });
    }

  }

  resetForm(e) {
    e.preventDefault();
    this.setState({error: {}});
    this.props.onChangeInput({}, true);

    this.props.history.push({
      pathname: "/motor/Car/full-insurance/Quotation",
      search: "",
      state: {fromHome: true}
    });
    this.init(true);
  }

  onChange(key, e) {
    const formData = this.state.formData;
    const error = this.state.error;
    delete error[key];
    if (e.target) {
      formData[key] = e.target.value;
    } else {
      formData[key] = e;
    }
    formData["req_key"] = this.props.motor.proposalDetails.req_key;

    if(key === 'veh_reg_format'){
      const customValidations = reg_format_validation[e];
      this.setState({
        ...this.state.validations,
        ...customValidations,
      })
    }
    if (
        key == "veh_reg_format_1" ||
        key == "veh_reg_format_2" ||
        key == "veh_reg_format_3"
    ) {
      formData["veh_registration_no"] =
          formData.veh_reg_format_1 +
          "-" +
          formData.veh_reg_format_2 +
          "-" +
          formData.veh_reg_format_3;
    }
    if(this.state.veh_reg_format=="10-SRI-1234"){
      formData["veh_registration_no"] =
          formData.veh_reg_format_2 +
          "-" +
          "SRI" +
          "-" +
          formData.veh_reg_format_3;
    }
    if(this.state.veh_reg_format=="302-1234"){
      formData["veh_registration_no"] =
          formData.veh_reg_format_2 +
          "-" +
          formData.veh_reg_format_3;
    }

    this.setState({ formData, error });
    this.props.clearErrors();
  }

  render() {
    const {motor, premium, isDocked, disabled, show} = this.props;
    const {premiumParams, field_errors} = premium;
    const { formData, is_leasing, is_registered, format1, format2, validations } = this.state;

    const isFull = premiumParams.insurance_type === "full-insurance";
    const vehicleType = premiumParams.vehicle_type;
    const insuranceType =
      premiumParams.insurance_type === "third-party" ? "Third-Party" : "Full";
    if (!show) {
      return null;
    }
    return (
      <div>
        <Helmet>
          <meta
            name="keywords"
            content="Full insurance ,Third-Party insurance, Quotation, vehicle, Insurance, Get me a quick quote, Toyota, Nissan, Mitsubishi, Honda, Suzuki, Maruti Suzuki, Hyundai, KIA, Audi, BMW, Daihatsu, Land Rover, Mazda, Mercedes Benz, Peugeot, Proton, Subaru, Volkswagen, Volvo, Chery, Ford, Jeep, Micro, Mini, Perodua, Jaguar, Lexus, Renault, Tata, Ssangyong, Isuzu, DFSK, Yamaha, Datsun, ZOTYE, Alfa Romeo, Chevrolet, MG"
          />
          <meta name="author" content="InsureMe Insurance Brokers (Pvt) Ltd" />
          <meta name="robots" content="index, follow" />
          <meta name="revisit-after" content="1 month" />
          <meta
            name="description"
            content={
              `${vehicleType 
              } Insurance : Quick quote, ${ 
              insuranceType 
              } Insurance`
            }
          />
        </Helmet>

        <div className="col-md-11 col-sm-11 col-xs-11">
          {isDocked ? (
            <div>
              <label className="nuclei-input-label">
                Select Vehicle Type?{" "}
              </label>
              <AutoCompleteInput
                type="text"
                className="form-control"
                id={"vehicle_type"}
                placeholder={"Vehicle Type"}
                value={premiumParams.vehicle_type}
                options={motor.vTypes.map(item => {
                  return {
                    label: item.vehicle_type,
                    value: item.vehicle_type
                  };
                })}
                disabled={disabled}
                onChange={e => this.handleChange("vehicle_type", e.value)}
                errorText={field_errors.vehicle_type}
                validation={validations.vehicle_type}
              />
            </div>
          ) : (
            <ButtonGroup
              label="Select Vehicle Type?"
              selected={premiumParams.vehicle_type}
              options={motor.vTypes.map(item => {
                return {
                  label: item.vehicle_type,
                  value: item.vehicle_type
                };
              })}
              disabled={disabled}
              errorText={field_errors.vehicle_type}
              tooltip={isDocked ? null : "Choose the type of Vehicle"}
              id="vehicle_type"
              onChange={this.handleChange}
            />
          )}
        </div>

        {isDocked ? null : (
          <div className="col-md-11 col-sm-11 col-xs-11">
            <ButtonGroup
              label="Select Insurance Type?"
              selected={premiumParams.insurance_type}
              options={[
                {label: "Full Insurance", value: "full-insurance"},
                {label: "Third Party", value: "third-party"}
              ]}
              tooltip={isDocked ? null : "Choose the type of Insurance"}
              id="insurance_type"
              disabled={disabled}
              onChange={this.handleChange}
            />
          </div>
        )}

        {isFull ? (
          <div className="col-md-11 col-sm-11 col-xs-11">
            <ButtonGroup
              label="Do you have a vehicle registration No?"
              selected={premiumParams.is_registered}
              options={[
                {label: "Yes", value: "R"},
                {label: "No", value: "U"}
              ]}
              disabled={disabled}
              tooltip={
                isDocked
                  ? null
                  : "If your vehicle is registered, Please click 'yes'"
              }
              id="is_registered"
              errorText={field_errors.is_registered}
              onChange={this.handleChange}
            />
          </div>
        ) : null}

        {isFull ? (
          <div className="col-md-11 col-sm-11 col-xs-11">
            <ButtonGroup
              label="Do you have any Lease,Loan or Hire on vehicle?"
              selected={premiumParams.hire_purchase}
              options={[
                {label: "Yes", value: "YES"},
                {label: "No", value: "NO"}
              ]}
              tooltip={
                isDocked
                  ? null
                  : "State 'yes' if there is a mortgage on your vehicle from any institution"
              }
              id="hire_purchase"
              disabled={disabled}
              errorText={field_errors.hire_purchase}
              onChange={this.handleChange}
            />
          </div>
        ) : null}

        {isFull ? (
          <div
            className={`col-xs-11  col-sm-11 col-md-${isDocked ? "11" : "4"}`}
          >
            <InputText
              label={"Estimated Market Value?"}
              placeholder="Rs."
              type={"number"}
              id="sum_insured"
              addCommas
              value={premiumParams.sum_insured}
              onChange={e => this.handleChange("sum_insured", e.target.value)}
              validation={validations.sum_insured}
              errorText={field_errors.sum_insured}
              disabled={disabled}
              tooltip={
                isDocked
                  ? null
                  : "The current market price of the vehicle including all extra fitings"
              }
            />
          </div>
        ) : null}

        <div className="col-md-11 col-sm-11 col-xs-11">
          <ButtonGroup
            label="Usage?"
            selected={premiumParams.purpose_of_use}
            options={motor.vUsages
              .filter(function(item) {
                return !(item.value === "Rent" && !isFull);


              })
              .map(item => {
                return {
                  label: item.label,
                  value: item.value
                };
              })}
            errorText={field_errors.purpose_of_use}
            disabled={disabled}
            tooltip={
              isDocked
                ? null
                : "Choose the purpose the vehicle is being currently used by you"
            }
            id="purpose_of_use"
            onChange={this.handleChange}
          />
        </div>

        {isFull ? (
          <div
            className={` col-sm-11 col-xs-11 col-md-${isDocked ? "11" : "4"}`}
          >
            <label className="nuclei-input-label">
              Choose the type of fuel{" "}
            </label>
            <AutoCompleteInput
              id={"fuel_type"}
              type="text"
              className="form-control"
              placeholder={"Fuel"}
              disabled={disabled}
              options={motor.fuelTypes
                .filter(function(item) {
                  if (item.value === "Plug-Hybrid") {
                    return false;
                  }
                  return true;
                })
                .map(item => {
                  return {
                    label: item.label,
                    value: item.value
                  };
                })}
              value={premiumParams.fuel_type}
              onChange={e => this.handleChange("fuel_type", e.value)}
              validation={validations.fuel_type}
              errorText={field_errors.fuel_type}
              tooltip={
                isDocked
                  ? null
                  : "Choose the type of fuel that is powering your vehicle"
              }
            />
          </div>
        ) : null}
        <div
          className="col-md-11 col-sm-11 col-xs-11"
          style={{paddingRight: 0}}
        >
          <label className="nuclei-input-label">
            {isFull
              ? "Make, Model  and Year of Manufacture?"
              : "Make and Model?"}{" "}
          </label>
          <div
            className={`zero-padding-left col-sm-12 col-xs-12 col-md-${
              isDocked ? "12" : "2"
            }`}
          >
            <AutoCompleteInput
              id={"make"}
              type="text"
              className="form-control"
              placeholder={"Make"}
              options={motor.vMakes.map(item => {
                return {
                  label: item.make,
                  value: item.make
                };
              })}
              disabled={disabled}
              value={premiumParams.vehicle_make}
              onChange={e => this.handleChangeVMake(e)}
              validation={validations.vehicle_make}
              errorText={field_errors.vehicle_make}
            />
          </div>

          <div
            className={`zero-padding-left col-sm-12 col-xs-12 col-md-${
              isDocked ? "12" : "2"
            }`}
          >
            <AutoCompleteInput
              type="text"
              className="form-control"
              id={"vehicle_model"}
              placeholder={"Model"}
              value={premiumParams.vehicle_model}
              options={motor.vModels.map(item => {
                return {
                  label: item.model,
                  value: item.model
                };
              })}
              disabled={disabled}
              onChange={e => this.handleChange("vehicle_model", e.value)}
              validation={validations.vehicle_model}
              errorText={field_errors.vehicle_model}
            />
          </div>

          {isFull ? (
            <div
              className={`zero-padding-left col-sm-12 col-xs-12 col-md-${
                isDocked ? "12" : "2"
              }`}
            >
              <AutoCompleteInput
                type="text"
                className="form-control"
                id={"manufacture_year"}
                placeholder={"Year"}
                value={premiumParams.manufacture_year}
                options={motor.vManuYears.map(item => {
                  return {
                    label: item.label,
                    value: item.value
                  };
                })}
                disabled={disabled}
                onChange={e => this.handleChange("manufacture_year", e.value)}
                validation={validations.manufacture_year}
                errorText={field_errors.manufacture_year}
                tooltip={
                  isDocked
                    ? null
                    : "Choose the make of your vehicle from the drop down.if it is not stated please choose other, Choose the model of your vehicle from the drop down.if it is not stated please choose other, State the year the vehicle was manufactured as stated in the vehicle Registration Book"
                }
              />
            </div>
          ) : null}
        </div>

        {isFull ? (
          <div
            className={` col-sm-11 col-xs-11 col-md-${isDocked ? "11" : "4"}`}
          >
            <label className="nuclei-input-label">No Claims Bonus?</label>
            <div className="zero-padding-left">
              <AutoCompleteInput
                type="text"
                className="form-control"
                id={"ncb"}
                placeholder={"Claims"}
                value={premiumParams.ncb}
                disabled={disabled}
                options={motor.ncbYears.map(item => {
                  return {
                    label: item.year,
                    value: item.id === "No" ? "0" : item.id
                  };
                })}
                onChange={e => this.handleChange("ncb", e.value)}
                validation={validations.ncb}
                errorText={field_errors.ncb}
                tooltip={
                  isDocked
                    ? null
                    : "The number of years you have not made a claim on the vehicle.This has to be supported with documentary proof from your current insurer"
                }
              />
            </div>
          </div>
        ) : null}

        {!isFull ? (
            <div className="col-md-11 col-sm-11 col-xs-11">
              <ButtonGroup
                  label="Vehicle Registration Format"
                  selected={!isEmpty(premiumParams.veh_reg_format) ? premiumParams.veh_reg_format : 'WP ABC-1234'}
                  options={[
                    { label: "WP ABC-1234", value: "WP ABC-1234" },
                    { label: "WP AB-1234", value: "WP AB-1234" },
                    { label: "302-1234", value: "302-1234" },
                    { label: "10-SRI-1234", value: "10-SRI-1234" }
                  ]}
                  value={premiumParams.veh_reg_format}
                  id="veh_reg_format"
                  validation={validations.veh_reg_format}
                  onChange={this.handleChange}
                  disabled={!!isDocked}
              />
            </div>
        ) : null}

        {/*{!isFull ? (*/}
        {/*    <div className="col-md-12 col-sm-12 col-xs-12 visible-xs">*/}
        {/*      <ButtonGroup*/}
        {/*          label="Vehicle Registration Format"*/}
        {/*          selected="WP ABC-1234"*/}
        {/*          options={[*/}
        {/*            { label: "WP ABC-1234", value: "WP ABC-1234" },*/}
        {/*            { label: "WP AB-1234", value: "WP AB-1234" },*/}
        {/*            { label: "302-1234", value: "302-1234" },*/}
        {/*            { label: "10-SRI-1234", value: "10-SRI-1234" }*/}
        {/*          ]}*/}
        {/*          value={premiumParams.veh_registration_no}*/}
        {/*          id="veh_registration_no"*/}
        {/*          validation={validations.veh_registration_no}*/}
        {/*          onChange={this.handleChange}*/}
        {/*          disabled={!!isDocked}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*) : null}*/}

        {!isFull ? (
            <div className="col-md-12 col-sm-12 col-xs-12   input-text">
              <label className="nuclei-input-label">
                Vehicle Registration Number
              </label>
              {format1 ? (
                  <div className="col-md-3 col-sm-3 col-xs-3 zero-padding-left">
                    <AutoCompleteInput
                        type="text"
                        className="form-control"
                        placeholder={"WP"}
                        options={[
                          { label: 'WP', value: 'WP' },
                          { label: 'CP', value: 'CP' },
                          { label: 'SP', value: 'SP' },
                          { label: 'UP', value: 'UP' },
                          { label: 'SG', value: 'SG' },
                          { label: 'NP', value: 'NP' },
                          { label: 'NC', value: 'NC' },
                          { label: 'UP', value: 'UP' },
                          { label: 'EP', value: 'EP' },
                          { label: 'NW', value: 'NW' }
                        ]}
                        value={premiumParams.veh_reg_format_1}
                        onChange={e => this.handleChange("veh_reg_format_1", e.value)}
                        errorText={this.state.error.veh_reg_format_1}
                        id="veh_reg_format_1"
                        disabled={!!isDocked}
                    />
                  </div>
              ) : null}
              <div className="col-md-2 col-sm-3 col-xs-3 zero-padding-left">
                <InputText
                    placeholder={this.state.veh_reg_format=='302-1234'?("302"):(this.state.veh_reg_format=="10-SRI-1234")?("10"):(this.state.veh_reg_format=="WP AB-1234")?("AB"):("ABC")}
                    type={"text"}
                    id="veh_reg_format_2"
                    value={!(isDocked) ? premiumParams.veh_reg_format_2 : premiumParams.veh_reg_format_2}
                    validation={validations.veh_reg_format_2}
                    onChange={e => this.handleChange("veh_reg_format_2", e.target.value)}
                    disabled={!!isDocked}
                    errorText={this.state.error.veh_reg_format_2}
                />
              </div>
              {format2 ? (
                  <div className="col-md-2 col-sm-3 col-xs-3 zero-padding-left">
                    <InputText
                        placeholder="SRI"
                        disabled={true}
                    />
                  </div>
              ) : null}
              <div className="col-md-3 col-sm-3 col-xs-3 zero-padding-left">
                <InputText
                    tooltip="Enter your vihicle number according to the selected vehicle number format"
                    placeholder="1234"
                    type={"number"}
                    id="veh_reg_format_3"
                    value={!(isDocked) ? premiumParams.veh_reg_format_3 : premiumParams.veh_reg_format_3}
                    validation={validations.veh_reg_format_3}
                    onChange={e => this.handleChange("veh_reg_format_3", e.target.value)}
                    disabled={!!isDocked}
                    errorText={this.state.error.veh_reg_format_3}
                />
              </div>
            </div>
        ) : null }

        {!isFull ? (
            <div className="col-md-12 col-sm-12 col-xs-12 input-text">
              <label className="nuclei-input-label">Engine Number</label>
              <div className="col-md-6 col-sm-9 col-xs-9 zero-padding-left">
                <InputText
                    tooltip="Engine No"
                    placeholder="Engine Number"
                    value={premiumParams.engine_no}
                    id="engine_no"
                    validation={validations.engine_no}
                    onChange={e => this.handleChange("engine_no", e.target.value)}
                    disabled={!!isDocked}
                    errorText={field_errors.engine_no}
                />
              </div>
            </div>
        ) : null}

        {!isFull ? (
            <div className="col-md-12 col-sm-12 col-xs-12   input-text">
              <label className="nuclei-input-label">Chassis Number</label>
              <div className="col-md-6 col-sm-9 col-xs-9 zero-padding-left">
                <InputText
                    tooltip="Chassis No."
                    placeholder="Chassis No."
                    value={premiumParams.chassis_no}
                    id="chassis_no"
                    validation={validations.chassis_no}
                    onChange={e => this.handleChange("chassis_no", e.target.value)}
                    disabled={!!isDocked}
                    errorText={field_errors.chassis_no}
                />
              </div>
            </div>
        ) : null}

        {isDocked || this.props.premium.loading ? null : (
          <div className="col-md-11 col-sm-11 col-xs-11">
            <Button
              // type="submit"
              color="red"
              className="nuclei-bottom-red-button"
              onClick={e => this.handleSubmit(e)}
            >
              {" "}
              Get a Quick Quote
            </Button>
            <Button
              type="clear"
              color="gray"
              className="nuclei-bottom-gray-button"
              onClick={e => this.resetForm(e)}
            >
              {" "}
              Clear{" "}
            </Button>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
    motor: state.motor,
    premium: state.premium,
    customerAuth: state.customerAuth
  };
}

export default connect(
  mapStateToProps,
  {
    getAuthToken,
    vehicleTypes,
    getVUsages,
    getNCBYears,
    getFuelTypes,
    getManufactureYears,
    getVMakes,
    getVModels,
    clearAll,
    setFieldErrors,
    clearErrors,
    getParamsByUniqHash,
    fetchPremiums,
    onChangeInput,
    removeFieldError,
    getFriMiLifeStyleCustomerInformation,
    validateToken
  }
)(withRouter(ClassicQuoteForm));
