import React, {Component} from "react";
import "./home.scss";
import Slider from "../../components/Slider/Slider";
import HeaderTitle from "../../components/HeaderTitle";
import MultipleCarousel from "../../components/MultipleCarousel/MultipleCarousel";
import HomeFeatureCard from "../../components/HomeFeatureCard/HomeFeatureCard";
import UserDetailList from "../../components/UserDetailList";
import InsuremePartners from "../../components/InsuremePartners";
import {
    getBlogData,
    getBlogImageData,
    getFeatureCardData,
    getInsureMePartnersData,
    getMultipleSliderData,
    getSliderData,
    getUserData
} from "../../redux/actions";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

class home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSlide: 0
        };
    }

    async componentDidMount() {
        document.title = "InsureMe.lk | Sri Lanka's First Insurance Comparison Website";
        await Promise.all([
            this.props.getSliderData(),
            this.props.getMultipleSliderData(),
            this.props.getFeatureCardData(),
            this.props.getBlogData(),
            this.props.getUserData(),
            this.props.getBlogImageData(),
            this.props.getInsureMePartnersData()
        ]);

        this.setState({
            data: this.props.data
        });
    }

    render() {
        return (
            <div className="" id="nuclei-home">
                <Helmet>
                    <meta
                        name="p:domain_verify"
                        content="300c6d374f32744d3db0e03d0a802196"
                    />
                    <meta
                        name="description"
                        content="InsureMe is a dynamic and innovative insurance brokering company offering Life and General Insurance solutions to corporate and individual customers"
                    />
                    <meta
                        name="keywords"
                        content="InsureMe, Insurance broker, Insurance comparison, Insurance, General insurance, Quotation, Discount, Buy now, Pay monthly, Monthly instalment, InMe club, Car, Jeep, Van, SUV, Cab, Motorcycle, Three-wheeler, Lorry, Travel, Home, Medical, Life, Credit Card Offer, Why InsureMe, InsureMe partners, Cardholders, Credit, Monthly, Sri Lanka, buy 100% online, professional, service, Insurance solutions, Corporate, Individual customers, Efficient, Effective, Claims handling, Claims support, Easy, Vehicle, AIA Insurance, AIG Insurance, Allianz Insurance, Allianz Life Insurance, Fairfirst Insurance, Ceylinco General Insurance, Ceylinco Life Insurance, HNB Assurance, HNB Insurance, Janashakthi Insurance, LOLC Insurance, Sri Lanka Insurance Corporation, Union Assurance, Online, Offline, Nation Trust Bank, NTB DISCOUNT, NTB OFFER, 10% off motor insurance"
                    />
                    <meta name="author" content="InsureMe Insurance Brokers (Pvt) Ltd"/>
                    <meta name="robots" content="index, follow"/>
                </Helmet>
                <div className="nuclei-home-carousel">
                    <Slider
                        onChangedSelected={e => this.setState({selectedSlide: e})}
                        selectedSlide={this.state.selectedSlide}
                        carouselImage={
                            this.state.data ? this.state.data.sliderData.carouselImage : []
                        }
                        onMyButtonClicked={item => this.clickedMe(item)}
                        dockPanel={
                            this.state.data ? this.state.data.sliderData.dockPanel : []
                        }
                    />
                </div>
                <div style={{display:"none"}}>
                    <HeaderTitle
                        mainFirstWord="Featured"
                        restOfTitle="Deals"
                        headerStyle={{fontWeight: "bold"}}
                    />
                    <MultipleCarousel
                        multipleImage={
                            this.state.data
                                ? this.state.data.multipleCarouselData.multipleImage
                                : []
                        }
                    />
                </div>
                <UserDetailList
                    userDetail={
                        this.state.data ? this.state.data.userData.userDetail : []
                    }
                />

                {this.props.data.blogData ? (
                    <div className="nuclei-feature-card-header">
                        <HeaderTitle
                            mainFirstWord="Blog"
                            restOfTitle="Features"
                            headerStyle={{fontWeight: "bold"}}
                        />
                    </div>
                ) : null}
                {this.props.data.blogData ? (
                    <HomeFeatureCard
                        featureCard={this.state.data ? this.state.data.blogData.items : []}
                    />
                ) : null}

                <InsuremePartners
                    partnersImg={
                        this.state.data
                            ? this.state.data.insureMePartnersData.partnersImg
                            : []
                    }
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.data,
        session: state.session
    };
}

export default connect(mapStateToProps, {
    getSliderData,
    getMultipleSliderData,
    getFeatureCardData,
    getBlogData,
    getUserData,
    getBlogImageData,
    getInsureMePartnersData
})(home);
