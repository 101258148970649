import React from "react";
import {IMAGE_ROOT} from '../config';
import {Link} from 'react-router-dom'
import {addCommas} from '../util';
import "./components.scss";
import "./Widget.scss";
function renderLabel(item, thirdParty) {
  if(thirdParty){
    return null;
  }

 if ((item.TotalPremium || item.total_premium )&& item.is_promo_discount === '1') {
   
   return(
   <div className="best-deal-icon-container">
   <span id="best-deal-icon" style={{background: `${item.promotion_color}`}}>
   {item.promotion_value} -
   <span className="best-deal-bank-label">{item.promotion_status}</span>
   </span>
    { item.promotion_2_status && item.promotion_2_value && item.promotion_2_color?
       <span id="best-deal-icon" style={{background: `${item.promotion_2_color}`}}>
       {item.promotion_2_value} -
       <span className="best-deal-bank-label">{item.promotion_2_status}</span>
       </span>
        :
        null
    }
   </div>
    );
  }
  return null;
}

function withoutAgentLabel (item, thirdParty) {
  if(thirdParty){
    return null;
  }else{
    if(item.company_name === 'FIL'){
      return(<span id="without-agent-icon">Without Agent</span>);
    }
  }
  return null;
}

const renderPricing = (item) => {

  if (item.is_promo_discount === "1") {
    return (
      <div>
      <p className="without-discount normal-price">Rs. {addCommas(item.TotalPremium || item.total_premium)}</p>
      <p>
         <span className="discount-price promo-bank-price-label">Rs. {addCommas(item.disc_total_premium)}
           <span className="promo-bank-label">(FOR {item.promotion_status})</span>
         </span>
      </p>
      </div>
    )
  }
  if (item.TotalPremium || item.total_premium) {
    return <p className="total-price">Rs.{addCommas(item.TotalPremium || item.total_premium)}</p>
  }
  return `${item.company_name}`
}

const renderCheckbox = (checked) => {
  if (checked) {
    return (<img src={require('../assets/img1.png')} alt="Insureme.lk" id="unchecked-image" />)
  }
  return (<img src={require('../assets/img2.png')} id="unchecked-image" alt="Insureme.lk" />)
}

export const Widget = ({item, thirdParty, onClick, cartItems, unq_hash, onBuy, onBuyLater, channel, promoCode}) => {
  const checked = cartItems.indexOf(item.qot_number)> -1
  const bodyClassName = checked ? 'widget-body-checked' : 'widget-body';

  if(!item.TotalPremium && !item.total_premium){
    return null;
  }

  return (
    <div
      className="col-md-4 col-sm-6 col-xs-12 widget"
      style={styles.mainDiv}
      onClick={e => onClick(e, item)}
    >
      <div className="pricing-table">
        {/* <pre>{JSON.stringify(item,'',2)}</pre> */}
        <div className={`panel panel-primary ${bodyClassName}`}>
          <div className="controle-header panel-heading panel-heading-landing">
            <div className="checkbox-container">
              {!thirdParty ? renderCheckbox(checked) : <p style={{color:'#0094f7'}}>insureme.lk</p>}
            </div>
            <br/>
              {/* <span  data-toggle="modal" data-target="#exampleModalCenter" id="best-deal-icon" data-toggle="modal" data-target="#myModal">Card Offer</span> */}
              {renderLabel(item, thirdParty)}
              {withoutAgentLabel(item, thirdParty)}
          </div>

          <div className="panel-body panel-body-landing">
            <img className="image-logo" src={`${IMAGE_ROOT}/${item.company_logo}`} alt="..." />
            <br />
            <p className="price">{renderPricing(item)}</p>
          </div>

          <div className="panel-footer panel-footer-landing" id="widgets-footer">
          {item.TotalPremium || item.total_premium? <Link to={`/account?unq_hash=${unq_hash}&qot_number=${item.qot_number}&channel=${channel}&promo=${promoCode}`} className="full-widget-button">BUY NOW</Link>:  ( <a href="https://contact-us.insureme.lk" target="_blank" rel="noopener noreferrer" className="full-widget-button">
        Contact Us
                                                                                                                                                                               </a>)}         
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainDiv: {
    marginTop: "20px"
  },
};