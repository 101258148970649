import React, {Component} from "react";

import {MI_INME_CHANNEL} from '../../config';

class DeclarationButton extends Component {
  
  constructor(props) {

    super(props);

    this.state = {
      type: "",
      ailments: [],  
      specialNote: null,
    };
  }
 
  renderSubQuestions = (options = null) => {
    
    const {selectedAilments} = this.props;
    
    if(options === undefined || options === null) {
      return this.renderErrorMessage();
    }

    const opt = JSON.parse(options.replace(/\\"/g, '"'));

    const {proceedWithSubQuestions, subQuestions} = opt;

    let subQuestion = [];
    if(proceedWithSubQuestions === true) {
      subQuestions.forEach((question, i) => {

          subQuestion.push(
            <div className="row" style={{marginLeft: "10px"}} key={i}>
                <div className="col-sm-12" >
                  <div>{question.question}</div>  
                </div>
                {this.renderAnswers(question.type ,question.answers, selectedAilments.remarks, selectedAilments.specialNote)}
            </div>
          );
      });
    }

    return (
      <div className="row">
        <div className="col-sm-12">
          {subQuestion}
        </div>
      </div>
    );
  }

  renderErrorMessage = () => {
    return (
        <div className="error-message"> 
          <p>Sorry, You are not allowed to purchase a Major Illness policy!</p>
        </div>
    );
  }

  renderAnswers = ( type,answers, ailments, note) => {
    let element = [];
    
    if(type === 'checkbox') {
      answers.forEach((answer, i) => {
        
        element.push(
          <div className="col-sm-6" key={i}>
               <label>
                 <input  type="checkbox" name="ailments" value={answer}  onChange={this.onChangeHandler.bind(this)} checked = {

                    (Array.isArray(ailments) && ailments.indexOf(answer) > -1)

                 } />
                 {answer}
               </label>
          </div>
        );
      });
    }else if(type === 'textarea'){
      element.push(
          <div className="col-sm-12" key="specialNoteTextArea">
              <textarea  type="textarea" name="specialnote" maxLength="255" rows="4" cols="60" onChange={this.onChangeHandler.bind(this)} value={note} />     
          </div>
      );
    }

    return element;
  }

  onChangeHandler = (e) => {
    
    const {value, checked, type} = e.target;
    const {onAilmentSelect, holder, declarations} = this.props;
  
    if( type === 'checkbox') {
      let selectedAilment = value;
    
        Object.assign(declarations, {
            selectedAilment,
            isChecked: checked,
            isNote: false
        });
    }

    if(type === 'textarea') {
      let specialNote = value;
      Object.assign(declarations, {
        id:declarations.id,
        specialNote: specialNote,
        isNote: true,
        isChecked: false
      }); 
   }
    onAilmentSelect(declarations, holder);

  }

  render() {
    const {declarations, onClickYesNo, channel, holder} = this.props;

    return (
      <div>
        <div className="illnes-declaration">
          <p className="illness-title">{declarations.title}</p>
          <p>{declarations.description}</p>

        </div>
        <div className="acceptance-declaration">
          <div className="btn-container btn-group">
            <button
                className={`btn ${this.props.clickedBtn === "yes"  ? "btn-success" : "deactivated"}`}
                onClick={() => {
                onClickYesNo(declarations,holder ,"yes");
              }}
            >
              Yes
            </button>
            <button
                className={`btn ${this.props.clickedBtn === "no" ? "btn-success" : "deactivated"}`}
                onClick={() => {
                onClickYesNo(declarations,holder ,"no");
              }}
            >
              No
            </button>
          </div>
          {this.props.clickedBtn === "no" ? (channel === MI_INME_CHANNEL ? this.renderSubQuestions(declarations.options) : this.renderErrorMessage()) : null}
        </div>
      </div>
    );
  }
}

export default DeclarationButton;
