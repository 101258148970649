import React, {Component} from "react";
import "./index.scss";
import Table from "../../components/Table";


const dataSampleClaims = [
    {
        id: '1',
        claim_ref: 'jsgfdhjsanvdhgsavdnsqd',
        status: 'Pending',
        amount: '10000 rs',
        approved: '10000 rs',
        category: 'accident'
    }, {
        id: '2',
        claim_ref: 'jsgfdhjsanvdhgsavdnsqd',
        status: 'Pending',
        amount: '10000 rs',
        approved: '10000 rs',
        category: 'accident'
    }, {
        id: '3',
        claim_ref: 'jsgfdhjsanvdhgsavdnsqd',
        status: 'Pending',
        amount: '10000 rs',
        approved: '10000 rs',
        category: 'accident'
    }, {
        id: '4',
        claim_ref: 'jsgfdhjsanvdhgsavdnsqd',
        status: 'Pending',
        amount: '10000 rs',
        approved: '10000 rs',
        category: 'accident'
    }, {
        id: '5',
        claim_ref: 'jsgfdhjsanvdhgsavdnsqd',
        status: 'Pending',
        amount: '10000 rs',
        approved: '10000 rs',
        category: 'accident'
    }, {
        id: '6',
        claim_ref: 'jsgfdhjsanvdhgsavdnsqd',
        status: 'Pending',
        amount: '10000 rs',
        approved: '10000 rs',
        category: 'accident'
    }, {
        id: '7',
        claim_ref: 'jsgfdhjsanvdhgsavdnsqd',
        status: 'Pending',
        amount: '10000 rs',
        approved: '10000 rs',
        category: 'accident'
    },
];

const titlesSampleClaims = {
    claim_ref: 'Claim Refference',
    status: 'Status',
    amount: 'Claim Amount',
    approved: 'Approved Amount',
    category: 'Category',
};

const dataSample = [

    {
        id: '1',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '2',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '3',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '4',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '5',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '6',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '7',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
    {
        id: '8',
        policy_number: 'jsgfdhjsanvdhgsavdnsqd',
        exp_data: '2019-1-1',
        total_cover: '10000 rs',
        premium: '10000 rs',
    },
];

const titlesSample = {
    policy_number: 'Policy Number',
    exp_data: 'Expire Date',
    total_cover: 'Total Cover',
    premium: 'Premium',
};

export default class PDMI extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            visibleColumns: []
        }
    }

    renderExpandedView(row, titles) {
        return (
            <div style={{textAlign: 'left'}}>
                <div className={'row'}>
                    <div className={'col-md-2'}>
                        <p>Policy Number</p>
                    </div>
                    <div className={'col-md-2'}>
                        <p>{row.policy_number}</p>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-2'}>
                        <p>Expire Date</p>
                    </div>
                    <div className={'col-md-2'}>
                        <p>{row.exp_data}</p>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-2'}>
                        <p>Total Cover</p>
                    </div>
                    <div className={'col-md-2'}>
                        <p>{row.policy_number}</p>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-2'}>
                        <p>Policy Number</p>
                    </div>
                    <div className={'col-md-2'}>
                        <p>{row.total_cover}</p>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-2'}>
                        <p>Premium</p>
                    </div>
                    <div className={'col-md-2'}>
                        <p>{row.premium}</p>
                    </div>
                </div>

                <Table
                    accordian={false}
                    data={dataSampleClaims}
                    titles={titlesSampleClaims}/>

            </div>
        );
    }

    render() {
        return (
            <div className={'policies-container'}>
                <Table
                    accordian={true}
                    data={dataSample}
                    titles={titlesSample}
                    renderExpandedView={(row, titles) => this.renderExpandedView(row, titles)}/>
            </div>
        );
    }
}
