// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import {
//   OverlayTrigger,
//   Popover,
//   ButtonToolbar,
//   Overlay
// } from "react-bootstrap";
// import Intercom from "react-intercom";
// import React, { Component } from "react";
// import { Spinner, Alert } from "../../components";
// import { getSliderData, postInquiry, getAuthToken } from "../../redux/actions";
// import { InputText, Button } from "../../components";
// import { getInquiryCookie } from "../../util";
// import { validateWithValidations, isEmptyObjectValue } from "../../util";
// import GridTile from "../Slider/GridTile";
// import "./FooterDockPanel.scss";

// const validations = {
//   cx_name: { presence: true },
//   cx_email: { presence: true, email: true },
//   cx_mobile: {
//     format: {
//       pattern: "^94[0-9]{9}",
//       flags: "i",
//       message: "mobile is not valid. ex: 947786..."
//     },
//     numericality: {
//       notLessThan: "11",
//       presence: true
//     },
//     presence: true,
//     length: {
//       is: 11
//     }
//   }
// };

// export class App extends Component {
//   render() {
//     const { appUser } = this.props;

//     const user = {
//       user_id: "256454",
//       email: "fef",
//       name: "efefe"
//     };

//     return (
//       <div className="app">
//         <Intercom appID="il4v96dr" />
//       </div>
//     );
//   }
// }
// class FooterDockPanel extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       data: [],
//       cx_mobile: "94",
//       error: {},
//       showQuote: false,
//       showCallBack: false,
//       showChat: true
//     };
//     this.handleClickQuote = e => {
//       this.setState({
//         targetQuote: e.target,
//         showQuote: !this.state.showQuote,
//         showCallBack: false,
//         showChat: true
//       });
//     };
//     this.handleClickCallback = e => {
//       this.setState({
//         targetCallBack: e.target,
//         showCallBack: !this.state.showCallBack,
//         showQuote: false,
//         showChat: false,
//         showChat: true
//       });
//     };
//     this.handleClickChat = e => {
//       this.setState({
//         targetChat: e.target,
//         showChat: !this.state.showChat,
//         showQuote: false,
//         showCallBack: false
//       });
//     };
//   }

//   async componentDidMount() {
//     await this.props.getAuthToken();
//     await this.props.getSliderData();
//     this.setState({
//       data: this.props.data.sliderData.dockPanel
//     });
//   }

//   renderGridTitle(dockPanel, i) {
//     return (
//       <div
//         key={i}
//         className="col-md-4 col-sm-4 col-xs-4 nuclei-dock-grid-tile zero-padding"
//       >
//         <GridTile gridTile={dockPanel.gridTile} />
//       </div>
//     );
//   }

//   onChange(key, value) {
//     const error = this.state.error;
//     delete error[key];

//     if (key === "cx_mobile") {
//       if (!value || value.length < 2) {
//         value = "94";
//       }
//     }
//     this.setState({ [key]: value, error });
//   }

//   async onSubmit() {
//     const inqueryBody = {
//       cx_name: this.state.cx_name,
//       cx_email: this.state.cx_email,
//       cx_mobile: this.state.cx_mobile
//     };
//     const error = validateWithValidations(inqueryBody, validations);
//     this.setState({ error });
//     if (isEmptyObjectValue(error)) {
//       this.setState({ loading: true });
//       inqueryBody.cx_mobile = inqueryBody.cx_mobile.substring(2);
//       await this.props.postInquiry(inqueryBody);
//       this.setState({ loading: false, isCompleted: true });
//     }
//   }

//   render() {
//     const isCompleted = getInquiryCookie().cx_inquiry_id;

//     const quotePopover = (
//       <Popover
//       className="footer-dock-panel-get-quote"
//       id="popover-trigger-click"
//     >
//       <div className="row">
//         {this.state.data.map((dockPanel, i) => {
//           return this.renderGridTitle(dockPanel, i);
//         })}
//       </div>
//     </Popover>
//     )

//     const callBackPopover = (
//       <Popover
//         id="popover-trigger-click-root-close"
//         className="footer-dock-panel-call-back"
//       >
//         <div className="row ">
//           {isCompleted || this.state.isCompleted ? (
//             <div className="thank-you">
//               <Alert
//                 type="info"
//                 message={"Thank you for submitting. We will call you back."}
//               >
//                 {" "}
//               </Alert>
//             </div>
//           ) : (
//             <div className="insureme-form ">
//               <div className="form-group input-text">
//                 <label className="nuclei-input-label">
//                   Get a Callback from us.
//                 </label>
//               </div>
//               <InputText
//                 validation={validations.cx_name}
//                 placeholder="Your Name"
//                 value={this.state.cx_name}
//                 errorText={this.state.error.cx_name}
//                 onChange={e => this.onChange("cx_name", e.target.value)}
//               />
//               <InputText
//                 validation={validations.cx_email}
//                 placeholder="Email"
//                 value={this.state.cx_email}
//                 errorText={this.state.error.cx_email}
//                 onChange={e => this.onChange("cx_email", e.target.value)}
//               />
//               <InputText
//                 validation={validations.cx_mobile}
//                 placeholder="9477... mobile"
//                 value={this.state.cx_mobile}
//                 errorText={this.state.error.cx_mobile}
//                 onChange={e => this.onChange("cx_mobile", e.target.value)}
//               />

//               <div className="display-flex">
//                 {this.state.loading ? (
//                   <Spinner size="small" />
//                 ) : (
//                   <Button
//                     onClick={() => this.onSubmit()}
//                     type="radius"
//                     color="red"
//                     className="nuclei-bottom-red-button "
//                   >
//                     Send
//                   </Button>
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </Popover>
//     );

//     return (
//       <div className="footer-dock-panel hidden-xs">
//         <img
//           className="dock-panel-background"
//           src={require("../../assets/footer white.png")}
//           alt="..."
//         />
//         <img
//           className="dock-panel-insureme-icon"
//           src={require("../../assets/dock4.png")}
//           alt="..."
//         />
//         <ButtonToolbar>
//           <div
//             onClick={this.handleClickChat}
//             className="dock-panel-insureme-chat dock-panel-hover"
//           >
//             {" "}
//             <Intercom appID="il4v96dr" />
//             <p className="dock-panel-insureme-text">Chat</p>
//           </div>
//           <Overlay
//             show={this.state.showChat}
//             target={this.state.targetChat}
//             placement="top"
//           >
//             <App />
//           </Overlay>
//         </ButtonToolbar>

//         <OverlayTrigger
//           id="dock-panel-insureme-icon"
//           trigger="click"
//           rootClose
//           placement="top"
//           overlay={callBackPopover}
//         >
//           <div
//             onClick={this.handleClickCallback}
//             className="dock-panel-insureme-call dock-panel-hover"
//           >
//             <img
//               className="dock-panel-insureme-image"
//               src={require("../../assets/dock1.png")}
//               alt="..."
//             />
//             <p className="dock-panel-insureme-text">Callback</p>
//           </div>
//         </OverlayTrigger>

//         <OverlayTrigger
//           id="dock-panel-insureme-icon"
//           trigger="click"
//           rootClose
//           placement="top"
//           overlay={quotePopover}
//         >
//     <div
//             onClick={this.handleClickCallback}
//             className="dock-panel-insureme-quote dock-panel-hover"
//           >
//             <img
//                className="dock-panel-insureme-image"
//                src={require("../../assets/dock3.png")}
//               alt="..."
//             />
//             <p className="dock-panel-insureme-text">Quote</p>
//           </div>
//         </OverlayTrigger>

//       </div>
//     );
//   }
// }

// function mapStateToProps(state) {
//   return {
//     data: state.data
//   };
// }
// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(
//     {
//       getSliderData,
//       postInquiry,
//       getAuthToken
//     },
//     dispatch
//   );
// }
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(FooterDockPanel);

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
  // OverlayTrigger,
  Popover,
  ButtonToolbar,
  Overlay
} from "react-bootstrap";
import {Menu, Dropdown ,Modal} from "antd";
import Intercom from "react-intercom";
import React, {Component} from "react";
import {Spinner} from "../../components";
import {
  getSliderData,
  postInquiry,
  getAuthToken,
  getLeadAuthToken,
  loginToLead,
  createLead
} from "../../redux/actions";
import {InputText, Button} from "../../components";
import {getInquiryCookie} from "../../util";
import {validateWithValidations, isEmptyObjectValue} from "../../util";
import GridTile from "../Slider/GridTile";
import "./FooterDockPanel.scss";
import 'antd/dist/antd.min.css';

const validations = {
  cx_name: {presence: true},
  cx_email: {presence: true, email: true},
  cx_mobile: {
    format: {
      pattern: "^[0-9]{9}",
      flags: "i",
      message: "mobile is not valid. ex: 947786..."
    },
    numericality: {
      notLessThan: "9",
      presence: true
    },
    presence: true,
    length: {
      is: 9
    }
  }
};

export class App extends Component {
  render() {
    // const {appUser} = this.props;

    // const user = {
    //   user_id: "256454",
    //   email: "fef",
    //   name: "efefe"
    // };

    return (
      <div className="app">
        <Intercom appID="il4v96dr" />
      </div>
    );
  }
}
class FooterDockPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cx_mobile: "",
      error: {},
      showQuote: false,
      showCallBack: false,
      showChat: false
    };
    this.handleClickQuote = e => {
      this.setState({
        targetQuote: e.target,
        showQuote: !this.state.showQuote,
        showCallBack: false,
        showChat: true
      });
    };
    this.handleClickCallback = e => {
      this.setState({
        targetCallBack: e.target,
        showCallBack: !this.state.showCallBack,
        showQuote: false,
        showChat: true,
      });
    };
    this.handleClickChat = e => {
      this.setState({
        targetChat: e.target,
        showChat: !this.state.showChat,
        showQuote: false,
        showCallBack: false
      });
    };
  }

  async componentDidMount() {
    await this.props.getAuthToken();
    await this.props.getSliderData();
    this.setState({
      data: this.props.data.sliderData.dockPanel
    });
  }

  renderGridTitle(dockPanel, i) {
    return (
      <div
        key={i}
        className="col-md-4 col-sm-4 col-xs-4 nuclei-dock-grid-tile zero-padding"
        onClick={
          ()=>this.setState({
            showQuote: false,
          })
        }
      >
        <GridTile gridTile={dockPanel.gridTile} />
      </div>
    );
  }

  insuremeQuote() {

    return (
        <div>
          <div className="row">
            {this.state.data.map((dockPanel, i) => {
              return this.renderGridTitle(dockPanel, i);
            })}
          </div>

        </div>
    );
  }

  onChange(key, value) {
    const error = this.state.error;
    delete error[key];

    // if (key === "cx_mobile") {
    //   if (!value || value.length < 2) {
    //     value = "94";
    //   }
    // }
    this.setState({[key]: value, error});
  }

  async onSubmit() {
    const inqueryBody = {
      cx_name: this.state.cx_name,
      cx_email: this.state.cx_email,
      cx_mobile: this.state.cx_mobile,
      cx_message: "Callback",
      inquiry_type: "callback",
      inquiry_media: "web",
      inquiry_code: "callback",
      email_subject_json: `InsureMe.lk Callback Inquiry`,
      email_body_json: {
        cx_name: this.state.cx_name,
        cx_email: this.state.cx_email,
        cx_mobile: this.state.cx_mobile,
        inquiry_type: "callback",
        inquiry_media: "web",
        inquiry_code: "callback"
      }
    };
    const leadBody = {
      userId: "19x1",
      assingedUserName: "Insureme",
      lastName: this.state.cx_name,
      leadsource: "Web",
      phone: this.state.cx_mobile,
      email: this.state.cx_email,
      description: "Request From Callback"
    };
    const error = validateWithValidations(inqueryBody, validations);
    this.setState({error});
    if (isEmptyObjectValue(error)) {
      this.setState({loading: true});
      await this.props.postInquiry(inqueryBody);
      await this.props.getLeadAuthToken();
      await this.props.loginToLead(leadBody);
      this.setState({loading: false, isCompleted: true,showQuote:false});
    }
  }

  insuremeCalleBack() {
    const isCompleted = getInquiryCookie().cx_inquiry_id;
    return (
      <div

      >
        <div className="row ">
          {isCompleted || this.state.isCompleted ? (
            <label className="nuclei-input-label thank-you">
              Thank you for submitting. We will call you back.
            </label>
          ) : (
            <div className="insureme-form ">
              <div className="form-group input-text">
                <label className="nuclei-input-label">
                  Get a Callback from us.
                </label>
              </div>
              <InputText
                validation={validations.cx_name}
                placeholder="Your Name"
                value={this.state.cx_name}
                errorText={this.state.error.cx_name}
                onChange={e => this.onChange("cx_name", e.target.value)}
              />
              <InputText
                validation={validations.cx_email}
                placeholder="Email"
                value={this.state.cx_email}
                errorText={this.state.error.cx_email}
                onChange={e => this.onChange("cx_email", e.target.value)}
              />
              <div className="mobileSection">
                <div className="mobileSection1">
                  <InputText placeholder="94" value="94" disabled="disabled" />
                </div>
                <div className="mobileSection2">
                  <InputText
                    validation={validations.cx_mobile}
                    placeholder="mobile"
                    value={this.state.cx_mobile}
                    errorText={this.state.error.cx_mobile}
                    onChange={e => this.onChange("cx_mobile", e.target.value)}
                  />
                </div>
              </div>
              <div className="">
                {this.state.loading ? (
                  <Spinner size="small" />
                ) : (
                  <Button
                    onClick={() => this.onSubmit()}
                    type="radius"
                    color="red"
                    className="nuclei-bottom-red-button "
                  >
                    Send
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="footer-dock-panel hidden-xs">
        <img
          className="dock-panel-background"
          src={require("../../assets/footer white.png")}
          alt="..."
        />
        <img
          className="dock-panel-insureme-icon"
          src={require("../../assets/dock4.png")}
          alt="..."
        />
        <ButtonToolbar>
          <div
            onClick={this.handleClickChat}
            className="dock-panel-insureme-chat dock-panel-hover"
          >
            {" "}
            <Intercom appID="il4v96dr" />
            <p className="dock-panel-insureme-text">Chat</p>
          </div>
          <Overlay
            show={this.state.showChat}
            target={this.state.targetChat}
            placement="top"
          >
            <App />
          </Overlay>
        </ButtonToolbar>
        <ButtonToolbar>

            <div
                onClick={this.handleClickCallback}
                className="dock-panel-insureme-call dock-panel-hover"
            >
              <img
                  className="dock-panel-insureme-image"
                  src={require("../../assets/dock1.png")}
                  alt="..."
              />
              <p className="dock-panel-insureme-text">Callback</p>
            </div>

          <Modal
              closable={false}
              footer={null}
              visible={this.state.showCallBack}
              onCancel={()=>this.setState({
                showCallBack: false
              })}
          >
            {this.insuremeCalleBack()}
          </Modal>
          {/*<div*/}
          {/*  onClick={this.handleClickCallback}*/}
          {/*  className="dock-panel-insureme-call dock-panel-hover"*/}
          {/*>*/}
          {/*  <img*/}
          {/*    className="dock-panel-insureme-image"*/}
          {/*    src={require("../../assets/dock1.png")}*/}
          {/*    alt="..."*/}
          {/*  />*/}
          {/*  <p className="dock-panel-insureme-text">Callback</p>*/}
          {/*</div>*/}
          {/*<Overlay*/}
          {/*  show={this.state.showCallBack}*/}
          {/*  target={this.state.targetCallBack}*/}
          {/*  placement="top"*/}
          {/*>*/}
          {/*  {this.insuremeCalleBack()}*/}
          {/*</Overlay>*/}
        </ButtonToolbar>

        <ButtonToolbar>


              <div
                onClick={this.handleClickQuote}
                className="dock-panel-insureme-quote dock-panel-hover"
              >
                <img
                  className="dock-panel-insureme-image"
                  src={require("../../assets/dock3.png")}
                  alt="..."
                />
                <p className="dock-panel-insureme-text">Quote</p>
              </div>

          <Modal
              closable={false}
              footer={null}
              visible={this.state.showQuote}
              onCancel={()=>this.setState({
                showQuote: false
              })}
          >
            {this.insuremeQuote()}
          </Modal>

          {/*<div*/}
          {/*  onClick={this.handleClickQuote}*/}
          {/*  className="dock-panel-insureme-quote dock-panel-hover"*/}
          {/*>*/}
          {/*  <img*/}
          {/*    className="dock-panel-insureme-image"*/}
          {/*    src={require("../../assets/dock3.png")}*/}
          {/*    alt="..."*/}
          {/*  />*/}
          {/*  <p className="dock-panel-insureme-text">Quote</p>*/}
          {/*</div>*/}
          {/*<Overlay*/}
          {/*  show={this.state.showQuote}*/}
          {/*  target={this.state.targetQuote}*/}
          {/*  placement="top"*/}
          {/*>*/}
          {/*  {this.insuremeQuote()}*/}
          {/*</Overlay>*/}
        </ButtonToolbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.data
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSliderData,
      postInquiry,
      getAuthToken,
      loginToLead,
      createLead,
      getLeadAuthToken
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterDockPanel);
