import React, {Component} from "react";
import "./Header.scss";
import {Link, withRouter} from "react-router-dom";
import Button from "../../components/Button";
import {INME_AUTH} from "../../config";
import {validateToken} from "../../redux/actions";
import {connect} from "react-redux";


class Header extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeHotline: 0,
      isLogin: false,
    };
  }

  async componentDidMount() {
    this.setState({
      authenticated: false
    });
    let token = localStorage.getItem('authToken');
    if (token) {
      const validToken = await this.props.validateToken(token);

      if (validToken.valid === true) {
        this.setState({
          isLogin: true
        });
      }  else {
        this.setState({
          isLogin: false,
        });
      }
    } else {
      this.setState({
        isLogin: false,
      });
    }
  }


  changeActiveHotline(i) {
    this.setState({activeHotline: i});
  }

  onLogout = () => {
    localStorage.removeItem('authToken');
    this.props.history.push(`/`);
    window.location.reload();
  };

  renderAuthBar() {
    if (this.state.isLogin === null) { //|| window.location.href.indexOf('profile') > -1
      return null;
    } else if (this.state.isLogin === true) {
      return (
          <>

            <ul className="nav navbar-nav justify-content-right"
                style={{float: 'right', paddingTop: 13, paddingLeft: 10}}>
              <li>
                <a className={'auth-nav-button'} style={{color: "yellow"}} href="/profile">Go to Profile</a>
              </li>

              <li className="dropdown" style={{float: "left"}}>
                <a className="dropdown-toggle no-padding" data-toggle="dropdown" href="#">
                  <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar"
                       className="avatar"/>
                </a>

                <ul className="dropdown-menu">
                  <li>
                    <Button
                        className={'logout-button'}
                        onClick={() => this.onLogout()}
                        style={{}}>
                      <p className={'no-margin-text'} style={{marginBottom: -2}}> Logout </p>
                    </Button>
                  </li>
                </ul>
              </li>
            </ul>


          </>
      );
    } else {
      return (
          <>

            <ul className="nav navbar-nav justify-content-center"
                style={{float: 'right', paddingTop: 13, paddingLeft: 10}}>
              <li>

                <Button
                    className={'login-button'}
                    onClick={() => this.onLogin()}
                    style={{backgroundColor: 'yellow'}}>
                  <a href={`${INME_AUTH}/v1/public_login`}><p className={'no-margin-text'} style={{
                    marginBottom: -2,
                    fontSize: 15,
                    fontWeight: "bold"
                  }}> Login </p></a>
                </Button>

              </li>

            </ul>
          </>
      );
    }
  }

  renderMessage() {
    if (this.state.isLogin === false) {
      return (

          <ul className='nav navbar-nav justify-content-center' >
            <li style={{padding: 20, fontSize: 18, fontWeight: 'bold', color: 'yellow'}}>Please login !</li>
          </ul>

      )
    }
  }

  render() {
    return (
      <div className="nuclei-navbar  navbar-wrapper ">
        <nav className="navbar ">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand" href= {'https://insureme.lk'}>
                <img
                  src={require("../../assets/dialog.png")}
                  id="brand-logo"
                  alt=""
                />
                <img
                  src={require("../../assets/insureme-dialog.png")}
                  id="brand-logo-insu"
                  alt=""
                />
                {/* <img src={require('../../assets/logo-insu.png')} id="brand-logo-insu" alt="" /> */}
                {/* <img src="https://insureme.lk/assets/images/dialog/insureme-dialog.png" id="brand-logo-insu" alt="" /> */}
              </a>
            </div>
            <div id="navbar" className="navbar-collapse collapse hidden-xs">
              {this.renderMessage()}
              {this.renderAuthBar()}
              <ul className="nav navbar-nav navbar-right header-item ">
                {this.props.hotLine.map((hotline, i) => {
                  let hotlineActive = "deactive";
                  if (this.state.activeHotline === i) {
                    hotlineActive = "";
                  }
                  return [
                    <li key={i} className={`fadeIn animated ${hotlineActive}`}>
                      <p className="contact-icon-text">{hotline.name}</p>
                      <p className="contact-number">
                        <a href={hotline.href} target={"_blank"}>{hotline.number}</a>
                      </p>
                    </li>,
                    <li key={i + 1} className="contact-icon">
                      <Link to="#">
                        <img
                            onClick={e => {
                              this.changeActiveHotline(i);
                            }}
                            className="nuclei-header-img"
                            src={hotline.image}
                            alt=""
                        />
                      </Link>
                    </li>
                  ];
                })}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    session: state.session,
    user: state.user,
    customerAuth: state.customerAuth
  };
}

export default connect(mapStateToProps, {
      // generateOTP,
      // verifyOTPCode,
      validateToken
    }
)(withRouter(Header));

