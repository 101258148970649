import React, {Component} from "react";
import "./HomeFeatureCard.scss";
import FeatureCard from "../FeatureCard";
// import striptags from 'striptags';
var h2p = require("html2plaintext");

export default class HomeFeatureCard extends Component {
  featureCard(featureCard, i) {
    if (i > 2) {
      var hiddenItem = "hidden-xs";
    } else {
      hiddenItem = "";
    }
    return (
      <div key={i} className={`col-md-4 col-sm-4 col-xs-12 ${hiddenItem}`}>
        <FeatureCard
          shadow="false"
          imageUrl={featureCard.image_url}
          header={featureCard.title}
          paragraph={h2p(featureCard.content_html)}
          getAQuoteUrl={
            featureCard.tags === "motor"
              ? "https://insureme.lk/motor/Car/full-insurance/Quotation"
              : featureCard.tags === "senior-citizens"
              ? "https://senior-citizen.insureme.lk/"
              : ""
          }
          readMoreUrl={featureCard.url}
          // badgeCaption='FEATURED'
          badgeColor="red"
          hover={featureCard.hover}
          textContentStyle={featureCard.textContentStyle}
        />
      </div>
    );
  }
  render() {
    return (
      <div>
        <div className="row">
          {this.props.featureCard.slice(0, 3).map((featureCard, i) => {
            return this.featureCard(featureCard, i);
          })}
        </div>
      </div>
    );
  }
}
