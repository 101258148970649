import axios from "axios";
import lodashPick from "lodash/pick";

import _ from "lodash";
import {
  hasAuthError,
  objectToLabelValue,
  getCommonError,
  // hasMultipleRecords,
  missingParameters,
  invalidRegNumber
} from "../../util";

import {SYS_API, MOTOR_API, FRIMI_LIFESTYLE_CX_INFO_GET_URL} from "../../config";
const CLEAR_ERRORS = "CLEAR_ERRORS";
const GET_V_TYPES = "GET_V_TYPES";
const GET_V_TYPES_DONE = "GET_V_TYPES_DONE";
const GET_V_TYPES_FAILED = "GET_V_TYPES_FAILED";

const GET_FUEL_TYPES = "GET_FUEL_TYPES";
const GET_FUEL_TYPES_DONE = "GET_FUEL_TYPES_DONE";
const GET_FUEL_TYPES_FAILED = "GET_FUEL_TYPES_FAILED";

const GET_NCB_YEARS = "GET_NCB_YEARS";
const GET_NCB_YEARS_DONE = "GET_NCB_YEARS_DONE";
const GET_NCB_YEARS_FAILED = "GET_NCB_YEARS_FAILED";

const GET_V_USAGE = "GET_V_USAGE";
const GET_V_USAGE_DONE = "GET_V_USAGE_DONE";
const GET_V_USAGE_FAILED = "GET_V_USAGE_FAILED";

const GET_MANU_YEARS = "GET_MANU_YEARS";
const GET_MANU_YEARS_DONE = "GET_MANU_YEARS_DONE";
const GET_MANU_YEARS_FAILED = "GET_MANU_YEARS_FAILED";

const GET_V_MAKES = "GET_V_MAKES";
const GET_V_MAKES_DONE = "GET_V_MAKES_DONE";
const GET_V_MAKES_FAILED = "GET_V_MAKES_FAILED";

const GET_V_MODELS = "GET_V_MODELS";
const GET_V_MODELS_DONE = "GET_V_MODELS_DONE";
const GET_V_MODELS_FAILED = "GET_V_MODELS_FAILED";

const GET_LEASING_COMPANY_LIST = "GET_LEASING_COMPANY_LIST";
const GET_LEASING_COMPANY_LIST_DONE = "GET_LEASING_COMPANY_LIST_DONE";
const GET_LEASING_COMPANY_LIST_FAILED = "GET_LEASING_COMPANY_LIST_FAILED";

const GET_COVER_NOTE = "GET_COVER_NOTE";
const GET_COVER_NOTE_DONE = "GET_COVER_NOTE_DONE";
const GET_COVER_NOTE_FAILED = "GET_COVER_NOTE_FAILED";

const GET_COVER_NOTE_EMAIL = "GET_COVER_NOTE_EMAIL";
const GET_COVER_NOTE_EMAIL_DONE = "GET_COVER_NOTE_EMAIL_DONE";
const GET_COVER_NOTE_EMAIL_FAILED = "GET_COVER_NOTE_EMAIL_FAILED";

const PROPOSAL_LOAD = "PROPOSAL_LOAD";
const PROPOSAL_LOAD_DONE = "PROPOSAL_LOAD_DONE";
const PROPOSAL_LOAD_FAILED = "PROPOSAL_LOAD_FAILED";

const FRIMI_LIFESTYLE_CX_INFO = 'FRIMI_LIFESTYLE_CX_INFO';
const FRIMI_LIFESTYLE_CX_INFO_DONE = 'FRIMI_LIFESTYLE_CX_INFO_DONE';
const FRIMI_LIFESTYLE_CX_INFO_FAILED = 'FRIMI_LIFESTYLE_CX_INFO_FAILED';

const CLEAR_MOTOR_ERRORS = 'CLEAR_MOTOR_STATE_ERRORS';

const INIT_STATE = {
  loading: false,
  errorMessage: null,
  vTypes: [],
  fuelTypes: [],
  ncbYears: [],
  vUsages: [],
  vManuYears: [],
  vMakes: [],
  vModels: [],
  comList: [],
  proposalDetails: [],
  coverDetails: [],
  coverEmailDetails: []
};

function isEmptyArray(array) {
  if (array && array.length > 0) {
    return false;
  }
  return true;
}

export const vehicleTypes = () => async (dispatch, getState) => {
  try {
    const {vTypes} = getState().motor;
    if (!isEmptyArray(vTypes)) {
      return null;
    }
    dispatch({type: GET_V_TYPES});
    const res = await axios.get(`${SYS_API}/v1/vehicle/type`);
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_V_TYPES_FAILED, payload: error});
    }
    return dispatch({type: GET_V_TYPES_DONE, payload: res.data.data});
  } catch (error) {
    return dispatch({
      type: GET_V_TYPES_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getNCBYears = () => async (dispatch, getState) => {
  try {
    const {ncbYears} = getState().motor;
    if (!isEmptyArray(ncbYears)) {
      return null;
    }
    dispatch({type: GET_NCB_YEARS});

    const res = await axios.get(`${SYS_API}/v1/vehicle/ncb_years`);
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_NCB_YEARS_FAILED, payload: error});
    }
    // const result = res.data.data || [];

    return dispatch({type: GET_NCB_YEARS_DONE, payload: res.data.data});
  } catch (error) {
    return dispatch({
      type: GET_NCB_YEARS_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getManufactureYears = () => async (dispatch, getState) => {
  try {
    const {vManuYears} = getState().motor;
    if (!isEmptyArray(vManuYears)) {
      return null;
    }
    dispatch({type: GET_MANU_YEARS});
    const res = await axios.get(`${SYS_API}/v1/vehicle/manufacture_years`);
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_MANU_YEARS_FAILED, payload: error});
    }
    const newVManuYears = objectToLabelValue(res.data.data) || [];

    return dispatch({
      type: GET_MANU_YEARS_DONE,
      payload: _.sortBy(newVManuYears, "value").reverse()
    });
  } catch (error) {
    return dispatch({
      type: GET_MANU_YEARS_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getFuelTypes = vType => async dispatch => {
  dispatch({type: GET_FUEL_TYPES});
  try {
    const res = await axios.get(
      `${SYS_API}/v1/vehicle/fuel_type?type=${vType}`
    );
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_FUEL_TYPES_FAILED, payload: error});
    }
    const fuelTypes = objectToLabelValue(res.data.data);
    return dispatch({type: GET_FUEL_TYPES_DONE, payload: fuelTypes});
  } catch (error) {
    return dispatch({
      type: GET_FUEL_TYPES_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getVUsages = vType => async (dispatch, getState) => {
  dispatch({type: GET_V_USAGE});
  try {
    const res = await axios.get(`${SYS_API}/v1/vehicle/usage?type=${vType}`);
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_V_USAGE_FAILED, payload: error});
    }
    const vUsages = objectToLabelValue(res.data.data);
    return dispatch({type: GET_V_USAGE_DONE, payload: vUsages});
  } catch (error) {
    return dispatch({
      type: GET_V_USAGE_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getVMakes = vType => async dispatch => {
  dispatch({type: GET_V_MAKES});
  try {
    const res = await axios.get(`${SYS_API}/v1/vehicle/make?type=${vType}`);
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_V_MAKES_FAILED, payload: error});
    }
    return dispatch({type: GET_V_MAKES_DONE, payload: res.data.data});
  } catch (error) {
    return dispatch({
      type: GET_V_MAKES_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getVModels = (vType, make) => async (dispatch, getState) => {
  dispatch({type: GET_V_MODELS});
  try {
    let vMakeId = null;
    getState().motor.vMakes.forEach(item => {
      if (item.make === make) {
        vMakeId = item.id;
      }
    });
    if (!vMakeId) {
      return dispatch({type: GET_V_MODELS_FAILED, payload: null});
    }
    const res = await axios.get(
      `${SYS_API}/v1/vehicle/model?type=${vType}&id=${vMakeId}`
    );
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_V_MODELS_FAILED, payload: error});
    }

    return dispatch({type: GET_V_MODELS_DONE, payload: res.data.data});
  } catch (error) {
    return dispatch({
      type: GET_V_MODELS_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const loadProposal = hash => async (dispatch, getState) => {
  try {
    dispatch({type: PROPOSAL_LOAD});
    const res = await axios.get(
      `${MOTOR_API}/v3/proposal/load?prop_hash=${hash}`
    );
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: PROPOSAL_LOAD_FAILED, payload: error});
    }

    return dispatch({type: PROPOSAL_LOAD_DONE, payload: res.data.data});
  } catch (error) {
    return dispatch({
      type: PROPOSAL_LOAD_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getCompanyList = () => async (dispatch, getState) => {
  try {
    dispatch({type: GET_LEASING_COMPANY_LIST});
    const res = await axios.get(`${SYS_API}/v1/company/finacial`);
    const error = hasAuthError(res);
    if (error) {
      return dispatch({
        type: GET_LEASING_COMPANY_LIST_FAILED,
        payload: error
      });
    }

    return dispatch({
      type: GET_LEASING_COMPANY_LIST_DONE,
      payload: res.data.data
    });
  } catch (error) {
    return dispatch({
      type: GET_LEASING_COMPANY_LIST_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const coverNoteGerate = body => async (dispatch, getState) => {
  const picked = lodashPick(body, [
    "req_key",
    "veh_registration_no",
    "engine_no",
    "chassis_no",
    "financial_company",
    "financial_company_branch",
    "lease_exp_date",
    "is_registered",
    "is_leasing"
  ]);
  try {
    dispatch({type: GET_COVER_NOTE});
    const res = await axios.post(`${MOTOR_API}/v2/cover-note/gen`, {
      ...picked,
      veh_reg_format: "1",
      conf_bank_name: "ONLINE",
      conf_bank_branch: "ONLINE",
      conf_bank_user: "ONLINE",
      pdf_status: "1"
    });
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_COVER_NOTE_FAILED, payload: error});
    }
    const error2 = missingParameters(res);
    if (error2) {
      return dispatch({type: GET_COVER_NOTE_FAILED, payload: error2});
    }
    const error3 = invalidRegNumber(res);
    if (error3) {
      return dispatch({type: GET_COVER_NOTE_FAILED, payload: error3});
    }
    return dispatch({type: GET_COVER_NOTE_DONE, payload: res.data.data});
  } catch (error) {
    return dispatch({
      type: GET_COVER_NOTE_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const sendCoverNoteEmail = body => async (dispatch, getState) => {
  try {
    dispatch({type: GET_COVER_NOTE_EMAIL});
    const res = await axios.post(`${SYS_API}/notification-api/v1/notify`, {
      ...body
    });
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_COVER_NOTE_EMAIL_FAILED, payload: error});
    }
    return dispatch({
      type: GET_COVER_NOTE_EMAIL_DONE,
      payload: res.data.data
    });
  } catch (error) {
    return dispatch({
      type: GET_COVER_NOTE_EMAIL_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getFriMiLifeStyleCustomerInformation = body => async (dispatch, getState) => {
  try {

    dispatch({type: FRIMI_LIFESTYLE_CX_INFO});

    const res = await axios.get(`${FRIMI_LIFESTYLE_CX_INFO_GET_URL}?token=${body}`);


    if(!res.data) {
      return dispatch({
        type: FRIMI_LIFESTYLE_CX_INFO_FAILED,
        payload: 'Invalid Response from FriMi Service'
      })
    }

    if(res.data.status && res.data.status == "ERR") {
      return  dispatch(
          {
            type: FRIMI_LIFESTYLE_CX_INFO_FAILED,
            payload: 'FriMi LifeStyle User Not Found'
          }
      );
    }

    return  dispatch(
        {
          type: FRIMI_LIFESTYLE_CX_INFO_DONE,
          payload: res.data.data
        }
    );

  }catch (error) {
    return dispatch({
      type: FRIMI_LIFESTYLE_CX_INFO_FAILED,
      payload: getCommonError(error)
    });
  }

};


export default function reducer(state = INIT_STATE, action) {
  const {type, payload} = action;

  switch (type) {
    case CLEAR_ERRORS:
      return {...state, errorMessage: false};
    case GET_V_TYPES:
      return {...state, loading: true};
    case GET_V_TYPES_DONE:
      return {...state, loading: false, vTypes: payload};
    case GET_V_TYPES_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case GET_FUEL_TYPES:
      return {...state, loading: true};
    case GET_FUEL_TYPES_DONE:
      return {...state, loading: false, fuelTypes: payload};
    case GET_FUEL_TYPES_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case GET_NCB_YEARS:
      return {...state, loading: true};
    case GET_NCB_YEARS_DONE:
      return {...state, loading: false, ncbYears: payload};
    case GET_NCB_YEARS_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case GET_V_USAGE:
      return {...state, loading: true};
    case GET_V_USAGE_DONE:
      return {...state, loading: false, vUsages: payload};
    case GET_V_USAGE_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case GET_MANU_YEARS:
      return {...state, loading: true};
    case GET_MANU_YEARS_DONE:
      return {...state, loading: false, vManuYears: payload};
    case GET_MANU_YEARS_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case GET_V_MAKES:
      return {...state, loading: true};
    case GET_V_MAKES_DONE:
      return {...state, loading: false, vMakes: payload};
    case GET_V_MAKES_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case GET_V_MODELS:
      return {...state, loading: true};
    case GET_V_MODELS_DONE:
      return {...state, loading: false, vModels: payload};
    case GET_V_MODELS_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case GET_LEASING_COMPANY_LIST:
      return {...state, loading: true};
    case GET_LEASING_COMPANY_LIST_DONE:
      return {...state, loading: false, comList: payload};
    case GET_LEASING_COMPANY_LIST_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case GET_COVER_NOTE:
      return {...state, loading: true};
    case GET_COVER_NOTE_DONE:
      return {...state, loading: false, coverDetails: payload};
    case GET_COVER_NOTE_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case GET_COVER_NOTE_EMAIL:
      return {...state, loading: true};
    case GET_COVER_NOTE_EMAIL_DONE:
      return {...state, loading: false, coverEmailDetails: payload};
    case GET_COVER_NOTE_EMAIL_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case PROPOSAL_LOAD:
      return {...state, loading: true};
    case PROPOSAL_LOAD_DONE:
      return {...state, loading: false, proposalDetails: payload};
    case PROPOSAL_LOAD_FAILED:
      return {...state, loading: false, errorMessage: payload};

    case FRIMI_LIFESTYLE_CX_INFO:
      return {...state, loading: true};
    case FRIMI_LIFESTYLE_CX_INFO_DONE:
      return {...state, loading: false, friMiCxDetails: payload};
    case FRIMI_LIFESTYLE_CX_INFO_FAILED:
      return {...state, loading: false, errorMessage: payload};
    case CLEAR_MOTOR_ERRORS:
      return {...state, loading: false, errorMessage: payload}
    default:
      return state;
  }
}
