export * from "./reducers/home";
export * from "./reducers/auth";
export * from "./reducers/motor";
export * from "./reducers/user";
export * from "./reducers/local";
export * from "./reducers/premium";
export * from "./reducers/leads";
export * from "./reducers/criticalillness";
export * from "./reducers/consumer";
export * from "./reducers/customerAuth";

export const clearErrors = () => dispatch => {
  return dispatch({type: "CLEAR_ERRORS"});
};
