import React, {Component} from "react";
import "./CustomPage.scss";
const styles = {
  CustomPageContainer: {
    padding: 20,
    backgroundColor: "white"
  }
};

export default class CustomPage extends Component {
  render() {
    let {title, status, buttonText, dynamicLink, description} = this.props;
    return (
      <div className="container" style={styles.CustomPageContainer}>
        <div className="col-md-12">
          <div className="result-page-image-container">
            <img
              className="img-responsive"
              src={require("../../assets/result-page-min.png")}
              alt="Page 404"
            />
            <span className="result-page-image-title">{status}</span>
          </div>
          <p className="nuclei-input-label text-center">{title}</p>
          <p className="custom-description">{description}</p>

          <br />
          {buttonText && dynamicLink ? (
            <a
              type="radius"
              href={dynamicLink}
              className="custom-result-button"
            >
              {buttonText}
            </a>
          ) : null}
        </div>
      </div>
    );
  }
}
