import axios from "axios";
import {INME_MPTOR_API, LOADING_COMMON_ERROR_MESSAGE} from "../../config";

const LOADING = "LOADING";
const LOADING_COMMON_ERROR = "LOADING_COMMON_ERROR";
const GET_FORM_DATA_DONE = "GET_FORM_DATA_DONE";
const GET_FORM_DATA_ERROR = "GET_FORM_DATA_ERROR";

const initState = {
    loading: false,
    status: null,
    message: null,
    data: null,
    errors: null,
};

export default function reducer(state = initState, action) {
    const {type, payload} = action;
    switch (type) {
        case LOADING:
            return {
                ...state,
                loading: payload
            };
        case LOADING_COMMON_ERROR:
            return {
                ...state,
                loading: false,
                message: payload.message,
                status: payload.status,
                errors: payload.errors,
            };
        case GET_FORM_DATA_DONE:
            return {
                ...state,
                loading: false,
                message: payload.message,
                status: payload.status,
                errors: null,
                data: payload.data,
            };

        default:
            return state;
    }
}

export const getFormData = (hash_code) => async dispatch => {
    try {
        dispatch({
            type: LOADING,
            payload: true
        });
        const res = await axios.post(`${INME_MPTOR_API}/v3/documents/get_documents`, {
            hash_code: hash_code
        });
        if (res.status !== 200) {
            return dispatch({
                type: LOADING_COMMON_ERROR,
                payload: {
                    status: 'error',
                    message: 'Error while getting data',
                    errors: null,
                }
            });
        }
        return dispatch({
            type: GET_FORM_DATA_DONE,
            payload: res.data
        });
    } catch (error) {
        console.log(error.message);
        return dispatch({
            type: LOADING_COMMON_ERROR,
            payload: {
                status: 'error',
                message: LOADING_COMMON_ERROR_MESSAGE,
                errors: [],
            }

        });
    }
};

export const uploadDocument = (data) => async dispatch => {
    try {
        dispatch({
            type: LOADING,
            payload: true
        });
        const res = await axios.post(`${INME_MPTOR_API}/v3/documents/upload_documents`, data);
        if (res.status !== 200) {
            return dispatch({
                type: LOADING_COMMON_ERROR,
                payload: {
                    status: 'error',
                    message: 'Error while getting data',
                    errors: null,
                }
            });
        }
        return dispatch({
            type: GET_FORM_DATA_DONE,
            payload: res.data
        });
    } catch (error) {
        console.log(error.message);
        return dispatch({
            type: LOADING_COMMON_ERROR,
            payload: {
                status: 'error',
                message: LOADING_COMMON_ERROR_MESSAGE,
                errors: [],
            }

        });
    }
};

export const removeDocument = (id,hash_code) => async dispatch => {
    try {
        dispatch({
            type: LOADING,
            payload: true
        });
        const res = await axios.post(`${INME_MPTOR_API}/v3/documents/remove_document`, {
            id: id,
            hash_code:hash_code
        });
        if (res.status !== 200) {
            return dispatch({
                type: LOADING_COMMON_ERROR,
                payload: {
                    status: 'error',
                    message: 'Error while getting data',
                    errors: null,
                }
            });
        }
        return dispatch({
            type: GET_FORM_DATA_DONE,
            payload: res.data
        });
    } catch (error) {
        console.log(error.message);
        return dispatch({
            type: LOADING_COMMON_ERROR,
            payload: {
                status: 'error',
                message: LOADING_COMMON_ERROR_MESSAGE,
                errors: [],
            }

        });
    }
};

export const submitForReview = (hash_code) => async dispatch => {
    try {
        dispatch({
            type: LOADING,
            payload: true
        });
        const res = await axios.post(`${INME_MPTOR_API}/v3/documents/submit-for-review`, {
            hash_code:hash_code
        });
        if (res.status !== 200) {
            return dispatch({
                type: LOADING_COMMON_ERROR,
                payload: {
                    status: 'error',
                    message: 'Error while getting data',
                    errors: null,
                }
            });
        }
        return dispatch({
            type: GET_FORM_DATA_DONE,
            payload: res.data
        });
    } catch (error) {
        return dispatch({
            type: LOADING_COMMON_ERROR,
            payload: {
                status: 'error',
                message: LOADING_COMMON_ERROR_MESSAGE,
                errors: [],
            }

        });
    }
};
