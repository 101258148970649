import React, {Component} from "react";
import "./index.scss";
import Tab from '../../components/Tab'
import Tabs from "../../components/Tabs";
import "react-circular-progressbar/dist/styles.css";

import {
    getCustomerPolicies,
    getPerDayPolicies
} from "../../redux/actions";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {Spinner} from "../../components";

class PersonalPolicies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customer_id: null,
            motorPolicies: null,
            perDayPolicies: null
        }

    }

    async componentDidMount() {

        await this.setState({
            customer_id: this.props.customerAuth.customerData
        });

        const motor = await this.props.getCustomerPolicies(this.state.customer_id);
        const perDay = await this.props.getPerDayPolicies(this.state.customer_id);

        this.setState({
            motorPolicies: motor.payload,
            perDayPolicies: perDay.payload
        });
    }

    render() {

        const {motorPolicies, perDayPolicies} = this.state;
        const {consumer} = this.props;

        return (

            <div className="personal-policies">
                <Spinner spinning={consumer.loading} />

                <Tabs title='Per Day Motor Insurance'>
                    <Tab
                        data = {perDayPolicies}
                    />
                </Tabs>
                <Tabs title='Motor'>
                    <Tab
                        data= {motorPolicies}
                    />
                </Tabs>
                <Tabs title='Corporate Policies'>
                    <Tab
                        // data= {motorPolicies}
                    />
                </Tabs>
                <Tabs title='Senior Citizen'>
                    <Tab
                        // data= {motorPolicies}
                    />
                </Tabs>
                <Tabs title='Major Illness'>
                    <Tab
                        // data= {motorPolicies}
                    />
                </Tabs>

            </div>

        );
    }

}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        consumer: state.consumer,
        customerAuth: state.customerAuth

    };
}

export default connect(mapStateToProps, {
        getCustomerPolicies,
        getPerDayPolicies
    }
)(withRouter(PersonalPolicies));
