import React, { Component } from "react";
import {
  InputText,
  Button,
  AutoCompleteInput,
  ButtonGroup,
  MonthYear,
  Alert,
  Spinner
} from "../../../components";
import {
  validateWithValidations,
  isEmptyObjectValue,
} from "../../../util";
import { getQueryParams } from "../../../history";
import "./ProposalForm.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import { connect } from "react-redux";
import {
  postInquiry,
  getAuthToken,
  onChangeInput,
  fetchPremiums,
  clearErrors,
  getCompanyList,
  coverNoteGerate,
  sendCoverNoteEmail,
  consumerLookupCustomerId,
  loadProposal
} from "../../../redux/actions";
import { bindActionCreators } from "redux";
import {Helmet} from "react-helmet";
import {isEmpty} from 'lodash'

const DEFAULT_FORM = {
  req_key: "",
  veh_reg_format: "WP ABC-1234",
  veh_registration_no: "WP-ABC-1234",
  veh_reg_format_1: "",
  veh_reg_format_2: "",
  veh_reg_format_3: "",
  engine_no: "",
  chassis_no: "",
  financial_company: "",
  financial_company_branch: "",
  lease_exp_date: "",
  conf_bank_name: "ONLINE",
  conf_bank_branch: "ONLINE",
  conf_bank_user: "ONLINE",
  pdf_status: "1",
  is_registered:"",
  is_leasing:''

};

const reg_format_validation = {
  "WP ABC-1234": {
    veh_reg_format_1: { presence: true,     format: { pattern: "[a-zA-Z0-9]{2,2}", flags: "i", message: "not valid" }, },
    veh_reg_format_2: { presence: true,     format: { pattern: "[a-zA-Z0-9]{3,3}", flags: "i", message: "not valid" }, },
    veh_reg_format_3: { presence: true,     format: { pattern: "[a-zA-Z0-9]{4,4}", flags: "i", message: "not valid" }, },
  },
  "WP AB-1234": {
    veh_reg_format_1: { presence: true,     format: { pattern: "[a-zA-Z0-9]{2,2}", flags: "i", message: "not valid" }, },
    veh_reg_format_2: { presence: true,     format: { pattern: "[a-zA-Z0-9]{2,2}", flags: "i", message: "not valid" }, },
    veh_reg_format_3: { presence: true,     format: { pattern: "[a-zA-Z0-9]{4,4}", flags: "i", message: "not valid" }, },
  },
  "302-1234": {
    veh_reg_format_1: { },
    veh_reg_format_2: { presence: true,     format: { pattern: "[a-zA-Z0-9]{3,3}", flags: "i", message: "not valid" }, },
    veh_reg_format_3: { presence: true,     format: { pattern: "[a-zA-Z0-9]{4,4}", flags: "i", message: "not valid" }, },
  },
  "10-SRI-1234": {
    veh_reg_format_1: { },
    veh_reg_format_2: { presence: true,     format: { pattern: "[a-zA-Z0-9]{2,2}", flags: "i", message: "not valid" }, },
    veh_reg_format_3: { presence: true,     format: { pattern: "[a-zA-Z0-9]{4,4}", flags: "i", message: "not valid" }, },
  },
}

class ProposalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      format1: true,
      format2:false,
      formData: DEFAULT_FORM,
      error: {},
      validations: {
        veh_registration_no: { presence: true },
        engine_no: { presence: true,
          format: {
            pattern: /^.{3,}$/,
            message: "enter minimum 3 characters"
          },
        },
        chassis_no: { presence: true, 
          format: {
            pattern: /^.{3,}$/,
            message: "enter minimum 3 characters"
          },
        },
        ...reg_format_validation[this.props.motor.proposalDetails.veh_reg_format ? DEFAULT_FORM.veh_reg_format : this.props.motor.proposalDetails.veh_reg_format]
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.resetForm = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  resetForm(e) {
    e.preventDefault();
    this.setState({ formData: DEFAULT_FORM, error: {} });
  }

  async handleChange(key, value) {
    
    await this.setState({ [key]: value });
    if (value == "302-1234" || value == "10-SRI-1234") {
      this.setState({ format1: false });
    } else {
      this.setState({ format1: true });
    }
    if(value == "10-SRI-1234"){
      this.setState({ format2: true});
    } else{
      this.setState({ format2: false});
    }

    await this.setState({
      validations: {
        veh_registration_no: { presence: true },
        engine_no: { presence: true,
          format: {
            pattern: /^.{3,}$/,
            message: "enter minimum 3 characters"
          },
        },
        chassis_no: { presence: true, 
          format: {
            pattern: /^.{3,}$/,
            message: "enter minimum 3 characters"
          },
        },
        ...reg_format_validation[value]
      }  
    });
  }

  async componentDidMount() {
    await this.props.getAuthToken();
    await this.props.getCompanyList();
    await this.props.loadProposal(this.props.match.params.req_key);

    if (!isEmpty(this.props.motor.proposalDetails.cover_type) && this.props.motor.proposalDetails.cover_type === 'T') {
      if (this.props.motor.proposalDetails.veh_reg_format == '302-1234' || this.props.motor.proposalDetails.veh_reg_format == '10-SRI-1234') {
        this.setState({ format1: false });
      } else {
        this.setState({ format1: true });
      }
      if (this.props.motor.proposalDetails.veh_reg_format == '10-SRI-1234') {
        this.setState({ format2: true });
      } else {
        this.setState({ format2: false });
      }
    }
    this.setState({
      is_leasing: this.props.motor.proposalDetails.is_leasing,
      is_registered: this.props.motor.proposalDetails.is_registered,
      formData:{
        ...DEFAULT_FORM,
        is_leasing: this.props.motor.proposalDetails.is_leasing,
        is_registered: this.props.motor.proposalDetails.is_registered,
        veh_reg_format_1: this.props.motor.proposalDetails.is_registered ? '' : 'WP',
        veh_reg_format_2: this.props.motor.proposalDetails.is_registered ? '' : 'ABC',
        veh_reg_format_3: this.props.motor.proposalDetails.is_registered ? '' : '1234', 
      }
    });
  }

  validate() {
    const error = validateWithValidations(this.state.formData, this.state.validations);
    this.setState({ error });
    return error;
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.props.clearErrors();
    const error = this.validate();

    if (isEmptyObjectValue(error)) {
      if (this.props.motor.errorMessage || !isEmptyObjectValue(this.props.motor.field_errors)) {
        window.scrollTo(0, 0);
        return null;
      }
      let vehicleData = this.state.formData;
      const req_key = this.props.motor.proposalDetails.req_key;
      if (vehicleData.req_key == '') {
        if (vehicleData.veh_reg_format === 'WP ABC-1234' || vehicleData.veh_reg_format === 'WP AB-1234') {
          vehicleData.veh_registration_no = vehicleData.veh_reg_format_1 + "-" + vehicleData.veh_reg_format_2 + "-" + vehicleData.veh_reg_format_3;
        } else if (vehicleData.veh_reg_format === '10-SRI-1234') {
          vehicleData.veh_registration_no = vehicleData.veh_reg_format_2 + "-" + "SRI" + "-" + vehicleData.veh_reg_format_3;
        } else if (vehicleData.veh_reg_format === '302-1234') {
          vehicleData.veh_registration_no = vehicleData.veh_reg_format_2 + "-" + vehicleData.veh_reg_format_3;
        }
        vehicleData.req_key = req_key;
      }
      const customerId = this.props.motor.proposalDetails.customer_id;
      const subject = 'InsureMe.lk | COVER NOTE - Motor Insurance : '+this.state.formData.veh_registration_no;
      const queryParams = getQueryParams(this.props.location.search);
      const cover = await this.props.coverNoteGerate({
        ...vehicleData
      });

      if (!this.props.motor.errorMessage){
        const linkCovernote = this.props.motor.coverDetails.url;
        const pdfUrl = this.props.motor.coverDetails.cn_url;
        const customerEmail = this.props.motor.coverDetails.email;
        const customerMobile = this.props.motor.coverDetails.mobile_number;
        await this.props.sendCoverNoteEmail({
          notification:'MOT_CVN_SEND',
          app_code:"WEB",
          app_user: "WEB",
          email:`${customerEmail}`,
          cover_note_link:`${pdfUrl}`,
          mobile:`${customerMobile}`,
          notify_data:{
            attachements:{
              cn_url:`${pdfUrl}`
            },
            subject:`${subject}`,
            params:{
  
            }
          },
        });
        // await this.props.history.push(`/payment/success/hash_code?title=completed&status=success&buttonText=Download Cover Note&dynamicLink=${linkCovernote}/${pdfUrl}`);
        window.location.href = `/payment/hash_code?title=completed&status=success&buttonText=Download Cover Note&dynamicLink=${pdfUrl}`;
      }    
    }
  }
  
  onChange(key, e) {
    const formData = this.state.formData;
    const error = this.state.error;
    delete error[key];
    if (e.target) {
      formData[key] = e.target.value;
    } else {
      formData[key] = e;
    }
    formData["req_key"] = this.props.motor.proposalDetails.req_key;

    if(key === 'veh_reg_format'){
      const customValidations = reg_format_validation[e];
      this.setState({
        ...this.state.validations,
        ...customValidations,
      })
    }
    if (
      key == "veh_reg_format_1" ||
      key == "veh_reg_format_2" ||
      key == "veh_reg_format_3"
    ) {
      formData["veh_registration_no"] =
        formData.veh_reg_format_1 +
        "-" +
        formData.veh_reg_format_2 +
        "-" +
        formData.veh_reg_format_3;
    }
    if(this.state.veh_reg_format=="10-SRI-1234"){
      formData["veh_registration_no"] =
        formData.veh_reg_format_2 +
        "-" +
        "SRI" +
        "-" +
        formData.veh_reg_format_3;
    }
    if(this.state.veh_reg_format=="302-1234"){
      formData["veh_registration_no"] =
        formData.veh_reg_format_2 +
        "-" +
        formData.veh_reg_format_3;
    }
    //this.props.onChangeInput({ [type]: e.target.value });
    this.setState({ formData, error });
    this.props.clearErrors();
  }

  render() {
    const premiumParams = this.props.premium.premiumParams;
    const motorParams = this.props.motor.proposalDetails;
    const { formData, is_leasing, is_registered, format1, format2, validations } = this.state;
    if (!isEmpty(motorParams.cover_type) && motorParams.cover_type === 'T') {
      formData.engine_no = motorParams.engine_no;
      formData.chassis_no = motorParams.chassis_no;
      formData.veh_reg_format = motorParams.veh_reg_format;
      formData.veh_reg_format_1 = motorParams.veh_reg_format_1;
      formData.veh_reg_format_2 = motorParams.veh_reg_format_2;
      formData.veh_reg_format_3 = motorParams.veh_reg_format_3;
    }

    return (
      <div className="row">
        <Helmet>
          <meta name="author" content="InsureMe Insurance Brokers (Pvt) Ltd" />      
          <meta name="robots" content="index, follow" />
          <meta name="revisit-after" content="1 month" />
          <title>Proposal Form | InsureMe.lk | Sri Lanka's First Insurance Comparison Website</title>
        </Helmet>
        {/* <pre>{JSON.stringify(this.state, "", 2)}</pre> */}
        <Spinner spinning={this.props.motor.loading} />
        <div className="container insureme-form">
        <Alert
          type="danger"
          message={
            this.props.auth.errorMessage ||
            this.props.premium.errorMessage ||
            this.props.motor.errorMessage ||
            this.props.user.errorMessage
          }
          onClick={() => this.props.clearErrors()}
        />
          <div className="col-md-12 col-sm-12 col-xs-12 ">
            <div className="col-md-12 col-sm-12">
              <HeaderTitle
                mainFirstWord="Vehicle"
                restOfTitle="Registration"
                headerIcon={true}
                headerIconStyle={{ marginLeft: "-40px" }}
              />
            </div>
            {is_registered ? (
              <div className="col-md-12 col-sm-12 col-xs-12 visible-lg visible-md visible-sm">
                <ButtonGroup
                  label="Vehicle Registration Format"
                  selected={formData.veh_reg_format}
                  options={[
                    { label: "WP ABC-1234", value: "WP ABC-1234" },
                    { label: "WP AB-1234", value: "WP AB-1234" },
                    { label: "302-1234", value: "302-1234" },
                    { label: "10-SRI-1234", value: "10-SRI-1234" }
                  ]}
                  value={formData.veh_reg_format}
                  id="veh_reg_format"
                  validation={validations.veh_reg_format}
                  onChange={this.handleChange}
                />
              </div>
            ) : null}
            {is_registered ? (
              <div className="col-md-12 col-sm-12 col-xs-12 visible-xs">
                <ButtonGroup
                  label="Vehicle Registration Format"
                  selected="WP ABC-1234"
                  options={[
                    { label: "WP ABC-1234", value: "WP ABC-1234" },
                    { label: "WP AB-1234", value: "WP AB-1234" },
                    { label: "302-1234", value: "302-1234" },
                    { label: "10-SRI-1234", value: "10-SRI-1234" }
                  ]}
                  value={formData.veh_registration_no}
                  id="veh_registration_no"
                  validation={validations.veh_registration_no}
                  onChange={this.handleChange}
                />
              </div>
            ) : null}
            {is_registered ? (
              <div className="col-md-12 col-sm-12 col-xs-12   input-text">
                <label className="nuclei-input-label">
                  Vehicle Registration Number
                </label>
                {format1 ? (
                  <div className="col-md-2 col-sm-3 col-xs-3 zero-padding-left">
                    <AutoCompleteInput
                        type="text"
                        className="form-control"
                        placeholder={"WP"}
                        options={[
                            { label: 'WP', value: 'WP' },
                            { label: 'CP', value: 'CP' },
                            { label: 'SP', value: 'SP' },
                            { label: 'UP', value: 'UP' },
                            { label: 'SG', value: 'SG' },
                            { label: 'NP', value: 'NP' },
                            { label: 'NC', value: 'NC' },
                            { label: 'UP', value: 'UP' },
                            { label: 'EP', value: 'EP' },
                            { label: 'NW', value: 'NW' }
                        ]}
                        value={formData.veh_reg_format_1}
                        onChange={e => this.onChange("veh_reg_format_1", e.value)}
                        errorText={this.state.error.veh_reg_format_1}
                        id="veh_reg_format_1"
                        // disabled = {this.state.veh_reg_format=="10-SRI-1234" ? true :false}
                    />
                  </div> 
                ) : null}
                <div className="col-md-2 col-sm-3 col-xs-3 zero-padding-left">
                  <InputText
                    value={formData.vehicle_number_2}
                    placeholder={this.state.veh_reg_format=='302-1234'?("302"):(this.state.veh_reg_format=="10-SRI-1234")?("10"):(this.state.veh_reg_format=="WP AB-1234")?("AB"):("ABC")}
                    type={"text"}
                    id="veh_reg_format_2"
                    value={formData.veh_reg_format_2}
                    validation={validations.veh_reg_format_2}
                    onChange={e => this.onChange("veh_reg_format_2", e)}
                    errorText={this.state.error.veh_reg_format_2}
                  />
                </div>
                {format2 ? (
                <div className="col-md-2 col-sm-3 col-xs-3 zero-padding-left">
                  <InputText
                    placeholder="SRI"
                    disabled={true}
                  />
                </div>
                ) : null}
                <div className="col-md-2 col-sm-3 col-xs-3 zero-padding-left">
                  <InputText
                    tooltip="Enter your vihicle number according to the selected vehicle number format"
                    value={formData.vehicle_number_3}
                    placeholder="1234"
                    type={"number"}
                    id="veh_reg_format_3"
                    value={formData.veh_reg_format_3}
                    validation={validations.veh_reg_format_3}
                    onChange={e => this.onChange("veh_reg_format_3", e)}
                    errorText={this.state.error.veh_reg_format_3}
                  />
                </div>
              </div>
            ) : null}
            <div className="col-md-12 col-sm-12 col-xs-12 input-text">
              <label className="nuclei-input-label">Engine Number</label>
              <div className="col-md-6 col-sm-9 col-xs-9 zero-padding-left">
                <InputText
                  value={formData.engine_number}
                  tooltip="Engine No"
                  placeholder="Engine Number"
                  value={formData.engine_no}
                  id="engine_no"
                  validation={validations.engine_no}
                  onChange={e => this.onChange("engine_no", e)}
                  errorText={this.state.error.engine_no}
                />
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-xs-12   input-text">
              <label className="nuclei-input-label">Chassis Number</label>
              <div className="col-md-6 col-sm-9 col-xs-9 zero-padding-left">
                <InputText
                  tooltip="Chassis No."
                  value={formData.chassis_number}
                  placeholder="Chassis No."
                  value={formData.chassis_no}
                  id="chassis_no"
                  validation={validations.chassis_no}
                  onChange={e => this.onChange("chassis_no", e)}
                  errorText={this.state.error.chassis_no}
                />
              </div>
            </div>
            {is_leasing ? (
              <div className="col-md-12 col-sm-12 col-xs-12   input-text">
                <label className="nuclei-input-label">
                  Leasing Company Name
                </label>
                <div className="col-md-6 col-sm-9 col-xs-9 zero-padding-left">
                  <AutoCompleteInput
                    type="text"
                    className="form-control"
                    tooltip="Leasing Company"
                    placeholder="Leasing Company"
                    value={formData.financial_company}
                    options={this.props.motor.comList.map(item => {
                      return {
                        label: item.company_name,
                        value: item.company_name
                      };
                    })}
                    id="financial_company"
                    validation={validations.financial_company}
                    onChange={e => this.onChange("financial_company", e.value)}
                    errorText={this.state.error.financial_company}
                  />
                </div>
              </div>
            ) : null}
            {is_leasing ? (
              <div className="col-md-12 col-sm-12 col-xs-12   input-text">
                <label className="nuclei-input-label">
                  Leasing Company Branch
                </label>
                <div className="col-md-6 col-sm-9 col-xs-9 zero-padding-left">
                  <InputText
                    placeholder="Leasing Company Branch"
                    tooltip="Leasing Company Branch"
                    id="financial_company_branch"
                    value={formData.financial_company_branch}
                    validation={validations.financial_company_branch}
                    onChange={e => this.onChange("financial_company_branch", e)}
                    errorText={this.state.error.financial_company_branch}
                  />
                </div>
              </div>
            ) : null}
            {is_leasing ? (
              <div className="col-md-12 col-sm-12 col-xs-12   input-text">
                <label className="nuclei-input-label">Lease Expiry</label>
                {/* <MonthYear
                  id="lease_exp_date"
                  validation={validations.lease_exp_date}
                  onChange={e => this.onChange("lease_exp_date", e)}
                /> */}
                <MonthYear
                  id="lease_exp_date"
                  onChange={e => this.onChange("lease_exp_date", e)}
                  
                  minDate={new Date()}
                  maxDate={new Date(new Date().getFullYear()+10+"-10-10")}
                />
              </div>
            ) : null}
            {/* <div className="col-md-12 col-sm-12 col-xs-12   input-text">
        <label className="nuclei-input-label">Vehicle Images</label>
            <div className="col-md-2 col-sm-2 col-xs-6 zero-padding-left">
              <ImageUpload name="Front" />
            </div>
            <div className="col-md-2 col-sm-2 col-xs-6 zero-padding-left">
              <ImageUpload name="Rear" />
            </div>
            <div className="col-md-2 col-sm-2 col-xs-6 zero-padding-left">
              <ImageUpload name="Left" />
            </div>
            <div className="col-md-2 col-sm-2 col-xs-6 zero-padding-left">
              <ImageUpload name="Right" />
            </div>
          </div>

          <div className="col-md-12 col-sm-12 col-xs-12   input-text">
        <label className="nuclei-input-label">Documents</label>
            <div className="col-md-2 col-sm-2 col-xs-6 zero-padding-left">
              <DocumentUpload name="Document" />
            </div>
            <div className="col-md-2 col-sm-2 col-xs-6 ">
              <DocumentUpload name="More" more={true} />
            </div>
          </div> */}

            {/* <div className="col-md-12 col-sm-12 col-xs-12 " id="privacy-policy">
              <span className="privacy-span">
                By Clicking on Proceed to Payment, You agree to the general
                <br />
                <a href="#">Terms & Conditions</a> and{" "}
                <a href="#">Privacy Policy</a>
              </span>
            </div> */}

            <div className="col-md-12 col-sm-12 zero-padding-left">
              <div className="col-md-8 col-sm-8 col-xs-12">
                <Button
                  type="radius"
                  color="red"
                  className="nuclei-bottom-red-button"
                  onClick={e => this.handleSubmit(e)}
                  // onClick={this.onSubmit}
                >
                  Save Details
                </Button>
                {/* <Button
                  type="radius"
                  color="gray"
                  className="nuclei-bottom-gray-button"
                  onClick={(e) => this.resetForm(e)}
                >
                  Clear
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
    motor: state.motor,
    premium: state.premium
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postInquiry,
      getAuthToken,
      fetchPremiums,
      onChangeInput,
      clearErrors,
      getCompanyList,
      coverNoteGerate,
      sendCoverNoteEmail,
      consumerLookupCustomerId,
      loadProposal
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalForm);
