import React, {Component} from "react";
import "./Spinner.scss";

export class Spinner extends Component {
  render() {
    if (this.props.spinning === false && this.props.children) {
      return this.props.children;
    }
    if (this.props.spinning === false && !this.props.children) {
      return null;
    }
    return (
      <div style={this.props.style} className={`spinner ${this.props.size}`}>
        <div className="dot1" />
        <div className="dot2" />
        <div className="dot3" />
      </div>
    );
  }
}

export default Spinner;
