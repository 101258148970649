import React, {Component} from "react";

class Dropzone extends Component {
    constructor(props) {
        super(props);
        this.state = {hightlight: false};
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    openFileDialog() {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    onFilesAdded(event) {
        if (this.props.disabled) return;
        const files = event.target.files;
        if (files.length > 0 && this.props.onFilesAdded) {
            this.props.onFilesAdded(files[0]);
        }
    }

    onDragOver(event) {
        event.preventDefault();
        if (this.props.disabed) return;
        this.setState({hightlight: true});
    }

    onDragLeave(event) {
        this.setState({hightlight: false});
    }

    onDrop(event) {
        event.preventDefault();
        if (this.props.disabed) return;
        const files = event.dataTransfer.files;

        if (files.length > 0 && this.props.onFilesAdded) {
            this.props.onFilesAdded(files[0]);
        }
        this.setState({hightlight: false});
    }

    render() {
        return (
            <div
                className={` ${this.state.hightlight ? "Highlight" : ""}`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onClick={this.openFileDialog}
                style={{cursor: this.props.disabled ? "default" : "pointer"}}>
                <input
                    accept={this.props.allowedFileTypes ? this.props.allowedFileTypes : '*'}
                    ref={this.fileInputRef}
                    className="FileInput"
                    type="file"
                    onChange={this.onFilesAdded}
                />
                <img
                    alt="upload"
                    className="Icon"
                    src={this.props.icon ? this.props.icon : 'https://inme-assets.s3-ap-southeast-1.amazonaws.com/web/icons/baseline-cloud_upload-24px.svg'}/>
                <p className={'upload-title'}>{this.props.title!==null ? this.props.title : 'Drop File'}</p>

            </div>
        );
    }
}

export default Dropzone;
