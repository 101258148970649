import React, {Component} from "react";
import "./BlogImageList.scss";

import BlogImage from "./BlogImage";
export default class BlogImageList extends Component {
  render() {
    return (
      <div className="row nuclei-blog-images">
        {this.props.blogImages.map((blogImage, i) => {
          if (i > 1) {
            var hiddenItem = "hidden-xs";
          } else {
            hiddenItem = "";
          }
          var grid = "col-md-4 col-sm-4 col-xs-12";
          if (this.props.twoImg) {
            grid = "col-md-6 col-sm-6 col-xs-6";
          }
          return (
            <div
              key={i}
              className={`${grid} nuclei-blog-image-list ${hiddenItem}`}
            >
              <BlogImage
                imgUrl={blogImage.imgUrl}
                imgLink={blogImage.imgLink}
                className="nuclei-blog-image-list-img"
                type="radius"
              />
            </div>
          );
        })}
      </div>
    );
  }
}
