import React, {Component} from "react";
import "./index.scss";
import Table from "../../components/Table";


const dataSample = [

    {
        id: '1',
        payment_ref: 'P20190919282263',
        exp_data: '2019-1-1',
        discount: 'Rs 10,000.00',
        payment_media: 'Cash',
        pay_amount: 'Rs 10,000.00',
        paid_date: '2019-1-1',
        policy_ref: 'qewsfdgnbt4567',
    },{
        id: '2',
        payment_ref: 'P20190919282263',
        exp_data: '2019-1-1',
        discount: 'Rs 10,000.00',
        payment_media: 'Cash',
        pay_amount: 'Rs 10,000.00',
        paid_date: '2019-1-1',
        policy_ref: 'qewsfdgnbt4567',
    },{
        id: '3',
        payment_ref: 'P20190919282263',
        exp_data: '2019-1-1',
        discount: 'Rs 10,000.00',
        payment_media: 'Cash',
        pay_amount: 'Rs 10,000.00',
        paid_date: '2019-1-1',
        policy_ref: 'qewsfdgnbt4567',
    },{
        id: '4',
        payment_ref: 'P20190919282263',
        exp_data: '2019-1-1',
        discount: 'Rs 10,000.00',
        payment_media: 'Cash',
        pay_amount: 'Rs 10,000.00',
        paid_date: '2019-1-1',
        policy_ref: 'qewsfdgnbt4567',
    },{
        id: '5',
        payment_ref: 'P20190919282263',
        exp_data: '2019-1-1',
        discount: 'Rs 10,000.00',
        payment_media: 'Cash',
        pay_amount: 'Rs 10,000.00',
        paid_date: '2019-1-1',
        policy_ref: 'qewsfdgnbt4567',
    },{
        id: '6',
        payment_ref: 'P20190919282263',
        exp_data: '2019-1-1',
        discount: 'Rs 10,000.00',
        payment_media: 'Cash',
        pay_amount: 'Rs 10,000.00',
        paid_date: '2019-1-1',
        policy_ref: 'qewsfdgnbt4567',
    },{
        id: '7',
        payment_ref: 'P20190919282263',
        exp_data: '2019-1-1',
        discount: 'Rs 10,000.00',
        payment_media: 'Cash',
        pay_amount: 'Rs 10,000.00',
        paid_date: '2019-1-1',
        policy_ref: 'qewsfdgnbt4567',
    },{
        id: '8',
        payment_ref: 'P20190919282263',
        exp_data: '2019-1-1',
        discount: 'Rs 10,000.00',
        payment_media: 'Cash',
        pay_amount: 'Rs 10,000.00',
        paid_date: '2019-1-1',
        policy_ref: 'qewsfdgnbt4567',
    },
];
const titlesSample = {
    payment_ref: 'Pay Reference',
    exp_data: 'Expire Date',
    discount: 'Discounted Amount',
    payment_media: 'Payment Method',
    pay_amount: 'Pay Amount',
    paid_date: 'Paid Date',
    policy_ref: 'Policy Reference',
};
export default class PDMI extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            visibleColumns: []
        }
    }

    renderExpandedView(row,titles) {
        return (
            <div style={{textAlign:'left'}}>
                <div className={'row'}>
                    <div className={'col-md-2'}>
                        <p>Pay Reference</p>
                    </div>
                    <div className={'col-md-2'}>
                        <p>{row.payment_ref}</p>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-2'}>
                        <p>Expire Date</p>
                    </div>
                    <div className={'col-md-2'}>
                        <p>{row.exp_data}</p>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-2'}>
                        <p>Discounted Amount</p>
                    </div>
                    <div className={'col-md-2'}>
                        <p>{row.discount}</p>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-2'}>
                        <p>Payment Method</p>
                    </div>
                    <div className={'col-md-2'}>
                        <p>{row.payment_media}</p>
                    </div>
                </div>

            </div>
        );
    }

    render() {
        return (
            <div className={'policies-container'}>
                <Table
                    accordian={true}
                    data={dataSample}
                    titles={titlesSample}
                    renderExpandedView={(row,titles)=>this.renderExpandedView(row,titles)}/>
            </div>
        );
    }
}
