export const AUTH_API = process.env.REACT_APP_AUTH_API
  ? process.env.REACT_APP_AUTH_API
  : "https://dev-auth-api.inme.lk";
export const SYS_API = process.env.REACT_APP_SYS_API
  ? process.env.REACT_APP_SYS_API
  : "https://dev-com-sys.inme.lk";
export const MOTOR_API = process.env.REACT_APP_MOTOR_API
  ? process.env.REACT_APP_MOTOR_API
  : "https://dev-motor.inme.inme.lk";
export const IMAGE_ROOT = process.env.REACT_APP_IMAGE_ROOT
  ? process.env.REACT_APP_IMAGE_ROOT
  : "https://app.insureme.lk";
export const PAYMENT_API = process.env.REACT_APP_PAYMENT_API
  ? process.env.REACT_APP_PAYMENT_API
  : "https://dev-payment.inme.lk";
export const LEAD_AUTH_API = process.env.REACT_APP_LEAD_AUTH_API
  ? process.env.REACT_APP_LEAD_AUTH_API
  : "https://dev-leads.inme.lk";
export const INME_HOME_API = process.env.REACT_APP_INME_HOME_API
  ? process.env.REACT_APP_INME_HOME_API
  : "https://web-api.insureme.lk";
export const RETAIL_API = process.env.REACT_APP_RETAIL_API
  ? process.env.REACT_APP_RETAIL_API
  : "https://retailapi.r2d2.insureme.lk";
export const ACCOUNT_API = process.env.REACT_APP_ACCOUNT_API
    ? process.env.REACT_APP_ACCOUNT_API
    : "https://dev-com-sys.inme.lk/inme-account-service";


export const WEB_IMAGE = process.env.REACT_APP_IMAGE
  ? process.env.REACT_APP_IMAGE
  : "https://insureme-prod.s3-ap-southeast-1.amazonaws.com";

export const INME_AUTH = process.env.REACT_APP_INME_AUTH
    ? process.env.REACT_APP_INME_AUTH
    : "https://dev-auth.inme.lk";

export const ASSETS_URL = `${IMAGE_ROOT  }/`;
//------------------- authentication
export const AUTH_USER_NAME = process.env.REACT_APP_AUTH_USER_NAME
  ? process.env.REACT_APP_AUTH_USER_NAME
  : "002MOTORMAP";
export const AUTH_PWD = process.env.REACT_APP_AUTH_PWD
  ? process.env.REACT_APP_AUTH_PWD
  : "002MOTORMAP@20:20";
export const API_KEY = process.env.REACT_APP_API_KEY
  ? process.env.REACT_APP_API_KEY
  : "25544zzz6MEA8LZpJ4L332JF3E23T22D";

export const AUTH_KEY_COOKIE = process.env.REACT_APP_AUTH_KEY_COOKIE
  ? process.env.REACT_APP_AUTH_KEY_COOKIE
  : "insureme.lk.auth_key";
export const INQUIRY_COOKIE = process.env.REACT_APP_INQUIRY_COOKIE
  ? process.env.REACT_APP_INQUIRY_COOKIE
  : "insureme.lk.cx_inquiry_id";
export const HIDE_XS_WIDTH = process.env.REACT_APP_HIDE_XS_WIDTH
  ? process.env.REACT_APP_HIDE_XS_WIDTH
  : 768;

export const LEAD_AUTH_USERNAME = process.env.REACT_APP_LEAD_AUTH_USERNAME
  ? process.env.REACT_APP_LEAD_AUTH_USERNAME
  : "admin";
export const LEAD_ACCESS_KEY = process.env.REACT_APP_LEAD_ACCESS_KEY
  ? process.env.REACT_APP_LEAD_ACCESS_KEY
  : "uSRqpFVQlpokeuUg"; //live environment access key
export const LEAD_TOKEN_COOKIE = process.env.REACT_APP_LEAD_TOKEN_COOKIE
  ? process.env.REACT_APP_LEAD_TOKEN_COOKIE
  : "insureme.lk.leadToken";
export const LEAD_SESSION_NAME = process.env.REACT_APP_LEAD_SESSION_NAME
  ? process.env.REACT_APP_LEAD_SESSION_NAME
  : "vtiger.sessionname";
export const LEAD_ASSIGNED_USER_ID = process.env.REACT_APP_LEAD_ASSIGNED_USER_ID
  ? process.env.REACT_APP_LEAD_ASSIGNED_USER_ID
  : "vtigerloginuserid";

export const PROMO_FRIMI_STYLE_TOKEN = process.env.REACT_APP_FRIMI_ID_TOKEN
    ? process.env.REACT_APP_FRIMI_ID_TOKEN
    : ".inme.lk.frimiToken";


export const TITLES = [
  {label: "Mr", value: "Mr"},
  {label: "Mrs", value: "Mrs"},
  {label: "Miss", value: "Miss"},
  {label: "Mstr", value: "Mstr"},
  {label: "Dr", value: "Dr"},
  {label: "Dr.Mrs", value: "Dr.Mrs"},
  {label: "Ven.", value: "Ven."},
  {label: "Rev.", value: "Rev."}
];

export const MI_INME_CHANNEL= process.env.MI_CHANNEL ? process.env.MI_CHANNEL : "INME";
export const ASSET_MANAGER_API_KEY= '9ba81f0173149699b1694e901a99a2f3dbc3727431bea203996d93a176adffaa80-9b7b-4bf7-9a1a-89a999e008ad' ;
export const INME_MPTOR_API= 'https://dev-motor.inme.inme.lk/index.php' ;
// export const INME_MPTOR_API= 'https://motor-core.insureme.lk' ;
export const LOADING_COMMON_ERROR_MESSAGE= 'Something went wrong!!!' ;

export const FRIMI_LIFESTYLE_CX_INFO_GET_URL = process.env.URL_FRIMI_LIFESTYLE_CUSTOMER_INFO ?
    process.env.URL_FRIMI_LIFESTYLE_CUSTOMER_INFO : 'https://dev-payment.inme.lk/frimi-merchant-app/api/merchant/firimi/v1/lifestyle/getUser';

export const CHANNEL_FRIMI = process.env.CHANNEL_FRIMI ? process.env.CHANNEL_FRIMI : "FriMi";
export const PROMO_CODE_FRIMI = process.env.PROMO_CODE_FRIMI ? process.env.PROMO_CODE_FRIMI : "FriMiLifeStyle";
