import React, {Component} from "react";
import "./PaymentBlockList.scss";
import PaymentBlock from "./PaymentBlock";

export default class PaymentBlockList extends Component {
  render() {
    return this.props.paymentBlock.map((paymentBlock, i) => {
      return (
        <PaymentBlock
          key={i}
          imgOneUrl={paymentBlock.imgOneUrl}
          imgTwoUrl={paymentBlock.imgTwoUrl}
          cardName={paymentBlock.cardName}
          discount={paymentBlock.discount}
          bankName={paymentBlock.bankName}
        />
      );
    });
  }
}
