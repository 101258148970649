import React, {Component} from "react";
import "./Table.scss";


export default class Table extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            visibleColumns: []
        }
    }

    renderICon(id) {
        let visibleColumns = this.state.visibleColumns;

        if (visibleColumns.indexOf(id) > -1) return (
            <svg className="bi bi-caret-down-fill" width="1em" height="1em"
                 viewBox="0 0 16 16"
                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z"/>
            </svg>
        );
        else {
            return (
                <svg className="bi bi-caret-right-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 011.659-.753l5.48 4.796a1 1 0 010 1.506z"/>
                </svg>
            );
        }
    }

    expandClick(id) {
        let visibleColumns = this.state.visibleColumns,
            index = visibleColumns.indexOf(id);
        if (index > -1) {
            visibleColumns.splice(index, 1)
        } else {
            visibleColumns.push(id);
        }
        this.setState({
            visibleColumns: visibleColumns
        })
    }

    renderHeader(titles) {
        let headers = [];

        for (let i in titles) {
            headers.push(<th scope="col" key={i}>{titles[i]}</th>);
        }
        return headers;
    }

    renderRows(data, titles) {
        let rows = [],
            counter = 1;
        for (let i in data) {
            let columns = [],
                row = data[i];
            let visibleColumns = this.state.visibleColumns,
                isActive = this.props.accordian !== undefined && this.props.accordian === true && visibleColumns.indexOf(row.id) > -1 ;
            for (let j in titles) {
                columns.push(<td key={i + j}>{row[j]}</td>)
            }


            rows.push(
                <tr onClick={() => this.expandClick(row.id)}>
                    <td>
                        {this.props.accordian !== undefined && this.props.accordian === true ? this.renderICon(row.id):counter}
                    </td>
                    {columns}
                </tr>);
            if (isActive) {
                rows.push(this.renderExpandedView(row, titles));
            }
            counter++;
        }
        return rows;
    }

    renderExpandedView(row, titles) {
        if (this.props.renderExpandedView === undefined) {
            return null;
        }
        return (
            <tr className={'expanded-view'}>
                <td></td>
                <td colSpan={'6'}>
                    {this.props.renderExpandedView(row, titles)}
                </td>
            </tr>
        );
    }

    render() {
        return (
            <table className="table table-hover">
                <thead>
                <tr>
                    <th className={'fit'} scope="col">{this.props.accordian ? '' : '#'}</th>
                    {this.renderHeader(this.props.titles)}
                </tr>
                </thead>
                <tbody>
                {this.renderRows(this.props.data, this.props.titles)}
                </tbody>
            </table>
        );
    }
}
