import axios from "axios";
import _ from "lodash";
import {
  getCommonError,
  getInquiryCookie,
  hasAuthError,
  hasFieldErrors,
  keyOBjects2Array
} from "../../util";
import {MOTOR_API, TITLES} from "../../config";
import lodashPick from "lodash/pick";
import {addQuery} from "../../history";
import {replaceLocal} from "./local";

const GET_BASIC_COVERS = "GET_BASIC_COVERS";
const FETCH_PREMIUMS = "FETCH_PREMIUMS";
const FETCH_PREMIUMS_DONE = "FETCH_PREMIUMS_DONE";
const FETCH_PREMIUMS_FAILED = "FETCH_PREMIUMS_FAILED";

const FETCH_PREMIUMS_BY_HASH = "FETCH_PREMIUMS_BY_HASH";
const FETCH_PREMIUMS_BY_HASH_DONE = "FETCH_PREMIUMS_BY_HASH_DONE";
const FETCH_PREMIUMS_BY_HASH_FAILED = "FETCH_PREMIUMS_BY_HASH_FAILED";

const GET_COVER_COMPARISON = "GET_COVER_COMPARISON";
const GET_COVER_COMPARISON_DONE = "GET_COVER_COMPARISON_DONE";
const GET_COVER_COMPARISON_FAILED = "GET_COVER_COMPARISON_FAILED";

const GET_PREMIUMS_BY_HASH = "GET_PREMIUMS_BY_HASH";
const GET_PREMIUMS_BY_HASH_DONE = "GET_PREMIUMS_BY_HASH_DONE";
const GET_PREMIUMS_BY_HASH_FAILED = "GET_PREMIUMS_BY_HASH_FAILED";

const CHANGE_PREMIUM_PARAM = "CHANGE_PREMIUM_PARAM";

const FETCH_PREMIUMS_FAILED_FIELD = "FETCH_PREMIUMS_FAILED_FIELD";
const FETCH_THIRD_PARTY_RESTRICTED = "FETCH_THIRD_PARTY_RESTRICTED";
const ADD_TO_CHART = "ADD_TO_CHART";
const CLEAR_ERRORS = "CLEAR_ERRORS";

const INIT_STATE = {
  loading: false,
  loadingCovers: false,
  errorMessage: null,
  basic_covers: [],
  premiums: [],
  cartItems: [],
  companies: [],
  field_errors: {},
  unq_hash: null,
  premiumParams: {
    purpose_of_use: "Private",
    is_registered: "R",
    hire_purchase: "YES"
  }
};

export default function reducer(state = INIT_STATE, action) {
  const {type, payload} = action;

  switch (type) {
    case CLEAR_ERRORS:
      return {...state, errorMessage: false};
    case FETCH_PREMIUMS:
      return {...state, loading: true, errorMessage: null};
    case FETCH_PREMIUMS_DONE:
      return {...state, loading: false, unq_hash: payload};
    case FETCH_PREMIUMS_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: payload,
        cartItems: [],
        premiums: []
      };

    case FETCH_PREMIUMS_BY_HASH:
      return {...state, loading: true, errorMessage: null};
    case FETCH_PREMIUMS_BY_HASH_DONE:
      return {...state, loading: false, premiums: payload};
    case FETCH_PREMIUMS_BY_HASH_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: payload,
        cartItems: [],
        premiums: []
      };
    case ADD_TO_CHART:
      return {...state, cartItems: payload};

    case GET_PREMIUMS_BY_HASH:
      return {...state, loadingHashParams: true, unq_hash: payload};
    case GET_PREMIUMS_BY_HASH_DONE:
      return {
        ...state,
        loadingHashParams: false,
        premiumParams: payload || INIT_STATE.premiumParams
      };
    case GET_PREMIUMS_BY_HASH_FAILED:
      return {...state, loadingHashParams: false, errorMessage: payload};

    case GET_COVER_COMPARISON:
      return {...state, loadingCovers: true};
    case GET_COVER_COMPARISON_DONE:
      return {...state, loadingCovers: false, companies: payload};
    case GET_COVER_COMPARISON_FAILED:
      return {...state, loadingCovers: false, errorMessage: payload};
    case CHANGE_PREMIUM_PARAM:
      return {
        ...state,
        premiumParams: payload
      };
    case GET_BASIC_COVERS:
      return {...state, basic_covers: payload};
    case FETCH_PREMIUMS_FAILED_FIELD:
      return {...state, field_errors: payload, loading: false};

    default:
      return state;
  }
}

async function getThirdPary(local) {
  const picked = lodashPick(local, [
    "vehicle_type",
    "purpose_of_use",
    "is_registered",
    "vehicle_make",
    "vehicle_model",
    "bank_name",
    "bank_branch",
    "bank_user",
    "customer_name",
    "cx_inquiry_id",
    "user_id",
    "cx_user_id",
    "veh_registration_no",
    "chassis_no",
    "engine_no",
    "veh_reg_format",
    "veh_reg_format_1",
    "veh_reg_format_2",
    "veh_reg_format_3"
  ]);

  return await axios.post(`${MOTOR_API}/v3/quote/tp`, {
    bank_name: "ONLINE",
    bank_branch: "ONLINE",
    bank_user: "ONLINE",
    customer_name: "ONLINE",
    user_id: "",
    cx_user_id: "",
    ...picked,
    cx_inquiry_id: getInquiryCookie().cx_inquiry_id || "",
    cover_type: "T", // this is always no for Thirdparty
    hire_purchase: "NO" // this is always no for Thirdparty
  });
}
async function getFullInsurance(local) {
  const picked = lodashPick(local, [
    "sum_insured",
    "vehicle_type",
    "purpose_of_use",
    "vehicle_make",
    "vehicle_model",
    "fuel_type",
    "manufacture_year",
    "is_registered",
    "hire_purchase",
    "seating_capacity",
    "ncb",
    "bank_name",
    "bank_branch",
    "bank_user",
    "covers",
    "cover_type",
    "cx_inquiry_id",
    "user_id",
    "cx_user_id"
  ]);

  return await axios.post(`${MOTOR_API}/v2/quote/full`, {
    bank_name: "ONLINE",
    bank_branch: "ONLINE",
    bank_user: "ONLINE",
    customer_name: "ONLINE",
    seating_capacity: "0",
    covers: "",
    user_id: "",
    cx_user_id: "",
    ...picked,
    cx_inquiry_id: getInquiryCookie().cx_inquiry_id || "",
    cover_type: "C"
  });
}

export const addToChart = qot_number => async (dispatch, getState) => {
  const cartItems = getState().premium.cartItems || [];
  const index = cartItems.indexOf(qot_number);
  if (index > -1) {
    cartItems.splice(index, 1);
  } else {
    cartItems.push(qot_number);
  }
  if (cartItems.length > 3) {
    cartItems.shift();
  }
  dispatch({type: ADD_TO_CHART, payload: cartItems});
};

/**
 *
 * @param {Object} premiumParams
 * @param {boolean} noPremium true means -  Don't not fetch Premiums
 * @param {boolean} dontChangeHash true means - Don't not change the query parameter of unqHash
 */
export const fetchPremiums = (
  premiumParams,
  noPremium,
  dontChangeHash
) => async dispatch => {
  try {
    dispatch({type: FETCH_PREMIUMS});

    let res = null;
    if (premiumParams.insurance_type === "full-insurance") {
      res = await getFullInsurance(premiumParams);
    } else {
      res = await getThirdPary(premiumParams);
    }

    const fieldError = hasFieldErrors(res);
    if (fieldError) {
      return dispatch({
        type: FETCH_PREMIUMS_FAILED_FIELD,
        payload: fieldError
      });
    }
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: FETCH_PREMIUMS_FAILED, payload: error});
    }
    const unq_hash = res.data.data.unq_hash;
    if (unq_hash && !dontChangeHash) {
      addQuery({unq_hash});
    }

    if (unq_hash && !noPremium) {
      dispatch(fetchPremiumsByUniqHash(unq_hash));
    }

    return dispatch({type: FETCH_PREMIUMS_DONE, payload: unq_hash});
  } catch (error) {
    return dispatch({
      type: FETCH_PREMIUMS_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getParamsByUniqHash = unq_hash => async dispatch => {
  try {
    dispatch({type: GET_PREMIUMS_BY_HASH, payload: unq_hash});
    const res = await axios.get(
      `${MOTOR_API}/v2/quote/params?unq_hash=${unq_hash}`
    );
    dispatch({type: FETCH_PREMIUMS_DONE, payload: unq_hash});

    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_PREMIUMS_BY_HASH_FAILED, payload: error});
    }

    let insurance_type = "full-insurance";
    if (res.data.data.cover_type === "T") {
      insurance_type = "third-party";
    }
    dispatch({
      type: GET_PREMIUMS_BY_HASH_DONE,
      payload: {
        cx_name: getInquiryCookie().cx_name,
        cx_mobile: getInquiryCookie().cx_mobile,
        cx_surname: getInquiryCookie().cx_surname || TITLES[0].value,
        cx_email: getInquiryCookie().cx_email,
        insurance_type: insurance_type,
        ...res.data.data
      }
    });
  } catch (error) {
    dispatch({
      type: GET_PREMIUMS_BY_HASH_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const fetchPremiumsByUniqHash = unq_hash => async dispatch => {
  dispatch({type: FETCH_PREMIUMS_BY_HASH});
  try {
    const res = await axios.get(
      `${MOTOR_API}/v2/premiums?unq_hash=${unq_hash}`
    );
    const fieldError = hasFieldErrors(res);
    if (fieldError) {
      return dispatch({
        type: FETCH_PREMIUMS_FAILED_FIELD,
        payload: fieldError
      });
    }
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: FETCH_PREMIUMS_FAILED, payload: error});
    }

    const data = res.data.data;
    dispatch({type: ADD_TO_CHART, payload: []});
    dispatch(replaceLocal(data.params));
    delete data.params;
    const premiums = keyOBjects2Array(data);
    return dispatch({
      type: FETCH_PREMIUMS_BY_HASH_DONE,
      payload: _.sortBy(premiums, ["total_premium", "TotalPremium"])
    });
  } catch (error) {
    return dispatch({
      type: FETCH_PREMIUMS_BY_HASH_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const getCoverComparisons = (unq_hash, quotations) => async dispatch => {
  try {
    dispatch({type: GET_COVER_COMPARISON});
    const res = await axios({
      method: "post",
      url: `${MOTOR_API}/v2/covers`,
      data: {
        unq_hash,
        quotations
      }
    });
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_COVER_COMPARISON_FAILED, payload: error});
    }

    const companies = convertToCompanyArrays(res.data.data);

    const newCompanies = [];
    if (quotations[0]) {
      const found = _.find(companies, {quot_number: quotations[0]}) || {};
      newCompanies.push(found);
    }
    if (quotations[1]) {
      const found = _.find(companies, {quot_number: quotations[1]}) || {};
      newCompanies.push(found);
    }
    if (quotations[2]) {
      const found = _.find(companies, {quot_number: quotations[2]}) || {};
      newCompanies.push(found);
    }

    dispatch({
      type: GET_BASIC_COVERS,
      payload: keyOBjects2Array(res.data.data.basic_covers)
    });
    dispatch({type: FETCH_PREMIUMS_DONE, payload: unq_hash});

    return dispatch({type: GET_COVER_COMPARISON_DONE, payload: newCompanies});
  } catch (error) {
    return dispatch({
      type: GET_COVER_COMPARISON_FAILED,
      payload: getCommonError(error)
    });
  }
};

export const onChangeInput = (payload, replaceAll) => (dispatch, getState) => {
  if (replaceAll) {
    return dispatch({
      type: CHANGE_PREMIUM_PARAM,
      payload: {...INIT_STATE.premiumParams, ...payload}
    });
  }
  const {premiumParams} = getState().premium;
  return dispatch({
    type: CHANGE_PREMIUM_PARAM,
    payload: {...premiumParams, ...payload}
  });
};

export const setFieldErrors = (payload, completelyReplace) => (
  dispatch,
  getState
) => {
  if (completelyReplace) {
    return dispatch({type: FETCH_PREMIUMS_FAILED_FIELD, payload: payload});
  }
  const errors = getState().premium.field_errors || {};
  return dispatch({
    type: FETCH_PREMIUMS_FAILED_FIELD,
    payload: {...errors, ...payload}
  });
};

export const removeFieldError = key => (dispatch, getState) => {
  const errors = getState().premium.field_errors || {};
  delete errors[key];
  return dispatch({
    type: FETCH_PREMIUMS_FAILED_FIELD,
    payload: {...errors}
  });
};

function convertToCompanyArrays(response) {
  const companies = [];
  for (var key in response.company_details) {
    companies.push({
      company_name: key,
      ...response.company_details[key],
      cover_details: keyOBjects2Array(response.cover_details[key])
    });
  }
  return companies;
}
