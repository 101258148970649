import React, {Component} from "react";
import {withRouter} from "react-router-dom";

import {FeatureCard, Jumbotron, Collapse} from "../../components";
import "./SearchResultsBlog.scss";
import {Tabs, Tab} from "react-bootstrap";

const MOTOR_LIST = [
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  },
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  },
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  },
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  }
];

const GENERAL_LIST = [
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  },
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  },
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  },
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  },
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  },
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  },
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  },
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  }
];

class SearchResultsBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null
    };
  }

  async componentDidMount() {
    await this.setSearchResult();
  }

  setSearchResult() {
    if (window.location.pathname === "/SearchResultsProduct") {
      this.setState({type: 1});
    } else if (window.location.pathname === "/SearchResultsBlog") {
      this.setState({type: 2});
    } else if (window.location.pathname === "/SearchResultsFAQ") {
      this.setState({type: 3});
    }
  }

  searchResults = [
    {
      id: "SearchResultsProduct",
      title: "Frequently Asked Questions",
      subTitle: "Here are answers to some of the genaral questiosn",
      productCount: "3"
    },
    {
      id: "SearchResultsBlog",
      title: "Search Results for Hatton National Banks",
      subTitle: "We have found 15 results in 3 categories",
      BlogCount: "3"
    },
    {
      id: "SearchResultsFAQ",
      title: "Frequently Asked Questions",
      subTitle: "Here are answers to some of the genaral questiosn",
      faqCount: "5"
    }
  ];

  renderTitle(type) {
    return (
      <Jumbotron
        title={this.searchResults[type - 1].title}
        subTitle={this.searchResults[type - 1].subTitle}
      />
    );
  }

  changeSearchResult(type) {
    this.props.history.push(this.searchResults[type - 1].id);
    this.setState({type: type});
  }

  renderProduct() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">
            <FeatureCard
              shadow
              imageUrl="https://firebasestorage.googleapis.com/v0/b/health-ccc69.appspot.com/o/Screenshot%20from%202018-06-16%2001-33-05.png?alt=media&token=88cc2cf9-3109-419f-9627-c9f2033c3a18"
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              readMoreUrl="/"
            />
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <FeatureCard
              shadow
              imageUrl="https://firebasestorage.googleapis.com/v0/b/health-ccc69.appspot.com/o/Screenshot%20from%202018-06-16%2001-33-05.png?alt=media&token=88cc2cf9-3109-419f-9627-c9f2033c3a18"
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              readMoreUrl="/"
            />
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <FeatureCard
              shadow
              imageUrl="https://firebasestorage.googleapis.com/v0/b/health-ccc69.appspot.com/o/Screenshot%20from%202018-06-16%2001-33-05.png?alt=media&token=88cc2cf9-3109-419f-9627-c9f2033c3a18"
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              readMoreUrl="/"
            />
          </div>
        </div>
      </div>
    );
  }

  renderBlogs() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-sm-4 col-xs-12">
            <FeatureCard
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              getAQuoteUrl="/"
              readMoreUrl="/"
              badgeCaption="FEATURED"
              badgeColor="blue"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12">
            <FeatureCard
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              getAQuoteUrl="/"
              readMoreUrl="/"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12">
            <FeatureCard
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              getAQuoteUrl="/"
              readMoreUrl="/"
            />
          </div>
        </div>
      </div>
    );
  }

  renderFaq() {
    return (
      <div className="container-fluid">
        <div>
          <h2 className="nuclei-text">Genaral</h2>
        </div>
        {GENERAL_LIST.map((item, index) => {
          return (
            <Collapse
              key={`faq-${index}`}
              index={`${index}`}
              title={item.title}
              content={item.content}
            />
          );
        })}

        <div className="blockC">
          <h2 className="text-left">Motor</h2>
        </div>

        {MOTOR_LIST.map((item, index) => {
          return (
            <Collapse
              key={index}
              index={`motor-${index}`}
              title={item.title}
              content={item.content}
            />
          );
        })}
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.state.type ? (
          <div className="row">
            {this.renderTitle(this.state.type)}
            <div className="nuclei-tabs">
              <Tabs
                defaultActiveKey={this.state.type}
                id="search-results"
                onSelect={e => this.changeSearchResult(e)}
              >
                <Tab
                  eventKey={1}
                  title={`${this.searchResults[0].productCount} Products`}
                >
                  {this.renderProduct()}
                </Tab>
                <Tab
                  eventKey={2}
                  title={`${this.searchResults[1].BlogCount} Blogs`}
                >
                  {this.renderBlogs()}
                </Tab>
                <Tab
                  eventKey={3}
                  title={`${this.searchResults[2].faqCount} FAQs`}
                >
                  {this.renderFaq()}
                </Tab>
              </Tabs>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(SearchResultsBlog);
