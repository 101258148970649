import {combineReducers} from "redux";

import data from "./home";
import auth from "./auth";
import motor from "./motor";
import user from "./user";
import local from "./local";
import premium from "./premium";
import lead from "./leads";
import ci from "./criticalillness";
import {sessionReducer} from "redux-react-session";
import documentManager from "./documentManager";
import consumer from "./consumer";
import customerAuth from "./customerAuth";


export default combineReducers({
  auth: auth,
  data: data,
  user: user,
  motor: motor,
  local: local,
  premium: premium,
  lead: lead,
  ci: ci,
  session: sessionReducer,
  uploader: documentManager,
  consumer: consumer,
  customerAuth: customerAuth
});
