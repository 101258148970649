import React, {Component} from "react";
import "./emailTemplate.scss";

export default class EmailTemplate extends Component {
  render() {
    return (
      <div>
        <div className="container" id="welcome-onboard">
          <div className="row">
            <h2>Welcome Onboard!</h2>
            <p>
              {" "}
              Thank you very much for your business and we are so thrilled that
              you chose us your trusted insurance partners, Given Below is the
              summary of the payment made.
              <br />
              <br />
              <p>
                Feel free to get in touch wtih us via the website,
                www.insureme.lk and we are here to help you around the clock
              </p>
            </p>
            <a href="#"> Read More </a>
          </div>
        </div>

        <div className="container" id="payment-summary">
          <div className="row">
            <h2>Payment Summary</h2>
          </div>
          <table>
            <tr>
              <td>Account Name</td>
              <td>Jane Doe</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>+94 776 379 116</td>
            </tr>
            <tr>
              <td>Email Addes</td>
              <td>janedoe@ymail.com</td>
            </tr>
            <tr>
              <td>Product Name</td>
              <td>MotoPlus</td>
            </tr>
            <tr>
              <td>Payment Amount</td>
              <td>Rs. 45,000</td>
            </tr>
            <tr>
              <td>Payment Status </td>
              <td>Successful</td>
            </tr>
          </table>
        </div>
        <div className="container" id="ads-images">
          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6">
              <a href="#">
                <img src={require("../../assets/ads1.jpg")} alt="..." />
              </a>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6">
              <a href="#">
                <img src={require("../../assets/ads2.jpg")} alt="..." />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
