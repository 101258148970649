import React, {Component} from "react";
import {Link} from "react-router-dom";
export default class CustomLink extends Component {
  render() {
    if (this.props.to && this.props.to.startsWith("http")) {
      return (
        <a
          href={this.props.to}
          className={this.props.className}
          id={this.props.id}
          style={this.props.style}
        >
          {this.props.children}
        </a>
      );
    }
    return (
      <Link
        {...this.props}
        to={{pathname: this.props.to, state: {fromHome: true}}}
      />
    );
  }
}
