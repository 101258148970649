import React, {Component} from "react";
import "./index.scss";
import {InputText, Spinner} from "../../components";

import {
    getCustomerDetails,
    validateToken
} from "../../redux/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class Profile extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            customer: {
                mobile_number: null,
                email: null,
                office_number: null,
                customer_id: null
            },
            details: '',
            formData: '',
            name_initials: ''
        };

    }

    async componentDidMount() {
        let token = localStorage.getItem('authToken');
        await this.props.validateToken(token);
        const customerId = this.props.customerAuth.customerData;

        this.setState({
            customer: {
                mobile_number: null,
                email: null,
                office_number: null,
                customer_id: customerId
            }
        });

        const customerData = this.state.customer;
        const data = await this.props.getCustomerDetails({...customerData});

        this.setState({
            details: data.payload
        });
    }

    // handleChange(e) {
    //     this.setState({details: e.data.payload});
    //     console.log(this.state.details);
    // }

    render() {

        const {details} = this.state;

        return (
            <div className={'profile-container'}>
                <Spinner spinning={this.props.consumer.loading} />
                <div className="col-md-3 profile-image">
                    <div className={'row profile-pic text-center'}>
                        <div className="avatar-container">
                            <span className="profile-header">My Profile</span>
                            <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar"
                                 className="image" />
                            <div className="overlay">
                                {/*
                                    TODO: Implement the profile photo upload feature
                                */}
                                {/*<div className="text">*/}
                                {/*    <a href="#">Edit*/}
                                {/*        <svg className="bi bi-pencil" width="1em" height="1em"*/}
                                {/*             viewBox="0 0 16 16"*/}
                                {/*             fill="currentColor" xmlns="http://www.w3.org/2000/svg">*/}
                                {/*            <path fill-rule="evenodd"*/}
                                {/*                  d="M11.293 1.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z"*/}
                                {/*                  clip-rule="evenodd" />*/}
                                {/*            <path fill-rule="evenodd"*/}
                                {/*                  d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 00.5.5H4v.5a.5.5 0 00.5.5H5v.5a.5.5 0 00.5.5H6v-1.5a.5.5 0 00-.5-.5H5v-.5a.5.5 0 00-.5-.5H3z"*/}
                                {/*                  clip-rule="evenodd" />*/}
                                {/*        </svg>*/}
                                {/*    </a>*/}

                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 profile-details">
                    <div className={'row text-left'}>
                        <div className={'centered-col col-md-6'}>
                            <InputText
                                label={'Full Name'}
                                value={details.name_initials}
                                placeholder={'Full Name'}
                                onChange={e => this.onChange()}
                            />
                        </div>
                    </div>

                    <div className={'row text-left'}>
                        <div className={'centered-col col-md-6'}>
                            <InputText
                                label={'Mobile Number'}
                                value={details.mobile_number}
                                placeholder={'Mobile Number'}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className={'row text-left'}>
                        <div className={'centered-col col-md-6'}>
                            <InputText
                                label={'NIC/Passport Number'}
                                value={details.nic_number}
                                placeholder={'NIC/Passport Number'}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-3 profile-details">
                    <div className={'row text-left'}>
                        <div className={'centered-col col-md-6'}>
                            <InputText
                                label={'Address'}
                                value={details.address}
                                placeholder={'Address'}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className={'row text-left'}>
                        <div className={'centered-col col-md-6'}>
                            <InputText
                                label={'City'}
                                value={details.city}
                                placeholder={'City'}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className={'row text-left'}>
                        <div className={'centered-col col-md-6'}>
                            <InputText
                                label={'Postal Code'}
                                value={details.city}
                                placeholder={'Postal Code'}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        consumer: state.consumer,
        customerAuth: state.customerAuth
    }
}

export default connect(mapStateToProps, {
        getCustomerDetails,
        validateToken
    }
)(withRouter(Profile));
