import React, {Component} from "react";
import {
  // BtnRectangle,
  // BlogImg,
  QuickTagList,
  FeatureCard,
  // BlogFeaturesImg,
  InputText
} from "../../components";
import "./blog-landing-page.scss";

import {getFeatureCardData} from "../../redux/actions";
import {connect} from "react-redux";
import HeaderTitle from "../../components/HeaderTitle";

const featureCard = [
  {
    shadow: true,
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/insureme-ui.appspot.com/o/Blog3.png?alt=media&token=e1ba2a19-73aa-49b3-bef3-8a2beb428c25",
    header: "Hatton National bank",
    paragraph:
      "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
    getAQuoteUrl: "/",
    readMoreUrl: "/",
    hover: true,
    textContentStyle: {maxHeight: "40.9px"}
  },
  {
    shadow: false,
    imageUrl: "",
    header: "Hatton National bank",
    paragraph:
      "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
    getAQuoteUrl: "/",
    readMoreUrl: "/",
    hover: true
  },
  {
    shadow: false,
    imageUrl: "",
    header: "Hatton National bank",
    paragraph:
      "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
    getAQuoteUrl: "/",
    readMoreUrl: "/",
    hover: true
  },
  {
    shadow: false,
    imageUrl: "",
    header: "Hatton National bank",
    paragraph:
      "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
    getAQuoteUrl: "/",
    readMoreUrl: "/",
    hover: true,
    badgeCaption: "FEATURED",
    badgeColor: "red"
  },
  {
    shadow: false,
    imageUrl: "",
    header: "Hatton National bank",
    paragraph:
      "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
    getAQuoteUrl: "/",
    readMoreUrl: "/",
    hover: true
  },
  {
    shadow: false,
    imageUrl: "",
    header: "Hatton National bank",
    paragraph:
      "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
    getAQuoteUrl: "/",
    readMoreUrl: "/",
    badgeCaption: "POPULAR",
    hover: true
  },
  {
    shadow: false,
    imageUrl: "",
    header: "Hatton National bank",
    paragraph:
      "This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium",
    getAQuoteUrl: "/",
    readMoreUrl: "/",
    hover: true
  }
];
class SearchResultsBlog extends Component {
  async componentDidMount() {}
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }

  onChange = (type, e) => {
    this.setState({[type]: e.target.value});
  };
  render() {
    return (
      <div>
        <div className="container-fluid zero-padding">
          <div className="row">
            <div className="container" />
            <div className="col-xs-8 col-md-8 col-sm-8 ">
              <img
                className=" nuclei-image-main"
                src={require("../../assets/Blog.png")}
                alt="..."
              />
            </div>
            <div className="col-xs-4 col-md-4 col-sm-4 zero-padding-left">
              <img
                className="  nuclei-image-sub"
                src={require("../../assets/Blog1.png")}
                alt="..."
              />
              <img
                className="  nuclei-image-sub-one"
                src={require("../../assets/Blog2.png")}
                alt="..."
              />
            </div>
            <br />
          </div>

          <HeaderTitle
            mainFirstWord="Featured"
            restOfTitle="Articles"
            headerStyle={{fontWeight: "bold"}}
          />
          <div className="col-md-8 col-sm-8 col-xs-12 zero-padding">
            <div className="col-md-6 col-sm-6 col-xs-12 zero-padding">
              {featureCard.map((featureCard, i) => {
                if (i < 3) {
                  let hiddenXs = "";
                  if (i > 0) {
                    hiddenXs = "hidden-xs";
                  }
                  return (
                    <div className={`${hiddenXs}`}>
                      <FeatureCard
                        shadow={featureCard.shadow}
                        imageUrl={featureCard.imageUrl}
                        header={featureCard.header}
                        paragraph={featureCard.paragraph}
                        getAQuoteUrl={featureCard.getAQuoteUrl}
                        readMoreUrl={featureCard.readMoreUrl}
                        badgeCaption={featureCard.badgeCaption}
                        badgeColor={featureCard.badgeColor}
                        hover={featureCard.hover}
                        textContentStyle={featureCard.textContentStyle}
                      />
                    </div>
                  );
                }
              })}
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 zero-padding">
              {featureCard.map((featureCard, i) => {
                if (i > 2) {
                  let hiddenXs = "";
                  if (i > 4) {
                    hiddenXs = "hidden-xs";
                  }
                  return (
                    <div className={`${hiddenXs}`}>
                      <FeatureCard
                        shadow={featureCard.shadow}
                        imageUrl={featureCard.imageUrl}
                        header={featureCard.header}
                        paragraph={featureCard.paragraph}
                        getAQuoteUrl={featureCard.getAQuoteUrl}
                        readMoreUrl={featureCard.readMoreUrl}
                        badgeCaption={featureCard.badgeCaption}
                        badgeColor={featureCard.badgeColor}
                        hover={featureCard.hover}
                        textContentStyle={featureCard.textContentStyle}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className="col-md-4 col-sm-4 col-xs-12 zero-padding">
            <div className="col-md-7 col-sm-7 col-xs-8 zero-padding">
              <InputText
                className="form-control email-input"
                id="exampleInputEmail1"
                placeholder="Email"
                type={"text"}
                value={this.state.email}
                onChange={e => this.onChange("email", e)}
                validation={{
                  numericality: {
                    message: " ",
                    presence: true
                  }
                }}
              />
            </div>
            <div className="col-md-5 col-sm-5 col-xs-4 zero-padding">
              <button id="Buttonsub" className="btn bt-subscribe" type="button">
                Subscribe
              </button>
            </div>
          </div>

          <div className="col-md-4 col-sm-4 hidden-xs zero-padding">
            <br />
            <img
              className="img-responsive"
              src={require("../../assets/Blog4.png")}
              alt="..."
              id="imgeR"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 zero-padding">
            <div className="col-md-12 col-sm-12 col-xs-6 quick-tag-list-left zero-padding">
              <QuickTagList
                header="Specials"
                list={[
                  {label: "Most Read"},
                  {label: "Featured"},
                  {label: "Popular"},
                  {label: "New"}
                ]}
              />
              <div className="col-md-4 col-sm-4 col-xs-12 share-social zero-padding visible-xs">
                <h3>Share This</h3>
                <div className="col-md-2 col-sm-3 col-xs-4 zero-padding">
                  <a href="" title="Click here">
                    <img
                      className="img-responsive"
                      src={require("../../assets/facebook.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-md-2 col-sm-3 col-xs-4 zero-padding">
                  <a href="" title="Click here">
                    <img
                      className="img-responsive"
                      src={require("../../assets/Twitter.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-md-2 col-sm-3 col-xs-4 zero-padding">
                  <a href="" title="Click here">
                    <img
                      className="img-responsive"
                      src={require("../../assets/googlePlus.png")}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-xs-6 quick-tag-list-right zero-padding">
              <QuickTagList
                header="Categories"
                list={[
                  {label: "Insurance"},
                  {label: "Products"},
                  {label: "Tips"},
                  {label: "How Tos"},
                  {label: "News"},
                  {label: "Motor"},
                  {label: "Life"},
                  {label: "Travel"},
                  {label: "Health"}
                ]}
              />
              <div className=" share-social zero-padding hidden-xs">
                <h2>Share This</h2>
                <div className="col-md-2 col-sm-3 col-xs-3">
                  <a href="" title="Click here">
                    <img
                      className="img-responsive"
                      src={require("../../assets/facebook.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-md-2 col-sm-3 col-xs-3">
                  <a href="" title="Click here">
                    <img
                      className="img-responsive"
                      src={require("../../assets/Twitter.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-md-2 col-sm-3 col-xs-3">
                  <a href="" title="Click here">
                    <img
                      className="img-responsive"
                      src={require("../../assets/googlePlus.png")}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-4 col-sm-4 col-xs-12 share-social zero-padding ">
            <h2>Share This</h2>
            <div className="col-md-2 col-sm-3 col-xs-3">
              <a href="#" title="Click here">
                <img
                  className="img-responsive"
                  src={require("../../assets/facebook.png")}
                />
              </a>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-3">
              <a href="#" title="Click here">
                <img
                  className="img-responsive"
                  src={require("../../assets/Twitter.png")}
                />
              </a>
            </div>
            <div className="col-md-2 col-sm-3 col-xs-3">
              <a href="#" title="Click here">
                <img
                  className="img-responsive"
                  src={require("../../assets/facebook.png")}
                />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.data
  };
}

export default connect(
  mapStateToProps,
  {
    getFeatureCardData
  }
)(SearchResultsBlog);
