import React, {Component} from "react";
import "./CartWidget.scss";
import {addCommas} from "../../util";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import _ from "lodash";

class CartWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false
    };
  }
  toggleDetails(showInfo) {
    this.setState({showInfo: !showInfo});
  }

  render() {
    let users = this.props.users;
    let companies = this.props.companies;
    let total = 0;
    let showInfo = this.state.showInfo;
    let info = [];
    let relationShips = this.props.relationships;
    for (let i in users) {
      let totalCover = 0;
      let totalPremium = 0;
      let user = users[i];
      let relationship = _.find(relationShips, {id: user.relationship.id});
      for (let j in user.plans) {
        let premium = user.plans[j];
        let company = companies.find(function(company) {
          return company.id === premium.companyId;
        });

        
        let limit = company.limits.find(function(limit) {
          return limit.id === premium.limitId;
        });

        if (limit) {
          totalCover = totalCover + limit.cover;
          totalPremium = totalPremium + limit.premium;
        }
        total = total + limit.premium;
      }

      info.push(
        <table key={i} className="cart-member-info">
          <thead>
            <tr className="member-name">
              <th>{user.nameInitials}</th>
              <th>Cover</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr className="premium-info">
              <td className="member-relation">
                {relationship ? `${relationship.description}  ` : ""}
              </td>
              <td className="member-cover">
                Rs.
                {addCommas(totalCover)}
              </td>
              <td className="member-premium">
                Rs.
                {addCommas(totalPremium)}
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
    $(document).ready(function() {
      $(".cart-widget").draggable({
        axis: "y",
        containment: "window",
        cursor: "all-scroll",
        zIndex: 100,
        drag: function(event, ui) {
          ui.position.top = addCommas(Math.max(100, ui.position.top));
          ui.position.top = addCommas(Math.min(200, ui.position.top));
        }
      });
    });
    total = parseFloat(total).toFixed(2);
    return (
      <div className="cart-widget">
        <h4 className="cart-totle">
          Premium total: <span>Rs.{addCommas(total)}</span>
        </h4>
        {showInfo ? <div className="cart-members">{info}</div> : null}
        <div className="clickable" onClick={() => this.toggleDetails(showInfo)}>
          {showInfo ? "fewer details " : "more details"}
          <span
            className={`glyphicon glyphicon-chevron-${
              showInfo ? "up" : "down"
            }`}
          />
        </div>
      </div>
    );
  }
}

export default CartWidget;
