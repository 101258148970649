import React, {Component} from "react";
import {Dropdown, InputText} from "../../components";
import {formatDate} from "../../util";
import {getInfoFromNIC} from "../../util-nic";
import {connect} from "react-redux";
import {getBalanceForNic, policyHolderValidate, setpolicyHolderDetails} from "../../redux/actions";
import _ from "lodash";
import {AgeFromDateString} from "age-calculator";
import VeryfyCode from "./VeryfyCode";

const validations = {
  nameInitials: {
    presence: {
      allowEmpty: false,
      message: "Name with Initials is required"
    },
    format: {
      pattern: "^[a-zA-Z ]+$",
      flag: "i",
      message: "Name Initials is not valid "
    }
  },
  nicNumber: {
    presence: {
      allowEmpty: false,
      message: "NIC Number is required"
    },
    format: {
      pattern: /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/m,
      flag: "i",
      presence: true,
      message: "NIC Number is not valid "
    }
  },
  dateOfBirth: {
    presence: {
      allowEmpty: false,
      message: "Date of birth is required"
    },
    format: {
      pattern: /^\d{4}-\d{2}-\d{2}$/,
      flag: "i",
      presence: true,
      message: "Date of birth is not valid "
    }
  },
  mobileNumber: {
    presence: {
      allowEmpty: false,
      message: "Mobile Number is required"
    },
    numericality: {
      pattern: "[0-9]{9}",
      notLessThan: "9",
      presence: true,
      message: "Mobile Number Not Valid"
    },
    length: {
      is: 9
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: "Email is required"
    },
    email: true,
  }
};

class PersonalIfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      currentHolder: {
        id: 0,
        nameInitials: "",
        nicNumber: "930251054v",
        dateOfBirth: "",
        mobileNumber: "",
        email: "",
        relationship: "",
        valid: false
      },
      relationShips: [],
      isFamilyMembers: false,
      email: "",
      updateBalanceCall: null
    };
  }

  componentDidMount() {
    const currentHolder = _.find(this.props.policyHolderList, {
      id: this.props.currentPolicyHolderId
    });
    if (currentHolder) {
      if (currentHolder.id === this.props.currentPolicyHolderId) {
        if (currentHolder.id === 0) {
          const myself = _.find(this.props.relationshipsOptions, {
            code: "MS"
          });
          currentHolder.relationship = myself;
        }
      }
    }

    this.setState({
      relationShips: this.props.relationshipsOptions,
      currentHolder: currentHolder
    });
  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillReceiveProps(nextprops) {
    const relationShips = _.cloneDeep(nextprops.relationshipsOptions);
    if (nextprops.currentHolder.id === nextprops.currentPolicyHolderId) {
      if (typeof nextprops.currentHolder.relationship === "object") {
        if (nextprops.currentHolder.relationship.code === "MS") {
          this.setState({
            isFamilyMembers: false,
            disabled: true
          });
        } else {
          _.remove(relationShips, {code: "MS"});
          this.setState({
            isFamilyMembers: true,
            disabled: false
          });
        }
      } else if (nextprops.currentHolder.id === 0) {
        let myself = nextprops.currentHolder;
        const myselfRelation = _.find(relationShips, {
          code: "MS"
        });
        myself.relationship = myselfRelation;
      } else {
        let notMyself = nextprops.currentHolder;
        const myselfRelation = _.find(relationShips, {
          value: "0"
        });
        notMyself.relationship = myselfRelation;
        _.remove(relationShips, {code: "MS"});
        this.setState({
          isFamilyMembers: true,
          disabled: false
        });
      }
    }

    this.updateErrorMessageForValidation(nextprops.currentHolder);
    this.setState({
      relationShips: relationShips,
      currentHolder: nextprops.currentHolder
    });
  }

  updateErrorMessageForValidation = currentHolder => {
    const error = {};
    if (
        _.some(this.props.policyHolderList, user => {
          return (
              user.nicNumber === currentHolder.nicNumber &&
              user.id !== currentHolder.id
          );
        }) &&
        currentHolder.nicNumber
    ) {
      error.nicNumber = "This NIC already used!";
    }
    if (
        _.some(this.props.policyHolderList, user => {
          return (
              user.nameInitials === currentHolder.nameInitials &&
              user.id !== currentHolder.id
          );
        }) &&
        currentHolder.nameInitials
    ) {
      error.nameInitials = "This Name already used!";
    }
    if (
        _.some(this.props.policyHolderList, user => {
          return (
              user.relationship.code &&
              user.relationship.code === currentHolder.relationship.code &&
              user.id !== currentHolder.id &&
              user.relationship.code !== "SO" &&
              user.relationship.code !== "DA" &&
              user.relationship.code !== "SIL" &&
              user.relationship.code !== "DIL"
          );
        })
    ) {
      error.relationship = "This relationship already used!";
    }
    if (
        !currentHolder.relationship.code &&
        error.relationship === "Relationship is required"
    ) {
      error.relationship = "Relationship is required";
    }
    const regex = "^[6-7]{2}[0-9]{7}$";
    const mobileNumber = currentHolder.mobileNumber;
    if (mobileNumber.match(regex) == null && this.state.error["mobileNumber"]) {
      error.mobileNumber = "Please enter your Dialog mobile number";
    }
    let ageFromString = new AgeFromDateString(currentHolder.dateOfBirth).age;
    if (
        (currentHolder.dateOfBirth && ageFromString < 1) ||
        ageFromString > 70
    ) {
      error["dateOfBirth"] = "age should be between 1 and 70";
    }

    this.setState({
      error
    });
  };

  onChangeHandler = (key, e) => {
    let currentHolder = this.state.currentHolder;
    const error = this.state.error;
    delete error[key];

    if (e.target) {
      currentHolder[key] = e.target.value;
    } else {
      currentHolder[key] = e;
    }
    if (key === "nicNumber") {
      delete error["dateOfBirth"];
      try {
        const info = getInfoFromNIC(currentHolder.nicNumber);
        const DOB = formatDate(info.dateOfBirth);

        if (info.dateOfBirth && DOB !== "NaN-NaN-NaN") {
          let ageFromString = new AgeFromDateString(DOB).age;
          if (ageFromString < 1 || ageFromString > 70) {
            error["dateOfBirth"] = "Critical Illness from 1 0 70 years.";
          }
          currentHolder["dateOfBirth"] = DOB;
        }


      } catch (error) {
      }
      if (error["dateOfBirth"] === undefined && error['nicNumber'] === undefined && currentHolder.nicNumber.length > 8) {
        this.updateBalance(currentHolder.nicNumber);
      }

    }
    if (key === "relationship") {
      let relationEvent = _.find(this.state.relationShips, {
        value: e.target.value
      });
      if (!relationEvent.code) {
        error[key] = "Relationship is required";
      } else {
        currentHolder[key] = relationEvent;
        if (relationEvent && relationEvent.code === "MS") {
          this.setState({
            isFamilyMembers: false
          });
        } else {
          this.setState({
            isFamilyMembers: true
          });
        }
      }
    }
    if (key === "mobileNumber") {
      const regex = "^[6-7]{2}[0-9]{7}$";
      const mobileNumber = currentHolder.mobileNumber;
      if (mobileNumber.match(regex) == null) {
        error[key] = "Please Enter Dialog mobile number";
      }
    }
    this.setState({currentHolder});
    this.forceUpdate();
    this.props.setpolicyHolderDetails(
        currentHolder,
        true,
        "policyholder",
        this.props.currentHolder.id
    );
  };

  updateBalance(nic) {
    let updateBalanceCall = this.state.updateBalanceCall;
    if (updateBalanceCall !== null) {
      clearTimeout(updateBalanceCall);
    }
    updateBalanceCall = setTimeout(() => {
      this.props.getBalanceForNic(nic);
    }, 2000);
    this.setState({
      updateBalanceCall: updateBalanceCall
    });
  }

  scrollTop(e) {
    e.preventDefault();
    window.scrollTo({top: 0, behavior: "smooth"});
  }

  render() {
    const {otpVerifyError, newState, verifiedNumbers} = this.props;
    const {error, disabled} = this.state;
    const {
      nameInitials,
      relationship,
      nicNumber,
      dateOfBirth,
      mobileNumber,
      email
    } = this.state.currentHolder;
    const verifyPayload = verifiedNumbers[nicNumber] === undefined ? null : verifiedNumbers[nicNumber];
    const {isFamilyMembers} = this.state;
    return (
        <div className="col-sm-12 col-xs-12 personal-info-form">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12 form-group input-text">
              <label className="nuclei-input-label mi-label text-left">
                Name with initials
              </label>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <InputText
                    tooltip={"Please fill your name with initials"}
                    placeholder="Name with initials"
                    type={"text"}
                    value={nameInitials}
                    onChange={e => this.onChangeHandler("nameInitials", e)}
                    validation={validations.nameInitials}
                    errorText={error.nameInitials}
                    minLength={3}
                    maxLength={30}
                    newState={newState}
                    onBlur={() => this.props.newUserState()}
                />
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-xs-12 form-group input-text">
              <label className="nuclei-input-label mi-label text-left">NIC Number</label>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <InputText
                    tooltip={"Please add your NIC number or Passport number"}
                    placeholder="NIC Number"
                    type={"text"}
                    value={nicNumber}
                    errorText={error.nicNumber}
                    onChange={e => this.onChangeHandler("nicNumber", e)}
                    validation={validations.nicNumber}
                    minLength={10}
                    maxLength={12}
                    newState={newState}
                    onBlur={() => this.props.newUserState()}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12 form-group input-text">
              <label className="nuclei-input-label mi-label text-left">Relationship</label>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <Dropdown
                    disabled={disabled}
                    tooltip={"Please select your relationship with nominee."}
                    options={this.state.relationShips}
                    value={relationship.value}
                    errorText={error.relationship}
                    validation={validations.relationShip}
                    onChange={e => this.onChangeHandler("relationship", e)}
                />
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-xs-12 form-group input-text">
              <label className="nuclei-input-label mi-label text-left">
                Date Of Birth
              </label>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <InputText
                    tooltip={"Please type your birthday as DD/MM/YY format"}
                    placeholder="Date Of Birth"
                    type={"text"}
                    value={dateOfBirth}
                    errorText={error.dateOfBirth}
                    onChange={e => this.onChangeHandler("dateOfBirth", e)}
                    validation={validations.dateOfBirth}
                    newState={newState}
                />
              </div>
            </div>
          </div>

          {isFamilyMembers ? null : (
              <div className="row">
                <div key={0} className="col-md-6 col-sm-6 col-xs-12 form-group input-text">
                  <label className="nuclei-input-label mi-label text-left">Email</label>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <InputText
                        tooltip={"Please add your correct email address"}
                        placeholder="Email"
                        type={"text"}
                        value={email}
                        disabled={isFamilyMembers}
                        errorText={error.email}
                        onChange={e => this.onChangeHandler("email", e)}
                        validation={validations.email}
                        minLength={5}
                        maxLength={60}
                        newState={newState}
                    />
                  </div>
                </div>
                <div key={1} className="col-md-6 col-sm-6 col-xs-12 form-group input-text">
                  <label className="nuclei-input-label mi-label text-left">
                    Mobile Number
                  </label>
                  <div
                      className="col-md-3 col-sm-3 col-xs-3"
                      style={{paddingRight: "12px"}}
                  >
                    <InputText disabled={true} value={"94"}/>
                  </div>
                  <div
                      className="col-md-9 col-sm-9 col-xs-9"
                      style={{paddingLeft: "0px"}}
                  >
                    <InputText
                        tooltip={
                          "This cover is exclusively for Dialog axiata customers only"
                        }
                        placeholder="7XXXXXXXX"
                        type={"tel"}
                        value={mobileNumber}
                        disabled={isFamilyMembers}
                        errorText={error.mobileNumber}
                        onChange={e => this.onChangeHandler("mobileNumber", e)}
                        validation={validations.mobileNumber}
                        minLength={9}
                        maxLength={9}
                        newState={newState}
                    />
                  </div>
                </div>
                <VeryfyCode
                    key={2}
                    email={email}
                    mobileNumber={mobileNumber}
                    otpVerifyError={otpVerifyError}
                    verifyPayload={verifyPayload}
                />
                <div key={3} className="col-md-12 col-sm-12 col-xs-12">
                  {otpVerifyError ? (
                      <div
                          className="alert alert-danger"
                          role="alert"
                          style={{color: "#FFF"}}
                      >
                        {otpVerifyError}.
                        <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                  ) : verifyPayload && verifyPayload.token ? (
                      <div
                          className="alert alert-success"
                          role="alert"
                          style={{color: "#FFF"}}
                      >
                        Your Phone Number is verified. Now you can{" "}
                        <a className="add-more" onClick={e => this.scrollTop(e)}>
                          <strong>add more members</strong>
                        </a>{" "}
                        to the policy.
                        <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                  ) : null}
                </div>
              </div>
          )}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.ci.loading,
    policyHolderList: state.ci.policyHolderList,
    policyHolderBalanceList: state.ci.policyHolderBalanceList,
    relationshipsOptions: state.ci.relationshipsOptions,
    currentPolicyHolderId: state.ci.currentPolicyHolderId,
    verifiedNumbers: state.ci.verifiedNumbers,
    verifyPayload: state.user.verifyPayload,
    otpVerifyError: state.user.otpVerifyError,
    companies: state.ci.companies,
  };
};

export default connect(
    mapStateToProps,
    {
      setpolicyHolderDetails,
      policyHolderValidate,
      getBalanceForNic
    }
)(PersonalIfoForm);
