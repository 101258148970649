import React from "react";
import "./Jumbotron.scss";

export const Jumbotron = ({
  title,
  subTitle,
  visible = "visible-xs",
  hidden = false,
  visibleFaq = "hidden-xs"
}) => {
  let style = {};
  if (hidden) {
    style = {display: "none"};
    visible = "";
    visibleFaq = "";
  }

  return (
    <div>
      <div className={`col-md-12 col-sm-12 col-xs-12 ${visibleFaq}`}>
        <div className="nuclei-jumbotron">
          <h2>{title}</h2>
          <p className="SubTitle">{subTitle}</p>
        </div>
      </div>

      <div style={style} className={`col-md-12 col-sm-12 col-xs-12 ${visible}`}>
        <div className="row">
          <div className="nuclei-jumbotron ">
            <span className="input-group-addon glyphicon glyphicon-search" />
            <input
              type="text"
              className="form-control-mobile-jumbotron"
              placeholder="Search"
            />
            <p className="SubTitle">{subTitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Jumbotron;
