import React, {Component} from "react";
import validate from "validate.js";
import * as util from "../util";
import "./FormInputs.scss";
import _ from "lodash";

export class InputText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blurred: false
        };
    }

    render() {
        let {
            tooltip,
            label,
            type = "text",
            placeholder,
            id,
            disabled,
            addCommas,
            value = "",
            errorText,
            errors,
            validation,
            onChange,
            required,
            maxLength,
            minLength,
            newState,
            onBlur
        } = this.props;
        if (!errorText && this.state.blurred && validation) {
            if (!value && validation.presence) {
                errorText = " required ";
            }
            if (validation.presence === false && !value) {
                errorText = null;
            } else {
                errorText = validate.single(value, validation);
            }
        }

        if(errors !== null && errors !== undefined && errors.length > 0){
            errorText = errors.map((err)=> <p className="form-input-error">{err}</p>)
        }
        if (newState && this.state.blurred) {
            errorText = null;
            this.state.blurred = false;
            newState = false;
        }

        return (
            <div className={`form-group `}>
                <label className="nuclei-input-label"> {label} </label>

                <input
                    name="text"
                    label="text"
                    // type={type}
                    value={addCommas ? util.addCommas(value) || "" : value}
                    required={required}
                    className={`form-control ${errorText ? "form-input-has-error" : ""}`}
                    id={id || label}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={e => {
                        if (addCommas && e && e.target && e.target.value) {
                            e.target.value = e.target.value.replace(/,/g, ""); //temp fix for addCommas
                            return onChange(e);
                        } else {
                            return onChange(e);
                        }
                    }}
                    onBlur={e => {
                        this.setState({blurred: true});
                        if (onBlur) onBlur()
                    }}
                    maxLength={maxLength}
                    minLength={minLength}
                />
                {errorText ? (
                    <p className="form-input-error">
                        {_.isArray(errorText) ? errorText[0] : errorText}
                    </p>
                ) : null}
                {/* below commented code here */}
            </div>
        );
    }
}

// {!tooltip ? (
//   <span>
//     <OverlayTrigger
//       trigger="click"
//       rootClose
//       placement="bottom"
//       overlay={
//         <Popover
//           id="popover-positioned-bottom"
//           className="tooltip-popover"
//           title={tooltip}
//         />
//       }
//     >
//       <button type="button" className="btn  nuclei-tooltip-text">
//         ?
//       </button>
//     </OverlayTrigger>
//   </span>
// ) : null}
