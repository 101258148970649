// import 'core-js/es6/string';
import {Router, Switch, Route} from "react-router-dom";
import {Provider} from "react-redux";
import history from "./history";
import $ from "jquery";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";



import {store} from "./redux/store";
import Blog from "./containers/blog/blog-landing-page";
import BlogPost from "./containers/blog/blog-post-details";
import ClassicQuotation from "./containers/motor/quotation/classic-quote";
import CoverComparison from "./containers/motor/cover-comparison/CoverComparison";
import EmailTm from "./containers/email-template/emailTemplate";
import FAQ from "./containers/faq/faq";
import Home from "./containers/home/home";
import Layout from "./containers/layouts/Layout";

import PersonalInfo from "./containers/my-account/personal-info/personal-info";
import PremiumComparison from "./containers/motor/premium-comparison/PremiumComparison";
import registerServiceWorker from "./registerServiceWorker";
import SearchResultsBlog from "./containers/searchResults/SearchResultsBlog";
import Step5 from "./containers/steps/step5/Step5";
import Test from "./containers/Test";
import Inquiry from "./containers/motor/inquiry/Inquiry";
import ProposalForm from "./containers/motor/proposal-form/ProposalForm";
import ViewComponents from "./containers/ViewComponents";
import PAGE404 from "./containers/custom-pages/page404";
import PaymentSuccessPage from "./containers/custom-pages/PaymentSuccessPage";
import CriticalIllness from "./containers/ci/CriticalIllness";
import DcumentUploader from "./containers/document-uploader/index";

import Promotions from "./containers/promo/promotion";
import SMEProduct from "./containers/landing-pages/sme-product/index";

import "./index.scss";
import Dashboard from "./containers/dashboard";
import CustomerAuth from "./containers/CustomerAuth";
import RaffleDraw from "./containers/dashboard/RaffleDraw";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

window.jQuery = $;
window.$ = $;
$(document).on("mobileinit", function() {
  $.mobile.autoInitializePage = false;
});
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/faq" component={FAQ} />
          <Route path="/cmd" component={ViewComponents} />
          <Route path="/emailtm" component={EmailTm} />
          <Route path="/SearchResultsBlog" component={SearchResultsBlog} />
          <Route path="/SearchResultsFAQ" component={SearchResultsBlog} />
          <Route path="/SearchResultsProduct" component={SearchResultsBlog} />
          <Route path="/blog-post" component={BlogPost} />
          <Route path="/blog" component={Blog} />
          <Route path="/test" component={Test} />

          <Route
            path="/motor/:vehicle_type/:insurance_type/Quotation"
            component={ClassicQuotation}
          />
          <Route
            path="/motor/:vehicle_type/:insurance_type/Inquiry"
            component={Inquiry}
          />
          <Route
            path="/motor/:vehicle_type/:insurance_type/Premium-Comparison"
            component={PremiumComparison}
          />
          <Route
            path="/motor/:vehicle_type/:insurance_type/Cover-Comparison"
            component={CoverComparison}
          />
          <Route path="/account" component={PersonalInfo} />

          <Route path="/motor/Proposal/:req_key" component={ProposalForm} />
          <Route path="/payment/:hash_code" component={PaymentSuccessPage} />
          {/* <Route path="/motor/Proposal/success/:code" component={PaymentSuccessPage} />  */}
          <Route path="/page404" component={PAGE404} />

          <Route
            path="/promotions/:campaign_type/:category/:promo_type/:offer"
            component={Promotions}
          />

          <Route path="/step5" component={Step5} />
          <Route path="/profile" component={Dashboard} />
          <Route path="/medical/major-illnesses-insurance-cover" component={CriticalIllness} />
          <Route path="/user/document/uploader/:hash_code" component={DcumentUploader} />
          <Route path='/per-day-motor-insurance' component={() => {
              window.location.href = 'https://insureme.lk/per-day-motor-insurance';
              return null;
          }} />
          <Route path="/auth" component={CustomerAuth}/>
          <Route path="/start-ups-&-sme" component={SMEProduct} />
          <Route path="/raffle-draw/winners/" component={RaffleDraw} />
          <Route component={PAGE404} />
        </Switch>
      </Layout>
    </Router>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
