
import React, { Component } from 'react';
import { IMAGE_ROOT } from '../../../config';
import { addCommas } from '../../../util';
import classes from './insurance-detail-card.scss';

class InsuranceDetailCard extends Component {

    render() {
        const company = this.props.company;
        if(!this.props.company){
            return null;
        }
        return (
            <div className="col-md-12 col-sm-12 col-xs-12 insurance-detail-card">

                <div className="insurance-image">
                    <img
                        src={`${IMAGE_ROOT}/${(company.logo|| company.company_logo)}`}
                        height="60px"
                        alt="" />
                    <div className="premium-price-label">Rs.  {
                        addCommas(company.TotalPremium || company.total_premium )}
                    </div>
                    { company.is_promo_discount == 1 ?
                        <div className="premium-label">
                            Rs.  {addCommas(company.disc_total_premium)}
                            <span className="premium-bank-label">( {company.promotion_status})</span>
                        </div> : null
                    }
                </div>

            </div >
        );
    }
}

export default InsuranceDetailCard;