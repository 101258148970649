import React from "react";
import "./Badge.scss";

export const Badge = props => {
  let {
    badgeCaption,
    className = "",
    color = "blue",
    type = "square",
    size,
    outline
  } = props;
  let newClasses = "";
  if (size) {
    newClasses = `${newClasses} badge-${size} `;
  }
  if (outline) {
    newClasses = `${newClasses} badge-outline `;
  }

  return (
    <span
      // {...props}
      className={`
                nuclei-badge
                badge-${color}
                badge-${type}
                ${newClasses}
                ${className}
                `}
    >
      {badgeCaption}
    </span>
  );
};

export default Badge;
