import React, {Component} from "react";
import "./CiFAQs.scss";
import {Collapse} from "../../components";
import _ from "lodash";

const GENERAL_LIST = [
  {
    title: "+ Who can buy this policy?",
    content: (
      <ol type="a">
        <li>
          Only a dialog customer can buy this product for self and family
          members who are between the age of 1 -70 yrs.
        </li>
      </ol>
    )
  },
  {
    title: "+ How can I buy this cover?",
    content: (
      <ol type="a">
        <li>
          This cover can only be bought online through www.InsureMe.lk or
          Insureme app else by calling the Insureme team on 076 828 2424.{" "}
        </li>
      </ol>
    )
  },
  {
    title: "+ How do I pay for this policy? ",
    content: (
      <ol type="a">
        
      
          <li>
            Pay Online through{" "}
            <a href="https://www.InsureMe.lk" target="_blank">
              www.InsureMe.lk
            </a>
         </li>
          {/* <li>
            Deposit to the bank
            <ul>
              <li> Account Name : InsureMe.lk</li>
              <li>
                Account No: Sampath Bank – Borella Branch– 000 410 022 842
              </li>
            </ul>
          </li>
          <li>Pay by a Cheque under InsureMe Insurance Brokers (Pvt) Ltd</li> */}
      </ol>
    )
  },
  {
    title: "+ What are the “Major Illnesses” covered under this policy?",
    content: (
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-6">
          <ul>
            <li>Heart Attack</li>
            <li>Stroke</li>
            <li>Open Heart Surgery/ Stenting</li>
            <li>Cancer</li>
            <li>Kidney Failure</li>
            <li>Fulminant Hepatitis</li>
            <li>Multiple Sclerosis</li>
            <li>Primary Pulmonary Arterial Hypertension</li>
            <li>Paralysis</li>
            <li>Muscular Dystrophy</li>
          </ul>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6">
          <ul>
            <li>Permanent Blindness</li>
            <li>Heart Valve Surgery</li>
            <li>Major Organ Transplant</li>
            <li>Surgery to the Aorta</li>
            <li>Chronic Liver Disease</li>
            <li>Permanent Deafness</li>
            <li>Chronic Lung Disease</li>
            <li>Coma</li>
            <li>Loss of Speech Organ</li>
          </ul>
        </div>
      </div>
    )
  },
  {
    title: "+ How much does this policy pay for these illnesses? ",
    content: (
      <table className="faq-table">
        <thead>
          <tr>
            <th>COVERAGE</th>
            <th>SCHEME (Rs.)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {" "}
              hospitalization benefit within Sri Lanka - Annual/Event Limit{" "}
            </td>
            <td style={{textAlign: "right"}}>1,000,000</td>
          </tr>
          <tr style={{backgroundColor: "#808080", color: "#ffffff"}}>
            <td>
              {" "}
              <strong>Section 1</strong>{" "}
            </td>
            <td />
          </tr>
          <tr>
            <td>
              {" "}
              Total hospital room chargers including admission fees per event{" "}
            </td>
            <td style={{textAlign: "right"}}>300,000</td>
          </tr>
          <tr style={{backgroundColor: "#808080", color: "#ffffff"}}>
            <td>
              {" "}
              <strong>Section 2</strong>{" "}
            </td>
            <td />
          </tr>
          <tr>
            <td> Specialized services per event </td>
            <td style={{textAlign: "right"}}>200,000</td>
          </tr>
          <tr style={{backgroundColor: "#808080", color: "#ffffff"}}>
            <td>
              {" "}
              <strong>Section 3</strong>{" "}
            </td>
            <td />
          </tr>
          <tr>
            <td>
              {" "}
              All other expenses including, surgeon’s & Doctor’s chargers’
              Operational expenses, medical expenses and emergency transport Per
              event{" "}
            </td>
            <td style={{textAlign: "right"}}>500,000</td>
          </tr>
          {/* <tr>
                    <td><strong>PREMIUM - Including Taxes</strong></td>
                    <td style={{textAlign:'right'}}><strong>3,768.24/-</strong></td>
                </tr> */}
          <tr>
            <td colSpan="2">
              {" "}
              <p>
                If you purchase a cover lower than Rs.1 Million, the benefit
                payable would be paid on a proportionate basis
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    )
  },
  {
    title: "+ How do I claim under this policy?",
    content: (
      <ol type="a">
        <li>
          Inform Ceylinco claims service desk on 0112 39 39 39, immediately upon
          admission in a hospital.
        </li>
        <li>A claim form would be sent to you from the claims desk.</li>
        <li>
          Complete and send the claims form to the correspondence address along
          with the following document. The payment would be made to the insured
          person.
        </li>
        <ol type="1">
          <li>Hospitalization claim</li>
          <li>Copy of the diagnosis card</li>
          <li>Copy of the identity card</li>
          <li>Original medical bills/invoice</li>
          <li>Original cash paid receipts</li>
          <li>Bank details/copy of pass book</li>
        </ol>
      </ol>
    )
  },
  {
    title: "+ When does the policy pay? ",
    content: (
      <ol type="a">
        <li>
          The company will reimburse the person insured up to the amount shown
          in the schedule of benefits, in respect of the hospitalization medical
          expenses necessarily incurred within the geographical limits of Sri
          Lanka, as a result of the person insured being diagnosed as suffering
          from any of the major illnesses defined above.
        </li>
      </ol>
    )
  },
  {
    title: "+ What you are not covered for?",
    content: (
      <>
        <p>The company shall not be responsible for,</p>
        <ol type="a">
          <li>
            Claims arising directly or indirectly as a result of a pre-existing
            Medical Condition of the person insured.
          </li>
          <li>
            Any expenses incurred other than the hospitalization expenses
            incurred for major illnesses listed above.
          </li>
          <li>
            Expenses incurred within first 30 days from the effective date of
            cover
          </li>
          <li>
            In respect of services of non-medical nature provided by a hospital.
          </li>
          <li>
            Expenses incurred outside the geographical limits of Sri Lanka
          </li>
        </ol>
      </>
    )
  },
  {
    title: "+ What is Insureme.lk?",
    content: (
      <ol type="a">
        <li>
          InsureMe.lk is the web platform of InsureMe Insurance Brokers (Pvt)
          Ltd registered Under the Insurance Regulatory Commission of Sri Lanka
          (IRCSL). InsureMe is a company powered by Dialog.
        </li>
      </ol>
    )
  },
  {
    title: "+ What is the insurance company this cover? ",
    content: (
      <ol type="a">
        <li>Ceylinco General Insurance Ltd.</li>
      </ol>
    )
  }
];

class CiFAQs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={`modal fade}`}
        id="cifaqs-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={require("../../assets/insureme-dialog.png")}
                id="brand-logo-faq-modal"
                alt=""
              />
              <h5 className="modal-title">
                Frequently Asked Questions (FAQ’s)
              </h5>
              <button
                type="button"
                style={{marginTop: "-20px"}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ci-faqs-modal">
              {GENERAL_LIST.map((item, index) => {
                return (
                  <Collapse
                    key={`faq-${index}`}
                    index={`${index}`}
                    title={item.title}
                    content={item.content}
                    titleClass="ci-faq-collapse"
                    titleTextClass="ci-faq-collapse-title-text"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CiFAQs;
