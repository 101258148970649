import {Popover, OverlayTrigger} from "react-bootstrap";
import React, {Component} from "react";
import Select from "react-select";
import validate from "validate.js";

import "./AutoCompleteInput.scss";

export class AutoCompleteInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blurred: false,
      selectedOption: null
    };
  }

  render() {
    let defaultValue = null;
    let {
      tooltip = "",
      label,
      disabled,
      placeholder,
      options = [],
      value,
      errorText,
      validation
    } = this.props;

    if (value) {
      options.forEach(item => {
        if (item.value === value) {
          defaultValue = item;
        }
      });
    }

    if (!errorText && this.state.blurred && validation) {
      errorText = validate.single(defaultValue, validation);
      if (!errorText && validation.presence && !defaultValue) {
        errorText = " required";
      }
    }

    return (
      <div className={`form-group `}>
        <label> {label} </label>
        <Select
          id={this.props.id}
          value={defaultValue}
          defaultValue={defaultValue}
          isDisabled={disabled}
          onChange={this.props.onChange}
          className={`form-control-auto ${
            errorText ? "form-input-has-error" : ""
          }`}
          options={this.props.options}
          placeholder={placeholder}
          blurInputOnSelect={false}
          onBlur={() => this.setState({blurred: true})}
        />
        {errorText ? <p className="form-input-error">{errorText}</p> : null}
          {/* below commented code here */}
      </div>
    );
  }
}

// {tooltip ? (
//   <span>
//     <OverlayTrigger
//       trigger="click"
//       rootClose
//       placement="bottom"
//       overlay={
//         <Popover
//           id="popover-positioned-bottom"
//           className="tooltip-popover"
//           title={tooltip}
//         />
//       }
//     >
//       <button type="button" className="btn  nuclei-tooltip-text">
//         ?
//       </button>
//     </OverlayTrigger>
//   </span>
// ) : null}