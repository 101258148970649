import React, {Component, useRef} from "react";
import {InputText, Button, Spinner} from "../../../components";
import "./index.scss";
import {getInquiryCookie} from "../../../util";
import {validateWithValidations, isEmptyObjectValue} from "../../../util";
import {
  getFeatureCardData,
  postInquiry,
  clearErrors
} from "../../../redux/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import headerBanner from '../../../assets/sme-product/landing-image.png';
import sideImage from '../../../assets/sme-product/side-01.png';
import IconOne from '../../../assets/sme-product/ic-01.png';
import IconTwo from '../../../assets/sme-product/ic-02.png';
import IconThree from '../../../assets/sme-product/ic-03.png';
import IconFour from '../../../assets/sme-product/ic-04.png';


import {Figure, Card} from "react-bootstrap";

const validations = {
  cx_name: {presence: true},
  cx_company: {presence: true},
  cx_email: {presence: true, email: true},
  cx_mobile: {
    format: {
      pattern: "^[0-9]{9}",
      flags: "i",
      message: "mobile is not valid. ex: 947xxxxxxxx"
    },
    numericality: {
      notLessThan: "9",
      presence: true
    },
    presence: true,
    length: {
      is: 9
    }
  }
};


class SMEProduct extends Component {
  async componentDidMount() {}
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      cx_name: "",
      cx_email: "",
      cx_mobile: "",
      cx_company: "",
      cx_message: "",
      inquiry_type: "motor-promo",
      errorMessage: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async handleSubmit() {
    const error = validateWithValidations(this.state, validations);
    this.setState({error});
    const inqueryBody = {
      cx_name: this.state.cx_name,
      cx_email: this.state.cx_email,
      cx_company: this.state.cx_company,
      cx_mobile: this.state.cx_mobile,
      cx_message: 'Corporate Product SME',
      inquiry_code: "corporateprodsme",
      insurance_type: "corporate",
      media_code: "SME-CORP",
      inquiry_type: "inquiry",
      promo_code: "no-promo",
      email_subject_json: `InsureMe.lk | Corporate | Product SME | 94${
        this.state.cx_mobile
      }`,
      email_body_json: {
        cx_name: this.state.cx_name,
        cx_email: this.state.cx_email,
        cx_company: this.state.cx_company,
        cx_mobile: this.state.cx_mobile,
        inquiry_code: "corporateprodsme",
        inquiry_media: "SME-CORP",
        inquiry_type: "inquiry"
      },
      inquiry_media: "SME-CORP-WEB",
     };

    if (isEmptyObjectValue(error)) {
      this.setState({loading: true});
      await this.props.postInquiry(inqueryBody);
      this.setState({loading: false, isCompleted: true, flag: true});
    }
    this.props.clearErrors();
    this.inquiryStatus();
  }

  scrollToNode = (node) => {
      node.scrollIntoView({behavior: 'smooth'});
  }
  onChange = (type, e) => {
    const error = this.state.error;
    delete error[type];
    this.setState({errorMessage: ""});
    this.setState({[type]: e.target.value, error});
  };
  clearErrors() {
    this.setState({errorMessage: ""});
  }
  inquiryStatus() {
    const current_page = this.props.match.params.promo_type;
    const form_submissions = [{type: "sb"}];
    form_submissions.forEach((item, i) => {
      if (item.type === current_page) {
        if (localStorage.getItem(`cx_inquiry_id_${  current_page}`)) {
          this.setState({flagOne: true});
        }
      }
    });
  }
  render() {
    const {flag, flagOne} = this.state;
    // const promo = this.props.match.params.promo_type;
    // const isCompleted = getInquiryCookie().cx_inquiry_id;
    return (
      <div>
       
          <div className="container-fluid zero-padding">
            <div className="row">
              <div className="container" />
                <div className="banner-container">
                  <Figure>
                    <Figure.Image
                      width={'100%'}
                      height={'auto'}
                      src={headerBanner}
                    />
                    <Figure.Caption>
                      <button  onClick={ () => this.scrollToNode(this.quoteForm)} className="btn btn-light btn-lg get-quote" >Get a Quote</button>
                      <button onClick={ () => this.scrollToNode(this.moreInfo)}  className="btn btn-light btn-lg more-info" >More Info</button>
                      <h1 className="caption-title">Group Health Insurance for SMEs & Start-Ups exclusively from InsureMe</h1>
                    </Figure.Caption>
                  </Figure>   
                </div>
            {/* End of bannner container */}
            </div>

          <div className="col-md-8 col-sm-6 col-xs-12 zero-padding">
              <div className="col-md-8 col-sm-12 col-xs-12">
                <h1 className="text-primary header-title">Get the best Health Insurance Plans</h1>
                <ul>
                  <li className="list-item">Specially designed for Startups and SMEs</li>
                  <li className="list-item">Guaranteed best premiums and assured claims support</li>
                  <li className="list-item">Cover for employees and their family</li>
                  <li className="list-item">Hospitalization payment on Lump Sum or Daily Cash basis</li>
                  <li className="list-item">Many limit options to choose</li>
                </ul>
              </div>
    
          </div>

          <section key="quote-form" ref={(node) => this.quoteForm = node}>
          <div className="col-md-4 col-sm-6 col-xs-12 zero-padding">
            <div className="col-md-4 col-sm-12 col-xs-12 zero-padding" />
              {flag || flagOne ? (
                <label className="col-md-12 col-sm-10 col-xs-10 zero-padding quote-form">
                  Thank you for submitting. We will call you back.
                </label>
              ) : (
                <div className="col-md-12 col-sm-10 col-xs-10 zero-padding quote-form">
                  <h3>Get a quote</h3>
              
                  <InputText
                    className="form-control email-input"
                    id="name-input"
                    placeholder="Name"
                    type={"text"}
                    value={this.state.cx_name}
                    onChange={e => this.onChange("cx_name", e)}
                    validation={{presence: true}}
                    errorText={this.state.error.cx_name}
                  />

                  <InputText
                    className="form-control email-input"
                    id="company-input"
                    placeholder="Company Name"
                    type={"text"}
                    value={this.state.cx_company}
                    onChange={e => this.onChange("cx_company", e)}
                    validation={{presence: true}}
                    errorText={this.state.error.cx_company}
                  />
                
                  
                  <div className="col-md-2 col-sm-2 col-xs-2 zero-padding">
                    <InputText
                      className="form-control email-input"
                      id="mobile-input"
                      type={"text"}
                      value="94"
                      disabled="disabled"
                    />
                  </div>
                  <div className="col-md-10 col-sm-10 col-xs-10 zero-padding">
                    <InputText
                      className="form-control email-input"
                      id="mobile-input"
                      placeholder="Mobile"
                      type={"text"}
                      value={this.state.cx_mobile}
                      onChange={e => this.onChange("cx_mobile", e)}
                      validation={validations.cx_mobile}
                      errorText={this.state.error.cx_mobile}
                    />
                  </div>

                  <InputText
                    className="form-control email-input"
                    id="email-input"
                    placeholder="Email"
                    type={"text"}
                    value={this.state.cx_email}
                    onChange={e => this.onChange("cx_email", e)}
                    validation={validations.cx_email}
                    errorText={this.state.error.cx_email}
                  />

                  {this.state.loading ? (
                    <Spinner size="" />
                  ) : (
                    <Button
                      type="radius"
                      color="green"
                      className="nuclei-bottom-green-button "
                      onClick={e => this.handleSubmit(e)}
                    >
                      Get a Quote Now
                    </Button>
                  )}
                </div>
              )}
          </div>

          </section>
          </div>          
          <div className="row why-section" ref={(node) => this.moreInfo = node}>
            <div className="col-md-8 col-sm-12 col-xs-12">
              <h1 className="text-warning header-title">Why is Group Health Insurance important for SMEs and Start-Ups?</h1>
                   
            </div>
             <div className="col-md-12 col-sm-12">
              <p>
                Across the globe, all leading companies acknowledge that the biggest asset in their organization are its employees. Thus, organizations take various measures to ensure the safety and wellbeing of their employees.
                Providing adequate health and life insurance protection for the employees and their families is one such measure adopted by organziations as best practice around the world.
              </p> 
             </div>
          </div>

          <div className="row why-not-section">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h1 className="text-warning header-title">Why have most Startups and SMEs not insured their employees?</h1>
              <h4>Many Start-ups and SMEs have not obtained Health Insurance Plans for their employees due to many reasons. They are being,</h4>
                   
            </div>
             <div className="col-md-12 col-sm-12">
             
              <ol className="ordered-list">
                <li>
                 
                 <h3><span className="order-item">1</span> Cost</h3>
                  <p>Affordability could be an issue for most SMEs and Start-ups and with smaller number of emplolyees, the bargaining power for them is generally lower. Thus, they have to pay a relatively hiigher premium</p>
                </li>
                <li>
                
                  <h3><span className="order-item">2</span> Minimum number of employees</h3>
                  <p>Most Insurance Companies have minimum number of employees, generally 25-50 to provide Group Health Insurance packages. Since SMEs and Startups unable to fulfill this requirement, they may find it difficult to buy an attractive Group Health Insurance Plan.</p>
                </li>
                <li>
                
                  <h3><span className="order-item">3</span> Lack of awareness</h3>
                  <p>This is a common issue as most companies in this category do not have an adequate awareness of Health Insurance options that are available and the procedure to buy a Group Health Insurance for its employees. Most of the SME/Startup owners who purchase Group Health Insurance plans do so far the veri first time, and hence may not be aware of how this could be done.</p>
                </li>
              </ol>
              <p className="bold-para">
                Hence they rely heavily on an insurance company's recommendation with the intent of minimizing the cost of insurance, they unfortuately end up choosing an insurance design that does not provide adequate cover for its employees and their family members.
              </p>
             </div>
          </div>

          <div className="row what-section">
            <div className="col-md-8 col-sm-12 col-xs-12">
              <h1 className="text-warning header-title">What Health Insurance solutions does InsureMe offer the SMEs and Start-Ups segment?</h1>
                   
            </div>
             <div className="col-md-12 col-sm-12">
              <p>
                Most established companies offer Life and Health Insurance for their employees as a retention strategy and InsureMe handlers many such insurances including thos of leading Corporate entities. Moreover, InsureMe has introduced technology tools such as Mobile Apps to facilitate claims processing to enhance efficiency and convenience. Being an active insurance intermediary, InsureMe, using its experties, experience and taking into consideration the needs of this segment, has tied up with leading insurance companies to develop two affordable but specially designed plans for this market. 
              </p> 
             </div>
          </div>

          <div className="row advantages-section">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h1 className="text-warning header-title adv-sec-h1">Advantages of InsureMe Group Health Insurance</h1>
                   
            </div>
            <div className="col-sm-12 adv-content">
            <div className="col-md-3 col-sm-12">
              <Card style={{width: '30rem', height: '40rem'}}>
                <Card.Img variant="top" src={IconOne} className="top-img" />
                <Card.Body>
                  <h5>Varied Benefits</h5>
                  <p>
                    Our Group Health Insurance plans offer different options to suite your needs with no medical checkups for enrollment
                  </p>
                </Card.Body>
                
              </Card>
            </div>
             <div className="col-md-3 col-sm-12">
              <Card style={{width: '30rem', height: '40rem'}}>
                <Card.Img variant="top" src={IconTwo} />
                <Card.Body>
                  <h5>Lower Cost</h5>
                    <p>
                      The cost of these plans are much cheaper and affordable than if bought individually 
                    </p>
                  
                </Card.Body>
                 
              </Card>
             </div>
             <div className="col-md-3 col-sm-12">
              <Card style={{width: '30rem', height: '40rem'}}>
                <Card.Img variant="top" src={IconThree} />
                <Card.Body>
                  <h5>Customized Cover</h5>
                  <p>
                    Customize the cover to fit your budget by choosing the ideal plan and the limit. The cover could be extended to include employee's family members as well
                  </p>
                </Card.Body>
                
              </Card>
             </div>
             <div className="col-md-3 col-sm-12">
              <Card style={{width: '30rem', height: '40rem'}}>
              <Card.Img variant="top" src={IconFour} />
                <Card.Body>
                  <h5>Assurance of Claim Support</h5>
                  <p>
                   In addition to offering the best plans, We extended our fullest support in the event of a claim to ensure claims are settled expeditiously
                  </p>
                </Card.Body>
                
              </Card>
             </div>
            </div> 
          </div>

          <div className="row why-is-section">
            <div className="col-md-7 col-sm-12 col-xs-12">
              <h1 className="text-warning header-title"> Why is Group Health Insurance Plans for SMEs and Start-Ups from InsureMe</h1>
              <p>
                  These Health Insurance plans cover the group of people who work in the same organization. Our plans are carefully designed to pay for medical expenses during the hospitalized period on a daily cash basis or as lumpsum with payments for Personal Accident or Critical Illness also being featrues. The ability to choose the plans and limits based on affordability with the freedom to include family members makes the Group Health Insurance offered by us a proposition unique that non can match.
              </p>     
            </div>
             <div className="col-md-5 col-sm-12">
                  <img src={sideImage} className="" alt="" />
             </div>
          </div>

          <div className="row benefit-section">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h1 className="text-warning header-title" >What are the benefits offered under InsureMe Group Health Insurance Plans for SMEs and Start-UPs</h1>     
            </div>
             <div className="col-md-12 col-sm-12">
               <div className="col-sm-12 col-md-5 options-tab" style={{marginRight: '3%',paddingBottom: '2%'}}>
               <h5 style={{paddingTop: '3%'}} key="sec-last-h1" className="h5-sec-01"><div className="option badge-primary badge-pill">Option 01</div></h5>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Hospitalisation Insurance which offers lumpsum payment up to the purchased limit, to settle the hospital bills</li>
                  <li className="list-group-item">You can select different coverage levels from LKR 100,000/- per annum to LKR 500,000 per annum</li>
                  <li className="list-group-item">Free Life Insurance cover of 5 times the Health Insurance Cover Limit for employees</li>
                </ul>
               </div>
               <div className="col-sm-12 col-md-6 options-tab">
                <h5 style={{paddingTop: '3%'}}><div className="option badge-primary badge-pill">Option 02</div></h5>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Daily Cash Cover during hospitalization. A fixed amount will be paid for each day in hospital</li>
                  <li className="list-group-item">You can select different coverage levels from LKR 50,000/- per annum to LKR 450,000 per annum</li>
                  <li className="list-group-item">Personal Accident Cover from LKR 50,000 to LKR 500,000</li>
                  <li className="list-group-item">Lumpusum hospitalization payment for 24 Critical Illness</li>
                </ul>
               </div>

             </div> 
 
          </div>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.data,
    leads: state.lead
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFeatureCardData,
      postInquiry,
      clearErrors
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SMEProduct);
