import React, {Component} from "react";
import "./MotorBreadcrumb.scss";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
// import {isIterable} from "core-js";

let selectedIndex;

class MotorBreadcrumbR extends Component {
  render() {
    const {match, location, unq_hash} = this.props;
    const {insurance_type, vehicle_type} = match.params;
    // const isCover =
    //   "/motor/:vehicle_type/:insurance_type/Cover-Comparison" === match.path;
    const list = [
      {
        to: `/motor/${vehicle_type}/${insurance_type}/Quotation`,
        nextTo: `/motor/${vehicle_type}/${insurance_type}/Inquiry`,
        search: `?unq_hash=${unq_hash}`,
        title: "Quotation"
      },

      {
        to: `/motor/${vehicle_type}/${insurance_type}/Premium-Comparison`,
        nextTo: `/motor/${vehicle_type}/${insurance_type}/Cover-Comparison`,
        search: `?unq_hash=${unq_hash}`,
        title: "Comparison"
      }
    ];
    list.push({
      to: `/account`,
      search: `?unq_hash=${unq_hash}`,
      title: "Payment"
    });

    list.push({
      to: `/account1`,
      search: `?unq_hash=${unq_hash}`,
      title: "Complete"
    });

    return list.map((item, i) => {
      if (location.pathname === item.to) {
        selectedIndex = i;
      }

      if (selectedIndex > i) {
        return (
          <div className="breadcrumb-item" key={i}>
            <Link
              className={
                location.pathname === item.to || location.pathname === item.nextTo
                  ? ""
                  : ""
              }
              to={item.to + item.search}
            >
              {item.title}
            </Link>
            {i === list.length - 1 ? null : (
              <i className="fas fa-arrow-right" />
            )}
          </div>
        );
      }
      return (
        <div className="breadcrumb-item" key={i}>
          <a
            className={
              location.pathname === item.to || location.pathname === item.nextTo
                ? "active"
                : ""
            }
            to={item}
          >
            {item.title}
          </a>
          {i === list.length - 1 ? null : <i className="fas fa-arrow-right" />}
        </div>
      );
    });
  }
}
export default withRouter(MotorBreadcrumbR);
