import React, {Component} from "react";
import "./AutoCompleteInput.scss";
// import validate from "validate.js";
import Select from "react-select";
import {DateTime} from "../components";
import _ from "lodash";
const CURRENT_YEAR = new Date().getFullYear();
const monthOptions = [
  {value: "01", label: "January", type: "month"},
  {value: "02", label: "February", type: "month"},
  {value: "03", label: "March", type: "month"},
  {value: "04", label: "April", type: "month"},
  {value: "05", label: "May", type: "month"},
  {value: "06", label: "June", type: "month"},
  {value: "07", label: "July", type: "month"},
  {value: "08", label: "August", type: "month"},
  {value: "09", label: "September", type: "month"},
  {value: "10", label: "October", type: "month"},
  {value: "11", label: "November", type: "month"},
  {value: "12", label: "December", type: "month"}
];

export class MonthYear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blurred: false,
      selectedOptionDate: null,
      placeholdersYear: null,
      placeholdersDate: null,
      placeholdersMonth: null,
      selectedOptionMonth: null,
      selectedOptionYear: null,
      yearsList: [],
      date: []
    };
  }
  componentWillReceiveProps(props, nextProps) {
    if (props.value !== nextProps.value) {
      this.setState({the_date: nextProps.value});
    }
  }

  componentDidMount() {
    const newYears = [];

    if (this.props.minDate || this.props.maxDate) {
      const minYear = this.props.minDate.getFullYear();
      const maxYear = this.props.maxDate.getFullYear();

      if (maxYear < minYear) {
        // eslint-disable-next-line no-throw-literal
        throw "Max year should be grader than min Year";
      }

      let current = minYear;
      while (current <= maxYear) {
        newYears.push({value: current, label: current, type: "year"});
        current++;
      }
    } else {
      _.times(100, v => {
        newYears.push({
          value: CURRENT_YEAR - v,
          label: CURRENT_YEAR - v,
          type: "year"
        });
      });
    }
    this.setState({yearsList: newYears});
  }
  //identify the length of the month
  dateRender = label => {
    if (
      label === "January" ||
      label === "March" ||
      label === "May" ||
      label === "July" ||
      label === "August" ||
      label === "October" ||
      label === "December"
    ) {
      for (let i = 1; i <= 31; i++) {
        this.state.date.push({
          value: (`0${  i}`).slice(-2),
          label: (`0${  i}`).slice(-2)
        });
      }
    } else if (
      label === "April" ||
      label === "June" ||
      label === "September" ||
      label === "November"
    ) {
      for (let i = 1; i <= 30; i++) {
        this.state.date.push({
          value: (`0${  i}`).slice(-2),
          label: (`0${  i}`).slice(-2)
        });
      }
    } else if (label === "February") {
      if (this.state.longYear === 0) {
        for (let i = 1; i <= 29; i++) {
          this.state.date.push({
            value: (`0${  i}`).slice(-2),
            label: (`0${  i}`).slice(-2)
          });
        }
      } else {
        for (let i = 1; i <= 28; i++) {
          this.state.date.push({
            value: (`0${  i}`).slice(-2),
            label: (`0${  i}`).slice(-2)
          });
        }
      }
    }
  };

  date() {
    return (
      <DateTime
        value={this.state.selectedOptionDate}
        catogary={"Date"}
        onChange={e => {
          this.handleChange("Date", e);
        }}
        options={this.state.date}
        placeholder={
          this.state.placeholdersDate
            ? this.state.placeholdersDate
            : new Date().getDate()
        }
      />
    );
  }

  handleChange = async (whatType, selectedOption) => {
    if (whatType === "Month") {
      this.setState({selectedOptionMonth: selectedOption});
      await this.dateRender(selectedOption.label);
    } else if (whatType === "Year") {
      this.selectedOptionDate = [];
      await this.setState({selectedOptionYear: selectedOption});
    } else if (whatType === "Date") {
      await this.setState({selectedOptionDate: selectedOption});
    }
    const {
      selectedOptionMonth,
      selectedOptionYear,
      selectedOptionDate
    } = this.state;

    if (
      selectedOptionMonth &&
      selectedOptionYear &&
      selectedOptionDate &&
      this.props.onChange
    ) {
      this.props.onChange(
        `${selectedOptionYear.value}-${selectedOptionMonth.value}-${
          selectedOptionDate.value
        }`
      );
    }
  };
  getSplitedDate() {
    const the_date = this.state.the_date || this.props.value;

    if (!the_date) {
      return {};
    } else {
      const list = the_date.split("-");
      return {
        selectedOptionYear: {value: list[0], label: list[0]},
        selectedOptionMonth: _.find(monthOptions, {value: list[1]}),
        selectedOptionDate: {value: list[2], label: list[2]}
      };
    }
  }

  render() {
    const actual_date = this.getSplitedDate();
    var {
      selectedOptionDate,
      selectedOptionYear,
      placeholdersYear,
      selectedOptionMonth,
      placeholdersDate,
      placeholdersMonth
    } = this.state;
    let options = this.state.date;
    let {label, errorText, validation} = this.props;
    return (
      <div className={`form-group`}>
        <label className="nuclei-input-label">{label}</label>
        <form>
          <div className="col-md-3 col-sm-4 col-xs-12 zero-padding-left">
            <Select
              value={actual_date.selectedOptionYear || selectedOptionYear}
              catogary={"Year"}
              isDisabled={this.props.disabled}
              onChange={e => {
                this.handleChange("Year", e);
              }}
              className={`form-control-auto ${
                errorText ? "form-input-has-error" : ""
              }`}
              options={this.props.yearsList || this.state.yearsList}
              placeholder={
                placeholdersYear ? placeholdersYear : new Date().getFullYear()
              }
              validation={validation}
              onBlur={e => {
                this.setState({
                  blurred: true,
                  placeholdersYear: "Year"
                });
              }}
            />
            {errorText ? <p className="form-input-error">{errorText}</p> : null}
          </div>

          <div className="col-md-3 col-sm-4 col-xs-7 zero-padding-left">
            <Select
              value={actual_date.selectedOptionMonth || selectedOptionMonth}
              isDisabled={this.props.disabled}
              catogary={"Month"}
              onChange={e => {
                this.handleChange("Month", e);
              }}
              className={`form-control-auto ${
                errorText ? "form-input-has-error" : ""
              }`}
              options={monthOptions}
              placeholder={
                placeholdersMonth
                  ? placeholdersMonth
                  : monthOptions[new Date().getMonth()].label
              }
              validation={validation}
              onBlur={e => {
                this.setState({
                  blurred: true,
                  placeholdersMonth: "Month"
                });
              }}
            />
            {errorText ? <p className="form-input-error">{errorText}</p> : null}
          </div>
          <div className="col-md-2 col-sm-4 col-xs-5 zero-padding-left">
            {this.state.date.length === 0 ? (
              <Select
                isDisabled={this.props.disabled}
                value={actual_date.selectedOptionDate || selectedOptionDate}
                catogary={"Date"}
                onChange={e => {
                  this.handleChange("Date", e);
                }}
                className={`form-control-auto ${
                  errorText ? "form-input-has-error" : ""
                }`}
                options={options}
                placeholder={
                  placeholdersDate ? placeholdersDate : new Date().getDate()
                }
                validation={validation}
                onBlur={e => {
                  this.setState({
                    blurred: true,
                    placeholdersDate: "Date"
                  });
                }}
              />
            ) : (
              this.date()
            )}
            {errorText ? <p className="form-input-error">{errorText}</p> : null}
           {/* below commented code here */}
          </div>
        </form>
      </div>
    );
  }
}

// {tooltip ? (
//   <span>
//     <OverlayTrigger
//       trigger="click"
//       rootClose
//       placement="bottom"
//       overlay={
//         <Popover
//           id="popover-positioned-bottom"
//           className="tooltip-popover"
//           title={
//             typeof tooltip == "boolean"
//               ? "Please set tooltip title"
//               : tooltip
//           }
//         />
//       }
//     >
//       <button type="button" className="btn  nuclei-tooltip-text">
//         ?
//       </button>
//     </OverlayTrigger>
//   </span>
// ) : null}