import React, {Component} from "react";
import {
  InputText,
  AutoCompleteInput,
  ButtonGroup,
  Button,
  MonthYear,
  // Date,
  Alert,
  Spinner
} from "../../../components";
import {
  validateWithValidations,
  isEmptyObjectValue,
  formatDate,
  getInquiryCookie,
  getPromoTokenCookie
} from "../../../util";
import {TITLES, PROMO_FRIMI_STYLE_TOKEN} from "../../../config";
import {getInfoFromNIC} from "../../../util-nic";
import {
  getLocalItems,
  getInsureCompanies,
  generateOTP,
  verifyOTPCode,
  clearErrors,
  postInquiry
} from "../../../redux/actions";
import "./personal-info-form.scss";
import _ from "lodash";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import {getQueryParams} from "../../../history";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  getAuthToken,
  consumerLookup,
  userRegisterAndBuy,
  fetchPremiumsByUniqHash,
  updateCustomer
} from "../../../redux/actions";
import queryString from "query-string";

const validations = {
  name_initials: {
    format: {
      pattern: "^[a-zA-Z ]+$",
      flags: "i",
      message: "Name is not valid "
    },
    presence: true
  },
  nic_number: {
    format: {
      pattern: /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/m,
      flags: "i",
      message: "NIC is not valid "
    },

    // string: {
    //   pattern: /[0-9]{9}[x|X|v|V]$/g,
    //   message: 'Invalid NIC format'
    // },
    presence: true
    // length: {
    //   is: 10, 
    //   is: 12
    // }
  },
  passport_number: {
    presence: true
  },
  date_of_birth: {presence: true},
  renwal_date: {presence: true},
  existing_insurer: {presence: true},
  address_no: {
    // format: {
    //   pattern:"^[0-9a-zA-Z.\/ ]+$" ,
    //   flags: "i",
    //   message: "Invalid",
    // }
  },
  street_one: {
    // format: {
    //   pattern:"^[0-9a-zA-Z.\/ ]+$" ,
    //   flags: "i",
    //   message: "Invalid format",
    // },
    presence: true
  },
  street_two: {
    presence: false
    // format: {
    //   pattern:"^[0-9a-zA-Z ]+" ,
    //   flags: "i",
    //   message: "Invalid format",
    // }
  },
  city: {
    presence: true
    // format: {
    //   pattern:"^[0-9a-zA-Z ]+$" ,
    //   flags: "i",
    //   message: "Invalid format",
    // }
  },
  mobile_number: {
    presence: true,
    numericality: {
      pattern: "[0-9]{10,11}",
      notLessThan: "9",
      presence: true
    },
    length: {
      is: 9
    }
  },

  email: {presence: true, email: true}
};
const defaultFormData = {
  title: "Mr",
  name_initials: "",
  first_name: "",
  date_of_birth: "",
  date_of_renew: "",
  is_renewal: "0",
  nic_number: "",
  passport_number: "",
  mobile_number: "",
  email: "",
  home_number: "",
  address_no: "",
  street_one: "",
  street_two: "",
  street_three: "",
  city: "",
  country: "",
  occupation: "",
  employee_id: "",
  product_code: "motor-full",
  step: "1",
  identify_type: "nic_number",
  otp_media: "SMS",
  remarks: "",
  fk_company_id: ""
};

// const MessageArea = ({title})=>{
//     return (<h5 className="messageArea">{title}</h5>)
// }
class PersonalInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: defaultFormData,
      error: {},
      date: [],
      showEdits: false,
      otpCode: null,
      otpError: null,
      thirdParty: false
    };
    this.onChange = this.onChange.bind(this);
    this.resetForm = this.onChange.bind(this);

    this.promoCode = (queryString.parse(this.props.location.search).promo) ? queryString.parse(this.props.location.search).promo : 'no-promo';
    this.channel = (queryString.parse(this.props.location.search).channel) ? queryString.parse(this.props.location.search).channel : 'Web-Motor';

  }

  async componentDidMount() {
    await this.props.getLocalItems();
    const {premiumParams} = this.props.premium;
    const localValues = this.getFrimiLifestyleInfo(this.channel,this.promoCode, premiumParams);

    let formData = {...localValues, ...omitBy(this.state.formData, isEmpty)};
    formData = {...this.state.formData, ...formData};
    this.setState({formData: formData});

    await this.props.getAuthToken();
    await this.props.consumerLookup(formData.mobile_number);
    const {street_one, city, nic_number, address_no, street_two, date_of_birth, fk_company_id} = this.props.user && this.props.user.consumerDetails ?
        this.props.user.consumerDetails : {};
    let info = {}
    if (typeof nic_number !== 'undefined') {
     info = getInfoFromNIC(nic_number);}
    const setFormData = {
      nic_number: nic_number,
      street_one: street_one,
      city: city,
      address_no: address_no,
      street_two: street_two,
      date_of_birth: nic_number ? formatDate(info.dateOfBirth) : date_of_birth,
      fk_company_id: fk_company_id
    };

    formData = {...this.state.formData, ...setFormData};
    this.setState({formData: formData});
    await this.props.getInsureCompanies();
    if (typeof this.props.premium.premiumParams.insurance_type !== 'undefined') {
      if (this.props.premium.premiumParams.insurance_type === 'third-party') {
          this.setState({
              thirdParty: true
          });
      }
    } else {
        this.setState({
            thirdParty: true
        });
    }
  }

  getFrimiLifestyleInfo = (channel, promoCode, premiumParams) => {


    const local = JSON.parse(localStorage.getItem(PROMO_FRIMI_STYLE_TOKEN));

    let nameInitials = getInquiryCookie().cx_name || `${local.friMiLifeStyleCx.firstName} ${local.friMiLifeStyleCx.LastName}` || "";
    let mobileNumber = getInquiryCookie().cx_mobile || local.friMiLifeStyleCx.mobileNo.toString() || "";
    let email = getInquiryCookie().cx_email || ""

    const localInfo = {
      title: premiumParams.surname,
       name_initials: nameInitials,
        mobile_number: mobileNumber,
        email: email,
        is_promo: premiumParams.insurance_type === "full-insurance" ? "1" : "0"
    }

    return localInfo;

  }
  onChange(key, e) {
    this.props.clearErrors();
    const formData = this.state.formData;
    const error = this.state.error;
    delete error[key];
    if (e.target) {
      formData[key] = e.target.value;
    } else {
      formData[key] = e;
    }

    if (key === "nic_number") {
      delete error["date_of_birth"];
      try {
        const info = getInfoFromNIC(formData.nic_number);
        if (info.dateOfBirth && info.dateOfBirth !== "Invalid Date") {
          formData["date_of_birth"] = formatDate(info.dateOfBirth);
        }
      } catch (error) {}
    }
    if (key === "renwal_date") {
      delete error["renwal_date1"];
    }

    this.setState({formData, error});
  }

  validate() {
    const error = validateWithValidations(this.state.formData, validations);
    this.setState({error});
    return error;
  }

  async getOTPCode() {
    const error = _.pick(this.validate(), [
      "title",
      "name_initials",
      "mobile_number",
      "email"
    ]);
    if (isEmptyObjectValue(error)) {
      //await this.props.consumerLookup(`${this.state.formData.mobile_number}`);
      await this.props.generateOTP(this.state.formData);
    //   if (this.props.user.consumerDetails) {
    //     this.setState({
    //       formData: {
    //         ...this.state.formData,
    //  //       ...this.props.user.consumerDetails
    //       }
    //     });
    //     // this means this is user is already in our db..
    //     //await this.props.generateOTP(this.state.formData);
    //    } else {
    //     await this.props.generateOTP(this.state.formData);
    //   }
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const {premiumParams} = this.props.premium;
    const formData = this.state.formData;
    const error = this.validate();
    if (this.state.formData.is_renewal === "0") {
      // this is new
      delete error["renwal_date"];
      delete error["existing_insurer"];
    } else {
      if (new Date() < new Date(formData.renwal_date)) {
        delete error["renwal_date1"];
      } else {
        error["renwal_date1"] = "Enter a future date";
      }
    }

    if (formData.identify_type === "nic_number") {
      // this is new
      delete error["passport_number"];
    } else {
      delete error["nic_number"];
    }
    if (isEmptyObjectValue(error)) {
      const queryParams = getQueryParams(this.props.location.search);

      //TODO : Need to discuss
      // if (this.props.user.consumerDetails) {
      //   const customer_id = this.props.user.consumerDetails.customer_id;
      //   await this.props.updateCustomer({
      //     ...formData,
      //     customer_id: `${customer_id}`
      //   });
      //   if (
      //     this.props.user.errorMessage ===
      //     "Consumer data not updated. Please Contact InsureMe.lk"
      //   ) {
      //     this.props.clearErrors();
      //   }
      // }
      if (!this.props.user.errorMessage) {
        await this.props.userRegisterAndBuy({
          ...formData,
          unq_hash: queryParams.unq_hash,
          quote_number: queryParams.qot_number,
          is_promo: premiumParams.insurance_type === "full-insurance" ? "1" : "0",
          channel: this.channel,
          promoCode: this.promoCode,
          cover_type: premiumParams.insurance_type
        });
      }
    }
  }

  async verifyOTP() {
    this.setState({otpError: null});
    if (this.state.otpCode) {
      if (this.state.otpCode.length === 6) {
        await this.props.verifyOTPCode(
          this.state.otpCode,
          this.props.user.generatedOTP
        );
        this.setState({error: {}});
      } else {
        this.setState({otpError: "OTP code length is not valid"});
      }
    } else {
      this.setState({otpError: "OTP code is required"});
    }
  }

  renderExtra(company) {
    const {formData} = this.state;
    const isNew = formData.is_renewal === "0";
    // const invalidDate = formData.renwal_error;
    const isDisabled = !!this.props.user.consumerDetails;
    const error = {
      ...this.state.error,
      ...this.props.user.fieldErrors
    };
    if (!this.props.user.verifyPayload) {
      return null;
    }
    const selectedCompany = company.company_name;
    return (
      <div>
        {this.props.user.consumerDetails ? (
            <div className="col-md-11 col-sm-11 col-xs-12">
              <Alert
                  type="success"
                  message="Great! We found your information already. Please proceed to Next."
              />
            </div>
        ) : (
            <div className="col-md-11 col-sm-11 col-xs-12" hidden={!!this.state.thirdParty}>
              <Alert
                  type="info"
                  message="Sorry, We couldn't find you in our system. Please fill the information "
              />
            </div>
        )}
        {error.renwal_date1 ? (
          <Alert type="danger" message="Enter a future date as Renewal Date." />
        ) : null}
        {isNew ? null : (
          <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text">
            <label className="nuclei-input-label">Existing Insurer</label>

            <AutoCompleteInput
              type="text"
              className="form-control"
              placeholder={"Select existing insurer"}
              tooltip="Select your existing insurer"
              options={this.props.user.insureCompanies
                .filter(function(item) {
                  if (item.company_code === selectedCompany) {
                    return false;
                  }
                  return true;
                })
                .map(item => {
                  return {
                    label: item.company_name,
                    value: item.id
                  };
                })}
              value={formData.existing_insurer}
              errorText={error.existing_insurer}
              onChange={e => this.onChange("existing_insurer", e.value)}
              validation={validations.existing_insurer}
            />
          </div>
        )}

        {isNew ? null : (
          <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text ">
            <label className="nuclei-input-label">Renewal Date</label>
            <MonthYear
              value={formData.renwal_date}
              onChange={v => this.onChange("renwal_date", v)}
              tooltip="Renewal Date"
              errorText={error.renwal_date}
              minDate={new Date()}
              maxDate={new Date(`${new Date().getFullYear() + 10  }-10-10`)}
            />
          </div>
        )}

        <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text ">
          <label className="nuclei-input-label">NIC or Passport</label>

          <div className="col-md-3 col-sm-3 col-xs-12 zero-padding-left">
            <AutoCompleteInput
              type="text"
              className="form-control"
              placeholder={"identify_type"}
              options={[
                {label: "NIC", value: "nic_number"},
                {label: "Passport", value: "passport_number"}
              ]}
              disabled={
                this.props.user.consumerDetails
                  ? this.props.user.consumerDetails.nic_number === "" ||
                    this.props.user.consumerDetails.nic_number === null
                    ? ""
                    : isDisabled
                  : ""
              }
              value={formData.identify_type}
              onChange={e => this.onChange("identify_type", e.value)}
            />
          </div>
          <div className="col-md-8 col-sm-8 col-xs-12 zero-padding-left">
            <InputText
              disabled={
                this.props.user.consumerDetails
                  ? this.props.user.consumerDetails.nic_number === "" ||
                    getPromoTokenCookie(PROMO_FRIMI_STYLE_TOKEN).friMiLifeStyleCx !== 'undefined' ? "" : getPromoTokenCookie(PROMO_FRIMI_STYLE_TOKEN).friMiLifeStyleCx.nic ||
                    this.props.user.consumerDetails.nic_number === null
                    ? ""
                    : isDisabled
                  : ""
              }
              tooltip={
                formData.identify_type === "nic_number"
                  ? "NIC Number"
                  : "Passport Number"
              }
              placeholder={
                formData.identify_type === "nic_number"
                  ? "NIC Number"
                  : "Passport Number"
              }
              value={
                formData.identify_type === "nic_number"
                  ? this.state.formData.nic_number
                  : formData.passport_number
              }
              errorText={
                formData.identify_type === "nic_number"
                  ? this.state.error.nic_number
                  : this.state.error.passport_number
              }
              onChange={e =>
                this.onChange(
                  formData.identify_type === "nic_number"
                    ? "nic_number"
                    : "passport_number",
                  e
                )
              }
              validation={
                formData.identify_type === "nic_number"
                  ? validations.nic_number
                  : validations.passport_number
              }
            />
          </div>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text ">
          <label className="nuclei-input-label">Date of Birth </label>

          <MonthYear
            disabled={isDisabled}
            value={this.state.formData.date_of_birth}
            onChange={v => this.onChange("date_of_birth", v)}
            tooltip="DOB"
            disabled={formData.identify_type === "nic_number"}
            errorText={error.date_of_birth}
          />
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text">
          <label className="nuclei-input-label">Address</label>

          <div className="col-md-3 col-sm-3 col-xs-4 zero-padding-left">
            <InputText
              placeholder="No"
              type={"text"}
              disabled=""
              value={this.state.formData.address_no}
              errorText={error.address_no}
              onChange={e => this.onChange("address_no", e)}
              validation={validations.address_no}
            />
          </div>

          <div className="col-md-7 col-sm-7 col-xs-7 zero-padding-left">
            <InputText
              tooltip="Your Street Address"
              placeholder="Street"
              type={"text"}
              disabled=""
              value={this.state.formData.street_one}
              errorText={error.street_one}
              onChange={e => this.onChange("street_one", e)}
              validation={validations.street_one}
            />
          </div>

          <div className="col-md-10 col-sm-11 col-xs-11 zero-padding-left ">
            <InputText
              placeholder="Street"
              type={"text"}
              disabled=""
              value={this.state.formData.street_two}
              errorText={error.street_two}
              onChange={e => this.onChange("street_two", e)}
              validation={validations.street_two}
            />
          </div>

          <div className="col-md-10 col-sm-11 col-xs-11 zero-padding-left ">
            <InputText
              placeholder="City"
              type={"text"}
              disabled=""
              value={this.state.formData.city}
              onChange={e => this.onChange("city", e)}
              validation={validations.city}
            />
          </div>

          <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text">
            <div className="col-md-12 col-sm-12 zero-padding-left">
              <div className=" nuclei-sub-button">
                <Button
                  type="radius"
                  color="red"
                  className="nuclei-bottom-red-button "
                  onClick={e => this.handleSubmit(e)}
                >
                  Upload Document
                </Button>
                {/* <Button
                    type="radius"
                    color="gray"
                    className="nuclei-bottom-gray-button "
                    onClick={e => this.resetForm(e)}
                  >
                    Clear
                  </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  resetForm(e) {
    e.preventDefault();

    this.setState({formData: defaultFormData, error: {}});
  }

  render() {
    const {formData} = this.state;
    // const isNew = formData.is_renewal === "0";
    const {generatedOTP} = this.props.user;
    const isDisabled =
      this.props.user.consumerDetails || this.props.user.otpSteps.lookupDone
        ? true
        : false;
    const error = {
      ...this.state.error,
      ...this.props.user.fieldErrors
    };
    return (
      <div className="personal-container">
        <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text ">
          <ButtonGroup
            options={[
              {value: "0", label: "New Insurance"},
              {value: "1", label: "Renew Insurance"}
            ]}
            id="product_buying_type"
            selected={formData.is_renewal}
            onChange={(id, v) => this.onChange("is_renewal", v)}
          />
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text ">
          <label className="nuclei-input-label">
            Title and Name of Registered Owner
          </label>

          <div className="col-md-3 col-sm-3 col-xs-12 zero-padding-left">
            <AutoCompleteInput
              type="text"
              disabled=""
              className="form-control"
              placeholder={"Mr."}
              options={TITLES}
              value={formData.title}
              onChange={e => this.onChange("title", e.value)}
            />
          </div>
          <div className="col-md-8 col-sm-8 col-xs-12 zero-padding-left">
            <InputText
              tooltip="Name with initials"
              placeholder="Name with initials"
              type={"text"}
              disabled={isDisabled}
              value={formData.name_initials}
              onChange={e => this.onChange("name_initials", e)}
              validation={validations.name_initials}
              errorText={error.name_initials}
            />
          </div>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text">
          <label className="nuclei-input-label">Mobile</label>

          <div className="col-md-3 col-sm-3 col-xs-4 zero-padding-left">
            <InputText disabled={true} value={"94"} />
          </div>
          <div className="col-md-8 col-sm-8 col-xs-8  zero-padding-left">
            <InputText
              disabled={isDisabled}
              tooltip="Your mobile number"
              placeholder="Mobile Number"
              type={"text"}
              value={formData.mobile_number}
              errorText={error.mobile_number}
              onChange={e => this.onChange("mobile_number", e)}
              validation={validations.mobile_number}
            />
          </div>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text">
          <label className="nuclei-input-label">Email</label>

          <div className="col-md-11 col-sm-11 col-xs-12 zero-padding-left">
            <InputText
              disabled=""
              tooltip="Your email Address"
              placeholder="Email Address"
              type={"text"}
              value={formData.email}
              onChange={e => this.onChange("email", e)}
              errorText={error.email}
              validation={validations.email}
            />
          </div>
        </div>

        {this.renderExtra(this.props.company)}
        {!generatedOTP ? (
          <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text">
            <br />
            <br />
            <br />
            <br />
            <label className="nuclei-input-label">Validate Your Identify</label>
            <div className="col-md-4 col-sm-4 col-xs-6 zero-padding-left">
              <AutoCompleteInput
                type="text"
                className="form-control"
                placeholder={"Validate via"}
                options={[{label: "Via SMS", value: "SMS"}]}
                value={formData.otp_media}
                onChange={e => this.onChange("otp_media", e.value)}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-xs-6 zero-padding-left">
              {!this.props.user.loading ? (
                <div className=" nuclei-sub-button" style={{marginTop: 9}}>
                  <Button
                    type="radius"
                    color="red"
                    className="nuclei-bottom-red-button "
                    onClick={e => this.getOTPCode(e)}
                  >
                    Get me OTP
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {generatedOTP &&

        !this.props.user.verifyPayload ? (
          <div className="col-md-12 col-sm-12 col-xs-12 form-group input-text">
            <br />
            <br />
            <br />
            <br />
            <label className="nuclei-input-label">
              {this.props.user.consumerDetails
                ? "You are already in our system. "
                : ""}
              We have sent an OTP. Please verify
            </label>
            <div className="col-md-4 col-sm-4 col-xs-6 zero-padding-left">
              <InputText
                placeholder="OTP Code"
                type={"text"}
                value={this.state.otpCode}
                onChange={e => this.setState({otpCode: e.target.value})}
                errorText={this.state.otpError}
                validation={{presence: true}}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-xs-6 zero-padding-left">
              <Button
                type="clear"
                color="gray"
                className="nuclei-bottom-gray-button"
                onClick={e => this.getOTPCode(e)}
                style={{marginTop: 13}}
              >
                Resend OTP
              </Button>
            </div>

            <div className="col-md-12 col-sm-12 col-xs-12 zero-padding-left">
              {this.props.user.otpVerifyError ? (
                <Alert
                  message={this.props.user.otpVerifyError}
                  type="danger"
                  onClick={() => this.props.clearErrors()}
                />
              ) : null}
              <div className=" nuclei-sub-button" style={{marginTop: 13}}>
                <Button
                  type="radius"
                  color="red"
                  className="nuclei-bottom-red-button "
                  onClick={e => this.verifyOTP(e)}
                >
                  Verify OTP
                </Button>
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-md-4 col-sm-4 col-xs-4 zero-padding-left">
          {!this.props.user.loading ? null : <Spinner size="small" />}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
    premium: state.premium,
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  {
    getAuthToken,
    getLocalItems,
    consumerLookup,
    userRegisterAndBuy,
    fetchPremiumsByUniqHash,
    getInsureCompanies,
    generateOTP,
    verifyOTPCode,
    clearErrors,
    postInquiry,
    updateCustomer
  }
)(withRouter(PersonalInfoForm));
