import React, { useState } from "react";
import {FileInput} from "../../components/FileInput";
import {Button, Spinner} from "../../components";
import { groupBy, isEmpty } from 'lodash';
import {Modal} from "antd";

export function Documents({
                              uploading,
                              documents,
                              hashCode,
                              removeDocs,
                              uploadDocs,
                              availableSubmit,
                              onSubmitForReview,
                              wording,
                              coverType,
                              acknowledgment
                          }) {
    const renderSingleDocs = (docs, hashCode) => {
        return (
            docs.map((doc, i) => {
                let item = docs[i];
                if (item.doc_id == 12) {
                    item.title = "NIC/Passport Front"
                } else if (item.doc_id == 13) {
                    item.title = "NIC/Passport Back"
                }
                return (
                    <div className="dropzone-content col-12 col-sm-6 col-md-3 col-lg-3 xl-2 justify-content-around">
                        <div className={'dropzone-files'}>
                            <FileInput
                                key={i}
                                hash_code={hashCode}
                                id={item.id}
                                title={item.title}
                                icon={item.icon}
                                status={item.status}
                                allowedFileTypes={item.allowedTypes}
                                onPressRemove={(id) => removeDocs(id)}
                                onPressUpload={(data) => uploadDocs(data)}
                                uploadedURL={item.uploadedURL}
                            />
                        </div>
                    </div>
                )
            })
        )
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {setShow(true)}

    const filterDocs = (docs) => {
        let multipleDocs = docs.filter(doc=>doc.is_multiple && doc.is_multiple==='1');
        const singleDocs =  docs.filter(function(doc) {
            return !multipleDocs.find(function(multipleDoc) {
                return doc.id === multipleDoc.id
            })
        })
        multipleDocs = multipleDocs? groupBy(multipleDocs, function(multipleDoc) {
            return multipleDoc.title;
        }):null;
        return ({singleDocs, multipleDocs})
    }

    const renderDocs = (docs, hashCode) =>{
        const preview = [];
        const filteredDocs = filterDocs(docs);
        if(filteredDocs && filteredDocs.singleDocs && filteredDocs.singleDocs.length>0) preview.push(<div className={'row'}>{renderSingleDocs(filteredDocs.singleDocs, hashCode)}</div>);
        if(filteredDocs && filteredDocs.multipleDocs)  {
            for (const key in filteredDocs.multipleDocs) {
                preview.push(
                    <>
                        <div className={'multiple-doc-title'}>{key}</div>
                        {renderSingleDocs(filteredDocs.multipleDocs[key], hashCode)}
                    </>
                )
            }
        }
        return preview;
    }

    const renderAcknowledgment = () => {
        return (
            <div>
                <Modal width={1200} style={{position: "absolute"}} visible={show} onOk={onSubmitForReview} onCancel={handleClose}>
                    <div style={{fontSize: '15px'}}>
                        <h3><b>Acknowledgment</b></h3>
                        <b>I certify that the above particulars are true and I have not suppressed or misstated any material fact. I undertake that the
                            vehicle to be insured shall not be driven by any person who to my knowledge has been refused any motor vehicle insurance or
                            continuance thereof I hereby agree that this declaration shall be held to promissory and shall form the basis of the contact between
                            me and the Insurer.</b>
                    </div>
                </Modal>
            </div>
        )
    }

    return (
        <div className="col-lg-12 col-md-12">
            {renderAcknowledgment()}
            <Spinner spinning={uploading}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <h2><b>Upload Documents</b></h2>
                    </div>
                </div>
                <div className={'container-fluid'} >
                    <div className={'row'}>
                        {renderDocs(documents, hashCode)}
                    </div>
                </div>
                {availableSubmit ? (
                    <div className="row">
                        <Button
                            color="red"
                            className="nuclei-bottom-red-button"
                            onClick={() => (!(acknowledgment) || coverType === 'Comprehensive') ? onSubmitForReview() : handleShow()}>
                            {isEmpty(wording) ? "Submit For Review" : "Proceed To Payment"}
                        </Button>
                    </div>
                ) : null}
            </Spinner>
        </div>

    )
}
