import React, {Component} from "react";
import ReactDOM from "react-dom";
import "./index.scss";

export default class RaffleDraw extends Component {
  render() {
    return (
      <iframe id="rafle-draw" src="https://sevenmediagroup.com/raffle-draw/winners/" marginheight="0" allowFullScreen="true" frameborder="0" width="100%" height="1200px" scrolling="no" />
    );
  }
}
