import axios from "axios";
import {
  AUTH_API,
  AUTH_USER_NAME,
  AUTH_PWD,
  API_KEY,
  AUTH_KEY_COOKIE
  } from "../../config";
import {hasAuthError} from "../../util";
import Cookies from "js-cookie";

const GET_TOKEN = "GET_TOKEN";
const GET_TOKEN_DONE = "GET_TOKEN_DONE";
const GET_TOKEN_FAILED = "GET_TOKEN_FAILED";
const CLEAR_ERRORS = "CLEAR_ERRORS";

const INIT_STATE = {
  loading: false,
  errorMessage: null
};

export default function reducer(state = INIT_STATE, action) {
  const {type, payload} = action;
  switch (type) {
    case CLEAR_ERRORS:
      return {...state, errorMessage: false};
    case GET_TOKEN:
      return {...state, loading: false, errorMessage: null};
    case GET_TOKEN_DONE:
      return {...state, loading: false};
    case GET_TOKEN_FAILED:
      return {...state, loading: false, errorMessage: payload};
    default:
      return state;
  }
}

export const getAuthToken = () => async dispatch => {
  const savedCookie = Cookies.get(AUTH_KEY_COOKIE);
  if (savedCookie) {
    axios.defaults.headers.common["auth_key"] = savedCookie;
    return null;
  }

  try {
    dispatch({type: GET_TOKEN});
    const res = await axios({
      method: "post",
      url: `${AUTH_API}/v2/token`,
      headers: {
        api_key: API_KEY
      },
      data: {
        user_name: AUTH_USER_NAME,
        password: AUTH_PWD
      }
    });
    const error = hasAuthError(res);
    if (error) {
      return dispatch({type: GET_TOKEN_FAILED, payload: error});
    }
    const auth_key = res.data.data.auth_key;
    axios.defaults.headers.common["auth_key"] = auth_key;
    Cookies.set(AUTH_KEY_COOKIE, auth_key, {
      expires: 1 / 48 // avilable for 30 mins
    });
    dispatch({type: GET_TOKEN_DONE, payload: auth_key});
  } catch (error) {
    return dispatch({type: GET_TOKEN_FAILED, payload: false});
  }
};


