const validations = {
    OTPcode: {
        presence: true,
        numericality: true,
        length: {
            is: 6,
            message: "OTP code must have 6 numbers"
        },
    },
    mobileNumber: {
        presence: true,
        numericality: true,
        length: {
            is: 10,
            message: "Mobile number must have 10 numbers"
        },
        format: {
            pattern: /^[0][7].*$/,
            message: "Mobile number should start with 07"
        }
    },
    fullName:{
        presence: true,
        format:{
            pattern: "[a-z ]+",
            flags: "i",
            message: "can only contain a-z"
        },
        length:{
            minimum:5
        }
    },
    nicOrPassportNumber:{
        presence: true,
        length:{
            minimum:5
        }
    },
    address:{
        presence: true,
        length:{
            minimum:5
        }
    },
};

export default validations;
