import React from "react";
import "./PaymentBlock.scss";

export const PaymentBlock = props => {
  let {
    paymentBlock = [],
    // styleCardName = {},
    // styleText = {},
    // className = "",
    // active = false
  } = props;

  return (
    <div>
      <div className="paymentContainer">
        {/* <div className = "paymentBlock"> */}
        {paymentBlock.map((paymentBlock, i) => {
          return paymentBlock.imgOneUrl && paymentBlock.imgTwoUrl ? (
            <div className="image-block">
              <img src={paymentBlock.imgOneUrl} className="method-logo2" />
              <img
                src={paymentBlock.imgTwoUrl}
                className="method-logo2 hidden-xs"
              />
            </div>
          ) : (
            <div className="paymentBlock">
              <div className="image-block-detailed">
                <img src={paymentBlock.imgOneUrl} className="method-logo2" />
              </div>
              <div className="block-details hidden-xs">
                <p id="card-head" style={paymentBlock.styleCardName}>
                  {paymentBlock.cardName}{" "}
                </p>
                {paymentBlock.discount ? (
                  <p style={paymentBlock.styleText}>
                    {paymentBlock.discount}% OFF for {paymentBlock.bankName}{" "}
                    Card Holders{" "}
                  </p>
                ) : null}
              </div>
            </div>
          );
        })}
        {/* {imgOneUrl && imgTwoUrl ? (
          <div className = "image-block">
            <img src={imgOneUrl} className = "method-logo2"/>
            <img src={imgTwoUrl}  className = "method-logo2 hidden-xs"/>
          </div>
        ) : (
            <div className = "paymentBlock">
              <div className="image-block-detailed">
                <img src={imgOneUrl} className="method-logo2" />               
              </div>
              <div className="block-details">
                <h4>{cardName} </h4>
                <p>{bankName}</p>
                <p>{discount}</p>              
              </div>

            </div>

        )} */}
      </div>
    </div>
  );
};

export default PaymentBlock;
