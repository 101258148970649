import React from "react";
import "./BlogImage.scss";

import {Link} from "react-router-dom";

export const BlogImage = props => {
  let {
    className = "",
    type = "square",
    width,
    height,
    imgUrl = "",
    imgLink = ""
  } = props;

  return (
    <div>
      {imgLink ? (
        <Link to={imgLink}>
          <img
            src={imgUrl}
            alt="..."
            width={width}
            height={height}
            className={`nuclei-blogImage blogImage-${type} ${className} `}
          />
        </Link>
      ) : (
        <img
          src={imgUrl}
          alt="..."
          width={width}
          height={height}
          className={`nuclei-blogImage blogImage-${type} ${className} `}
        />
      )}
    </div>
  );
};

export default BlogImage;
