import React, {Component} from "react";
import {connect} from "react-redux";
import "./classic-quote.scss";
import {Alert, Spinner} from "../../../components";
import HeaderTitle from "../../../components/HeaderTitle";
import {
  getAuthToken,
  vehicleTypes,
  clearErrors,
  getVUsages,
  getNCBYears,
  getFuelTypes,
  getManufactureYears,
  getVMakes,
  getVModels,
  setLocalItem,
  getLocalItems,
  clearAll
} from "../../../redux/actions";
import ClassicQuoteForm from "./classic-quote-form";
import MotorBreadcrumb from "..//MotorBreadcrumb";

class ClassicQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFull: true
    };
  }

  async componentDidMount() {
    await this.props.getAuthToken();
  }

  render() {
    return (
      <div>
        <MotorBreadcrumb />
        <div className="container insureme-form zero-padding">
          <Alert
            type="danger"
            message={
              this.props.auth.errorMessage ||
              this.props.premium.errorMessage ||
              this.props.motor.errorMessage
            }
            onClick={() => this.props.clearErrors()}
          />
          <div className="row nuclei-motor-quotation">
            <HeaderTitle
              mainFirstWord="Insurance"
              restOfTitle="Detail"
              headerStyle={{fontWeight: "bold"}}
              headerIcon={true}
            />

            <Spinner
              style={{margin: `10px auto`}}
              spinning={this.props.motor.loading}
            />

            <div className="nuclei-motor-quotation-header">
              <ClassicQuoteForm show={true} noHash />
              <div
                className={`zero-padding-left col-sm-12 col-xs-12 col-md-12`}
              >
                {" "}
                <Spinner
                  spinning={this.props.premium.loading}
                  style={{margin: `10px auto`}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
    motor: state.motor,
    premium: state.premium
  };
}
export default connect(
  mapStateToProps,
  {
    getAuthToken,
    vehicleTypes,
    getVUsages,
    getNCBYears,
    getFuelTypes,
    getManufactureYears,
    getVMakes,
    getVModels,
    setLocalItem,
    getLocalItems,
    clearAll,
    clearErrors
  }
)(ClassicQuote);
