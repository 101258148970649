/* eslint-disable no-useless-concat */
import validate from "validate.js";
import Cookies from "js-cookie";
import {INQUIRY_COOKIE} from "./config";
import _isString from "lodash/isString";

export function placeParams(pathRegex, params) {
  var segments = pathRegex.replace(/\?/gi, "").split("/");

  return segments
    .map(segment => {
      var offset = segment.indexOf(":") + 1;
      if (!offset) return segment;

      var key = segment.slice(offset);
      return params[key];
    })
    .join("/");
}

export function leadAuthError(response) {
  if (response.data && response.data.success === false) {
    return response.data.error.message;
  }
  return false;
}

export function keyOBjects2Array(object) {
  const arr = [];
  for (var key in object) {
    arr.push(object[key]);
  }
  return arr;
}

export function hasAuthError(response) {
  if (response.data && response.data.status === 800) {
    if (response.data.error_format === 0) {
      return response.data.error.message;
    }
    if (
      response.data.error_format === 1 &&
      _isString(response.data.error.errors)
    ) {
      return response.data.error.errors;
    }
  }
  // {"status":800,"error_format":1,"error":{"message":"Missing Parameters","code":1002,"errors":"Vehicle details does not match our system records(e.g : Vehicle Type\/Make\/Model)"}}
  return false;
}

export function hasFieldErrors(response) {
  if (response.data && response.data.status === 1002) {
    if (
      response.data.error_format === 1 &&
      !_isString(response.data.error.errors)
    ) {
      return response.data.error.errors;
    }
  }
  if (response.data && response.data.status === 800) {
    if (
      response.data.error_format === 1 &&
      !_isString(response.data.error.errors)
    ) {
      return response.data.error.errors;
    }
  }
  // {"status":800,"error_format":1,"error":{"message":"Missing Parameters","code":1002,"errors":"Vehicle details does not match our system records(e.g : Vehicle Type\/Make\/Model)"}}
  return false;
}

export function hasMultipleRecords(response) {
  if (response.data.status === 800) {
    return true;
  }
}

export function missingParameters(response) {
  if (response.data.status === 800) {
    return response.data.error.errors.error.lease_exp_date;
  }
}

export function invalidRegNumber(response) {
  if (response.data.status === 800) {
    return response.data.error.errors.error.veh_registration_no;
  }
}

export function missingPara(response) {
  if (response.data.status === 800) {
    return response.data.error.message;
  }
}

//{"status":800,"error_format":1,"error":{"message":"Missing Parameters","code":1002,"errors":{"vehicle_make":"The Vehicle Make field is required.","vehicle_model":"The Vehicle Model field is required."}}}
export function getCommonError(errorResponse) {
  const errorData = errorResponse;
  if (typeof errorData === "string") {
    return errorData;
  }

  if (typeof errorData.message === "string") {
    return errorData.message;
  }

  if (!errorData.message && errorData.status) {
    if (errorData.status === 400) {
      return "inputs may not valid";
    } else if (errorData.status === 401) {
      return "Unauthorized request";
    } else if (errorData.status === 403) {
      return "Forbidden method..";
    } else if (errorData.status === 404) {
      return "Not found";
    } else if (405) {
      return "request is not allowed";
    } else if (errorData.status === 500) {
      return "internal server error";
    } else {
      return "code:32KDSS something went wrong";
    }
  }

  return "code:KIS73 something went wrong";
}

export function convertToSlug(str = "") {
  return str.replace(/\s/g, "-");
}

export function convertToWord(str = "") {
  return str.replace(/[-]/g, " ");
}

export function objectToLabelValue(object) {
  const arr = [];
  for (var key in object) {
    arr.push({value: key, label: object[key]});
  }
  return arr;
}

export function validateWithValidations(values, validations) {
  const error = {};
  for (var key in validations) {
    error[key] = validate.single(values[key], validations[key]);
    if (validations[key].presence === false && !values[key]) {
      error[key] = null;
    }
    if (!error[key] && validations[key].presence && !values[key]) {
      error[key] = "required";
    }
  }
  return error;
}

export function isEmptyObjectValue(object) {
  let isEmpty = true;
  for (var key in object) {
    if (object[key]) {
      isEmpty = false;
    }
  }
  return isEmpty;
}

export function debounce(func, wait, immediate) {
  var timeout;
  return () => {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function addCommas(nStr = "") {
  nStr = nStr.toString().replace(/,/g, "");
  nStr += "";
  let x = nStr.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? `.${  x[1]}` : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}

export function formatDate(date) {
  var d = new Date(date),
    month = `${  d.getMonth() + 1}`,
    day = `${  d.getDate()}`,
    year = d.getFullYear();

  if (month.length < 2) month = `0${  month}`;
  if (day.length < 2) day = `0${  day}`;

  return [year, month, day].join("-");
}

export function saveOnCookie(id, value) {
  Cookies.set(id, value, {
    expires: 1 / 24 // valid for 24 hours
  });
  return getFromCookie(id);
}

export function getFromCookie(id) {
  return Cookies.get(id) || null;
}

export function getInquiryCookie() {
  const result = {
    cx_name: "",
    cx_mobile: "",
    cx_email: "",
    cx_inquiry_id: ""
  };
  try {
    const v = JSON.parse(getFromCookie(INQUIRY_COOKIE));
    return {
      ...result,
      ...v
    };
  } catch (error) {
    return result;
  }
}

export function setInquiryCookie(inquiry) {
  try {
    const v = JSON.stringify(inquiry);
    saveOnCookie(INQUIRY_COOKIE, v);
  } catch (error) {}
}

export function setPromoTokenCookie(promoType, info) {
  try {
    const v = JSON.stringify(info);
    saveOnCookie(promoType, v);
  } catch (error) {}
}

export function getPromoTokenCookie(promoType) {
  const result = {
    cx_name: "",
    cx_mobile: "",
    cx_email: "",
    cx_inquiry_id: ""
  };
  try {
    const v = JSON.parse(getFromCookie(promoType));
    return {
      ...result,
      ...v
    };
  } catch (error) {
    return result;
  }
}

export const formatNumber = (input, enLongSuffix = false, prefix = "") => {
  let ranges = [
    {divider: 1000000, suffix: 'M', lSuffix: 'Million',},
    {divider: 1000, suffix: 'k'},
    {divider: 100, suffix: 'h'},
    {divider: 10, suffix: 'da'},
  ];
  for (let i in ranges) {
    let range = ranges[i],
        answer = input / range.divider;
    if (answer >= 1 && answer < 10) {
      if (enLongSuffix !== undefined && enLongSuffix === true && range['lSuffix'] !== undefined) {
        return `${prefix + numberWithCommas(answer.toString())  } ${  range.lSuffix}`;
      }
      return `${prefix + numberWithCommas(answer.toString())  } ${  range.suffix}`;
    }
  }
  return prefix + numberWithCommas(input.toString());
};

export function numberWithCommas(x) {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
//cron job for clear local storage
var cron = require("node-schedule");
var rule = new cron.RecurrenceRule();
rule.dayOfWeek = [0, 1, 2, 3, 4, 5, 6];
rule.hour = 1;
rule.minute = 10;
// cron.scheduleJob(rule, function(){
//   localStorage.clear();
// });

cron.scheduleJob("0 0 */1 * *", function() {
  localStorage.clear();
});

export function validateEmail(mail) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    return (false)
}
