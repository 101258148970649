import React, {Component} from "react";
import "./CriticalIllness.scss";
import {ASSETS_URL} from "../../config";
import ReactLoading from "react-loading";
import DeclarationButton from "./DeclarationButton";
import {MI_INME_CHANNEL} from '../../config';
import {validateEmail} from '../../util';

class DeclarationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      acceptedDeclarations: [],
      enableBuyCover: false,
      acceptDeclarations: {},
      activeHolder: 0,
      agentEmail: null,
      isAgentEmailValid: false
    };
  }

  renderItem = () => {

    const {channel, checkedCompanies} = this.props;
    const {activeHolder} = this.state;

    const holder = checkedCompanies[activeHolder].holder;
    const checkedCompany = checkedCompanies[activeHolder].holder.checkedCompany;

    let acceptDeclarations = this.state.acceptDeclarations;
    return checkedCompany.map((company, i) => {
      let agreed = null;
      
      if (acceptDeclarations[holder.id] !== undefined && acceptDeclarations[holder.id][company.id] !== undefined) {
         agreed = acceptDeclarations[holder.id][company.id]['agree'];
        if(Object.keys(acceptDeclarations[holder.id][company.id]['declarations']).length < company.declarations.length) {
          agreed = false;
        }

        
      }

      return (
        <div key={i}>
          <div className="company-container">
            <div className="logo-container">
              <img src={ASSETS_URL + company.company_logo} alt="" />
            </div>
            <div className="company-title">
              <p>{company.name}</p>
            </div>
          </div>
          <div className="cover-title">
            <p>The personal health declaration of the person to be insured</p>
          </div>
          {company.declarations.map((declarations, i) => {
            let clickedBtn = null;
            let selectedAilments = [];
            if (acceptDeclarations[holder.id] !== undefined && 
                acceptDeclarations[holder.id][company.id] !== undefined && 
                acceptDeclarations[holder.id][company.id]['declarations'][declarations.id] !== undefined) 
            {
                clickedBtn = acceptDeclarations[holder.id][company.id]['declarations'][declarations.id].type;
                selectedAilments = acceptDeclarations[holder.id][company.id]['declarations'][declarations.id] ;
            }
            return (
              <DeclarationButton
                  key={i}
                  declarations={declarations}
                  clickedBtn={clickedBtn}
                  onClickYesNo={(item,holder ,type) => this.onClickYesNo(item, holder, type)}
                  onAilmentSelect= {(item, holder) => this.onAilmentSelect(item, holder)}
                  selectedAilments={selectedAilments}
                  channel={channel}
                  holder={holder}
              />
            );
          })}
          <div className="accept-final">
            <p>
              The person to be insured declares the information about self is
              true.
            </p>
            <button
                className={`btn ${ agreed ? "btn-success" : "deactivated"}`}
                onClick={() => this.onClickDeclare(company, holder, "declare")}
            >
              Agree
            </button>
          </div>
          {this.renderAgentDetails(channel)}
        </div>
      );
    });
  };

  clickHolder(i) {


    this.setState({activeHolder: i});
  
  }

  renderDeclarations() {
    let activeHolder = this.state.activeHolder;
    const {checkedCompanies} = this.props;
    if (checkedCompanies[activeHolder] === undefined) activeHolder = 0;
    let holders = this.props.checkedCompanies.map((holder, i) => {

      let active = (activeHolder === i ? "active" : "");
      return (
          <div key={i} className="policy-holder col-md-2 col-xs-3" onClick={() => this.clickHolder(i)}>

            <div className="avatar">
              <img src={require("../../assets/ci/add-user-icon.png")} alt="" />
            </div>
            <div className={`user-title`}>
              <p className={active}>{holder.holder.nameInitials}</p>
              <p className={`relation ${active}`}>
                {holder.holder.relationship.description}
              </p>
            </div>
          </div>
      );
    });
    return (
        <div className="container-declarations">
          <div className="holders-scroll-bar row">
            {holders}
          </div>
          {this.renderItem()}

        </div>
    );

  }


  onClickDeclare = (company, holder) => {
    let valid =true;
    const {channel} = this.props;
    let {acceptDeclarations} = this.state;
   
    if (acceptDeclarations[holder.id] === undefined) {
      acceptDeclarations[holder.id] = {};
    }

    if (acceptDeclarations[holder.id][company.id] === undefined) {
      acceptDeclarations[holder.id][company.id] = {agree: false, declarations: []};
    }
        
    let declaredAsNo =  Object.values(acceptDeclarations[holder.id][company.id]['declarations']).filter( (i) => {
      if (i.type === 'no') {
          return true;
        }                   
    });
    
    if(declaredAsNo.length > 0 && channel === MI_INME_CHANNEL) {
           
        for(let i in declaredAsNo) {
          let agreed = false;
         
          if(declaredAsNo[i].HasOptions === true && (declaredAsNo[i].remarks.length > 0 || declaredAsNo[i].specialNote !== "")) {
             
              agreed = true;
            }else{
              agreed = false;
            }
            valid  = valid && agreed;
        } 
        
    }else if(declaredAsNo.length > 0){
      valid = false;
    }

    acceptDeclarations[holder.id][company.id]['agree'] = valid;
    this.setState({acceptDeclarations});
  };

  onClickYesNo(item, holder, type) {
    
    const {acceptDeclarations} = this.state;
    if (acceptDeclarations[holder.id] === undefined) {

      acceptDeclarations[holder.id] = {};
    }
    if (acceptDeclarations[holder.id][item.company_id] === undefined) {
      acceptDeclarations[holder.id][item.company_id] = {
        agree: false, 
        declarations: {}
      };

    }
    
    let declaration = {
      id: item.id,
      HasOptions: item.options !== null ? true : false,
      type:type,
      remarks: [],
      specialNote: ''
    };

    if ( acceptDeclarations[holder.id][item.company_id]["declarations"][item.id] === undefined)  {
      acceptDeclarations[holder.id][item.company_id]["declarations"][item.id] =  declaration;
    }
    
    acceptDeclarations[holder.id][item.company_id]['agree'] = false;
    Object.assign(acceptDeclarations[holder.id][item.company_id]["declarations"][item.id], declaration);
  
    this.setState({acceptDeclarations});
  }

  onAilmentSelect(selectedAilment, holder) {

    const {acceptDeclarations}  = this.state;
    
    const declarations = acceptDeclarations[holder.id][selectedAilment.company_id]['declarations'][selectedAilment.id];
    
    const ailmentIndex = declarations.remarks.indexOf(selectedAilment.selectedAilment);
    
    if (selectedAilment.isChecked === true && ailmentIndex < 0) {
      declarations.remarks.push(selectedAilment.selectedAilment);  
    }
    
    if(selectedAilment.isChecked === false && selectedAilment.isNote === false) {
      declarations.remarks.splice(ailmentIndex , 1);
    }
    
    if(selectedAilment.isNote === true){
      declarations.specialNote = selectedAilment.specialNote;
    }
    
    acceptDeclarations[holder.id][selectedAilment.company_id]['agree'] =  false;
    Object.assign(acceptDeclarations[holder.id][selectedAilment.company_id]['declarations'], declarations);
    
    this.setState({acceptDeclarations});
  }


  onChangeHandler = (e) => {
    let {agentEmail, isAgentEmailValid} = this.state;
    let email = e.target.value;
    isAgentEmailValid = validateEmail(email);
    agentEmail = email;
    this.setState({agentEmail, isAgentEmailValid});

  }


  renderAgentDetails = (channel) => {
    if(channel === MI_INME_CHANNEL) {
      return(
        <>
          <hr />
          <div className="row">
              <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="agent-email">Agent Email</label>
                    <input type="email" className="form-control" name="agent-email" id="agent-email" placeholder="john@insureme.lk" onChange={(e) => this.onChangeHandler(e)} maxLength="20" />
                    <div className="error-message" style={{color: 'red'}}>
                      {this.state.isAgentEmailValid === false ? <p>You have entered an invalid email address!</p> : null}
                    </div>
                  </div>
              </div>
          </div>
        </>
      )
    }

    return null;
  }

  async handleBuyCover(agentEmail) {
    this.setState({
      loading: true
    });
    let buyCoverFunctn = this.props.buyCoverFn;
    if (buyCoverFunctn === undefined || typeof buyCoverFunctn !== "function") {
      this.setState({
        loading: false
      });
      return;
    }

    let declarations = this.state.acceptDeclarations;

    await buyCoverFunctn(declarations, agentEmail);
  
  }

  getEnabledStatus() {
    let holders = this.props.holders,
        acceptDeclarations = this.state.acceptDeclarations,
        loading = this.state.loading,
        companies = this.props.companies,
        valid = true;
 
    for (let i in holders) {
      let holder = holders[i];
        for (let j in holder.plans) {
          let plan = holder.plans[j],
              company = companies.find((item) => item.id === plan.companyId);
          if (
              acceptDeclarations[holder.id] === undefined ||
              acceptDeclarations[holder.id][company.id] === undefined || 
              acceptDeclarations[holder.id][company.id]['agree'] !== true ||
              Object.keys(acceptDeclarations[holder.id][company.id]['declarations']).length < company.declarations.length
              ) {
            valid = false;
          }else{
            valid = valid && acceptDeclarations[holder.id][company.id]['agree'];
          }

       }
      
     }
    if (loading) {
      valid = false
    }
    return valid ;
  }

  modalCloseClickHandler = () => {
      this.setState({acceptDeclarations:[]});
  }

  render() {
    
    const {holders} = this.props;
    const {loading,agentEmail} = this.state;
    const checkedCompanies = this.props.checkedCompanies,
        enabledSubmit = this.getEnabledStatus();
    return (
      <div
        className={`modal fade}`}
        id="declaration-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Declaration</h5>
              <button
                type="button"
                style={{marginTop: "-20px"}}
                onClick={() => this.modalCloseClickHandler()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body declarations-modal">
              {checkedCompanies.length === 0 ? (
                <h3>No Company Selected</h3>
              ) : (
                <div>
                  {this.renderDeclarations(holders)}
                  <div className="row buy-cover">
                    <button
                        className="btn"
                        disabled={!enabledSubmit}
                        onClick={() => this.handleBuyCover(agentEmail)}
                    >
                      Buy Cover
                      {loading ? (
                        <ReactLoading type={"spin"} color={"#fff"} width={20} />
                      ) : null}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DeclarationModal;
