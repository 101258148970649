import React, {Component} from "react";
// import ReactDOM from "react-dom";
// import {Provider} from "react-redux";
// import {BrowserRouter, Switch, Route} from "react-router-dom";
import Button from "../components/Button";
import HeaderTitle from "../components/HeaderTitle";
import Jumbotron from "../components/Jumbotron";
import FeatureCard from "../components/FeatureCard";
import ButtonGroup from "../components/ButtonGroup";
import Collapse from "../components/Collapse";
import {Alert, MonthYear} from "../components";

import "../index.scss";

const MOTOR_LIST = [
  {
    title: " Lorem ipsum dolor sit amet,consecteturr adipiscing elit.",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eaLorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea`
  }
];

export default class App extends Component {
  viewData = () => {
    window.open("/home/home.js");
  };
  handle = v => {};
  render() {
    return (
      <div>
        <a href="/">HOME</a>
        <br />
        <a href="/SearchResultsBlog">SearchResultsBlog</a>
        <br />
        <a href="/SearchResultsFAQ">SearchResultsFAQ</a>
        <br />
        <a href="/SearchResultsProduct">SearchResultsProduct</a>
        <br />
        <a href="/faq">faq</a>
        <br />
        <a href="/emailtm">Email</a>
        <br />
        <a href="/Step1">Step1</a>
        <br />
        <a href="/Step2">Step2</a>
        <br />
        <a href="/Step3">Step3</a>
        <br />
        <a href="/Step4">Step4</a>
        <br />
        <a href="/Step5">Step5</a>
        <br />
        <a href="/Step6">Step6</a>
        <br />
        <a href="/Step7">Step7</a>
        <br />
        <HeaderTitle mainWord="Alert" restOfTitle="alerts" subTitle={""} />
        <br />
        <br />
        <Alert type="danger" message="Wrong authentication credentials" />
        <HeaderTitle
          mainWord="UI"
          restOfTitle="library"
          subTitle={"You can import this component from components/HeaderTitle"}
        />
        <br />
        <br />
        <HeaderTitle
          mainWord="Button"
          restOfTitle="Components"
          subTitle={"You can import this component from components/Button"}
        />
        <br />
        <Button type="" color="blue">
          Compare Now
        </Button>
        <Button type="" color="red">
          Compare Now
        </Button>
        <Button type="" color="yellow">
          Compare Now
        </Button>
        <br />
        <Button type="radius" color="blue">
          Compare Now
        </Button>
        <Button type="radius" color="red">
          Compare Now
        </Button>
        <Button type="radius" color="yellow">
          Compare Now
        </Button>
        <br />
        <Button type="rounded" color="blue">
          Compare Now
        </Button>
        <Button type="rounded" color="red">
          Compare Now
        </Button>
        <Button type="rounded" color="yellow">
          Compare Now
        </Button>
        <br />
        <br />
        <br />
        <Button type="rounded" color="blue">
          Compare Now
        </Button>
        <Button type="radius" color="blue">
          Compare Now
        </Button>
        <Button type="" color="blue">
          Compare Now
        </Button>
        <br />
        <Button type="rounded" color="red">
          Compare Now
        </Button>
        <Button type="radius" color="red">
          Compare Now
        </Button>
        <Button type="" color="red">
          Compare Now
        </Button>
        <br />
        <Button type="rounded" color="yellow">
          Compare Now
        </Button>
        <Button type="radius" color="yellow">
          Compare Now
        </Button>
        <Button type="" color="yellow">
          Compare Now
        </Button>
        <br />
        <br />
        <br />
        <Button type="sliced" color="blue">
          Compare Now
        </Button>
        <Button type="sliced" color="red">
          Compare Now
        </Button>
        <Button type="sliced" color="yellow">
          Compare Now
        </Button>
        <br />
        <br />
        <br />
        <Button type="" size="small" color="blue">
          Compare Now
        </Button>
        <Button type="" size="small" color="red">
          Compare Now
        </Button>
        <Button type="" size="small" color="yellow">
          Compare Now
        </Button>
        <br />
        <Button type="radius" size="small" color="blue">
          Compare Now
        </Button>
        <Button type="radius" size="small" color="red">
          Compare Now
        </Button>
        <Button type="radius" size="small" color="yellow">
          Compare Now
        </Button>
        <br />
        <Button type="rounded" size="small" color="blue">
          Compare Now
        </Button>
        <Button type="rounded" size="small" color="red">
          Compare Now
        </Button>
        <Button type="rounded" size="small" color="yellow">
          Compare Now
        </Button>
        <br />
        <Button type="sliced" size="small" color="blue">
          Compare Now
        </Button>
        <Button type="sliced" size="small" color="red">
          Compare Now
        </Button>
        <Button type="sliced" size="small" color="yellow">
          Compare Now
        </Button>
        <br />
        <Button type="radius" outline size="small" color="blue">
          Compare Now
        </Button>
        <Button type="radius" outline size="small" color="red">
          Compare Now
        </Button>
        <Button type="radius" outline size="small" color="yellow">
          Compare Now
        </Button>
        <br />
        <Button type="radius" color="red">
          Clear
        </Button>
        <br />
        <HeaderTitle
          mainWord="Jumbotron"
          restOfTitle="Components"
          subTitle={"You can import this component from components/Jumbotron"}
        />
        <Jumbotron
          title="Frequently Asked Questions"
          subTitle="Here are answers to some of the genaral questiosn"
        />
        <br />
        <br />
        <HeaderTitle
          mainWord="FeatureCard"
          restOfTitle="Components"
          subTitle={"You can import this component from components/FeatureCard"}
        />
        <div className="row">
          <div className="col-md-4">
            <FeatureCard
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
            />
          </div>
          <div className="col-md-4">
            <FeatureCard
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              readMoreUrl="/"
            />
          </div>
          <div className="col-md-4">
            <FeatureCard
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              getAQuoteUrl="/"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <FeatureCard
              shadow
              imageUrl="https://firebasestorage.googleapis.com/v0/b/health-ccc69.appspot.com/o/ads2.bf521623.jpg?alt=media&token=d9b67e63-c132-42d3-b343-2e8fffc234a4"
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              readMoreUrl="/"
            />
          </div>
          <div className="col-md-4">
            <FeatureCard
              shadow
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              getAQuoteUrl="/"
              readMoreUrl="/"
            />
          </div>
          <div className="col-md-4">
            <FeatureCard
              shadow
              header="Hatton National bank"
              paragraph="This month we are featuring our banking partner,hattan National Bank. HNB is offering an exclusive 12 months interest free settlement plan on top pf a 10% discount on your premium"
              getAQuoteUrl="/"
              readMoreUrl="/"
            />
          </div>
        </div>
        <HeaderTitle
          mainWord="ButtonGroup"
          restOfTitle="Components"
          subTitle={"You can import this component from components/ButtonGroup"}
        />
        <ButtonGroup
          label=""
          selected="3 Products"
          onChange={() => this.handle}
          options={[
            {label: "3 Products", value: "3 Products"},
            {label: "1 Blogs", value: "1 Blogs"},
            {label: "3 FAQs", value: "3 FAQs"}
          ]}
        />
        <ButtonGroup
          label=""
          selected="Private"
          onChange={() => this.handle}
          options={[
            {label: "Private", value: "Private"},
            {label: "Hire", value: "Hire"},
            {label: "Rent", value: "Rent"}
          ]}
        />
        <ButtonGroup
          label="Do you have a vehicle registration No?"
          selected="Yes"
          onChange={() => this.handle}
          options={[
            {label: "Yes", value: "Yes"},
            {label: "No", value: "No"}
          ]}
          tooltip
        />
        form-control
        <HeaderTitle
          mainWord="Collapse"
          restOfTitle="Components"
          subTitle={"You can import this component from components/Collapse"}
        />
        <div>
          <h2 className="text-left">Genaral</h2>
        </div>
        {MOTOR_LIST.map((item, index) => {
          return (
            <Collapse
              key={`faq-${index}`}
              index={`${index}`}
              title={item.title}
              content={item.content}
            />
          );
        })}
        <MonthYear
          id="lease_exp_date"
          onChange={e => this.handle}
          minDate={new Date()}
          maxDate={new Date("2020-10-10")}
        />
        <span id="best-deal-icon" data-toggle="modal" data-target="#myModal">
          Card Offer
        </span>
      </div>
    );
  }
}
