import React, {Component} from "react";

export class Dropdown extends Component {
  render() {
    let error = this.props.errorText,
      selected = this.props.value,
      disabled = this.props.disabled

    return (
      <div className="form-group">
        <label className="nuclei-input-label" />
        <select
          disabled={disabled}
          onChange={this.props.onChange}
          value={selected}
          className={`form-control  ${
            this.props.white ? "nuceli-white-box" : ""
          } ${error ? "form-input-has-error" : ""} `}
        >
          {this.props.options.map((option, i) => (
            <option
              key={i}
              className="nuclei-option"
              value={option.value}
              // selected={option.value === selected}
              // defaultValue={option.value === selected? option.value :null}
            >
              {option.label}
            </option>
          ))}
        </select>
        {error ? <p className="form-input-error">{error}</p> : null}
     {/* below commented code here */}
      </div>
    );
  }
}
