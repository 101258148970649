import React, {Component} from "react";
import {Overlay} from "../../components";
import Slider from "react-rangeslider";
import {ASSETS_URL} from "../../config";
import {connect} from "react-redux";
import {getBalanceForNic, setpolicyHolderDetails} from "../../redux/actions";
import {formatNumber} from "../../util";

class Companies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSelected: false,
            currentValue: 0,
            updateBalanceCall: null,
        };
    }

    componentDidMount() {
        this.updateSlider(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateSlider(nextProps);
    }

    updateSlider(props) {
        let currentHolder = props.currentHolder;
        let balances = props.policyHolderBalanceList[currentHolder.nicNumber];
        let company = props.companies;
        let getBalanceLoading = props.getBalanceLoading;
        let planSelected = props.currentHolder.plans.find((plan) => plan.companyId === company.id);

        if (balances === undefined && currentHolder.nicNumber.length > 8 && getBalanceLoading === false) {
            this.updateBalance(props);
        }
        let balance = balances && balances[company.id] ? balances[company.id] : 0,
            selectSlider = true,
            limitSelected = null;
        if (balance >= 1000000) {
            selectSlider = false;
            limitSelected = {cover: 0};
        } else {
            let maximumLimit = company.limits.find((limit) => limit.cover + balance === 1000000);
            if (planSelected === undefined && maximumLimit !== undefined) {
                selectSlider = true;
                limitSelected = maximumLimit;
            } else {
                let selectedLimit = company.limits.find((limit) => limit.id === planSelected.limitId);
                if (selectedLimit !== undefined && maximumLimit !== undefined && selectedLimit.cover > maximumLimit.cover) {
                    selectSlider = true;
                    limitSelected = maximumLimit;
                } else {
                    selectSlider = true;
                    limitSelected = selectedLimit;
                }
            }
        }
        this.props.setpolicyHolderDetails(
            {
                companyId: company.id,
                limitId: selectSlider && limitSelected !== null && limitSelected !== undefined && limitSelected['id'] !== undefined ? limitSelected.id : null
            },
            selectSlider,
            "company",
            currentHolder.id
        );
        this.setState({
            isSelected: selectSlider,
            currentValue: selectSlider && limitSelected !== null && limitSelected !== undefined ? limitSelected.cover : 0
        });
    }

    updateBalance(props) {
        let updateBalanceCall = this.state.updateBalanceCall;
        if (updateBalanceCall !== null) {
            clearTimeout(updateBalanceCall);
        }
        updateBalanceCall = setTimeout(() => {
            this.props.getBalanceForNic(props.currentHolder.nicNumber);
        }, 2000);
        this.setState({
            updateBalanceCall: updateBalanceCall
        });
    }

    getBalance() {
        let policyHolderBalanceList = this.props.policyHolderBalanceList,
            nicNumber = this.props.currentHolder.nicNumber,
            balance = 0,
            companyId = this.props.companies.id;
        if (policyHolderBalanceList !== undefined && policyHolderBalanceList[nicNumber] !== undefined && policyHolderBalanceList[nicNumber][companyId] !== undefined) {
            balance = policyHolderBalanceList[nicNumber][companyId];
        }

        return balance;
    }

    onChangeRangeSlider = (value) => {
        let company = this.props.companies;
        let balance = this.getBalance();
        let isSelected = this.state.isSelected;
        if (balance + value > 1000000 || !isSelected) return;
        let selectedLimit = company.limits.find((limit) => limit.cover === value);
        if (selectedLimit !== undefined) {
            this.props.setpolicyHolderDetails(
                {companyId: company.id, limitId: selectedLimit.id},
                true,
                "company",
                this.props.currentPolicyHolderId
            );
            this.setState({
                isSelected: true,
                currentValue: selectedLimit.cover
            });
        }

    };

    onSelectCompany() {
        try {
            let company = this.props.companies;
            let balance = this.getBalance();
            let plan = this.props.currentHolder.plans.find((i) => i.companyId === company.id);
            let isSelected = this.state.isSelected;
            if (isSelected) {
                this.props.setpolicyHolderDetails({companyId: company.id}, false, "company", this.props.currentPolicyHolderId);
                this.setState({
                    isSelected: false,
                    currentValue: 0
                });
            } else {
                let maximumLimit = company.limits.find((limit) => limit.cover + balance === 1000000);
                if (maximumLimit) {
                    plan = {
                        companyId: company.id,
                        limitId: maximumLimit.id,
                    };
                    this.props.setpolicyHolderDetails(plan, true, "company", this.props.currentPolicyHolderId);
                    this.setState({
                        isSelected: true,
                        currentValue: maximumLimit.cover
                    });
                }
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    renderAlert(balance, max) {
        if (balance > 0 && balance < max) {
            return (
                <label className="nuclei-input-label company-hint-for-balnce text-left">
                    currently you are already covered for {formatNumber(balance, true, "Rs. ")}.
                    Maximum cover allowed is {formatNumber(max, true, "Rs. ")}
                </label>
            );
        } else if (balance > 0 && balance >= max) {
            return (
                <label className="nuclei-input-label company-hint-for-balnce text-left">Sorry you cannot purchase this
                    cover since you already have a policy(or policies) up to {formatNumber(max, true, "Rs. ")}</label>
            );
        }

    }

    getMinMax() {
        let compnay = this.props.companies;
        let range = {
            min: 0,
            max: 0
        }
        try {
            range.min = compnay.limits[0].cover;
            range.max = compnay.limits[compnay.limits.length - 1].cover;
        } catch (e) {
        }
        return range;
    }

    render() {
        let company = this.props.companies;
        let balance = this.getBalance();
        let range = this.getMinMax();
        let isSelected = this.state.isSelected;
        let lables = {};
        let isDisabled = balance >= 1000000;
        let currentValue = this.state.currentValue;
        lables[range.min] = formatNumber(range.min, true, "Rs. ");
        lables[range.max / 2] = (
            <p style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "#60ec60",
                marginBottom: "5px"
            }}>
                {formatNumber(currentValue, true, "Rs. ")}
            </p>
        );
        lables[range.max] = formatNumber(range.max, false, "Rs. ");
        let logo_url = ASSETS_URL + company.company_logo;
        return (
            <div className="row slider">
                <div className="col-sm-12 col-md-12 col-xs-12">
                    <div className="col-sm-3 col-md-3 col-xs-3">
                        <div
                            className={`logo-container ${isSelected ? "active" : ""}`}
                            onClick={() => this.onSelectCompany()}>
                            {isDisabled ? <Overlay /> : null}
                            <img src={logo_url} alt="" />
                        </div>
                    </div>
                    <div className="col-sm-9 col-md-9 col-xs-9">
                        <Slider
                            min={range.min}
                            max={range.max}
                            step={100000}
                            reverse={false}
                            tooltip={false}
                            value={currentValue}
                            format={(i) => formatNumber(i, false, "Rs. ")}
                            labels={lables}
                            orientation="horizontal"
                            onChange={value => this.onChangeRangeSlider(value)}
                        />
                    </div>
                </div>
                {this.renderAlert(balance, range.max)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentPolicyHolderId: state.ci.currentPolicyHolderId,
        getBalanceLoading: state.ci.getBalanceLoading,
        policyHolderBalanceList: state.ci.policyHolderBalanceList
    };
};

export default connect(
    mapStateToProps,
    {
        setpolicyHolderDetails,
        getBalanceForNic
    }
)(Companies);
