import {compose, createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import { sessionService, sessionReducer } from 'redux-react-session';

import rootReducer from "./reducers";

const middleware = applyMiddleware(thunk);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enHanceCreateStore = composeEnhancers(middleware)(createStore);
const theStore = enHanceCreateStore(rootReducer);
sessionService.initSessionService(theStore, { driver: 'COOKIES' });

export const store = theStore;

