import React, {Component} from "react";
import "./SliderButtons.scss";
import "../animate.scss";

import Button from "../Button";
import {Link} from "react-router-dom";

export default class SliderButtons extends Component {
  render() {
    return (
      <div className="nuclei-slider-caption ">
        <div className="">
          <Link
            to={
              this.props.sliderDetail.headerUrl
                ? this.props.sliderDetail.headerUrl
                : ""
            }
          >
            <Button
              type="sliced"
              size="small"
              color="red"
              className="nuclei-slider-main-button"
            >
              {this.props.sliderDetail.header}
            </Button>
          </Link>
          <p className="nuclei-slider-description">
            {this.props.sliderDetail.description}
          </p>
          <div className="nuclier-yellow-bottom-button">
            <Link
              to={
                this.props.sliderDetail.bottomTextUrl
                  ? this.props.sliderDetail.bottomTextUrl
                  : ""
              }
            >
              <Button
                type="rounded"
                color="yellow"
                className="nuclei-slider-bottom-button"
              >
                {this.props.sliderDetail.bottomText}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
