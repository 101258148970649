import React, {Component} from "react";
import "./UserDetail.scss";

import BlogImage from "./BlogImage";

export default class UserDetail extends Component {
  render() {
    return (
      <div>
        <div className="nuclei-blog-caption">
          <div className="nuclei-caption">
            <div className="nuclei-user-details">
              <div className="nuclei-user-image">
                <BlogImage
                  imgUrl={this.props.userImgUrl}
                  width="100px"
                  height="100px"
                  type="rounded"
                  className="user-img"
                />
              </div>
              <div className="nuclei-user-info-block">
                <div className="nuclei-user-heading">
                  <p className="user-comment">{this.props.comment}</p>
                  <h4 className="user-name">{this.props.userName}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
