import React from "react";
import "./Button.scss";

export const Button = props => {
  let {
    children,
    disabled,
    className = "",
    color = "blue",
    type = "square",
    size,
    outline = "",
    onSubmit,
    style = {}
  } = props;
  let newClasses = "";
  let options = {};

  if (size) {
    newClasses = `${newClasses} button-${size} `;
  }
  if (outline) {
    newClasses = `${newClasses} button-outline `;
  }

  if(disabled && disabled === true){
    options['disabled'] = true;
  }
  return (
    <button
      // {...props}
        {...options}
      className={`nuclei-button button-${color} button-${type} ${newClasses} ${className}`}
      onClick={props.onClick}
      style={style}
      onSubmit={e => {
        return onSubmit(e);
      }}
    >
      {children}
    </button>
  );
};

export default Button;
