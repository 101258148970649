import React, {Component} from "react";
import "./index.scss";
import Dropzone from "./dropzone/Dropzone";
import Progress from "./progress/Progress";
import {Icon} from 'react-icons-kit';
import {bin} from 'react-icons-kit/icomoon/bin'
import {ic_remove_red_eye} from 'react-icons-kit/md/ic_remove_red_eye'
import {Modal, Image} from "antd";
import pdfImage from '../../assets/pdf.png';

export class FileInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            file: null,
            previewFile: null,
            progress: 0,
            selectedFile: null,
            zoomIn: false,
        };
    }

    async upload() {
        this.setState({
            uploading: true
        });
        try {
            let data = new FormData(),
                file = this.state.file;
            await data.append('asset', file);
            await data.append('id', this.props.id);
            await data.append('hash_code', this.props.hash_code);
            await this.props.onPressUpload(data);
        } catch (e) {
            console.log(e.message);
        }
        this.setState({
            uploading: true
        });
    }

    async onFilesAdded(file) {
        await this.setState({
            file: file,
            previewFile: URL.createObjectURL(file)
        })
        await this.upload()
    }

    returnPreviewFile(file){
        if (this.state.file.type.includes('pdf'))return pdfImage;
        return file;
    }

    renderDropZone() {
        return (
            <div className={'dropzone-container'}>
                <Dropzone
                    icon={this.state.previewFile !== null ? this.returnPreviewFile(this.state.previewFile) : this.props.icon}
                    onFilesAdded={(file) => this.onFilesAdded(file)}
                    disabled={this.state.uploading}
                    title={this.props.title}
                    allowedFileTypes={this.props.allowedFileTypes}
                />
                {this.state.uploading ? (
                    <div className={'progress-wrapper'}>
                        <Progress progress={this.state.progress}/>
                    </div>
                ) : null}

            </div>
        );
    }

    renderUploadedView() {
        return (
            <div className={'dropzone-container'}>
                {this.renderPreview(this.props.title ? this.props.title : 'Uploaded File')}
                {this.props.status < 2 ? (
                    <div className={'action-wrapper'}>
                        <button className={'remove-image'} onClick={() => {
                            if (this.props.onPressRemove) {
                                this.props.onPressRemove(this.props.id)
                            }
                        }}><Icon icon={bin}/>
                        </button>
                    </div>
                ) : null}
            </div>
        );
    }

    renderPreview(title) {
        if (["jpg", "png", "gif", "jpeg", "JPG", "PNG", "GIF", "JPEG"].includes(this.props.uploadedURL.split('.').pop())) {
            return (
                <>
                    <Image
                        width={200}
                        id="uploadedImage"
                        src={this.props.uploadedURL}
                    />
                    <p className={'upload-title'}>{title}</p>
                </>
            )
        } else {
            return (
                <>
                    <embed id="uploadedPdf" src={this.props.uploadedURL} type="application/pdf"/>
                    <div className="ant-image-mask" onClick={()=>this.onClickPreview(this.props.uploadedURL)}>
                        <Icon icon={ic_remove_red_eye} className={'preview-icon'}/>Preview
                    </div>
                    <p className={'upload-title'}>{title}</p>
                </>
            )
        }
    }

    render() {
        return (
            <div className={'upload-container'}>
                {this.props.uploadedURL ? this.renderUploadedView() : this.renderDropZone()}
                {this.zoomInPreview()}
            </div>
        );
    }

    onClickPreview(file) {
        this.setState({
            zoomIn: true,
            selectedFile: file
        })
    }

    zoomInPreview() {
        const fileType = this.state.selectedFile ? this.state.selectedFile.split('.').pop() : null;
        return (
            <Modal width={1000} visible={this.state.zoomIn} className={'zoom-in-modal'} footer={null}
                   onCancel={() => this.setState({zoomIn: false})}>

                {
                    fileType === 'PDF' || fileType === 'pdf' ?
                        <embed className={'preview'}
                               src={this.state.selectedFile} type="application/pdf"/> :
                        {}
                }

            </Modal>
        )
    }
}
