import {getInquiryCookie} from "../../util";

// const SET_VALUE = "SET_VALUE";
const GET_VALUES = "GET_VALUES";
const LOCAL_STORE_INME = "motor.insureme.lk";

const INIT_STATE = {
  sum_insured: "",
  vehicle_type: "Car",
  insurance_type: "full-insurance",
  purpose_of_use: "Private",
  hire_purchase: "YES",
  is_registered: "R",
  vehicle_make: "",
  vehicle_model: "",
  fuel_type: "",
  manufacture_year: "",
  seating_capacity: "0",
  ncb: 0,
  bank_name: "ONLINE",
  bank_branch: "ONLINE",
  bank_user: "ONLINE",
  customer_name: "ONLINE",
  cx_inquiry_id: getInquiryCookie().cx_inquiry_id || "",
  user_id: "",
  cx_user_id: "",
  cx_name: "",
  cx_email: "",
  cx_mobile: "",
  cx_message: "",
  inquiry_id: "",
  inquiry_type: "comparison_request",
  inquiry_media: "web",
  inquiry_code: "webonline",
  unq_hash_code: ""
};

export const setLocalItem = (key, value) => dispatch => {
  let obj = INIT_STATE;
  try {
    obj = JSON.parse(localStorage.getItem(LOCAL_STORE_INME)) || INIT_STATE;
  } catch (error) {}
  obj[key] = value;
  localStorage.setItem(LOCAL_STORE_INME, JSON.stringify(obj));
  return dispatch({type: GET_VALUES, payload: obj});
};

export const replaceLocal = value => dispatch => {
  let obj = INIT_STATE;
  try {
    obj = JSON.parse(localStorage.getItem(LOCAL_STORE_INME)) || INIT_STATE;
  } catch (error) {}
  obj = {...obj, ...value};

  localStorage.setItem(LOCAL_STORE_INME, JSON.stringify(obj));
  return dispatch({type: GET_VALUES, payload: obj});
};

export const getLocalItems = () => async dispatch => {
  let obj = INIT_STATE;
  try {
    obj = JSON.parse(localStorage.getItem(LOCAL_STORE_INME)) || INIT_STATE;
  } catch (error) {}
  return dispatch({type: GET_VALUES, payload: obj});
};

export const removeLocalItem = key => async dispatch => {
  let obj = INIT_STATE;
  try {
    obj = JSON.parse(localStorage.getItem(LOCAL_STORE_INME)) || INIT_STATE;
  } catch (error) {}
  delete obj[key];
  localStorage.setItem(LOCAL_STORE_INME, JSON.stringify(obj));
  return dispatch({type: GET_VALUES, payload: obj});
};

export const clearAll = () => async dispatch => {
  let obj = INIT_STATE;
  try {
    localStorage.removeItem(LOCAL_STORE_INME);
  } catch (error) {}
  return dispatch({type: GET_VALUES, payload: obj});
};

export default function reducer(state = INIT_STATE, action) {
  const {type, payload} = action;

  switch (type) {
    case GET_VALUES:
      return {...state, ...payload};
    default:
      return state;
  }
}
